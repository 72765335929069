import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { ActivitiesForToday, GetStatistics, GetUserIssues } from 'services/contracts/Overview.contract';
import { listPendingIssuesByClientManagar } from 'services/Issue.service';
import { listPendingMaintenancesByClientManagar } from 'services/Maintenance.service';
import { getActivitiesForToday, getStatistics, getUserIssues } from 'services/Overview.service';

type Loading = 'pendingIssues' | 'pendingMaintenances' | 'indicators' | 'activities' | 'user_issues';

type PendingIssue = Issue.With<
    | 'user'
    | 'user_who_resolved'
    | 'user_responsible'
    | 'supplier'
    | 'issueType'
    | 'client',
    Issue.PendingModel
>;

type PendingMaintenance = Maintenance.PendingModel & { client: Client.Model };

export type OverviewContextValue = {
    loadings: Loading[],

    fetchPendingIssues: () => Promise<void>,

    pendingIssues: PendingIssue[],
    pendingMaintenances: PendingMaintenance[],

    isCreateIssueModalVisible: boolean,
    setIsCreateIssueModalVisible: Dispatch<SetStateAction<OverviewContextValue['isCreateIssueModalVisible']>>,

    isIssueDetailsModalVisible: boolean,
    setIsIssueDetailsModalVisible: Dispatch<SetStateAction<OverviewContextValue['isIssueDetailsModalVisible']>>,

    pendingIssueId: PendingIssue['id'] | null,
    setPendingIssueId: Dispatch<SetStateAction<OverviewContextValue['pendingIssueId']>>,

    pendingIssue: PendingIssue | null,

    generalStatistics: GetStatistics.Data,
    activitiesForToday: ActivitiesForToday.Data[],
    userIssues: GetUserIssues.Data[],
};

type Props = { children: (value: OverviewContextValue) => ReactNode };

const OverviewContext = createContext<OverviewContextValue | null>(null);

/**
 * @see https://www.youtube.com/watch?v=I7dwJxGuGYQ
 * @todo Abstract `loadings` state updates (Add/Remove) with `useReducer`
 */
export function OverviewContextProvider({ children }: Props) {

    const [loadings, setLoadings] = useState<OverviewContextValue['loadings']>(['indicators', 'pendingMaintenances', 'pendingIssues', 'activities', 'user_issues']);
    const [pendingIssues, setPendingIssues] = useState<OverviewContextValue['pendingIssues']>([]);
    const [pendingMaintenances, setPendingMaintenances] = useState<OverviewContextValue['pendingMaintenances']>([]);

    const [isCreateIssueModalVisible, setIsCreateIssueModalVisible] = useState(false);
    const [isIssueDetailsModalVisible, setIsIssueDetailsModalVisible] = useState(false);

    const [pendingIssueId, setPendingIssueId] = useState<OverviewContextValue['pendingIssueId']>(null);

    const [generalStatistics, setGeneralStatistics] = useState<GetStatistics.Data>({} as GetStatistics.Data);
    const [activitiesForToday, setActivitiesForToday] = useState<ActivitiesForToday.Data[]>([]);
    const [userIssues, setUserIssues] = useState<GetUserIssues.Data[]>([]);

    const app = App.useApp();

    const fetchPendingIssues = async () => {
        setLoadings(prevState => [...prevState, 'pendingIssues']);

        const response = await listPendingIssuesByClientManagar();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'pendingIssues')]);

        if (!response.success)
            return handleServiceError(app, response);

        setPendingIssues(response.issues);
    };

    const memoizedFetchPendingIssues = useCallback(fetchPendingIssues, [app]);

    const fetchPendingMaintenances = async () => {
        setLoadings(prevState => [...prevState, 'pendingMaintenances']);

        const response = await listPendingMaintenancesByClientManagar();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'pendingMaintenances')]);

        if (!response.success)
            return handleServiceError(app, response);

        setPendingMaintenances(response.maintenances);
    };

    const memoizedFetchPendingMaintenances = useCallback(fetchPendingMaintenances, [app]);

    const fetchGeneralStatistics = async () => {
        setLoadings(prevState => [...prevState, 'indicators']);

        const response = await getStatistics();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'indicators')]);

        if (!response.success)
            return handleServiceError(app, response);

        setGeneralStatistics(response.data);
    };

    const memoizedFetchGeneralStatistics = useCallback(fetchGeneralStatistics, [app]);

    const fetchActivitiesForToday = async () => {
        setLoadings(prevState => [...prevState, 'activities']);

        const response = await getActivitiesForToday();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'activities')]);

        if (!response.success)
            return handleServiceError(app, response);

        setActivitiesForToday(response.activities);
    };

    const memoizedFetchActivitiesForToday = useCallback(fetchActivitiesForToday, [app]);

    const fetchUserIssues = async () => {
        setLoadings(prevState => [...prevState, 'user_issues']);

        const response = await getUserIssues();

        setLoadings(prevState => [...prevState.filter(loading => loading !== 'user_issues')]);

        if (!response.success)
            return handleServiceError(app, response);

        setUserIssues(response.issues);
    };

    const memoizedFetchUserIssues = useCallback(fetchUserIssues, [app]);

    useEffect(() => {
        memoizedFetchPendingIssues();
        memoizedFetchPendingMaintenances();
        memoizedFetchGeneralStatistics();
        memoizedFetchActivitiesForToday();
        memoizedFetchUserIssues();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const pendingIssue = pendingIssues.find(issue => issue.id === pendingIssueId) ?? null;

    const value: OverviewContextValue = {
        loadings,

        fetchPendingIssues: memoizedFetchPendingIssues,

        pendingIssues,
        pendingMaintenances,

        isCreateIssueModalVisible,
        setIsCreateIssueModalVisible,

        isIssueDetailsModalVisible,
        setIsIssueDetailsModalVisible,

        pendingIssueId,
        setPendingIssueId,

        pendingIssue,

        generalStatistics,
        activitiesForToday,
        userIssues
    };

    return (
        <OverviewContext.Provider value={value}>
            {children(value)}
        </OverviewContext.Provider>
    );
}

export function useOverview() {
    const context = useContext(OverviewContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `OverviewContextProvider`.');

    return context;
}
