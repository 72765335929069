import React, { useMemo } from 'react';

import { Button, Divider, Modal } from 'antd';

import List from 'components/_Common/List';
import { Dayjs } from 'dayjs';

import { getCurrentBookingDateColumns } from './CurrentBookingDateColumns';

type Props = {
    bookingDate: Dayjs | null,
    currentBookingDateReserves: any[] | null,
    handleClose: () => void,
    openNewReserveModal: () => void,
    fetchReserves: () => void,
};

export function CurrentBookingDateReservesModal({
    bookingDate,
    currentBookingDateReserves, 
    handleClose,
    openNewReserveModal,
    fetchReserves,
}: Props) {
    if (!bookingDate)
        throw new TypeError('The `bookingDate` prop value is unknown!');

    if (!currentBookingDateReserves)
        throw new TypeError('The `currentBookingDateReserves` prop value is unknown!');

    const columns = useMemo(() => getCurrentBookingDateColumns(fetchReserves), [fetchReserves]);

    return (
        <Modal
            open
            width={610}
            title={`Reservas em ${bookingDate.format('DD/MM/YYYY')}`}
            footer={null}
            onCancel={handleClose}
        >
            <Divider />

            <Button
                type="primary"
                onClick={openNewReserveModal}
                block
            >
                Cadastrar
            </Button>

            <List
                columns={columns}
                data={currentBookingDateReserves}
                showPagination={false}
            />
        </Modal>
    );
}
