import React, { useMemo } from 'react';

import { Card, Tabs } from 'antd';

import List from 'components/_Common/List';
import { DATE_CLIENT_FORMAT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { useSchedule } from 'lib/providers/ScheduleContextProvider';
import { ISSUES_COLUMNS, MAINTENANCES_COLUMNS } from 'pages/schedule/Columns';

export function TodaySchedule() {
    const { date, isLoading, issues, maintenances, isOverviewModule } = useSchedule();

    const filteredIssuesColumns = useMemo(() => (
        !isOverviewModule
            ? ISSUES_COLUMNS.filter(column => column.key !== 'clientName')
            : ISSUES_COLUMNS
    ), [isOverviewModule]);

    const filteredMaintenancesColumns = useMemo(() => (
        !isOverviewModule
            ? MAINTENANCES_COLUMNS.filter(column => column.key !== 'clientName')
            : MAINTENANCES_COLUMNS
    ), [isOverviewModule]);
    
    const items = useMemo(() => [
        {
            key: 'issues',
            label: 'Chamados',
            children: (
                <List
                    columns={filteredIssuesColumns}
                    data={issues}
                    loading={isLoading}
                    size="small"
                />
            )
        },
        {
            key: 'maintenances',
            label: 'Manutenções',
            children: (
                <List
                    columns={filteredMaintenancesColumns}
                    data={maintenances}
                    loading={isLoading}
                    size="small"
                />
            )
        }
    ], [isLoading, issues, maintenances, filteredIssuesColumns, filteredMaintenancesColumns]);

    const content = Authentication.getUserProfile() === 'issue'
        ? (
            <List
                columns={filteredIssuesColumns}
                data={issues}
                loading={isLoading}
                size="small"
            />
        ) : <Tabs items={items} size="small" />;

    return (
        <Card title={date.format(DATE_CLIENT_FORMAT)}>
            {content}
        </Card>
    );
}
