import React, { Component } from 'react';

import { Col, Divider,Form, Input, Modal, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import API from '../../../lib/API';
import { VehicleInterface } from '../../_Common/_Interfaces/Vehicle';

type State = {
    saving: boolean;
};

type Props = {
    vehicle: VehicleInterface;
    apartmentId: number;
    isVisible: boolean;
    hideModalForm: Function;
};

class FormVehicle extends Component<Props, State> {
    state: State = {
        saving: false
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    onCancel = () => this.props.hideModalForm();

    saveItem = async (vehicle: VehicleInterface) => {
        this.setState({ saving: true });

        vehicle.apartmentId = this.props.apartmentId;

        if (this.props.vehicle.id)
            await API.put(`/default/vehicle/${this.props.vehicle.id}`, vehicle);
        else
            await API.post('/default/vehicle', vehicle);

        this.setState({ saving: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    };

    render() {
        const { isVisible, vehicle } = this.props;
        const { saving } = this.state;

        return (
            <Modal
                width={1000}
                centered
                title={vehicle.id ? 'Editar veículo' : 'Novo veículo'}
                destroyOnClose={true}
                open={isVisible}
                confirmLoading={saving}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Divider />
                <Form ref={this.formRef} layout="vertical" initialValues={vehicle} onFinish={this.onOk}>
                    <Row style={{ padding: 0 }}>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="type" label="Tipo de veículo" rules={[{ required: true, message: 'Selecione um tipo de veículo' }]} >
                                <Select>
                                    <Select.Option value='car'>Carro</Select.Option>
                                    <Select.Option value='motorbike'>Moto</Select.Option>
                                    <Select.Option value='other'>Outros</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="brand" label="Marca" rules={[{ required: true, message: 'Digite a marca' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="model" label="Modelo" rules={[{ required: true, message: 'Digite o modelo' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="board" label="Placa" rules={[{ required: true, message: 'Digite a placa' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="tag" label="TAG" rules={[{ required: false, message: 'Digite a TAG' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="apartmentId">
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal >
        );
    }
}

export default FormVehicle;
