import React from 'react';

import { Card, Typography } from 'antd';

import dayjs from 'dayjs';
import { useReport } from 'lib/providers/ReportContextProvider';

export function ModelCard() {

    const {
        loading,
        callSpecificReportMethod
    } = useReport();

    const data: Array<{
        type: 'issue' | 'maintenance' | 'construction' | 'all',
        title: string,
        reports: Array<{
            title: string,
            filters: Report.Filter
        }>
    }> = [
        {
            type: 'issue',
            title: 'Chamados',
            reports: [{
                title: 'Finalizados no mês',
                filters: { isFinished: true, first_date: dayjs().startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
            },
            {
                title: 'Finalizados no mês anterior',
                filters: { isFinished: true, first_date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), clients: null }
            },
            {
                title: 'Finalizados nos últimos 3 meses',
                filters: { isFinished: true, first_date: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
            },
            {
                title: 'Finalizados nos últimos 6 meses',
                filters: { isFinished: true, first_date: dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
            }]
        },
        {
            type: 'maintenance',
            title: 'Manutenções',
            reports: [
                {
                    title: 'Finalizadas no mês',
                    filters: { isFinished: true, first_date: dayjs().startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas no mês anterior',
                    filters: { isFinished: true, first_date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas nos últimos 3 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas nos últimos 6 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                }
            ]
        },
        {
            type: 'construction',
            title: 'Obras',
            reports: [
                {
                    title: 'Finalizadas no mês',
                    filters: { isFinished: true, first_date: dayjs().startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas no mês anterior',
                    filters: { isFinished: true, first_date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas nos últimos 3 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },

                {
                    title: 'Finalizadas nos últimos 6 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                }
            ]
        },
        {
            type: 'all',
            title: 'Chamados e manutenções',
            reports: [
                {
                    title: 'Consolidado de finalizações no mês',
                    filters: { isFinished: true, first_date: dayjs().startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },
                {
                    title: 'Consolidado de finalizações no mês anterior',
                    filters: { isFinished: true, first_date: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), clients: null }
                },
                {
                    title: 'Consolidado de finalizações nos últimos 3 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                },
                {
                    title: 'Consolidado de finalizações nos últimos 6 meses',
                    filters: { isFinished: true, first_date: dayjs().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'), last_date: dayjs().endOf('month').format('YYYY-MM-DD'), clients: null }
                }
            ]
        }
    ];

    return (
        <Card title='Modelos prontos para uso' loading={loading}>
            <Typography.Paragraph>
                Nesta seção, você pode utilizar os modelos prontos e os filtros pré-definidos para gerar automaticamente informações de todos os seus condomínios. Cada relatório será criado como um documento individual específico de cada condomínio e enviado diretamente para o seu e-mail.
            </Typography.Paragraph>
            {
                data.map(model => {
                    return <Typography.Paragraph>
                        <Typography.Title level={5}>{model.title}</Typography.Title>
                        <ul>
                            {
                                model.reports.map(report =>
                                    <li>
                                        <Typography.Link
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => callSpecificReportMethod(model.type, report.filters)}>
                                            {report.title}
                                        </Typography.Link>
                                    </li>
                                )}
                        </ul>
                    </Typography.Paragraph>;
                })
            }
        </Card>
    );
}
