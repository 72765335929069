import React, { useEffect, useState } from 'react';

import { 
    Button,
    Card,
    Descriptions,
    Result, 
    Typography 
} from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { formatBRL } from '../../lib/helpers/BRL';
import { findMonthBudget } from '../../services/BudgetService';

type Props = {
    budget: Budget.Model;
}

const IndexMonthBudget: React.FC<Props> = (props) => {
    const [isSaving, setSaving] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [budgetMonth, setBudgetMonth] = useState<Array<Budget.CostCenterBudgetMonth> | null>(null);

    async function generateBudget() {
        setLoading(true);
        if (!props.budget)
            return;

        // const response = await generateMonthBudget(props.budget.id);
        // notification.config(response);
        // setSaving(true);
    }

    async function getBudgetMonth() {
        setLoading(true);
        const response = await findMonthBudget(props.budget.id, dayjs().date(1).format('YYYY-MM-DD'));
        if ('status' in response)
            return;
        setBudgetMonth(response);
        setLoading(false);
        setSaving(false);
    }

    function renderBudgetMonth() {
        return <Descriptions size='small' title={`Orçamento do mês ${dayjs().date(1).format('MM/YYYY')}`} bordered>
            {budgetMonth?.map(cost_center_month =>
                <>
                    <Descriptions.Item label={cost_center_month.cost_center.name} span={2}>{cost_center_month.cost_center.calculation_type === 'quota'
                        ? 'Quota parte'
                        : 'Unidade'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor mensal">{formatBRL(cost_center_month.amount)}</Descriptions.Item>
                </>
            )}
        </Descriptions>;
    }

    useEffect(() => {
        getBudgetMonth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving]);
    return <Card loading={isLoading}>
        {
            budgetMonth && budgetMonth.length > 0
                ? renderBudgetMonth()
                : <Result
                    status="info"
                    title="Clique no botão abaixo para distribuir os valores de forma mensal"
                    extra={[
                        <Button type="primary" key="console" onClick={() => generateBudget()}>
                            Gerar orçamento
                        </Button>
                    ]}
                >
                    <div className="desc">
                        <Typography.Paragraph>
                            <Typography.Text
                                strong
                                style={{
                                    fontSize: 16
                                }}
                            >
                                Dicas importantes ao gerar um orçamento:
                            </Typography.Text>
                            <Typography.Paragraph><CheckCircleOutlined className="site-result-demo-error-icon" /> Analise as despesas anteriores para identificar padrões e tendências.</Typography.Paragraph>
                            <Typography.Paragraph><CheckCircleOutlined className="site-result-demo-error-icon" /> Inclua todas as despesas relevantes, como manutenção, limpeza, segurança e reparos emergenciais.</Typography.Paragraph>
                            <Typography.Paragraph><CheckCircleOutlined className="site-result-demo-error-icon" /> Reserve um fundo de contingência para lidar com imprevistos.</Typography.Paragraph>
                            <Typography.Paragraph><CheckCircleOutlined className="site-result-demo-error-icon" /> Negocie contratos e fornecedores para obter melhores preços e condições de pagamento.</Typography.Paragraph>
                            <Typography.Paragraph><CheckCircleOutlined className="site-result-demo-error-icon" /> Comunique-se de forma transparente com os condôminos, apresentando o orçamento proposto e recebendo feedback.</Typography.Paragraph>
                        </Typography.Paragraph>
                    </div>
                </Result>
        }
    </Card>;

};

export default IndexMonthBudget;