import React, { Component } from 'react';

import { Col, Divider, Form, Input, Modal, notification,Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';

import dayjs from 'dayjs';

import API from '../../lib/API';
import { createReserve } from '../../services/Reserve.service';
import { ApartmentInterface } from '../_Common/_Interfaces/Apartment';
import { ReserveInterface } from '../_Common/_Interfaces/Reserve';

type State = {
    saving: boolean;
};

type Props = {
    bookingDate: dayjs.Dayjs | null,
    reserve: ReserveInterface;
    apartments: Array<ApartmentInterface>;
    ambients: Ambient.SimpleModel[];
    isVisible: boolean;
    hideModalForm: Function;
};

class FormReserve extends Component<Props, State> {
    state: State = {
        saving: false
    };

    private formRef = React.createRef<FormInstance>();

    onCancel = () => this.props.hideModalForm();

    saveItem = async (reserve: ReserveInterface) => {
        this.setState({ saving: true });

        if (this.props.reserve.id)
            await API.put(`/reserve/${this.props.reserve.id}`, reserve);
        else {
            const body = {
                ambientId: reserve.ambientId,
                apartmentId: reserve.apartmentId,
                bookingDate: reserve.bookingDate.format('YYYY/MM/DD'),
            };
        
            const response = await createReserve(body);

            if (!response)
                notification.warning({ message: 'Atenção', description: 'Este ambiente já foi reservado nesta data.' });
            else if ('status' in response)
                notification.error({ message: 'Erro', description: response.message });
            else
                notification.success({ message: 'Sucesso', description: 'Reserva realizada com sucesso'});
        }

        this.setState({ saving: false }, () => this.props.hideModalForm(true));
    };

    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    };

    render() {
        const { isVisible, apartments, ambients, reserve, bookingDate } = this.props;
        const { saving } = this.state;

        return (
            <Modal
                centered
                title={reserve.id ? 'Editar reserva' : 'Nova reserva'}
                destroyOnClose={true}
                open={isVisible}
                confirmLoading={saving}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form ref={this.formRef} layout="vertical" initialValues={reserve} onFinish={this.onOk}>
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Form.Item name="apartmentId" label="Apartamento" rules={[{ required: true, message: 'Selecione um apartamento' }]} >
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {apartments.map((apartment: ApartmentInterface, key: number) =>
                                        <Select.Option key={key} value={apartment.id}>
                                            {`${apartment.tower.name} - ${apartment.name}`}
                                        </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="ambientId" label="Ambiente" rules={[{ required: true, message: 'Selecione um ambiente' }]} >
                                <Select
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {ambients.map((ambient, key) =>
                                        <Select.Option key={key} value={ambient.id}>
                                            {ambient.name}
                                        </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="bookingDate" initialValue={bookingDate}>
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal >
        );
    }
}

export default FormReserve;
