import React, { useState } from 'react';

import { Divider, Form, Input, InputNumber, Modal, notification, Radio,Select } from 'antd';

import { createCostCenter, updateCostCenter } from '../../services/CostCenterService';


type Props = {
    isOpen: boolean,
    closeForm: Function,
    costCenter: CostCenter.Model | undefined,
    budgetId: Budget.Model['id'] | undefined
};

export type Values = {
    name: CostCenter.Model['name'],
    calculation_type: CostCenter.Model['calculation_type'],
    calculate_for_invoice: CostCenter.Model['calculate_for_invoice'],
    amount: CostCenter.Model['amount'],
    budget_id: CostCenter.Model['budget_id']
};

const FormCostCenter: React.FC<Props> = (props: Props) => {

    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);

    async function onFinish() {
        try {
            setSaving(true);

            const costCenter = await form.validateFields();
            const response = props.costCenter?.id
                ? await updateCostCenter(props.costCenter?.id, costCenter)
                : await createCostCenter(costCenter);

            notification.open(response);
            setSaving(false);
            props.closeForm();
        } catch (error) {
            setSaving(false);
        }
    };

    return (
        <Modal
            centered
            title={props.costCenter?.id ? 'Editar rubrica' : 'Cadastrar rubrica'}
            destroyOnClose={true}
            open={props.isOpen}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={() => props.closeForm()}
            afterClose={() => form.resetFields()}
            okText="Salvar"
        >
            <Divider />
            <Form form={form} layout="vertical" initialValues={props.costCenter} onFinish={onFinish}>
                <Form.Item name="name" label="Nome" tooltip="Por exemplo: Serviços de portaria" rules={[{ required: true, message: 'Digite o nome' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="calculation_type" label="Método de distribuição de custos" tooltip="Você deve analisar sua convenção coletiva e selecionar a opção que se enquadra com o tipo de despesa." rules={[{ required: true, message: 'Selecione uma das opções' }]}>
                    <Select>
                        <Select.Option value="quota">Quota parte</Select.Option>
                        <Select.Option value="unit">Unidade</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="calculate_for_invoice" label="Deve ser distribuído na cota condominial?" tooltip="Caso você deseja somente prever esse custo, marque a opção 'Não'." rules={[{ required: true, message: 'Selecione uma data' }]}>
                    <Radio.Group>
                        <Radio value={false}>Não</Radio>
                        <Radio value={true}>Sim</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="amount" label="Orçamento total do período" tooltip="O valor informado de contabilizar o período total do ano fiscal, o sistema irá gerar os valores mensais para você." rules={[{ required: true, message: 'Digite o valor' }]}>
                    <InputNumber
                        defaultValue={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        addonAfter="R$"
                    />
                </Form.Item>
                <Form.Item name="budget_id" initialValue={props.budgetId} hidden={true} />
            </Form>
        </Modal >
    );

};

export default FormCostCenter;