import API from '../lib/API';
import { CreateCostCenter, FindCostCenter, ListCostCenter, UpdateCostCenter } from './contracts/CostCenterServiceContract';

const route = '/cost_center';

export function listCostCenter(): Promise<ListCostCenter.Response> {
    return API.get(route);
};

export function findCostCenter(id: CostCenter.Model['id']): Promise<FindCostCenter.Response> {
    return API.get(`${route}/${id}`);
};

export function createCostCenter(body: CreateCostCenter.Body): Promise<CreateCostCenter.Response> {
    return API.post(route, body);
};

export function updateCostCenter(id: CostCenter.Model['id'], body: UpdateCostCenter.Body): Promise<UpdateCostCenter.Response> {
    return API.put(`${route}/${id}`, body);
};

export function removeCostCenter() {

};