import React from 'react';
import { useState } from 'react';

import { Button, Popconfirm, Space } from 'antd';

import { DeleteOutlined,EditOutlined } from '@ant-design/icons';

type SupplierActionsCellProps = {
    onEdit: () => void,
    onRemove: () => Promise<void>,
};

export function SupplierActionsCell({ onEdit, onRemove }: SupplierActionsCellProps) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    return (
        <Space size="middle">
            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={onEdit}
            />
    
            <Popconfirm
                title="Excluir fornecedor"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isRemoving }}
                onConfirm={async () => {
                    setIsRemoving(true);

                    await onRemove();

                    setIsRemoving(false);
                }}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    title="Excluir"
                    onClick={() => setIsPopconfirmVisible(true)}
                />
            </Popconfirm>
        </Space>
    );
}
