import React, { useEffect, useState } from 'react';

import { Button, Drawer, Form, Input, Select, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import UploadGcondo from 'components/_Common/UploadGcondo';

import API from '../../../lib/API';
import Authentication from '../../../lib/Authentication';
import { IssueInterface } from '../../_Common/_Interfaces/Issue';
import { IssueTypeInterface } from '../../_Common/_Interfaces/IssueType';

type Props = {
    isOpen: boolean;
    closeForm: Function;
};

const FormIssue: React.FC<Props> = (props: Props) => {
    const [form] = Form.useForm();
    const [isSaving, setIsSaving] = useState(false);
    const [issueTypes, setIssueTypes] = useState([]);
    const [fileList, setFileList] = useState<Array<UploadFile>>([]);

    async function onFinish() {
        setIsSaving(true);
        let issue = await form.validateFields();
        issue.userId = Authentication.getUserId();

        API.post('/issue', issue).then((data) => {
            form.resetFields();
            setIsSaving(false);
            props.closeForm();
            if (fileList.length > 0) {
                fileList.forEach(async (file: any) => {
                    await API.post('/default/issueFile', {
                        filename: file.name,
                        url: file.response ?? file.url,
                        issueId: data.id
                    });
                });
            }
        });
    };

    async function fetchData() {
        const issueTypes = await API.get('/default/issueType');
        setIssueTypes(issueTypes);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Drawer title="Novo chamado" placement="right" onClose={() => props.closeForm()} open={props.isOpen}>
            <Form form={form} layout="vertical" initialValues={{} as IssueInterface} onFinish={onFinish}>
                <Form.Item name="issueTypeId" label="Tipo de chamado" rules={[{ required: true, message: 'Selecione o tipo' }]} >
                    <Select loading={issueTypes.length <= 0}>
                        {
                            issueTypes.map((issueType: IssueTypeInterface) => (
                                <Select.Option value={issueType.id}>{issueType.name}</Select.Option>
                            )) ?? null
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="subject" label="Assunto" rules={[{ required: true, message: 'Digite o assunto' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="Descrição" rules={[{ required: true, message: 'Digite a descrição' }]}>
                    <TextArea />
                </Form.Item>
                <Form.Item>
                    <UploadGcondo addFile={setFileList} />
                </Form.Item>
                <Form.Item>
                    <Button loading={isSaving} type="primary" htmlType="submit" block>
                        Salvar
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );

};

export default FormIssue;