import React from 'react';

import { ColumnsType } from 'antd/es/table';

import dayjs from 'dayjs';

import { PendingActionsCell } from './PendingActionsCell';

export const getPendingColumns = (fetchPendingReserves: () => void): ColumnsType<Reserve.Model> => [
    {
        dataIndex: ['apartment', 'name'],
        render: (value, record) => `${record.apartment.tower.name} ${value}`
    },

    {
        dataIndex: ['ambient', 'name'],
    },

    {
        dataIndex: 'bookingDate',
        render: value => dayjs(value).format('DD/MM/YYYY'),
    },

    {
        width: '100px',
        align: 'center',
        render: (_, record) => (
            <PendingActionsCell
                record={record}
                fetchPendingReserves={fetchPendingReserves}
            />
        ),
    },
];