import React, { useState } from 'react';

import { App, Button, Popconfirm, Space } from 'antd';

import { CloudDownloadOutlined,DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDocument } from 'lib/providers/DocumentContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteDocument } from 'services/Document.service';

type Props = { document: Document.With<'client' | 'document_type' | 'files'> };

export function DocumentActionsCell({ document }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const {
        setDocumentId,
        setIsEditModalVisible,
        fetchDocuments,
    } = useDocument();

    const app = App.useApp();

    const handleEdit = () => {
        setDocumentId(document.id);
        setIsEditModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteDocument(document.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        if (response.type === 'error')
            return app.notification.error({ message: response.message, description: response.description });

        app.notification.open(response);
        setIsPopconfirmVisible(false);
        fetchDocuments();
    };

    const downloadButton = document.files.length === 1
        ? (
            <Button
                type="text"
                href={document.files[0].url}
                target="_blank"
                icon={<CloudDownloadOutlined />}
                title="Baixar"
            />
        ) : null;

    return (
        <Space size="middle">
            {downloadButton}

            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={handleEdit}
            />

            <Popconfirm
                title="Excluir documento"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    title="Excluir"
                    onClick={() => setIsPopconfirmVisible(true)}
                />
            </Popconfirm>
        </Space>
    );
}
