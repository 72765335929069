import API from '../lib/API';
import { Categories, Category, CreatePayment, DeletePayment, ListPayments, UpdatePayment } from './contracts/Payment.contract';

const route = '/payments';

export function listPayments(): Promise<ListPayments.Response> {
    return API.get(route);
};

export function createPayment(body: CreatePayment.Body): Promise<CreatePayment.Response> {
    return API.post(route, body);
};

export function updatePayment(id: Payment.Model['id'], body: UpdatePayment.Body): Promise<UpdatePayment.Response> {
    return API.put(`${route}/${id}`, body);
};

export function deletePayment(id: Payment.Model['id']): Promise<DeletePayment.Response> {
    return API.delete(`${route}/${id}`);
};

export function paymentMade(id: Payment.Model['id'], date: string): Promise<CreatePayment.Response> {
    return API.patch(`${route}/${id}/payment-made`, { payment_date: date });
};

export function uploadFile(body: CreatePayment.BodyFile): Promise<CreatePayment.Response> {
    return API.post('/default/PaymentFile', body);
};

export function createCategory(body: Category.Body): Promise<Category.Response> {
    return API.post(`${route}/category`, body);
};

export function listPaymentCategories(): Promise<Categories.Response> {
    return API.get(`${route}/categories`);
};