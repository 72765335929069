import React, { useEffect, useState } from 'react';

import {
    Button,
    Card,
    Col,
    ColorPicker,
    ColorPickerProps,
    Form,
    Input,
    notification,
    Row,
    UploadFile
} from 'antd';
import Link from 'antd/es/typography/Link';
import { MaskedInput } from 'antd-mask-input';

import { LinkOutlined } from '@ant-design/icons';
import { makeCnpj, makeRawCnpj } from 'lib/helpers/Text.helper';
import { useAccount } from 'lib/providers/AccountContextProvider';
import { UploadField } from 'lib/UploadField';
import { updateAccount } from 'services/Account.service';

type Values = {
    name: string,
    cnpj: string,
    primary_email: string,
    primary_phone: string,
    url: string,
    logo: UploadFile[],
    /** @example '#87e0d1' */
    primary_color: ColorPickerProps['color'] | null
    /** @example '#87e0d1' */
    secondary_color: ColorPickerProps['color'] | null
}

export function ClientManagerCard() {
    const [form] = Form.useForm<Values>();
    const [isSavingClientManager, setIsSavingClientManager] = useState<boolean>(false);

    const logoClientManager = Form.useWatch('logo', form);

    const { clientManager, isLoading } = useAccount();

    const defaultURL =
        ['unit', 'plan', 'trial'].includes(clientManager.contract)
            ? 'https://app.gcondo.com.br/gc/'
            : 'https://sindigest.com.br/gc/';

    const onFinish = async (values: Values) => {
        setIsSavingClientManager(true);

        const logo: string | null = values.logo ?
            values.logo[0].url
                ? values.logo[0].url
                : values.logo[0].response
            : null;

        const response = await updateAccount({
            logo: logo,
            name: values.name,
            cnpj: makeRawCnpj(values.cnpj),
            primary_email: values.primary_email,
            primary_phone: values.primary_phone,
            url: values.url,
            primary_color: typeof values.primary_color === 'string'
                ? values.primary_color
                : values.primary_color?.toHexString(),
            secondary_color: typeof values.secondary_color === 'string'
                ? values.secondary_color
                : values.secondary_color?.toHexString()
        });

        setIsSavingClientManager(false);

        if (response.success)
            notification.success({ message: response.message, description: response.description });

    };

    useEffect(() => {
        if (!clientManager.id)
            return;

        if (clientManager.logo) {
            const logo: UploadFile[] = [{
                uid: clientManager.logo,
                name: clientManager.logo,
                url: clientManager.logo,
                status: 'done'
            }];

            form.setFieldsValue({ logo });
        }

        form.setFieldsValue({
            name: clientManager.name,
            cnpj: clientManager.cnpj ? makeCnpj(clientManager.cnpj) : '',
            primary_email: clientManager.primary_email,
            primary_phone: clientManager.primary_phone,
            url: clientManager.url,
            primary_color: clientManager.primary_color,
            secondary_color: clientManager.secondary_color
        });

    }, [clientManager]);

    return (
        <Card title="Informações da empresa" loading={isLoading}>
            <Form
                form={form}
                onFinish={onFinish}
                name="createClientManager"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={6}>
                        <Form.Item>
                            <UploadField
                                name="logo"
                                type='picture-card'
                                buttonText='Logo'
                                maxCount={1}
                                multiple={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18}>
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="name"
                                    label="Nome"
                                    rules={[{ required: true, message: 'Por favor, digite um nome.' }]}
                                >
                                    <Input maxLength={500} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="cnpj"
                                    label="CNPJ"
                                    rules={[{ required: true, message: 'Por favor, digite um CNPJ.' }]}
                                >
                                    <MaskedInput mask="00.000.000/0000-00" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="primary_email"
                                    label="E-mail principal"
                                    rules={[
                                        { required: true, message: 'Por favor, digite um e-mail principal.' },
                                        { type: 'email', message: 'Por favor, digite um e-mail válido.' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="primary_phone"
                                    label="Telefone principal"
                                    rules={[{ required: true, message: 'Por favor, digite um telefone principal.' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item
                                    name="url"
                                    label={<Link href={`${defaultURL}${clientManager.url}`} target='_blank'>Página da empresa (URL) <LinkOutlined /></Link>}
                                >
                                    <Input addonBefore={defaultURL.replace('https://', '')} maxLength={500} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="primary_color"
                                    label="Cor principal"
                                >
                                    <ColorPicker format="hex" showText />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item
                                    name="secondary_color"
                                    label="Cor secundária"
                                >
                                    <ColorPicker format="hex" showText />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <Button loading={isSavingClientManager} type="primary" htmlType="submit">
                            Atualizar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};