import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import Request from 'lib/Request';
import { findClientByURL } from 'services/Client.service';
import { ListIssue } from 'services/contracts/Issue.contract';

type ClientResponse = Awaited<ReturnType<typeof findClientByURL>>;

type Client = Extract<ClientResponse, { client: any }>['client'];

type Value = {
    isLoading: boolean,
    loadClient: () => Client,
    issues: Issue.Model[]
};

export type ClientContextProviderProps = {
    url: NonNullable<Client.Model['url']>,
    children: (value: Value) => ReactNode
};

const ClientContext = createContext<Value | null>(null);

export function ClientContextProvider({ url, children }: ClientContextProviderProps) {
    const [client, setClient] = useState<Client | null>(null);
    const [issues, setIssues] = useState<Issue.Model[]>([]);
    const [isLoading, setIsLoading] = useState<Value['isLoading']>(true);

    const app = App.useApp();

    const fetchIssues = async () => {
        const response: ListIssue.Response = await Request.get(`/external/issue/client?client_id=${client?.id}`);

        if (!response.success)
            return;

        setIssues(response.issues);
    };

    useEffect(() => {
        const fetchClient = async () => {
            setIsLoading(true);

            const clientResponse = await findClientByURL(url);

            if (!clientResponse.success)
                return handleServiceError(app, clientResponse);

            setClient(clientResponse.client);
            setIsLoading(false);
        };

        fetchClient();
    }, [url, app]);

    useEffect(() => {
        fetchIssues();
    }, [client]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadClient = useCallback(() => {
        if (!client)
            throw new TypeError('Something went wrong!');

        return client;
    }, [client]);

    const value: Value = useMemo(() => ({
        isLoading,
        loadClient,
        issues
    }), [
        isLoading,
        loadClient,
        issues
    ]);

    return (
        <ClientContext.Provider value={value}>
            {children(value)}
        </ClientContext.Provider>
    );
}

export function useClient() {
    const context = useContext(ClientContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ClientContextProvider`.');

    return context;
}