import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Row, Select, SelectProps, Tooltip } from 'antd';

import { ClearOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { Show } from 'lib/Show';
import { makeClientOptions } from 'pages/issue/Filters';

dayjs.extend(isBetween);

type SelectOptions = NonNullable<SelectProps['options']>;

type Props = { isOverviewModule: boolean };

export type Filter = {
    client: Maintenance.Model['clientId'] | null | undefined,
    status: 'scheduled' | 'expires_this_month' | 'expires_next_month' | 'late' | 'finished' | null | undefined,
    created_at: [Dayjs | null, Dayjs | null],
    maintenance_type_id: Array<Maintenance.Model['maintenance_type_id']> | null | undefined,
    tag: Maintenance.Model['tag'] | null | undefined
};

const filterOption = makeFilterOption();

export function Filters({ isOverviewModule }: Props) {
    const [filter, setFilter] = useState<Filter>({} as Filter);
    const {
        maintenances,
        maintenanceTypes, 
        setMaintenancesFiltered
    } = useMaintenance();

    const Client = () => (
        <Select
            style={{ width: '100%' }}
            value={filter.client}
            onClear={() => setFilter({ ...filter, client: null })}
            onSelect={(value) => setFilter({ ...filter, client: value })}
            placeholder="Condomínio"
            options={makeClientOptions()}
            filterOption={filterOption}
            allowClear
            showSearch
        />
    );

    const Status = () => {
        const options: SelectOptions = [
            { value: 'late', label: 'Atrasado'},
            { value: 'expires_this_month', label: 'Vence este mês'},
            { value: 'expires_next_month', label: 'Vence no próxmio mês'},
            { value: 'scheduled', label: 'Agendado'},
            { value: 'finished', label: 'Finalizada'},
        ];

        return (
            <Select
                options={options}
                style={{ width: '100%' }}
                value={filter.status}
                onClear={() => setFilter({ ...filter, status: null })}
                onSelect={(value) => setFilter({ ...filter, status: value })}
                placeholder="Status"
                allowClear
            />
        );
    };

    const CreatedAt = () => (
        <Tooltip title="O filtro será aplicado de acordo com o filtro de status, por exemplo, se você optar por não selecionar nenhum status, o sistema irá retornar todos os casos com data estimada e que foram finalizado no período filtrado.">
            <DatePicker.RangePicker
                style={{ width: '100%' }}
                value={filter.created_at}
                onChange={(value) => {
                    if (value === null)
                        return setFilter({ ...filter, created_at: [null, null] });

                    setFilter({ ...filter, created_at: [dayjs(value[0]), dayjs(value[1])] });
                }}
                placeholder={['De', 'até']}
                format="DD/MM/YYYY"
            />
        </Tooltip>
    );

    const Tag = () => {
        const options: SelectOptions = [
            { value: 'corrective', label: 'Corretiva'},
            { value: 'predictive', label: 'Preditiva'},
            { value: 'preventive', label: 'Preventiva'},
        ];

        return (
            <Select
                options={options}
                style={{ width: '100%' }}
                value={filter.tag}
                onClear={() => setFilter({ ...filter, tag: null })}
                onSelect={(value) => setFilter({ ...filter, tag: value })}
                placeholder="Objetivo"
                allowClear
            />
        );
    };

    const MaintenanceType = () => {
        const parsedTypes: SelectOptions = [];

        const tags = {
            'fire': {
                index: 0,
                label: 'Bombeiro e incêndio'
            },
            'employee': {
                index: 1,
                label: 'Funcionários'
            },
            'infrastructure': {
                index: 2,
                label: 'Infraestrutura'
            },
            'custom': {
                index: 3,
                label: 'Customizada'
            }
        };

        maintenanceTypes.forEach((type) => {
            if (!parsedTypes[tags[type.tag].index])
                parsedTypes[tags[type.tag].index] = {
                    label: tags[type.tag].label,
                    options: []
                };
            parsedTypes[tags[type.tag].index].options.push({
                label: type.name,
                value: type.id
            });
        });

        return (
            <Select
                style={{ width: '100%' }}
                value={filter.maintenance_type_id}
                onChange={(value) => setFilter({ ...filter, maintenance_type_id: value })}
                filterOption={(input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder="Categoria"
                mode="multiple"
                allowClear
                options={parsedTypes}
            />)
        ;
    };

    const ClearFilter = () => (
        <Button
            type="primary"
            icon={<ClearOutlined />}
            onClick={() => setFilter({} as Filter)}
            title="Limpar fltros"
        />
    );

    const handleFilter = () => {
        let data = maintenances;

        if (filter.client !== null && filter.client !== undefined)
            data = data.filter(maintenance => maintenance.clientId === filter.client);

        if (filter.status !== null && filter.status !== undefined) {
            if (filter.status === 'finished')
                data = data.filter(maintenance => maintenance.isFinished);

            if (filter.status === 'late')
                data = data.filter(maintenance => !maintenance.isFinished && dayjs(maintenance.estimatedDate).isBefore(dayjs()));

            if (filter.status === 'expires_this_month')
                data = data.filter(maintenance => !maintenance.isFinished && dayjs(maintenance.estimatedDate).format('MM/YYYY') === dayjs().format('MM/YYYY'));

            if (filter.status === 'expires_next_month')
                data = data.filter(maintenance => !maintenance.isFinished && dayjs(maintenance.estimatedDate).format('MM/YYYY') === dayjs().add(1, 'month').format('MM/YYYY'));

            if (filter.status === 'scheduled')
                data = data.filter(maintenance => !maintenance.isFinished && dayjs().add(1, 'month').isBefore(maintenance.estimatedDate));
        }

        if (filter.tag !== null && filter.tag !== undefined)
            data = data.filter(maintenance => maintenance.tag === filter.tag);

        if (filter.maintenance_type_id !== null && filter.maintenance_type_id !== undefined && filter.maintenance_type_id.length > 0)
            data = data.filter(maintenance => filter.maintenance_type_id?.includes(maintenance.maintenance_type_id));

        if (filter.created_at !== undefined && filter.created_at[0] !== null && filter.created_at[1] !== null)
            data = data.filter(maintenance => (dayjs(maintenance.estimatedDate).isBefore(dayjs()) && !maintenance.isFinished)
                || (dayjs(maintenance.estimatedDate).isBetween(filter.created_at[0], filter.created_at[1]) && !maintenance.isFinished)
                || (dayjs(maintenance.completionDate).isBetween(filter.created_at[0], filter.created_at[1]) && maintenance.isFinished)
            );

        setMaintenancesFiltered(data);
    };

    useEffect(() => {
        handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const colProps = {
        style: { marginTop: 5 },
        xs: 24,
        sm: 24,
    };

    return (
        <Row align="middle">
            <Show when={isOverviewModule}>
                <Col {...colProps} md={4} lg={4}>
                    <Client />
                </Col>
            </Show>

            <Col {...colProps} md={3} lg={3}>
                <Status />
            </Col>

            <Col {...colProps} md={6} lg={6}>
                <CreatedAt />
            </Col>

            <Col {...colProps} md={5} lg={5}>
                <MaintenanceType />
            </Col>

            <Col {...colProps} md={3} lg={3}>
                <Tag />
            </Col>

            <Col {...colProps} md={1} lg={1}>
                <ClearFilter />
            </Col>
        </Row >
    );
};