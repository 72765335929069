import Request from 'lib/Request';

import API from '../lib/API';
import {
    CreateGeneralMeeting,
    CreateGeneralMeetingQuestionAnswerCollectionWithoutAuthentication,
    DeleteGeneralMeeting,
    FindGeneralMeetingWithoutAuthentication,
    GenerateReportGeneralMeetings,
    ListGeneralMeetings,
    PublishGeneralMeeting,
    UpdateGeneralMeeting,
} from './contracts/GeneralMeeting.contract';

const route = '/general_meeting';

export function listGeneralMeetings(): Promise<ListGeneralMeetings.Response> {
    return API.get(route);
};

export function generateReportGeneralMeetings(id: GeneralMeeting.Model['id']): Promise<GenerateReportGeneralMeetings.Response> {
    return API.get(`${route}/report/${id}`);
};

export function createGeneralMeeting(body: CreateGeneralMeeting.Body): Promise<CreateGeneralMeeting.Response> {
    return API.post(route, body);
};

export function updateGeneralMeeting(id: GeneralMeeting.Model['id'], body: UpdateGeneralMeeting.Body): Promise<UpdateGeneralMeeting.Response> {
    return API.put(`${route}/${id}`, body);
};

export function publishGeneralMeeting(id: GeneralMeeting.Model['id'], body: PublishGeneralMeeting.Body): Promise<PublishGeneralMeeting.Response> {
    return API.put(`${route}/${id}/publish`, body);
};

export function deleteGeneralMeeting(id: GeneralMeeting.Model['id']): Promise<DeleteGeneralMeeting.Response> {
    return API.delete(`${route}/${id}`);
};

export function findGeneralMeetingWithoutAuthentication(
    id: GeneralMeeting.Model['id']
): Promise<FindGeneralMeetingWithoutAuthentication.Response> {
    return Request.get(`/external/general_meeting/${id}`);
}

export function createGeneralMeetingQuestionAnswerCollectionWithoutAuthentication(
    id: GeneralMeeting.Model['id'],
    body: CreateGeneralMeetingQuestionAnswerCollectionWithoutAuthentication.Body,
): Promise<CreateGeneralMeetingQuestionAnswerCollectionWithoutAuthentication.Response> {
    return Request.post(`/external/general_meeting/${id}`, body);
}