import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { ApartmentOutlined, BuildOutlined, TeamOutlined } from '@ant-design/icons';
import { Indicator } from 'lib/Indicator';

import { listApartment } from '../../services/ApartmentService';
import { listResident } from '../../services/ResidentService';
import { listTower } from '../../services/TowerService';

const colProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8
};

export function Indicators() {
    const [towers, setTowers] = useState<Array<Apartment.Tower>>();
    const [apartments, setApartments] = useState<Array<Apartment.Model>>();
    const [residents, setResidents] = useState<Array<Apartment.Resident>>();

    async function fetchData() {
        const promises = [];

        promises.push(listApartment().then((response) => {
            if ('status' in response)
                return;
            setApartments(response);
        }));

        promises.push(listResident().then((response) => {
            if ('status' in response)
                return;
            setResidents(response);
        }));

        promises.push(listTower().then((response) => {
            if ('status' in response)
                return;
            setTowers(response);
        }));

        Promise.all(promises);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Row gutter={[0, 10]}>
            <Col {...colProps}>
                <Indicator
                    label="Blocos"
                    value={towers?.length}
                    icon={<BuildOutlined />}
                    loading={towers === undefined}
                />
            </Col>

            <Col {...colProps}>
                <Indicator
                    label="Unidades"
                    value={apartments?.length}
                    icon={<ApartmentOutlined />}
                    loading={apartments === undefined}
                />
            </Col>

            <Col {...colProps}>
                <Indicator
                    label="Condôminos"
                    value={residents?.length}
                    icon={<TeamOutlined />}
                    loading={residents === undefined}
                />
            </Col>

        </Row>
    );

};