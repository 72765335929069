import React, { Dispatch, SetStateAction, useState } from 'react';

import { App, Popconfirm, Space } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { sleep } from 'lib/Sleep';
import { deleteApartmentType } from 'services/ApartmentService';

type Props = {
    type: Apartment.Type,
    fetchTypes: () => void,
    setTypeId: Dispatch<SetStateAction<Apartment.Type['id'] | null>>,
    setIsEditTypeModalVisible: Dispatch<SetStateAction<boolean>>,
};

export function TypeActionsCell({ type, fetchTypes, setTypeId, setIsEditTypeModalVisible }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();

    const handleEdit = () => {
        setTypeId(type.id);
        setIsEditTypeModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteApartmentType(type.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        if (response.type === 'error')
            return app.notification.error({ message: response.message, description: response.description });

        app.notification.open(response);
        setIsPopconfirmVisible(false);
        fetchTypes();
    };

    return (
        <Space size="middle">
            <EditOutlined onClick={handleEdit} title="Editar" />

            <Popconfirm
                title="Excluir tipo de unidade"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <DeleteOutlined title="Excluir" onClick={() => setIsPopconfirmVisible(true)} />
            </Popconfirm>
        </Space>
    );
}
