import React, { useCallback, useEffect, useState } from 'react';

import { App, Button, Row, Space, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { ConsumptionListModal, ConsumptionReadingFormModal, FormConsumptionModal } from 'components/Consumption';
import { ConsumptionContext, Value } from 'lib/hooks/useConsumption';
import { Show } from 'lib/Show';
import { listApartment } from 'services/ApartmentService';
import { listConsumption, listConsumptionReading, listTypeConsumption } from 'services/Consumption.service';

import { Columns } from './Columns';
import { ConsumptionReport } from './Consumption.report';

export function Consumptions() {
    const [typeConsumption, setTypeConsumption] = useState<Consumption.Type[]>([]);
    const [consumptions, setConsumptions] = useState<Consumption.Model[]>([]);
    const [consumptionsReading, setConsumptionsReading] = useState<Consumption.Reading[]>([]);
    const [apartments, setApartments] = useState<Apartment.Model[]>([]);
    const [isFormConsumptionModalVisible, setIsFormConsumptionModalVisible] = useState(false);
    const [isConsumptionListModalVisible, setIsConsumptionListModalVisible] = useState(false);
    const [isConsumptionReadingFormModalVisible, setIsConsumptionReadingFormModalVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const app = App.useApp();

    const fetchConsumptions = useCallback(async () => {
        setIsFetching(true);

        const promises = [];

        promises.push(listConsumptionReading().then((response) => {
            if ('status' in response)
                return;
            setConsumptionsReading(response);
        }));

        promises.push(listTypeConsumption().then((response) => {
            if ('status' in response)
                return;
            setTypeConsumption(response);
        }));

        promises.push(listConsumption().then((response) => {
            if ('status' in response)
                return;
            setConsumptions(response);
        }));

        Promise.all(promises).then(() => setIsFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app]);

    const fetchApartments = useCallback(async () => {
        const response = await listApartment();

        if ('status' in response)
            return;

        setApartments(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app]);

    useEffect(() => {
        fetchApartments();
        fetchConsumptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchConsumptions]);



    const value: Value = {
        isFetching,

        apartments,

        typeConsumption,
        consumptions,
        consumptionsReading,
        fetchConsumptions,

        isFormConsumptionModalVisible,
        setIsFormConsumptionModalVisible,

        isConsumptionListModalVisible,
        setIsConsumptionListModalVisible,

        isConsumptionReadingFormModalVisible,
        setIsConsumptionReadingFormModalVisible
    };

    return (
        <ConsumptionContext.Provider value={value}>
            <Content>
                <Row justify="space-between" align="middle">
                    <Typography.Title level={3}>
                        Leituras realizadas
                    </Typography.Title>

                    <Space wrap>
                        <Button
                            type="default"
                            onClick={() => ConsumptionReport(consumptionsReading)}
                        >
                            Exportar
                        </Button>
                        <Button
                            type="default"
                            onClick={() => setIsConsumptionListModalVisible(true)}
                        >
                            Definições de consumo
                        </Button>

                        <Button type="primary" onClick={() => setIsConsumptionReadingFormModalVisible(true)}>
                            Cadastrar
                        </Button>
                    </Space>
                </Row>
                <List
                    columns={Columns(consumptions)}
                    loading={isFetching}
                    data={consumptionsReading} />
            </Content>

            <Show when={isFormConsumptionModalVisible}>
                <FormConsumptionModal />
            </Show>

            <Show when={isConsumptionListModalVisible}>
                <ConsumptionListModal />
            </Show>

            <Show when={isConsumptionReadingFormModalVisible}>
                <ConsumptionReadingFormModal />
            </Show>

        </ConsumptionContext.Provider>
    );
}
