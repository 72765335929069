import React from 'react';

import API from '../../../lib/API';
import { ResidentInterface } from '../../_Common/_Interfaces/Resident';
import List from '../../_Common/List';

type State = {
    residents: Array<ResidentInterface>,
    loading: Boolean
};

type Props = {
    apartmentId: number;
    history: any;
    match: any;
}

export default class ResidentList extends React.Component<Props, State> {

    state = {
        loading: true,
        residents: []
    };

    columns = [
        { title: 'Nome', key: 'name', dataIndex: 'name' },
        { title: 'Contatos', key: 'phone', render: (_: any, resident: ResidentInterface) => (`${resident.phone ?? ''}\n${resident.cellphone ?? ''}`) },
        { title: 'Email', key: 'email', dataIndex: 'email' }
    ];

    fetchData = async () => {
        const { apartmentId } = this.props;
        const residents = await API.get(`/resident/apartment/${apartmentId}`);
        this.setState({ residents, loading: false });
    };

    componentDidMount = async () => {
        await this.fetchData();
    };

    render() {

        const { residents, loading } = this.state;

        return (
            <List data={residents} loading={loading} columns={this.columns} />
        );
    }

}