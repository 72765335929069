import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Modal, QRCode, Row } from 'antd';

import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { useAccount } from 'lib/providers/AccountContextProvider';

const wl = makeWhiteLabel();

export function ClientQrCodeModal() {
    const { setIsQrCodeModalVisible, setClientId, clientSelected } = useAccount();

    if (clientSelected === null)
        throw new TypeError('Null value was provided to `clientSelected` constant!');

    const downloadQRCode = () => {
        const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');

        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = `${clientSelected.name} - QRCode.png`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        }
    };

    const close = () => {
        setIsQrCodeModalVisible(false);
        setClientId(null);
    };

    return (
        <Modal
            open
            title="QRCode"
            onCancel={close}
            footer={null}
        >
            <Row>
                <div id='myqrcode'>
                    <QRCode
                        type="canvas"
                        bgColor="#fff"
                        value={`${wl.defaultUrl}/client/${clientSelected.url}/issue`}
                    />
                </div>
            </Row>
            <Row>
                <a href={`${wl.defaultUrl}/client/${clientSelected.url}/issue`} target='_blank' rel="noreferrer">
                    {`${wl.defaultUrl}/client/${clientSelected.url}/issue`}
                </a>
            </Row>
            <Row>
                <Button
                    type="dashed"
                    block
                    onClick={downloadQRCode}
                >
                    Baixar QRCode
                </Button>
            </Row>
        </Modal>
    );
}

