import React from 'react';

import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

const wl = makeWhiteLabel();

export function SecondaryHeaderLogo() {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    
    const iconSrc = isMobile
        ? wl.icon_mini ?? wl.icon
        : wl.icon;

    return (
        <div className="header-logo-wrapper">
            <img
                src={iconSrc}
                alt="Gcondo logo"
                height="40"
            />
        </div>
    );
}
