import React, { useEffect, useState } from 'react';

import { Button, notification, Row, Space, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { downloadSupplierReport } from 'components/Supplier/Excel';
import FormSupplier from 'components/Supplier/Form';
import Authentication from 'lib/Authentication';
import { listServiceCategory } from 'services/ServiceCategory.service';
import { listSuppliers, removeSupplier } from 'services/Supplier.service';

import { getColumns } from './Columns';

export function Suppliers() {
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFinishing, setFinishing] = useState(false);
    const [supplierId, setSupplierId] = useState<number | null>(null);
    const [suppliers, setSuppliers] = useState<Array<Supplier.Model>>([]);
    const [serviceCategories, setServiceCategories] = useState<ServiceCategory.Model[]>([]);

    const supplier = suppliers.find((m) => m.id === supplierId);

    const closeForm = () => {
        setOpen(false);
        setFinishing(false);
        setSupplierId(null);
    };

    const deleteSupplier = async (supplierId: Supplier.Model['id']) => {
        const response = await removeSupplier(supplierId);
        fetchData();
        notification.open(response);
    };

    const columns = getColumns(setOpen, setSupplierId, deleteSupplier, serviceCategories);

    const fetchData = async () => {
        setLoading(true);

        const clients = Authentication.getClients().map(client => client.id);

        const supplierResponse = await listSuppliers(clients);

        if (!supplierResponse.success)
            return;

        const serviceCategoryResponse = await listServiceCategory();

        if ('status' in serviceCategoryResponse)
            return;

        setServiceCategories(serviceCategoryResponse);
        setSuppliers(supplierResponse.suppliers);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [isOpen, isFinishing]);

    return (
        <Content>
            <Row className="space-between" align="middle">
                <Typography.Title level={3}>
                    Fornecedores
                </Typography.Title>
                <Space>
                    <Button
                        type="default"
                        onClick={() => downloadSupplierReport(suppliers)}
                    >
                        Gerar relatório
                    </Button>
                    <Button
                        className="right"
                        type="primary"
                        onClick={() => setOpen(true)}
                    >
                        Cadastrar
                    </Button>
                </Space>

            </Row>

            <List
                data={suppliers}
                columns={columns}
                loading={isLoading} />

            <FormSupplier
                key={`form-${supplier?.id}`}
                closeForm={closeForm}
                isOpen={isOpen}
                supplier={supplier} />
        </Content>
    );

}