import React, { CSSProperties, ReactNode } from 'react';

import { Layout as AntdLayout } from 'antd';

import { COPYRIGHT } from 'internal-constants';

type Props = {
    backgroundColor: string | null,
    children: ReactNode,
};

export function Layout({ backgroundColor, children }: Props) {
    // This is assigned only when a re-render happens!
    // If the value needs to be in real time, see the link above
    // https://chakra-ui.com/docs/hooks/use-media-query
    const isMobile = window.matchMedia('(max-width: 768px').matches;

    const style: CSSProperties = {
        margin: isMobile ? '0px 10px' : '0px 16px',
        padding: isMobile ? '12px 16px' : '12px 168px 64px',
        minHeight: 280,
    };

    return (
        <AntdLayout
            style={{
                minHeight: '100vh'
            }}
        >
            <AntdLayout.Header style={{ backgroundColor: backgroundColor ?? undefined }} />
            <AntdLayout.Content style={style}>
                {children}
            </AntdLayout.Content>

            <AntdLayout.Footer
                style={{
                    textAlign: 'center'
                }}
            >
                {COPYRIGHT}
            </AntdLayout.Footer>
        </AntdLayout>
    );
}
