import React, { Component } from 'react';

import { Button, Col, Form, Input, Modal, notification, Radio, Row, Select, Space, Typography, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormInstance } from 'antd/lib/form';
import ImgCrop from 'antd-img-crop';
import { MaskedInput } from 'antd-mask-input';

import { FilesGalery } from 'components/_Common/FilesGalery';
import { Show } from 'lib/Show';
import { UploadField } from 'lib/UploadField';
import { validateResident } from 'services/ResidentService';

import API from '../../lib/API';
import { ApartmentInterface } from '../_Common/_Interfaces/Apartment';
import { ResidentInterface } from '../_Common/_Interfaces/Resident';

type State = {
    apartments: Array<ApartmentInterface>;
    saving: boolean;
    fileList: any;
    type: string;
    personType: 'pf' | 'pj';
    modalValidate: boolean;
    is_approval_pending: boolean | null;
};

type Props = {
    resident: ResidentInterface;
    isVisible: boolean;
    hideModalForm: Function;
};

class FormResident extends Component<Props, State> {

    state: State = {
        apartments: [],
        fileList: [],
        saving: false,
        type: 'resident',
        personType: 'pf',
        modalValidate: false,
        is_approval_pending: null
    };

    private formRef = React.createRef<FormInstance>();
    private formValidateRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 12
    };

    onCancel = () => this.props.hideModalForm();

    fetcOptions = async () => {
        this.setState({
            apartments: await API.get('/apartment')
        });
    };

    saveItem = async (resident: any) => {
        this.setState({ saving: true });

        resident.files = resident.files !== undefined
            ? resident.files.map((file: UploadFile) => ({ url: file.response ?? file.url, filename: file.name }))
            : [];


        let picture = null;
        
        if(resident.picture.length > 0)
            picture = resident.picture[0].response ?? resident.picture[0].url;
        
        if (this.props.resident.id)
            notification.open(await API.put(`/resident/${this.props.resident.id}`, { ...resident, picture }));
        else
            notification.open(await API.post('/resident', { ...resident, picture }));

        this.setState({ saving: false }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();

    };

    componentDidUpdate(prevProps: Props) {
        const { resident, isVisible } = this.props;

        if (resident !== prevProps.resident) {
            if (resident.id) {
                this.setState({
                    type: resident.type ?? 'resident'
                });
            } else
                this.setState({ type: resident.type ?? 'resident' });

            if (isVisible)
                this.fetcOptions();
        }
    };

    onValidate = async () => {
        this.setState({ saving: true });
        const { resident, hideModalForm } = this.props;
        const values = await this.formValidateRef.current?.validateFields();
        const response = await validateResident(resident.id, values);
        this.setState({ saving: false });
        notification.open(response);
        hideModalForm(true);
        this.formRef.current?.resetFields();

    };

    render() {
        const { isVisible, resident } = this.props;
        const { saving, apartments, type, personType, modalValidate, is_approval_pending } = this.state;

        const files: UploadFile[] = resident.files?.map(file => (
            {
                uid: file.id,
                name: file.filename,
                url: file.url,
                status: 'done',
            }
        )) ?? [];

        const picture: UploadFile[] = resident.picture
            ? [{
                uid: resident.picture,
                name: resident.picture,
                url: resident.picture,
                status: 'done',
            }]
            : [];

        return (
            <Modal
                width={1000}
                centered
                title={resident.id ? 'Editar morador' : 'Novo morador'}
                destroyOnClose={true}
                open={isVisible}
                confirmLoading={saving}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Form
                    ref={this.formRef}
                    layout="vertical"
                    initialValues={{ ...resident, files, picture }}
                    onFinish={this.onOk}
                >
                    <ImgCrop>
                        <Form.Item name="picture" label="">
                            <UploadField name="picture" type='picture-card' buttonText='Foto' maxCount={1} />
                        </Form.Item>
                    </ImgCrop>
                    <Typography.Title style={{ marginTop: 0 }} level={5}>Dados do morador</Typography.Title>
                    <Row style={{ padding: 0 }}>
                        <Col lg={9} {...this.propsForSmallScreen}>
                            <Form.Item name="name" label="Nome completo" rules={[{ required: true, message: 'Digite o nome' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="nickname" label="Apelido" tooltip="">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={9} {...this.propsForSmallScreen}>
                            <Form.Item name="email" label="Email">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ padding: 0 }}>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="personType" label="Pessoa física ou jurídica?" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                                <Radio.Group onChange={(e) => this.setState({ personType: e.target.value })}>
                                    <Radio value="pf" >Pessoa física</Radio>
                                    <Radio value="pj" >Pessoa jurídica</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            // eslint-disable-next-line eqeqeq
                            personType == 'pj'
                                ? <Col lg={6} {...this.propsForSmallScreen}>
                                    <Form.Item name="cpf" label="CNPJ" rules={[{ message: 'Digite o CNPJ' }]}>
                                        <MaskedInput mask={'00.000.000/0000-00'} />
                                    </Form.Item>
                                </Col>
                                : null
                        }
                    </Row>
                    <Row style={{ padding: 0 }}>
                        {
                            // eslint-disable-next-line eqeqeq
                            personType == 'pf'
                                ? <>
                                    <Col lg={4} {...this.propsForSmallScreen}>
                                        <Form.Item name="sex" label="Sexo" >
                                            <Select>
                                                <Select.Option value="feminine">Feminino</Select.Option>
                                                <Select.Option value="masculine">Masculino</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={6} {...this.propsForSmallScreen}>
                                        <Form.Item name="cpf" label="CPF" rules={[{ message: 'Digite o CPF' }]}>
                                            <MaskedInput mask={'000.000.000-00'} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={6} {...this.propsForSmallScreen}>
                                        <Form.Item name="rg" label="RG" tooltip="">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} {...this.propsForSmallScreen}>
                                        <Form.Item name="occupation" label="Profissão">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </>
                                : null
                        }

                    </Row>
                    <Row style={{ padding: 0 }}>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="birthdate" label="Data de nascimento">
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                        <Col lg={5} {...this.propsForSmallScreen}>
                            <Form.Item name="phone" label="Telefone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={5} {...this.propsForSmallScreen}>
                            <Form.Item name="cellphone" label="Celular">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="apartmentId" label="Apartamento" rules={[{ required: true, message: 'Selecione um apartamento' }]} >
                                <Select
                                    disabled={apartments.length <= 0}
                                    loading={apartments.length <= 0}
                                    showSearch
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {apartments.map((apartment: ApartmentInterface, key: number) =>
                                        <Select.Option key={key} value={apartment.id}>
                                            {`${apartment.tower.name} - ${apartment.name}`}
                                        </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ padding: 0 }}>
                        <Col lg={6} {...this.propsForSmallScreen}>
                            <Form.Item name="type" label="Proprietário ou morador?" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                                <Radio.Group onChange={(e) => this.setState({ type: e.target.value })}>
                                    <Radio value="resident" >Morador</Radio>
                                    <Radio value="tenant" >Inquilino</Radio>
                                    <Radio value="owner" >Proprietário</Radio>
                                    <Radio value="authorized_visitor" >Visitante autorizado</Radio>
                                    <Radio value="local_employee" >Funcionário do local</Radio>
                                    <Radio value="emergency_contact" >Contato de emergência</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            // eslint-disable-next-line eqeqeq
                            type == 'owner'
                                ? <Col lg={6} {...this.propsForSmallScreen}>
                                    <Form.Item name="isResident" label="Reside no apartamento?" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                                        <Radio.Group defaultValue={resident.isResident}>
                                            <Radio value={1} >Sim</Radio>
                                            <Radio value={0} >Não</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                : null

                        }
                    </Row>

                    <Row style={{ padding: 0 }}>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <UploadField
                                name="files"
                                type="picture"
                                buttonText="Anexar documentos e imagens"
                                multiple
                            />
                        </Col>
                    </Row>

                    <Typography.Title style={{ marginTop: 0 }} level={5}>Dados de acesso</Typography.Title>
                    <Row style={{ padding: 0 }}>
                        <Col lg={8} {...this.propsForSmallScreen}>
                            <Form.Item name="rfid" label="Tag de acesso (RFID)" tooltip="Clique no campo e aproxime a tag do leitor.">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Show when={resident.is_approval_pending}>
                        <Row>
                            <Typography.Title style={{ marginTop: 0 }} level={5}>O condômino está pendente de aprovação, por favor, confirme a documentação abaixo:</Typography.Title>
                            <Button onClick={() => {
                                this.setState({ modalValidate: true });
                            }}>Clique aqui para aprovar ou reprovar o condômino</Button>
                        </Row>
                    </Show>
                    <Show when={resident?.external_resident_creation_files ? true : false}>
                        <Row>
                            <FilesGalery files={resident.external_resident_creation_files} />
                        </Row>
                    </Show>
                    <Form.Item name="picture">
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
                <Show when={modalValidate}>
                    <Modal
                        onCancel={() => this.setState({ modalValidate: false })}
                        title='Validar cadastro'
                        onOk={this.onValidate}
                        confirmLoading={saving}
                        open>
                        <Form layout="vertical" ref={this.formValidateRef}>
                            <Form.Item name='is_approval_pending' label='Qual sua decisão?'>
                                <Radio.Group onChange={(value) => this.setState({ is_approval_pending: value.target.value })}>
                                    <Space direction="vertical">
                                        <Radio value={false}>Aprovar cadastro</Radio>
                                        <Radio value={true}>Reprovar cadastro</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name='justification'
                                label='Justifique'
                                // @ts-ignore What is this..?
                                hidden={!is_approval_pending ?? is_approval_pending}
                                // eslint-disable-next-line eqeqeq
                                rules={[{ required: is_approval_pending == undefined ? false : is_approval_pending, message: 'Por favor, justifique.' }]}
                            >
                                <TextArea />
                            </Form.Item>
                        </Form>
                    </Modal>
                </Show>
            </Modal >
        );
    }
}

export default FormResident;
