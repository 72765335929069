import React, { forwardRef } from 'react';
import PrimitiveReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';

type Props = { onChange: ReCAPTCHAProps['onChange'] };

const SITE_KEY = '6LeATkslAAAAAIyy1DHsR-XcdVZVI21GXD5gVHlQ' as const;

export const ReCAPTCHA = forwardRef<PrimitiveReCAPTCHA, Props>(({ onChange }, ref) => {
    const size = window.matchMedia('(max-width: 991px)').matches
        ? 'compact'
        : 'normal';
    
    return (
        <PrimitiveReCAPTCHA
            ref={ref}
            size={size}
            onChange={onChange}
            sitekey={SITE_KEY}
        />
    );
});
