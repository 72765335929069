import React, { } from 'react';

import { Col, Row, Typography } from 'antd';

import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

import Authentication from '../../lib/Authentication';
import Content from '../_Common/_Layout/Content';
import { Indicators } from './Indicators';
import IssueClient from './Issue';
import IssuePerMonthChart from './IssuePerMonthChart';
import ScheduleIndex from './Schedule';

const wl = makeWhiteLabel();
const DashboardIndex: React.FC = () => {
    return (
        <Content>
            <Row>
                <Typography.Paragraph>
                    <Typography.Title
                        level={3}
                        style={{ marginBottom: 0 }}
                    >
                        Bem-vindo, {Authentication.getUserName()}!
                    </Typography.Title>

                    <Typography.Text type="secondary">
                        Somos a <b style={{ color: wl.colorSecondary }}>evolução</b> da gestão condominial
                    </Typography.Text>
                </Typography.Paragraph>
            </Row>

            <Indicators />

            <IssueClient />

            <Row style={{ paddingTop: 10 }}>
                <Col sm={24} md={24} lg={12}>
                    <IssuePerMonthChart />
                </Col>

                <Col sm={24} md={24} lg={12}>
                    <ScheduleIndex />
                </Col>
            </Row>
        </Content>
    );

};

export default DashboardIndex;