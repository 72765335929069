import React, { useEffect,useState } from 'react';

import { Card, Tabs, TabsProps } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';

import dayjs from 'dayjs';

import { listMaintenance } from '../../services/Maintenance.service';
import { listNextNoticeSchedules } from '../../services/Notice.service';
import { listReservesTodayAndAfter } from '../../services/Reserve.service';
import { ReserveActionsCell } from './ReserveActionsCell';
import { ScheduleContext } from './useSchedule';

const columnsMaintenance: ColumnsType<Maintenance.Model> = [
    {
        title: 'Data estimada',
        dataIndex: 'estimatedDate',
        key: 'estimatedDate',
        render: (estimatedDate) => (dayjs(estimatedDate).format('DD/MM/YYYY'))
    },
    {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description'
    }
];

const columnsNotice: ColumnsType<Notice.ScheduleModel> = [
    {
        title: 'Data do envio',
        dataIndex: 'when',
        key: 'when',
        render: (_, n) => (`${dayjs(n.when).format('DD/MM/YYYY')} - ${n.whatTime}`)
    },
    {
        title: 'Assunto',
        dataIndex: 'subject',
        key: 'subject',
        render: (_, n) => (n.notice?.title)
    }
];

const columnsReserve: ColumnsType<Reserve.Model> = [
    {
        dataIndex: ['apartment', 'name'],
        render: (value, record) => `${record.apartment.tower.name} ${value}`
    },

    {
        dataIndex: ['ambient', 'name'],
    },

    {
        dataIndex: 'bookingDate',
        render: value => dayjs(value).format('DD/MM/YYYY'),
    },

    {
        width: '100px',
        align: 'center',
        render: (_, record) => <ReserveActionsCell record={record} />,
    },
];

const ScheduleIndex: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [notices, setNotices] = useState<Array<Notice.ScheduleModel>>([]);
    const [maintenances, setMaintenances] = useState<Array<Maintenance.Model>>([]);
    const [reserves, setReserves] = useState<Reserve.Model[]>([]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Avisos',
            children: <Table
                showHeader={false}
                pagination={false}
                columns={columnsNotice}
                dataSource={notices} />
        },

        {
            key: '2',
            label: 'Manutenções',
            children: <Table
                showHeader={false}
                pagination={false}
                columns={columnsMaintenance}
                dataSource={maintenances} />
        },

        {
            key: '3',
            label: 'Eventos',
            children: <Table
                showHeader={false}
                pagination={false}
                columns={columnsReserve}
                dataSource={reserves} />
        }
    ];

    const fetchReserves = async () => {
        const response = await listReservesTodayAndAfter();

        if ('status' in response)
            return console.error(response.message);

        setReserves(response);
    };

    async function fetchData() {
        const promises = [];
        setLoading(true);

        promises.push(listMaintenance().then((response) => {
            if (!response.success)
                return console.error(response.message);

            setMaintenances(response.maintenances.filter((m) => m.isFinished));
        }));

        promises.push(listNextNoticeSchedules().then((response) => {
            if ('status' in response)
                return console.error(response.message);

            setNotices(response);
        }));

        promises.push(listReservesTodayAndAfter().then((response) => {
            if ('status' in response)
                return console.error(response.message);

            setReserves(response);
        }));

        Promise.all(promises).then(() => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <ScheduleContext.Provider value={{ fetchReserves }}>
            <Card title="Agenda" loading={loading}>
                <Tabs defaultActiveKey="1" items={items} />
            </Card>
        </ScheduleContext.Provider>
    );
};

export default ScheduleIndex;