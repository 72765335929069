import React from 'react';

import {
    Alert,
    Drawer,
} from 'antd';

import { FilesGalery } from 'components/_Common/FilesGalery';
import dayjs from 'dayjs';
import { DATE_CLIENT_FORMAT } from 'internal-constants';
import { usePayment } from 'lib/providers/PaymentContextProvider';

export default function ViewPaymentModal() {
    const { isViewModalVisible, setIsViewModalVisible, setPaymentId, payment } = usePayment();

    const close = () => {
        setIsViewModalVisible(false);
        setPaymentId(undefined);
    };

    return (
        <Drawer
            open={isViewModalVisible}
            destroyOnClose
            title={payment !== null ? payment.name : ''}
            placement="right"
            onClose={close}
        >
            <Alert
                message={`Pagamento realizado em ${dayjs(payment?.payment_date).format(DATE_CLIENT_FORMAT)}`}
                type="success"
                showIcon
                style={{ marginBottom: 16 }}
            />

            <FilesGalery files={payment !== null ? payment.files : []} />
        </Drawer>
    );
}