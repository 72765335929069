import dayjs from 'dayjs';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

const wl = makeWhiteLabel();
const year = dayjs().year();

export const COPYRIGHT = `${wl.name} ©${year}` as const;
export const DATE_SERVER_FORMAT = 'YYYY-MM-DD' as const;
export const DATE_CLIENT_FORMAT = 'DD/MM/YYYY' as const;
export const DATETIME_SERVER_FORMAT = `${DATE_SERVER_FORMAT} HH:MM:ss` as const;
export const DATETIME_CLIENT_FORMAT = `${DATE_CLIENT_FORMAT} HH:mm` as const;