import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Input, Row, Select, SelectProps } from 'antd';

import { ClearOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';

dayjs.extend(isBetween);

type Props = { isOverviewModule: boolean };

export type Filter = {
    client: Issue.Model['clientId'] | null | undefined,
    status: Issue.Model['status'] | 'overdue' | null | undefined,
    created_at: [Dayjs | null, Dayjs | null],
    issueTypeId: Array<Issue.Model['issueTypeId']> | null | undefined,
    user_responsible_id: Issue.Model['user_responsible_id'] | null | undefined,
    search: string,
};

type ClientSelectProps = SelectProps<Issue.Model['clientId']>;
type Options = NonNullable<ClientSelectProps['options']>;

/** @factory */
export const makeClientOptions = (): Options => {
    const clients = Authentication.getClients();

    const options = clients.map(({ id, name }) => ({
        value: id,
        label: name
    }));

    return options;
};

const filterOption = makeFilterOption();

export function Filters({ isOverviewModule }: Props) {
    const [filter, setFilter] = useState<Filter>({} as Filter);
    const { issues, issueTypes, users, setIssuesFiltered } = useIssue();

    const Client = () => (
        <Select
            style={{ width: '100%' }}
            value={filter.client}
            onClear={() => setFilter({ ...filter, client: null })}
            onSelect={(value) => setFilter({ ...filter, client: value })}
            placeholder="Condomínio"
            options={makeClientOptions()}
            filterOption={filterOption}
            allowClear
            showSearch
        />
    );

    const Status = () => (
        <Select
            style={{ width: '100%' }}
            value={filter.status}
            onClear={() => setFilter({ ...filter, status: null })}
            onSelect={(value) => setFilter({ ...filter, status: value })}
            placeholder="Status"
            allowClear
        >
            {/* TODO: Add `overdue` option (derived issue status) */}
            <Select.Option value="overdue">Atrasado</Select.Option>
            <Select.Option value="opened">Pendente</Select.Option>
            <Select.Option value="in_progress">Em andamento</Select.Option>
            <Select.Option value="waiting_ogm">Aguardando assembleia</Select.Option>
            <Select.Option value="closed">Resolvido</Select.Option>
        </Select>
    );

    const CreatedAt = () => (
        <DatePicker.RangePicker
            style={{ width: '100%' }}
            value={filter.created_at}
            onChange={(value) => {
                if (value === null)
                    return setFilter({ ...filter, created_at: [null, null] });

                setFilter({ ...filter, created_at: [dayjs(value[0]), dayjs(value[1])] });
            }}
            placeholder={['Aberto de', 'até']}
            format="DD/MM/YYYY"
        />
    );

    const IssueType = () => (
        <Select
            style={{ width: '100%' }}
            value={filter.issueTypeId}
            onChange={(value) => setFilter({ ...filter, issueTypeId: value })}
            placeholder="Categoria"
            mode="multiple"
            allowClear
            options={issueTypes.map(type => ({ value: type.id, label: type.name }))}
            filterOption={filterOption}
        />
    );

    const Responsible = () => {
        const userLoggedId = Authentication.getUserId();

        const options = [
            { value: userLoggedId, label: 'Meus chamados' },

            ...users
                .filter(user => user.id !== userLoggedId)
                .map(user => ({ value: user.id, label: user.name }))
        ];

        return (
            <Select
                style={{ width: '100%' }}
                value={filter.user_responsible_id}
                onChange={(value) => setFilter({ ...filter, user_responsible_id: value })}
                placeholder="Responsável"
                allowClear
                showSearch
                options={options}
                filterOption={filterOption}
            />
        );
    };

    const Search = () => (
        <Input.Search
            style={{ width: '100%' }}
            defaultValue={filter.search}
            allowClear
            onSearch={(value) => setFilter({ ...filter, search: value })}
            placeholder="Pesquisar"
        />
    );

    const ClearFilter = () => (
        <Button
            type="primary"
            icon={<ClearOutlined />}
            onClick={() => setFilter({} as Filter)}
            title="Limpar fltros"
        />
    );

    const handleFilter = () => {
        let data = issues;

        if (filter.client !== null && filter.client !== undefined)
            data = data.filter(issue => filter.client === issue.clientId);

        if (filter.status !== null && filter.status !== undefined) {
            if (filter.status === 'closed')
                data = data.filter(issue => issue.status === 'closed');
            else if (filter.status === 'waiting_ogm')
                data = data.filter(issue => issue.status === 'waiting_ogm');
            else if (filter.status === 'overdue')
                data = data.filter(issue => ['opened', 'in_progress', 'waiting_ogm'].includes(issue.status) && dayjs(issue.deadline).isBefore(dayjs()));
            else
                data = data.filter(issue => filter.status === issue.status && (issue.deadline === null || dayjs(issue.deadline).isAfter(dayjs())));
        }

        if (filter.issueTypeId !== null && filter.issueTypeId !== undefined && filter.issueTypeId.length > 0)
            data = data.filter(issue => filter.issueTypeId?.includes(issue.issueTypeId));

        if (filter.user_responsible_id !== null && filter.user_responsible_id !== undefined)
            data = data.filter(issue => filter.user_responsible_id === issue.user_responsible_id);

        if (filter.created_at !== undefined && filter.created_at[0] !== null && filter.created_at[1] !== null)
            data = data.filter(issue => dayjs(issue.creation_date).isBetween(filter.created_at[0], filter.created_at[1]));

        if (filter.search !== undefined && filter.search.length > 0)
            data = data.filter(issue => issue.subject).filter(issue => issue.description).filter(issue =>
                issue.subject.toLowerCase().includes(filter.search.toLowerCase()) ||
                issue.description.toLowerCase().includes(filter.search.toLowerCase()));

        setIssuesFiltered(data);
    };

    useEffect(() => {
        handleFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const colProps = {
        style: { marginTop: 5 },
        xs: 24,
        sm: 24,
    };

    return (
        <Row align="middle">
            <Show when={isOverviewModule}>
                <Col {...colProps} md={4} lg={4}>
                    <Client />
                </Col>
            </Show>

            <Col {...colProps} md={3} lg={3}>
                <Status />
            </Col>

            <Col {...colProps} md={6} lg={6}>
                <CreatedAt />
            </Col>

            <Col {...colProps} md={3} lg={3}>
                <Responsible />
            </Col>

            <Col {...colProps} md={3} lg={3}>
                <IssueType />
            </Col>

            <Col {...colProps} md={4} lg={4}>
                <Search />
            </Col>

            <Col {...colProps} md={1} lg={1}>
                <ClearFilter />
            </Col>
        </Row >
    );
};