import { useState } from 'react';
import { Link } from 'react-router-dom';

import React, { App, Button, notification, Popconfirm, Space, TableProps, Tag } from 'antd';

import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, LinkOutlined, PieChartOutlined, SendOutlined, StopOutlined } from '@ant-design/icons';
import { NonAuthRoutes } from 'components/_Common/_Routes/Routes';
import dayjs from 'dayjs';
import { DATE_CLIENT_FORMAT } from 'internal-constants';
import { useGeneralMeeting } from 'lib/providers/GeneralMeetingContextProvider';
import { publishGeneralMeeting } from 'services/GeneralMeeting.service';

type Columns = NonNullable<TableProps<GeneralMeeting.Model>['columns']>;

type Props = { generalMeeting: GeneralMeeting.Model };

const Actions = ({ generalMeeting }: Props) => {
    const [isSending, setIsSending] = useState(false);
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);

    const app = App.useApp();

    const {
        setGeneralMeetingId,
        setIsEditModalVisible,
        fetchGeneralMeetings
    } = useGeneralMeeting();

    const handleEdit = () => {
        setGeneralMeetingId(generalMeeting.id);
        setIsEditModalVisible(true);
    };

    const handleCopyToClipboard = async () => {
        const { host } = document.location;

        const route = NonAuthRoutes.externalGeneralMeetingView;
        const parsedRoute = route.replace(':id', generalMeeting.id.toString());

        const link = host + parsedRoute;
        
        await navigator.clipboard.writeText(link);

        app.notification.info({ 
            message: 'Link copiado com sucesso',
            description: `Foi copiado para a sua área de transferência o link público da enquete #${generalMeeting.id}`
        });
    };

    const handlePublish = async () => {
        setIsSending(true);

        const response = await publishGeneralMeeting(generalMeeting.id, {
            available: !generalMeeting.available
        });

        notification.open(response);
        setIsPopconfirmVisible(false);
        fetchGeneralMeetings();
        setIsSending(false);
    };

    const isAvailable = generalMeeting.available;

    return (
        <Space size="middle">
            <Popconfirm
                title="Alterar status"
                description={`Tem certeza que deseja ${isAvailable ? 'bloquear' : 'liberar'} esta enquete?`}
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handlePublish}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <Button
                    type="text"
                    icon={isAvailable ? <StopOutlined /> : <SendOutlined />}
                    title={isAvailable ? 'Bloquear' : 'Liberar'}
                    onClick={() => setIsPopconfirmVisible(true)}
                />
            </Popconfirm>
            
            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={handleEdit}
            />

            <Button
                type="text"
                icon={<LinkOutlined />}
                title="Copiar"
                onClick={handleCopyToClipboard}
            />

            <Link to={`/general_meeting_report/${generalMeeting.id}`}>
                <Button
                    type="text"
                    icon={<PieChartOutlined />}
                    title="Ver relatório"
                />
            </Link>
        </Space>
    );
};

export const COLUMNS: Columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        render: (value) => `#${value}`,
        width: 40,
    },

    {
        title: 'Título',
        dataIndex: 'title',
        ellipsis: true,
        width: 180,
    },

    {
        title: 'Status',
        dataIndex: 'available',
        render: (_, generalMeeting) => {
            const today = dayjs();
            const parsedClosingDate = dayjs(generalMeeting.closing_date);

            if (today.isAfter(parsedClosingDate, 'day'))
                return <Tag icon={<ExclamationCircleOutlined />} color="red">Expirada</Tag>;

            if (generalMeeting.available)
                return <Tag icon={<CheckCircleOutlined />} color="success">Liberada</Tag>;
            
            if (!generalMeeting.available)
                return <Tag icon={<ExclamationCircleOutlined />} color="orange">Bloqueada</Tag>;

            return null;
        },
        width: 140,
    },

    {
        title: 'Prazo',
        dataIndex: 'closing_date',
        render: value => dayjs(value).format(DATE_CLIENT_FORMAT),
        width: 110,
    },

    {
        title: 'Perguntas',
        dataIndex: ['questions', 'length'],
        width: 60,
    },

    {
        width: 120,
        align: 'center',
        render: (_, generalMeeting) => <Actions generalMeeting={generalMeeting} />,
    },
];