import React, { useState } from 'react';

import { Button, Col, Divider, Dropdown, MenuProps, Row, Typography } from 'antd';

import { SnippetsOutlined } from '@ant-design/icons';

import Content from '../_Common/_Layout/Content';
import FormIssue from './Form/Issue';
import ApartmentSearch from './View/ApartmentSearch';
import ReserveToday from './View/Reserve';

const ConciergeIndex: React.FC = () => {
    const [open, setOpen] = useState(false);

    const items: MenuProps['items'] = [
        { key: '1', label: (<Button type="link" icon={<SnippetsOutlined />} onClick={() => setOpen(true)}>Chamado</Button>) },
        // { key: '2', label: (<Button type="link" icon={<SnippetsOutlined />} onClick={() => setOpen(true)}>Reserva</Button>) },
        // { key: '3', label: (<Button type="link" icon={<SnippetsOutlined />} onClick={() => setOpen(true)}>Correspondências e entregas</Button>) }
    ];

    return (
        <Content>
            <Row className='space-between' align="middle">
                <Typography.Title level={3}>Módulo Portaria
                    <Divider type='vertical' />
                    <Dropdown menu={{ items }} placement="bottom">
                        <Button type='primary'>Cadastrar</Button>
                    </Dropdown>
                </Typography.Title>
            </Row>
            <Row>
                {/* <Col span={14}>
                    <Card bodyStyle={{ padding: 0 }}>
                        <DeviceView />
                    </Card>
                </Col> */}
                <Col span={24}>
                    <ApartmentSearch />
                    <Divider />
                    <ReserveToday />
                </Col>
            </Row>
            <FormIssue isOpen={open} closeForm={() => setOpen(false)} />
        </Content>
    );

};

export default ConciergeIndex;