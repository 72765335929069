import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { CurrentBookingDateActionsCell } from './CurrentBookingDateActionsCell';

export const getCurrentBookingDateColumns = (fetchReserves: () => void): ColumnsType<Reserve.Model> => [
    {
        dataIndex: ['apartment', 'name'],
        title: 'Apartamento',
        render: (value, record) => `${record.apartment.tower.name} ${value}`
    },

    {
        title: 'Ambiente',
        dataIndex: ['ambient', 'name'],
    },

    {
        width: '100px',
        align: 'center',
        render: (_, reserve) => (
            <CurrentBookingDateActionsCell 
                reserve={reserve} 
                fetchReserves={fetchReserves}
            />
        ),
    },
];