import React from 'react';

import type { TableColumnsType } from 'antd';

import { NoticeActionsCell } from 'components/Notice/NoticeActionsCell';

export const Columns: TableColumnsType<Notice.Model> = [
    {
        title: 'Título',
        dataIndex: 'title',
    },

    {
        title: 'Conteúdo',
        dataIndex: 'body',
    },

    {
        title: 'Enviado imediatamente',
        dataIndex: 'immediate_dispatch',
        render: value => value ? 'Sim' : 'Não',
    },

    {
        width: '100px',
        align: 'center',
        render: (_, notice) => <NoticeActionsCell notice={notice} />,
    },
];