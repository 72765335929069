import React, { useEffect, useState } from 'react';

import { App, Col, Divider, Form, Input, InputNumber, Modal, Row, Spin } from 'antd';

import { sleep } from 'lib/Sleep';
import { findApartmentType, updateApartmentType } from 'services/ApartmentService';

type Props = {
    close: () => void,
    fetchTypes: () => void,
    typeId: number,
};

export type Values = {
    name: string,
    idealFraction: number,
};

export function EditTypeModal({ close, fetchTypes, typeId }: Props) {
    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    if (!typeId)
        throw new TypeError('The `typeId` property cannot be unknown!');

    const fetchType = async () => {
        setIsFetching(true);

        const response = await findApartmentType(typeId);

        setIsFetching(false);

        if ('message' in response)
            return app.notification.open(response);

        form.setFieldsValue(response.type);
    };

    useEffect(() => {
        fetchType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = async (values: Values) => {
        setIsSending(true);

        const response = await updateApartmentType(typeId, values);

        // Improves UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchTypes();
    };

    return (
        <Modal
            open
            width={610}
            title="Editar tipo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Spin spinning={isFetching}>
                <Divider />

                <Form
                    form={form}
                    onFinish={handleFinish}
                    name="editType"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row>
                        <Col span="12">
                            <Form.Item<Values>
                                name="name"
                                label="Nome"
                                rules={[{
                                    required: true,
                                    message: 'Por favor, digite um nome.'
                                }]}
                            >
                                <Input data-1p-ignore />
                            </Form.Item>
                        </Col>

                        <Col span="12">                
                            <Form.Item<Values>
                                name="idealFraction"
                                label="Fração ideal"
                                rules={[{
                                    required: true,
                                    message: 'Por favor, digite um valor.'
                                }]}
                            >
                                <InputNumber
                                    decimalSeparator=","
                                    min={0}
                                    max={1}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
