import React, { useState } from 'react';

import { Form, Input, Modal, notification, Select, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import Authentication from 'lib/Authentication';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { UploadField } from 'lib/UploadField';
import { ParsedFile } from 'services/contracts/Issue.contract';
import { createIssueFollowUp, uploadFile } from 'services/Issue.service';

export type Values = {
    description: Issue.IssueFollowUp['description'],
    issue_id: Issue.IssueFollowUp['issue_id'],
    is_internal: boolean,
    files: UploadFile[] | [] | null,
};

export default function FollowUpCreateModal() {
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);

    const {
        issue,
        setIsFollowUpCreateModal,
        fetchIssues
    } = useIssue();

    if (!issue)
        throw new Error('Value of the `issue` property is unknown');

    const close = () => {
        setIsFollowUpCreateModal(false);
        setSaving(false);
        form.resetFields();
    };

    async function onFinish() {
        try {
            setSaving(true);

            const issue_follow_up = await form.validateFields();

            const { files, ...body } = issue_follow_up;

            const response = await createIssueFollowUp(body);

            if (files && response.id) {
                const parsedFiles: ParsedFile[] = files.map((file: UploadFile): ParsedFile => ({
                    filename: file.name,
                    url: file.response ?? file.url,
                    issue_follow_up_id: response.id,
                }));

                await Promise.all(parsedFiles.map(file => uploadFile(file)));
            }

            await fetchIssues();
            notification.success({ message: response.message, description: response.description });

            close();
        } catch (error) {
            setSaving(false);
        }
    };

    return (
        <Modal
            centered
            title="Novo acompanhamento"
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={close}
            afterClose={() => form.resetFields()}
            okText="Salvar"
            open
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Atualizar status do chamado" rules={[{ required: true }]} name="new_status" initialValue={issue.status}>
                    <Select>
                        <Select.Option value={'opened'}>Pendente</Select.Option>
                        <Select.Option value={'in_progress'}>Em andamento</Select.Option>
                        <Select.Option value={'waiting_ogm'}>Aguardando assembleia</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="description" label="Descreva" rules={[{ required: true, message: 'Descreva' }]}>
                    <TextArea rows={5} allowClear />
                </Form.Item>

                <Form.Item hidden={true} name="issue_id" initialValue={issue.id}>
                    <Input />
                </Form.Item>

                <Form.Item hidden={true} name="user_id" initialValue={Authentication.getUserId()}>
                    <Input />
                </Form.Item>

                <Form.Item label="Interno ou público (disponível aos condôminos)?" rules={[{ required: true }]} name="is_internal" initialValue={true} help='Ao selecionar a opção "Público" o comentário se torna acessível aos condôminos pelo aplicativo ou nas emissões de relatórios.'>
                    <Select>
                        <Select.Option value={true}>Interno</Select.Option>
                        <Select.Option value={false}>Público</Select.Option>
                    </Select>
                </Form.Item>

                <UploadField name='files' type='picture' multiple />
            </Form>
        </Modal >
    );

};