import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useSearchParams } from 'lib/hooks/useSearchParams';
import { listMaintenaceType, listMaintenance } from 'services/Maintenance.service';

type Value = {
    isLoading: boolean,
    maintenances: Maintenance.Model[],
    maintenancesFiltered: Maintenance.Model[],
    maintenanceTypes: Maintenance.Type[],
    maintenance: Maintenance.Model | null,
    maintenanceId: Maintenance.Model['id'] | null,
    setMaintenanceId: Dispatch<SetStateAction<Value['maintenanceId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    isDetailModalVisible: boolean,
    setIsDetailModalVisible: Dispatch<SetStateAction<Value['isDetailModalVisible']>>,
    isFinishMaintenanceModal: boolean,
    setIsFinishMaintenanceModal: Dispatch<SetStateAction<Value['isFinishMaintenanceModal']>>,
    isReportMaintenanceModal: boolean,
    setIsReportMaintenanceModal: Dispatch<SetStateAction<Value['isReportMaintenanceModal']>>,
    isTutorialMaintenanceModal: boolean,
    setIsTutorialMaintenanceModal: Dispatch<SetStateAction<Value['isTutorialMaintenanceModal']>>,
    setMaintenancesFiltered: Dispatch<SetStateAction<Value['maintenancesFiltered']>>,
    fetchMaintenances: () => Promise<void>,
    isOverviewModule: boolean,
};

type Props = { isOverviewModule: boolean, children: (value: Value) => ReactNode };

const MaintenanceContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function MaintenanceContextProvider({ isOverviewModule, children }: Props) {
    const searchParams = useSearchParams();

    const history = useHistory();
    
    const [isLoading, setIsLoading] = useState(false);

    const [maintenances, setMaintenances] = useState<Maintenance.Model[]>([]);
    const [maintenancesFiltered, setMaintenancesFiltered] = useState<Maintenance.Model[]>([]);
    const [maintenanceTypes, setMaintenanceTypes] = useState<Maintenance.Type[]>([]);
    
    const [maintenanceId, setMaintenanceId] = useState<Maintenance.Model['id'] | null>(() => {
        const finish = searchParams.get('finish');
        const edit = searchParams.get('edit');
        const detail = searchParams.get('detail');

        if (finish !== null)
            return parseInt(finish, 10);
    
        if (edit !== null)
            return parseInt(edit, 10);

        if (detail !== null)
            return parseInt(detail, 10);

        return null;
    });

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(() => maintenanceId !== null && searchParams.has('edit'));
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(() => maintenanceId !== null && searchParams.has('detail'));
    const [isFinishMaintenanceModal, setIsFinishMaintenanceModal] = useState(() => maintenanceId !== null && searchParams.has('finish'));
    const [isReportMaintenanceModal, setIsReportMaintenanceModal] = useState<boolean>(false);
    const [isTutorialMaintenanceModal, setIsTutorialMaintenanceModal] = useState<boolean>(false);

    const app = App.useApp();

    const fetchMaintenances = async () => {
        setIsLoading(true);

        const clients = isOverviewModule
            ? Authentication.getClients().map(client => client.id)
            : [Authentication.getCurrentClientId()];

        const response = await listMaintenance(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setMaintenances(response.maintenances);
        setMaintenancesFiltered(response.maintenances);
    };

    const fetchMaintenanceTypes = async () => {
        setIsLoading(true);

        const response = await listMaintenaceType();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setMaintenanceTypes(response.maintenance_types);
    };

    const memoizedFetchMaintenances = useCallback(fetchMaintenances, [app, isOverviewModule]);

    useEffect(() => {
        fetchMaintenanceTypes();
        memoizedFetchMaintenances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedFetchMaintenances]);

    useEffect(() => {
        // Only delete the parameter when the modal is closed
        if (isFinishMaintenanceModal === false) {
            searchParams.delete('finish');

            history.replace({ search: searchParams.toString() });
        }
    }, [searchParams, history, isFinishMaintenanceModal]);

    useEffect(() => {
        // Only delete the parameter when the modal is closed
        if (isEditModalVisible === false) {
            searchParams.delete('edit');

            history.replace({ search: searchParams.toString() });
        }
    }, [searchParams, history, isEditModalVisible]);

    useEffect(() => {
        // Only delete the parameter when the modal is closed
        if (isDetailModalVisible === false) {
            searchParams.delete('detail');

            history.replace({ search: searchParams.toString() });
        }
    }, [searchParams, history, isDetailModalVisible]);

    const maintenance = useMemo(() => {
        if (!maintenanceId || maintenances.length === 0)
            return null;

        const found = maintenances.find(maintenance => maintenance.id === maintenanceId);

        if (!found)
            throw new Error(`Could not find a maintenance with id ${maintenanceId}`);

        return found;

    }, [maintenances, maintenanceId]);

    const value: Value = {
        isLoading,
        maintenances,
        maintenancesFiltered,
        maintenanceTypes,
        maintenance,
        maintenanceId,
        setMaintenanceId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        isDetailModalVisible,
        setIsDetailModalVisible,
        isFinishMaintenanceModal,
        setIsFinishMaintenanceModal,
        isReportMaintenanceModal,
        setIsReportMaintenanceModal,
        isTutorialMaintenanceModal,
        setIsTutorialMaintenanceModal,
        setMaintenancesFiltered,
        fetchMaintenances: memoizedFetchMaintenances,
        isOverviewModule,
    };

    return (
        <MaintenanceContext.Provider value={value}>
            {children(value)}
        </MaintenanceContext.Provider>
    );
}

export function useMaintenance() {
    const context = useContext(MaintenanceContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `MaintenanceContextProvider`.');

    return context;
}