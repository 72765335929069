import React from 'react';

import { Tag } from 'antd';

import { getIssueDerivedStatus, getIssueDerivedStatusTagProps } from 'lib/helpers/Issue.helper';

import API from '../../../lib/API';
import { IssueInterface } from '../../_Common/_Interfaces/Issue';
import List from '../../_Common/List';

type State = {
    issues: Array<IssueInterface>,
    loading: Boolean
};

type Props = {
    apartmentId: number;
    history: any;
    match: any;
}

export default class IssueList extends React.Component<Props, State> {

    state = {
        loading: true,
        issues: []
    };

    columns = [
        { title: 'ID', key: 'id', render: (_: any, issue: IssueInterface) => `#${issue.id}` },
        {
            title: 'Status', key: 'id', render: (_: any, issue: IssueInterface) => {
                const derivedStatus = getIssueDerivedStatus(issue.status, issue.deadline);
                const props = getIssueDerivedStatusTagProps(derivedStatus);

                return <Tag {...props} />;
            }
        },
        { title: 'Tipo', key: 'issueTypeId', render: (_: any, issue: IssueInterface) => issue.issue_type.name },
        { title: 'Assunto', key: 'subject', dataIndex: 'subject' }
    ];

    fetchData = async () => {
        const { apartmentId } = this.props;
        const issues = await API.get(`/issue/apartment/${apartmentId}`);
        this.setState({ issues, loading: false });
    };

    componentDidMount = async () => {
        await this.fetchData();
    };

    render() {

        const { issues, loading } = this.state;

        return (
            <List data={issues} loading={loading} columns={this.columns} />
        );
    }

}