import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listIssueType } from 'services/Issue.service';
import { listMaintenaceType } from 'services/Maintenance.service';
import { generateConsolidatedReportIssuesAndMaintenances, generateDetailedReportConstructions, generateDetailedReportIssues, generateDetailedReportMaintenances } from 'services/Report.service';
import { listUser } from 'services/UserService';

export type ReportContextValue = {
    loading: boolean,
    callSpecificReportMethod: (report: 'issue' | 'maintenance' | 'construction' | 'all', filters: Report.Filter) => void
    users: User.Model[],
    types: Issue.Type[],
    maintenanceTypes: Maintenance.Type[],
};

type Props = { children: (value: ReportContextValue) => ReactNode };

const ReportContext = createContext<ReportContextValue | null>(null);

/**
 * @see https://www.youtube.com/watch?v=I7dwJxGuGYQ
 * @todo Abstract `loadings` state updates (Add/Remove) with `useReducer`
 */
export function ReportContextProvider({ children }: Props) {

    const [loading, setLoading] = useState<boolean>(false);

    const app = App.useApp();

    const [types, setIssueTypes] = useState<Issue.Type[]>([]);
    const [users, setUsers,] = useState<User.Model[]>([]);
    const [maintenanceTypes, setMaintenanceTypes] = useState<Maintenance.Type[]>([]);


    const fetchIssueTypes = async () => {
        setLoading(true);

        const response = await listIssueType();

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIssueTypes(response.issue_types);
    };

    const fetchUsers = async () => {
        setLoading(true);

        const clients = Authentication.getClients().map(client => client.id);

        const response = await listUser(clients);

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setUsers(response.users);
    };

    const fetchMaintenanceTypes = async () => {
        setLoading(true);

        const response = await listMaintenaceType();

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setMaintenanceTypes(response.maintenance_types);
    };


    useEffect(() => {
        fetchUsers();
        fetchIssueTypes();
        fetchMaintenanceTypes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callSpecificReportMethod = (report: 'issue' | 'maintenance' | 'construction' | 'all', filters: Report.Filter) => {
        if (report === 'issue')
            generateReportIssuesDetailed(filters);

        if (report === 'maintenance')
            generateReportMaintenancesDetailed(filters);

        if (report === 'construction')
            generateReportConstructionsDetailed(filters);

        if (report === 'all')
            generateReportConsolidated(filters);
    };

    const generateReportConsolidated = async (filters: Report.Filter) => {
        setLoading(true);

        const response = await generateConsolidatedReportIssuesAndMaintenances(filters);

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.info({
            message: 'Atenção',
            description: 'O relatório solicitado está sendo processado e logo será enviado para o seu e-mail.'
        });
    };

    const generateReportIssuesDetailed = async (filters: Report.Filter) => {
        setLoading(true);

        const response = await generateDetailedReportIssues(filters);

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.info({
            message: 'Atenção',
            description: 'O relatório solicitado está sendo processado e logo será enviado para o seu e-mail.'
        });
    };

    const generateReportMaintenancesDetailed = async (filters: Report.Filter) => {
        setLoading(true);

        const response = await generateDetailedReportMaintenances(filters);

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.info({
            message: 'Atenção',
            description: 'O relatório solicitado está sendo processado e logo será enviado para o seu e-mail.'
        });
    };

    const generateReportConstructionsDetailed = async (filters: Report.Filter) => {
        setLoading(true);

        const response = await generateDetailedReportConstructions(filters);

        setLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        app.notification.info({
            message: 'Atenção',
            description: 'O relatório solicitado está sendo processado e logo será enviado para o seu e-mail.'
        });
    };

    const value: ReportContextValue = {
        loading,
        callSpecificReportMethod: callSpecificReportMethod,
        users,
        types,
        maintenanceTypes,
    };

    return (
        <ReportContext.Provider value={value}>
            {children(value)}
        </ReportContext.Provider>
    );
}

export function useReport() {
    const context = useContext(ReportContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ReportContextProvider`.');

    return context;
}
