import React, { useEffect, useState } from 'react';

import { App, Divider, Form, Input, Modal, notification, Select } from 'antd';

import { createApartment, listApartmentTypes, updateApartment } from 'services/ApartmentService';
import { listResident } from 'services/ResidentService';
import { listTower } from 'services/TowerService';

type Props = {
    isOpen: boolean,
    closeForm: Function,
    apartment: Apartment.Model | undefined
};

export type Values = {
    name: Apartment.Model['name'],
    towerId: Apartment.Model['towerId'],
    apartment_type_id: Apartment.Model['apartment_type_id'],
    mainOwnerId: Apartment.Model['mainOwnerId']
};

const FormApartment: React.FC<Props> = (props: Props) => {

    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);
    const [isLoding, setLoading] = useState(false);
    const [towers, setTowers] = useState<Array<Apartment.Tower>>();
    const [residents, setResidents] = useState<Array<Apartment.Resident>>();
    const [apartmentTypes, setApartmentTypes] = useState<Array<Apartment.Type>>();

    const app = App.useApp();

    async function onFinish() {
        try {
            setSaving(true);

            const apartment = await form.validateFields();
            const response = props.apartment?.id
                ? await updateApartment(props.apartment?.id, apartment)
                : await createApartment(apartment);

            notification.open(response);
            setSaving(false);
            props.closeForm();
        } catch (error) {
            console.log(form.getFieldsValue());
            setSaving(false);
        }
    };

    async function fetchData() {
        setLoading(true);
        const promises = [];

        promises.push(listTower().then((response) => {
            if ('status' in response)
                return;
            setTowers(response);
        }));

        promises.push(listResident().then((response) => {
            if ('status' in response)
                return;
            setResidents(response);
        }));

        promises.push(listApartmentTypes().then((response) => {
            if ('type' in response)
                return app.notification.open(response);
        
            setApartmentTypes(response.types);
        }));

        Promise.all(promises).then(() => setLoading(false));

    }

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    return (
        <Modal
            centered
            title={props.apartment?.id ? 'Editar unidade' : 'Cadastrar unidade'}
            destroyOnClose={true}
            open={props.isOpen}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={() => props.closeForm()}
            afterClose={() => form.resetFields()}
            okText="Salvar"
        >
            <Divider />
            <Form form={form} layout="vertical" initialValues={props.apartment} onFinish={onFinish}>
                <Form.Item name="towerId" label="Torre ou bloco" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                    <Select loading={isLoding} options={towers?.map(t => ({ value: t.id, label: t.name }))} />
                </Form.Item>
                <Form.Item name="name" label="Apartamento" rules={[{ required: true, message: 'Descreva' }]}>
                    <Input maxLength={40} />
                </Form.Item>
                <Form.Item name="apartment_type_id" label="Tipo de apartamento" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                    <Select loading={isLoding} options={apartmentTypes?.map(t => ({ value: t.id, label: t.name }))} />
                </Form.Item>
                <Form.Item name="mainOwnerId" label="Proprietário principal">
                    <Select 
                        loading={isLoding}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={residents?.map(t => ({ value: t.id, label: t.name }))} />
                </Form.Item>
            </Form>
        </Modal >
    );

};

export default FormApartment;