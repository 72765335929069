import config from 'config';
import Authentication from 'lib/Authentication';

import API from '../lib/API';
import { 
    CreateApartment, 
    CreateApartmentType,
    DeleteApartmentType,
    FindApartment, 
    FindApartmentType,
    ListApartment,
    ListApartmentsByTowers,
    ListApartmentTypes,
    ReconcileUnitsWithDebit,
    UpdateApartment,
    UpdateApartmentType,
} from './contracts/ApartmentServiceContract';

const route = '/apartment';

export function listApartment(): Promise<ListApartment.Response> {
    return API.get(route);
};

export function findApartment(id: Apartment.Model['id']): Promise<FindApartment.Response> {
    return API.get(`${route}/${id}`);
};

export function createApartment(body: CreateApartment.Body): Promise<CreateApartment.Response> {
    return API.post(route, body);
};

export function updateApartment(id: Apartment.Model['id'], body: UpdateApartment.Body): Promise<UpdateApartment.Response> {
    return API.put(`${route}/${id}`, body);
};

export function listApartmentTypes(): Promise<ListApartmentTypes.Response> {
    return API.get(`${route}/type`);
};

export function findApartmentType(id: Apartment.Type['id']): Promise<FindApartmentType.Response> {
    return API.get(`${route}/type/${id}`);
};

export function createApartmentType(body: CreateApartmentType.Body): Promise<CreateApartmentType.Response> {
    return API.post(`${route}/type`, body);
};

export function updateApartmentType(id: Apartment.Type['id'], body: UpdateApartmentType.Body): Promise<UpdateApartmentType.Response> {
    return API.put(`${route}/type/${id}`, body);
};

export function deleteApartmentType(id: Apartment.Type['id']): Promise<DeleteApartmentType.Response> {
    return API.delete(`${route}/type/${id}`);
}

export function listApartmentsByTowers(ids: number[]): Promise<ListApartmentsByTowers.Response> {
    const params = new URLSearchParams();

    /** @see https://blog.shalvah.me/posts/fun-stuff-representing-arrays-and-objects-in-query-strings */
    ids.forEach(id => params.append('ids[]', id.toString()));

    return API.get(`${route}/towers?${params.toString()}`);
}

export const reconcileUnitsWithDebit = async (file: File): Promise<ReconcileUnitsWithDebit.Response> => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await fetch(`${config.api}/${route}/reconcile`, {
        body: formData,
        headers: {
            'x-authorization-gcondo': Authentication.getToken(),
            'x-client-gcondo': Authentication.getCurrentClientId().toString(),
        },
        method: 'POST'
    });

    return response.json();
};