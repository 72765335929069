import React, { Fragment, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Card, Col, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateDocumentModal } from 'components/Document/CreateDocumentModal';
import { DocumentsFilterBar } from 'components/Document/DocumentsFilterBar';
import { EditDocumentModal } from 'components/Document/EditDocumentModal';
import { DocumentContextProvider } from 'lib/providers/DocumentContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Documents({ location }: RouteComponentProps) {
    const isOverviewModule = location.pathname.includes('/overview');

    const filteredColumns = useMemo(() => (
        !isOverviewModule
            ? Columns.filter(column => column.key !== 'clientName')
            : Columns
    ), [isOverviewModule]);

    return (
        <DocumentContextProvider isOverviewModule={isOverviewModule}>
            {({
                documents,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Documentos
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <DocumentsFilterBar isOverviewModule={isOverviewModule} />

                                    <List
                                        columns={filteredColumns}
                                        data={documents}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateDocumentModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditDocumentModal />
                    </Show>
                </Fragment>
            )}
        </DocumentContextProvider>
    );
}
