import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Divider, Modal } from 'antd';

import { handleServiceError } from '../../lib/helpers/ServiceHelper';
import { sleep } from '../../lib/Sleep';
import { listPendingReservesTodayAndAfter } from '../../services/Reserve.service';
import List from '../_Common/List';
import { getPendingColumns } from './PendingColumns';

type Props = {
    onCancel: () => void,
}

export function PendingReservesModal({ onCancel }: Props) {
    const [isFetching, setIsFetching] = useState(false);
    const [reserves, setReserves] = useState<Reserve.Model[]>([]);

    const app = App.useApp();

    const fetchPendingReserves = useCallback(async () => {
        setIsFetching(true);

        const response = await listPendingReservesTodayAndAfter();

        // Improves UX
        await sleep(1000);

        setIsFetching(false);

        if (!response.success)
            return handleServiceError(app, response);

        setReserves(response.reserves);
    }, [app]);

    useEffect(() => {
        fetchPendingReserves();
    }, [fetchPendingReserves]);

    const columns = useMemo(() => getPendingColumns(fetchPendingReserves), [fetchPendingReserves]);

    return (
        <Modal
            open
            width={610}
            title="Reservas pendentes"
            footer={null}
            onCancel={onCancel}
        >
            <Divider />

            <List
                columns={columns}
                data={reserves}
                loading={isFetching}
                showHeader={false}
                showPagination={false}
            />
        </Modal>
    );
}
