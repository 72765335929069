import React, { 
    createContext, 
    Dispatch, 
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listGeneralMeetings } from 'services/GeneralMeeting.service';

type Value = {
    isLoading: boolean,
    generalMeetings: GeneralMeeting.Model[],
    generalMeeting: GeneralMeeting.Model | null,
    generalMeetingId: GeneralMeeting.Model['id'] | null,
    setGeneralMeetingId: Dispatch<SetStateAction<Value['generalMeetingId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    fetchGeneralMeetings: () => Promise<void>,
};

type Props = { children: (value: Value) => ReactNode };

const GeneralMeetingContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function GeneralMeetingContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [generalMeetings, setGeneralMeetings] = useState<GeneralMeeting.Model[]>([]);
    const [generalMeetingId, setGeneralMeetingId] = useState<GeneralMeeting.Model['id'] | null>(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const app = App.useApp();

    const fetchGeneralMeetings = async () => {
        setIsLoading(true);

        const response = await listGeneralMeetings();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setGeneralMeetings(response.general_meetings);
    };

    const memoizedFetchGeneralMeetings = useCallback(fetchGeneralMeetings, [app]);

    useEffect(() => {
        memoizedFetchGeneralMeetings();
    }, [memoizedFetchGeneralMeetings]);

    const generalMeeting = useMemo(() => {
        if (!generalMeetingId)
            return null;

        const found = generalMeetings.find(generalMeeting => generalMeeting.id === generalMeetingId);

        if (!found)
            throw new Error(`Could not find a generalMeeting with id ${generalMeetingId}`);

        return found;

    }, [generalMeetings, generalMeetingId]);

    const value: Value = { 
        isLoading, 
        generalMeetings,
        generalMeeting,
        generalMeetingId,
        setGeneralMeetingId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        fetchGeneralMeetings: memoizedFetchGeneralMeetings,
    };

    return (
        <GeneralMeetingContext.Provider value={value}>
            {children(value)}
        </GeneralMeetingContext.Provider>
    );
}

export function useGeneralMeeting() {
    const context = useContext(GeneralMeetingContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `GeneralMeetingContextProvider`.');

    return context;
}