import React from 'react';

import { Row, Spin, SpinProps } from 'antd';

type Props ={
    size?: SpinProps['size']
}

const LoadingGcondo: React.FC<Props> = (props) => {

    return <Row className='center'>
        <Spin style={{ justifySelf: 'center' }} tip="Carregando..." size={props.size ?? 'large'} />
    </Row>;
};

export default LoadingGcondo;