import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Col, Row, Typography } from 'antd';

import { CheckCircleOutlined, CloseCircleOutlined, LeftOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import { AuthRoutes } from 'components/_Common/_Routes/Routes';
import { ChartIssuesByType } from 'components/Stats/ChartIssuesByType';
import { ChartIssuesClosedBySupplier } from 'components/Stats/ChartIssuesClosedBySupplier';
import { ChartIssuesClosedByUser } from 'components/Stats/ChartIssuesClosedByUser';
import { ChartIssuesPerClient } from 'components/Stats/ChartIssuesPerClient';
import { ChartIssuesPerMonthAndYear } from 'components/Stats/ChartIssuesPerMonthAndYear';
import { IssueChampionUser } from 'components/Stats/IssueChampionUser';
import { Indicator } from 'lib/Indicator';
import { StatsContextProvider } from 'lib/providers/StatsContextProvider';

import { Filters } from './Filters';

export function Stats() {
    const history = useHistory();

    return (
        <StatsContextProvider>
            {({ stats, isLoading }) => (
                <Content>
                    <Row justify="space-between" align="middle">
                        <Typography.Title level={3}>
                            Estatísticas de chamados
                        </Typography.Title>
                        <Button
                            type="link"
                            icon={<LeftOutlined />}
                            onClick={() => history.push(AuthRoutes.overviewIssue)}
                        >
                            Voltar para página anterior
                        </Button>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Filters />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6}>
                            <Row>
                                <Col span={24}>
                                    <Indicator
                                        loading={isLoading}
                                        iconBackgroundColor='#B7EB8F'
                                        icon={<CheckCircleOutlined />}
                                        label='Chamados resolvidos'
                                        value={stats.total_closed_issues} />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Indicator
                                        loading={isLoading}
                                        iconBackgroundColor='#FFA39E'
                                        icon={<CloseCircleOutlined />}
                                        label='Chamados pendentes'
                                        value={stats.total_pending_issues} />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={18} lg={18}>
                            <IssueChampionUser />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <ChartIssuesPerClient />
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <ChartIssuesByType />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <ChartIssuesPerMonthAndYear />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <ChartIssuesClosedByUser />
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <ChartIssuesClosedBySupplier />
                        </Col>
                    </Row>
                </Content>
            )}
        </StatsContextProvider>
    );
}
