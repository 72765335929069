import React, { useState } from 'react';

import { App, Popconfirm, Space } from 'antd';

import { CalendarOutlined, DeleteOutlined,EditOutlined } from '@ant-design/icons';

import { handleServiceError,Options } from '../../lib/helpers/ServiceHelper';
import { useAmbient } from '../../lib/hooks/useAmbient';
import { sleep } from '../../lib/Sleep';
import { deleteAmbient } from '../../services/Ambient.service';

type Props = { record: Ambient.SimpleModel };

export function AmbientActionsCell({ record }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();

    const {
        setAmbientId,
        setIsEditAmbientModalVisible,
        setIsAmbientDatesModalVisible,
        fetchAmbients,
    } = useAmbient();

    const handleEdit = () => {
        setAmbientId(record.id);
        setIsEditAmbientModalVisible(true);
    };

    const handleDates = () => {
        setAmbientId(record.id);
        setIsAmbientDatesModalVisible(true);
    };


    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteAmbient(record.id);

        // Improves UX
        await sleep(1000);

        setIsSending(false);
        setIsPopconfirmVisible(false);

        if (!response.success) {
            const message: Options['message'] = (message) => message.includes('This ambient has reservations booked')
                ? 'Não é possível excluir um ambiente com reservas ativas.'
                : message;

            return handleServiceError(app, response, { message });
        }

        setIsPopconfirmVisible(false);
        fetchAmbients();
    };

    return (
        <Space size="middle">
            <EditOutlined onClick={handleEdit} title="Editar" />

            <CalendarOutlined onClick={handleDates} title="Datas" />

            <Popconfirm
                title="Excluir ambiente"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <DeleteOutlined title="Excluir" onClick={() => setIsPopconfirmVisible(true)} />
            </Popconfirm>
        </Space>
    );
}
