import React, { useState } from 'react';

import { App, Button, Popconfirm, Space } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNotice } from 'lib/providers/NoticeContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteNotice} from 'services/Notice.service';

type Props = { notice: Notice.Model };

export function NoticeActionsCell({ notice }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const {
        setNoticeId,
        setIsEditModalVisible,
        fetchNotices,
    } = useNotice();

    const app = App.useApp();

    const handleEdit = () => {
        setNoticeId(notice.id);
        setIsEditModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteNotice(notice.id);

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsSending(false);

        if (response.type === 'error')
            return app.notification.error({ message: response.message, description: response.description });

        app.notification.open(response);
        setIsPopconfirmVisible(false);
        fetchNotices();
    };

    return (
        <Space size="middle">
            <Button
                type="text"
                icon={<EditOutlined />}
                title="Editar"
                onClick={handleEdit}
            />

            <Popconfirm
                title="Excluir Aviso"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    title="Excluir"
                    onClick={() => setIsPopconfirmVisible(true)}
                />
            </Popconfirm>
        </Space>
    );
}
