import React, { useState } from 'react';

import {
    App,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    SelectProps,
    Tag,
    Tooltip,
    UploadFile
} from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DATE_SERVER_FORMAT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { DynamicSelect } from 'lib/DynamicSelect';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { usePayment } from 'lib/providers/PaymentContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { ParsedFile } from 'services/contracts/Payment.contract';
import { createCategory, createPayment, uploadFile } from 'services/Payment.service';

type Body = Parameters<typeof createPayment>['0'];
type SelectOptions = NonNullable<SelectProps['options']>;

export default function CreatePaymentModal() {
    const [paymentType, setPaymentType] = useState('single');
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm();

    const { 
        setIsCreateModalVisible, 
        fetchPayments,
        categories,
        fetchCategories,

    } = usePayment();

    const handleTypeChange = (value: Payment.Type) => {
        setPaymentType(value);
    };

    const app = App.useApp();

    const close = () => {
        setIsCreateModalVisible(false);
    };

    const onFinish = async () => {
        setIsSending(true);
        const values = await form.validateFields();

        const body: Body = {
            name: values.name,
            type: values.type,
            payment_category_id: values.category,
            client_id: values.client_id,
            value: values.value,
            due_date: values.due_date.format(DATE_SERVER_FORMAT),
            remember_to_pay_before_in_days: values.remember_to_pay_before_in_days,
            notify_each_in_days: values.notify_each_in_days,
            generate_next_payment_in_days: values.generate_next_payment_in_days,
            status: 'pending_payment',
            installment_quantity: values.installment_quantity,
        };

        const response = await createPayment(body);

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        if (values.files) {
            const parsedFiles: ParsedFile[] = response.payment.flatMap((payment) =>
                values.files.map((file: UploadFile): ParsedFile => ({
                    filename: file.name,
                    url: file.response ?? file.url,
                    payment_id: payment.id,
                }))
            );

            await Promise.all(parsedFiles.map(file => uploadFile(file)));
        }

        await fetchPayments();
        close();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();

    const helpValueInstallment = (
        <span style={{ display: 'flex', paddingTop: '6px', alignItems: 'center' }}>
            <Tag color="yellow">
                Atenção:
            </Tag>

            Necessário inserir o valor total do pagamento.
        </span>
    );

    const parsedCategories: SelectOptions = categories.map(({ id, name }) => ({ value: id, label: name }));

    const handleNewCategoryOption = async (option: string) => {
        setIsSending(true);

        // eslint-disable-next-line eqeqeq
        if (option == '') {
            setIsSending(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva a categoria que deseja inserir' });
        }

        await createCategory({ name: option });

        await sleep(1000);

        setIsSending(false);

        const fetchedCategories = await fetchCategories();
        const newCategory = fetchedCategories?.find(category => category.name === option);

        form.setFieldValue('category', newCategory?.id);
    };

    const parsedTypes = [
        { value: 'single', label: 'Simples' },
        { value: 'recurrent', label: 'Recorrente' },
        { value: 'immediate_recurrent', label: 'Recorrente imediato' },
        { value: 'installment', label: 'Parcelado' },
    ];

    return (
        <Modal
            centered
            title="Criar pagamento"
            destroyOnClose={true}
            onCancel={close}
            confirmLoading={isSending}
            okText="Salvar"
            open={true}
            onOk={onFinish}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>

                <Form.Item
                    name="client_id"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select options={clients} filterOption={filterOption} />
                </Form.Item>

                <Form.Item name="name" label="Título do pagamento" rules={[{ required: true, message: 'Descreva o título do pagamento' }]}>
                    <Input />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Categoria"
                    rules={[{ required: true, message: 'Por favor, selecione a categoria.' }]}
                >
                    <DynamicSelect
                        options={parsedCategories}
                        dropdown={{ placeholder: 'Insira aqui uma nova categoria', onNewOption: handleNewCategoryOption }}
                    />
                </Form.Item>

                <Form.Item name="type" label="Tipo do pagamento" rules={[{ required: true, message: 'Selecione o tipo do pagamento' }]}>
                    <Select onChange={handleTypeChange} options={parsedTypes} />
                </Form.Item>

                <Show when={paymentType === 'installment'}>
                    <Form.Item
                        name="installment_quantity"
                        label={
                            <span>
                                Quantidade de parcelas:
                                <Tooltip title="A quantidade de parcelas interfere na quantidade de pagamentos que irá ser criado. Ex: Pagamento em 3x, será criado 3 pagamentos com o valor total dividido para cada mês e definindo automaticamente as datas de vencimento para cada parcela">
                                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <InputNumber
                            addonAfter="x"
                            min={1}
                            maxLength={2}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Show>

                <Form.Item
                    name="value"
                    label="Valor"
                    help={paymentType === 'installment' ? helpValueInstallment : ''}
                    rules={[{ required: true, message: 'Por favor, selecione um valor.' }]}
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name="due_date" label="Data de vencimento" rules={[{ required: true, message: 'Selecione a data de vencimento' }]}>
                    <DatePicker format={'DD/MM/YYYY'} disabledDate={(current) => current && current < dayjs().endOf('day')} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="remember_to_pay_before_in_days" label="Com quantos dias de antecedência deseja ser lembrado deste pagamento?" rules={[{ required: true, message: 'Informe os dias para lembrar antes da data de vencimento' }]}>
                    <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="notify_each_in_days" label="Gostaria de receber notificações sobre este pagamento durante quantos dias?" rules={[{ required: true, message: 'Informe os dias para notificação' }]}>
                    <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>

                <Show when={paymentType === 'recurrent'}>
                    <Form.Item name="generate_next_payment_in_days" label="Informe com quantos dias de antecedência à data de vencimento você deseja que o próximo pagamento seja criado automaticamente">
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Show>

                <UploadField name='files' type='picture' multiple />
            </Form >
        </Modal >
    );
}