import React, { 
    createContext,
    ReactNode, 
    useCallback, 
    useContext, 
    useEffect,
    useState,
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { FilterIssueStats } from 'pages/overview/issue/stats/Filters';
import { generateStats } from 'services/Stats.service';


export type StatsContextValue = {
    isLoading: boolean,
    fetchStats: (filters:FilterIssueStats) => Promise<void>,
    stats: Stats.IssueStats,
};

type Props = { children: (value: StatsContextValue) => ReactNode };

const StatsContext = createContext<StatsContextValue | null>(null);

/**
 * @see https://www.youtube.com/watch?v=I7dwJxGuGYQ
 * @todo Abstract `loadings` state updates (Add/Remove) with `useReducer`
 */
export function StatsContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<StatsContextValue['stats']>({} as Stats.IssueStats);
    const app = App.useApp();

    const fetchStats = async (filters:FilterIssueStats | {} = {}) => {
        setIsLoading(true);
        const response = await generateStats(filters);

        if (!response.success)
            return handleServiceError(app, response);

        setStats(response.issues);
        setIsLoading(false);
    };

    const memoizedFetchStats = useCallback(fetchStats, [app]);


    useEffect(() => {
        memoizedFetchStats();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const value: StatsContextValue = {
        isLoading,
        fetchStats: fetchStats,
        stats
    };

    return (
        <StatsContext.Provider value={value}>
            {children(value)}
        </StatsContext.Provider>
    );
}

export function useStats() {
    const context = useContext(StatsContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `StatsContextProvider`.');

    return context;
}
