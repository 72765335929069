import React, { useEffect, useState } from 'react';

import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { Pie } from '@ant-design/charts';
import { LeftOutlined } from '@ant-design/icons';
import { AuthRoutes } from 'components/_Common/_Routes/Routes';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { generateReportGeneralMeetings } from 'services/GeneralMeeting.service';

const wl = makeWhiteLabel();

type Props = {
    history: any;
    match: any;
};

type Report = {
    title: string;
    type: 'objective' | 'dissertation',
    data: []
};

export function GeneralMeetingReport(props: Props) {
    const { id } = props.match.params;
    const [report, setReport] = useState<Report[]>([]);

    const fetchReport = async () => {
        const response = await generateReportGeneralMeetings(id);

        if (!response.success)
            return;

        setReport(response.report);

    };

    const generateCharts = () => {
        if (report && report.length === 0)
            return 'Nenhum dado para ser exibido';

        return report.map((item, index) => {
            const config = {
                appendPadding: 10,
                data: item.data,
                angleField: 'value',
                colorField: 'name',
                radius: 0.9,
                label: {
                    type: 'inner',
                    offset: '-30%',
                    content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
                    style: {
                        fontSize: 14,
                        textAlign: 'center',
                    },
                },
                interactions: [
                    {
                        type: 'element-active',
                    },
                ],
            };

            if (item.type === 'objective')
                return <Row>
                    <Col span={24} key={index}>
                        <Card title={item.title}>
                            <Pie {...config} />
                        </Card>
                    </Col>
                </Row>;

            console.log(item);
            return <Row>
                <Col span={24} key={index}>
                    <Card title={item.title}>
                        <Carousel dots={true} autoplay>
                            {item.data.map((item: any, index) =>
                                item.value.map((val: any, subIndex: number) => (
                                    <div key={`${index}-${subIndex}`}>
                                        <h2 style={{ margin: 0, height: '160px', color: '#fff', lineHeight: '160px', textAlign: 'center', background: wl.triggerBg }}>
                                            {val.answer}
                                        </h2>
                                    </div>
                                ))
                            )}
                        </Carousel>
                    </Card>
                </Col>
            </Row>;
        });
    };

    useEffect(() => {
        fetchReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Content>
            <Row justify="space-between" align="middle">
                <Typography.Title level={3}>
                    Resultados
                </Typography.Title>

                <Button
                    type="link"
                    icon={<LeftOutlined />}
                    onClick={() => props.history.push(AuthRoutes.general_meeting)}
                >
                    Voltar para página anterior
                </Button>
            </Row>
            {generateCharts()}
        </Content>
    );
}
