import React, { useState } from 'react';

import { Button, Form, Input, message, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import { useResidentFirstAccess } from 'lib/providers/ResidentFirstAccessContextProvider';
import { ReCAPTCHA } from 'lib/ReCAPTCHA';
import { findResidentByParams } from 'services/Client.service';

type Values = {
    cpf: string,
    email: string,
};

export function FormResidentIdentification() {
    const [captcha, setCaptcha] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [form] = Form.useForm<Values>();

    const { 
        setIsFormResidentIdentification, 
        setIsFormResidentExternal, 
        client, 
        setResident 
    } = useResidentFirstAccess();

    const onFinish = async ({ cpf, email }: Values) => {
        if (!client)
            return;

        setIsLoading(true);

        if (!cpf && !email){
            message.error('Por favor, preencha pelo menos um campo: CPF ou e-mail.');
            
            setIsLoading(false);
            return;
        }

        const response = await findResidentByParams({
            cpf: cpf,
            email: email,
            client_id: client?.id
        });

        setIsLoading(false);

        if (!response.success)
            return;

        setResident(response.resident);
        setIsFormResidentIdentification(false);
        setIsFormResidentExternal(true);
    };

    return (
        <Row>
            <div className="form-container" style={{ width: '100%' }}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    name="residentIdentification"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item
                        name="cpf"
                        label="CPF"
                        rules={[{ required: false, message: 'Por favor, digite um CPF.' }]}
                    >
                        <MaskedInput 
                            mask='000.000.000-00'
                            placeholder="Insira um CPF para condultar seu cadastro" 
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            { required: false, message: 'Por favor, digite um e-mail.'},
                            { type: 'email', message: 'Por favor, digite um e-mail válido.'}
                        ]}
                    >
                        <Input placeholder="Insira um e-mail para consultar seu cadastro"/>
                    </Form.Item>

                    <ReCAPTCHA onChange={setCaptcha} />

                    <Form.Item style={{ marginTop: 10 }}>
                        <Button
                            loading={isLoading}
                            disabled={captcha === null ? true : false}
                            size="large"
                            style={{ backgroundColor: client?.client_manager.primary_color ?? 'unset' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Consultar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Row>
    );
}
