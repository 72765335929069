import React, { Fragment } from 'react';

import { Button, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateNoticeModal } from 'components/Notice/CreateNoticeModal';
import { EditNoticeModal } from 'components/Notice/EditNoticeModal';
import { NoticeContextProvider } from 'lib/providers/NoticeContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Notices() {
    return (
        <NoticeContextProvider>
            {({
                notices,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Avisos
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <List
                            columns={Columns}
                            data={notices}
                            loading={isLoading}
                        />
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateNoticeModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditNoticeModal />
                    </Show>
                </Fragment>
            )}
        </NoticeContextProvider>
    );
}
