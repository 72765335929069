import React from 'react';

import { Button,Col, Form, FormInstance, FormItemProps, Row, Select, SelectProps, Space } from 'antd';

import { MinusOutlined,PlusOutlined } from '@ant-design/icons';

import { makeFilterOption } from './helpers/Form.helper';

type ClientsFieldProps<Values> = 
    & { form: FormInstance<Values>, options: NonNullable<SelectProps['options']> }
    & Pick<FormItemProps, 'name' | 'label' | 'tooltip' | 'rules' | 'initialValue'>
    & Pick<SelectProps, 'disabled' | 'loading'>

const filterOption = makeFilterOption();

export function ClientsField<Values = any>({
    name,
    label,
    tooltip,
    rules,
    initialValue,
    disabled,
    loading,
    options,
    form,
}: ClientsFieldProps<Values>) {
    const handleSelectAll = () => {
        form.setFieldValue(name, options.map(client => client.value));
        form.validateFields([name]);
    };

    const handleDeselectAll = () => {
        form.setFieldValue(name, []);
        form.validateFields([name]);
    };

    return (
        <Row>
            <Col span={24}>
                <Form.Item
                    name={name}
                    label={label}
                    tooltip={tooltip}
                    rules={rules}
                    initialValue={initialValue}
                >
                    <Select
                        disabled={disabled}
                        loading={loading} 
                        options={options}
                        mode="multiple" 
                        filterOption={filterOption}
                    />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Space size="middle" wrap>
                    <Button
                        type="primary"
                        onClick={handleSelectAll}
                        icon={<PlusOutlined />}
                    >
                        Selecionar todos
                    </Button>

                    <Button
                        type="dashed"
                        onClick={handleDeselectAll}
                        icon={<MinusOutlined />}
                    >
                        Desmarcar todos
                    </Button>
                </Space>
            </Col>
        </Row>
    );
}
