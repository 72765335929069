import React from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Dropdown, Layout, MenuProps, Select, SelectProps, Space, Typography } from 'antd';

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { AuthRoutes } from 'components/_Common/_Routes/Routes';
import Authentication from 'lib/Authentication';
import { Show } from 'lib/Show';

type ClientSelectProps = SelectProps<Client.Model['id']>;
type Options = NonNullable<ClientSelectProps['options']>;
type FilterOption = NonNullable<ClientSelectProps['filterOption']>;
type OnSelect = NonNullable<ClientSelectProps['onSelect']>;

const headerStyle = { 
    padding: 0,
    paddingRight: 30,
    background: '#fff'
};

/** @factory */
export const makeClientOptions = (): Options => {
    const clients = Authentication.getClients();
        
    const options = clients.map(({ id, name }) => ({
        value: id,
        label: name
    }));

    return options;
};

/** @factory */
const makeFilterOption = (): FilterOption => (input, option) => {
    const label = option?.label ?? '';

    if (typeof label !== 'string')
        throw new Error('All `options[number].label` MUST BE a string');

    const loweredLabel = label.toLowerCase();
    const loweredInput = input.toLowerCase();

    const isMatched = loweredLabel.includes(loweredInput);

    return isMatched;
};

const filterOption = makeFilterOption();

export function SecondaryHeader() {
    const history = useHistory();

    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    
    const handleClientSelect: OnSelect = (id) => {
        Authentication.setCurrentClientId(id);
        history.push(AuthRoutes.dashboard);
    };

    const logout = () => {
        Authentication.logout();
        history.push('/');
    };

    const clients = makeClientOptions();

    const items: MenuProps['items'] = [
        {
            key: 'account',
            label: 'Minha conta',
            onClick: () => history.push('/account'),
        },
        {
            key: 'logout',
            label: 'Desconectar',
            onClick: logout,
        }
    ];

    const user = Authentication.getUser();

    const userName = isMobile
        ? user.personal?.name.split(' ')[0]
        : user.personal?.name;

    return (
        <Layout.Header style={headerStyle}>
            <Space 
                align="center" 
                style={{ 
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Show when={!isMobile}>
                    <Typography.Title 
                        className="client-title"
                        level={3} 
                        style={{ margin: 5, marginLeft: 35 }}
                    >
                        {Authentication.getClientManager().name}
                    </Typography.Title>
                </Show>

                <Space
                    align="center"
                    style={{ 
                        display: 'flex',
                        justifyContent: isMobile
                            ? 'flex-start' 
                            : 'flex-end' 
                    }}
                >
                    <Select
                        placeholder="Selecione um condomínio"
                        options={clients}
                        showSearch
                        filterOption={filterOption}
                        style={{ width: '240px' }}
                        onSelect={handleClientSelect}
                    />

                    <Dropdown menu={{ items }}>
                        <Space style={{ cursor: 'pointer' }}>
                            <Avatar
                                size={38}
                                src={user.personal?.picture}
                                icon={<UserOutlined />}
                            />

                            {userName}

                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </Space>
            </Space>
        </Layout.Header>
    );
}
