import React, { } from 'react';

import { Avatar, Badge, Card, Col, Progress, Row, Space, Typography } from 'antd';

import { StarFilled } from '@ant-design/icons';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { useStats } from 'lib/providers/StatsContextProvider';

const wl = makeWhiteLabel();

export function IssueChampionUser() {
    const { stats, isLoading } = useStats();

    const total_closed = stats.total_closed_issues ?? 0;
    const users = stats.total_issues_closed_by_user ?? [];

    return <Card loading={isLoading}>
        <Row>
            {users.slice(0, 3).map((user, key) => {
                const [firstName, lastName] = user.name.split(' ');

                return <Col xs={24} sm={24} md={8} lg={8} key={key}>
                    <Space.Compact direction='vertical' style={{ width: '90%', alignItems: 'center' }}>
                        <Badge count={<StarFilled style={{ color: 'gold' }} />} >
                            <Avatar
                                src={user?.picture}
                                style={{ backgroundColor: wl.colorPrimary }}
                                size={{ xs: 24, sm: 32, md: 40, lg: 100, xl: 100, xxl: 100 }}>
                                {firstName[0]}
                                {lastName ? lastName[0] : ''}
                            </Avatar>
                        </Badge>
                        <Typography.Text style={{ margin: 2 }} strong>
                            {user.name}
                        </Typography.Text>
                        <Progress percent={(user.value * 100) / total_closed} format={() => `${user.value} / ${total_closed}`} />
                    </Space.Compact>
                </Col>;
            })}
        </Row>
    </Card>;
};