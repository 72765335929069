import React from 'react';

import { Card, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { CalendarOutlined, CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useOverview } from 'lib/providers/OverviewContextProvider';

export const renderStatusMaintenance = (maintenance: Maintenance.Model) => {
    if (maintenance.isFinished)
        return <Tag icon={<CheckCircleOutlined />} color='success'>Finalizada</Tag>;

    if (dayjs(maintenance.estimatedDate).isBefore(dayjs()))
        return <Tag icon={<ExclamationCircleOutlined />} color='red'>Atrasado</Tag>;

    if (dayjs(maintenance.estimatedDate).format('MM/YYYY') === dayjs().format('MM/YYYY'))
        return <Tag icon={<ClockCircleOutlined />} color='volcano'>Vence este mês</Tag>;

    if (dayjs(maintenance.estimatedDate).format('MM/YYYY') === dayjs().add(1, 'month').format('MM/YYYY'))
        return <Tag icon={<ClockCircleOutlined />} color='gold'>Vence no próximo mês</Tag>;

    return <Tag icon={<CalendarOutlined />} color='blue'>Agendado</Tag>;
};

export const renderRecurrenceMaintenance = (maintenance: Maintenance.Model) => {
    if (maintenance.type === 'single')
        return 'Única';

    const typeOfRecurrence = maintenance.type_of_recurrence;
    const recurrence = maintenance.recurrence;

    if (typeOfRecurrence === 'day')
        return maintenance.recurrence === 1 ? 'Diariamente' : `A cada ${recurrence} dias`;

    if (typeOfRecurrence === 'week')
        return maintenance.recurrence === 1 ? 'Semanalmente' : `A cada ${recurrence} semanas`;


    if (typeOfRecurrence === 'month')
        return maintenance.recurrence === 1 ? 'Mensalmente' : `A cada ${recurrence} meses`;


    return maintenance.recurrence === 1 ? 'Anual' : `A cada ${recurrence} anos`;
};

const COLUMNS: ColumnsType<Maintenance.Model> = [
    {
        dataIndex: 'estimatedDate',
        render: (_, maintenance) => renderStatusMaintenance(maintenance),
        key: 'status'
    },
    {
        dataIndex: 'estimatedDate',
        // Prevents cell line wrap
        render: value => <span style={{ wordWrap: 'normal' }}>{dayjs(value).format('DD/MM/YYYY')}</span>,
        key: 'estimatedDate'
    },

    {
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        dataIndex: 'description',
        key: 'description',
        render: (_, m) => m.maintenance_type ? m.maintenance_type.name : m.description
    }
];

export function PendingMaintenancesCard() {
    const { loadings, pendingMaintenances } = useOverview();

    return (
        <Card
            title="Manutenções pendentes"
            loading={loadings.includes('pendingMaintenances')}
        >
            <Table
                columns={COLUMNS}
                dataSource={pendingMaintenances}
                showHeader={false}
                pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                rowKey="id"
                size="small"
                rootClassName="table-horizontal-scroll"
            />
        </Card>
    );
}
