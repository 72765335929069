import { useCallback, useEffect, useState } from 'react';

import { App, Form, FormInstance, SelectProps } from 'antd';

import { handleServiceError } from '../../lib/helpers/ServiceHelper';
import { listApartmentsByTowers } from '../../services/ApartmentService';
import { listTower } from '../../services/TowerService';
import { Values } from './CreateNoticeModal';

type Options = NonNullable<SelectProps['options']>;

type ApartmentOptions = Array<{
    towerId: Apartment.Tower['id']
} & Options[number]>;

export function useNoticeOptions(form: FormInstance<Values>) {
    const [isFetching, setIsFetching] = useState(true);

    // Options
    const [towerOptions, setTowerOptions] = useState<Options>([]);
    const [apartmentOptions, setApartmentOptions] = useState<ApartmentOptions>([]);

    // Fields watched
    const towers = Form.useWatch('towers', form);
    
    // Fields visiblity
    const isApartmentsFieldVisible = towers !== undefined && towers !== null && towers.length > 0;

    // Mandatory fields
    const isTowersFieldRequired = isApartmentsFieldVisible;

    const app = App.useApp();

    const fetchTowers = async () => {
        setIsFetching(true);

        const response = await listTower();

        const towers = 'status' in response ?  [] : response;

        const options = towers.map(tower => ({ value: tower.id, label: tower.name }));;

        setTowerOptions(options);

        setIsFetching(false);
    };

    useEffect(() => {
        fetchTowers();
    }, []);

    const fetchApartments = async () => {
        setIsFetching(true);

        if (!towers)
            throw new TypeError('Something went wrong! The `towers` state is undefined');

        const response = await listApartmentsByTowers(towers);

        setIsFetching(false);

        if (!response.success)
            return handleServiceError(app, response);

        const options = response.apartments.map(apartment => ({
            value: apartment.id,
            label: `${apartment.tower.name} - ${apartment.name}`,
            towerId: apartment.towerId,
        })); 

        setApartmentOptions(options);
    };
    
    const memoizedFetchApartments = useCallback(fetchApartments, [towers, app]);

    useEffect(() => {
        if (towers !== undefined && towers !== null)
            memoizedFetchApartments();

    }, [towers, memoizedFetchApartments]);

    return {
        isFetching,
        towerOptions,
        apartmentOptions,
        isApartmentsFieldVisible,
        isTowersFieldRequired,
    };
}