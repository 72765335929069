import React, { useEffect, useState } from 'react';

import { Card, Select } from 'antd';

import API from '../../../lib/API';
import { ApartmentInterface } from '../../_Common/_Interfaces/Apartment';
import ViewApartment from './Apartment';

const ApartmentSearch: React.FC = () => {
    const [apartmentView, setAparmentView] = useState(false);
    const [apartment, setApartment] = useState({} as ApartmentInterface);
    const [apartments, setApartments] = useState(Array<ApartmentInterface>);

    const closeModal = () => {
        setAparmentView(false);
        setApartment({} as ApartmentInterface);
    };

    async function fetchData() {
        const apartments = await API.get('/apartment');
        setApartments(apartments);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Card title="Unidades">
                <Select
                    style={{ width: '100%' }} disabled={apartments.length <= 0} loading={apartments.length <= 0}
                    showSearch
                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onSelect={(id: number) => {
                        const apartment = apartments.find((a: ApartmentInterface) => a.id === id);
                        setApartment(apartment ?? {} as ApartmentInterface);
                        setAparmentView(true);
                    }}
                >
                    {apartments.map((apartment: ApartmentInterface, key: number) =>
                        <Select.Option key={key} value={apartment.id}>
                            {`${apartment.tower.name} - ${apartment.name}`}
                        </Select.Option>)}
                </Select>
            </Card>
            <ViewApartment isOpen={apartmentView} closeModal={closeModal} apartment={apartment} />
        </>
    );

};

export default ApartmentSearch;