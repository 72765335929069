import React from 'react';

import { Divider, Row, Typography } from 'antd';

import { useResidentFirstAccess } from 'lib/providers/ResidentFirstAccessContextProvider';
import { Show } from 'lib/Show';

export function SuccessPage() {

    const { client } = useResidentFirstAccess();

    const getLinkBySO = () => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return <Typography.Link href={client?.client_manager.app_android} style={{ color: client?.client_manager.primary_color ?? 'unset' }}>Clique aqui para realizar o download do aplicativo</Typography.Link>;
        }

        if (/iPad|iPhone|iPad/.test(userAgent)) {
            return <Typography.Link href={client?.client_manager.app_ios} style={{ color: client?.client_manager.primary_color ?? 'unset' }}>Clique aqui para realizar o download do aplicativo</Typography.Link>;
        }

        return <>
            <Divider />
            <Typography.Link href={client?.client_manager.app_android} style={{ color: client?.client_manager.primary_color ?? 'unset' }}>Clique aqui para realizar o download do ANDROID</Typography.Link>
            <Divider />
            <Typography.Link href={client?.client_manager.app_ios} style={{ color: client?.client_manager.primary_color ?? 'unset' }}>Clique aqui para realizar o download do IOS</Typography.Link>
        </>;
    };


    return (
        <Row>
            <Typography.Title level={3} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Parabéns! Seu cadastro foi realizado com sucesso.</Typography.Title>
            <Show when={client?.external_resident_creation_workflow_type === 'approval'}>
                <Typography.Title level={4} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Adicionalmente, criamos um aplicativo para facilitar o acesso às informações do seu condomínio. </Typography.Title>
                <Typography.Title level={4} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Assim que seu cadastro for aprovado, você receberá um e-mail informando que seu acesso ao aplicativo foi liberado. Nesse momento, já pode começar a baixar o aplicativo enquanto aguarda.</Typography.Title>
            </Show>
            <Show when={client?.external_resident_creation_workflow_type === 'auto'}>
                <Typography.Title level={4} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Adicionalmente, desenvolvemos um aplicativo para simplificar o acesso às informações do seu condomínio.</Typography.Title>
            </Show>
            {getLinkBySO()}
        </Row>
    );
};