import React, {  } from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, Layout, Space } from 'antd';

import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import Authentication from 'lib/Authentication';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

const wl = makeWhiteLabel();

const headerStyle = {
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-between'
};

export function Header() {
    const history = useHistory();

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const iconSrc = isMobile
        ? wl.icon_mini ?? wl.icon
        : wl.icon;

    const user = Authentication.getUser();

    const userName = isMobile
        ? user.personal?.name.split(' ')[0]
        : user.personal?.name;

    return (
        <Layout.Header style={headerStyle}>
            <img
                src={iconSrc}
                alt="Gcondo logo"
                height="40"
            />

            <Space style={{ color: '#ffffff' }}>
                <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={history.goBack}
                >
                    Voltar
                </Button>

                <Avatar
                    size={38}
                    src={user.personal?.picture}
                    icon={<UserOutlined />}
                />

                {userName}
            </Space>
        </Layout.Header>
    );
}
