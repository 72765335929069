import React from 'react';

import { Image, Space, Typography } from 'antd';

type File = { filename: string, url: string };

type Props = { files: File[] };

const IMAGE_FILE_EXTENSIONS = ['jpg', 'png', 'jpeg'] as const;

const isValidImageFileExtension = (value: any) => IMAGE_FILE_EXTENSIONS.includes(value.toLowerCase());

const isValidImageFile = ({ url }: File ) => {
    const lowerCaseUrl = url.toLowerCase();
        
    const splittedLowerCaseUrl = lowerCaseUrl.split('.');

    const extension = splittedLowerCaseUrl.length > 1
        ? splittedLowerCaseUrl[splittedLowerCaseUrl.length - 1]
        : null;

    if (!extension)
        return false;

    const isValid = isValidImageFileExtension(extension);

    return isValid;
};

export function FilesGalery({ files }: Props) {
    const images = files.filter(isValidImageFile);
    const others = files.filter(file => !isValidImageFile(file));

    const previews = (
        <Image.PreviewGroup>
            {images.map(({ url }, index) => (
                <Image 
                    width={200}
                    src={url}
                    key={index}
                />
            ))}
        </Image.PreviewGroup>
    );

    const links = (
        <Space direction="vertical">
            {others.map(({ url, filename }, index) => (
                <a 
                    href={url} 
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                >
                    {filename}
                </a>
            ))}
        </Space>
    );

    return (
        <Space direction="vertical" size="middle">
            <Space direction="vertical">
                <Typography.Text strong>
                    Imagens
                </Typography.Text>

                {images.length > 0 ? previews : 'Não há imagens.'}
            </Space>

            <Space direction="vertical">
                <Typography.Text strong>
                    Outros arquivos
                </Typography.Text>

                {others.length > 0 ? links : 'Não há outros arquivos.'}
            </Space>
        </Space>
    );
}
