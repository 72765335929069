import API from '../lib/API';
import { CreateClientUserNotes, FindClientUserNotes } from './contracts/ClientUserNotes.contract';

const route = '/client_user_notes';

export function findClientUserNotes(): Promise<FindClientUserNotes.Response> {
    return API.get(route);
};

export function saveClientUserNotes(body: CreateClientUserNotes.Body): Promise<CreateClientUserNotes.Response> {
    return API.post(route, body);
};