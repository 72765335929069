import React, { useState } from 'react';

import { App, Button, Card, Col, DatePicker, Form, Input, Modal, Radio, Row, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { CloseOutlined } from '@ant-design/icons';
import { useGeneralMeeting } from 'lib/providers/GeneralMeetingContextProvider';
import { Show } from 'lib/Show';
import { createGeneralMeeting } from 'services/GeneralMeeting.service';
import { ValidatorRule } from 'types/packages/antd';

type Values = {
    title: string,
    description: string,
    closing_date: string,
    questions: GeneralMeeting.Question[],
};

export function CreateGeneralMeetingModal() {
    const [isSending, setIsSending] = useState(false);

    const { setIsCreateModalVisible, fetchGeneralMeetings } = useGeneralMeeting();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const watchedQuestions = Form.useWatch('questions', form) ?? [];

    const close = () => setIsCreateModalVisible(false);

    const onFinish = async ({ title, description, closing_date, questions }: Values) => {
        setIsSending(true);

        const response = await createGeneralMeeting({
            title,
            description,
            closing_date,
            questions
        });

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchGeneralMeetings();
    };

    /** @see https://ant.design/components/form#components-form-demo-dynamic-form-item */
    const requiredQuestionValidator: ValidatorRule = {
        validator: async (_, value) => {    
            if (!Array.isArray(value) || value.length < 1)
                return Promise.reject('Por favor, adicione ao menos uma pergunta.');
    
            Promise.resolve();
        },
    };

    const requiredQuestionOptionValidator: ValidatorRule = {
        validator: async (_, value) => {    
            if (!Array.isArray(value) || value.length < 1)
                return Promise.reject('Por favor, adicione ao menos uma opção.');

            if (value.includes(undefined))
                return Promise.reject('Por favor, preencha todas as opções');
        
            Promise.resolve();
        },
    };

    return (
        <Modal
            title="Criar enquete"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Criar"
            onCancel={close}
            cancelText="Cancelar"
            width={1000}
            open
        >
            <Form
                form={form}
                onFinish={onFinish}
                name="createGeneralMeeting"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                        >
                            <Input maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            name="description"
                            label="Pauta"
                            rules={[{ required: true, message: 'Por favor, digite a pauta.' }]}
                        >
                            <TextArea />
                        </Form.Item>

                        <Form.Item
                            name="closing_date"
                            label="Prazo de conclusão"
                            rules={[{ required: true, message: 'Por favor, informe a data.' }]}
                        >
                            <DatePicker
                                size='middle'
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.List name="questions" rules={[requiredQuestionValidator]}>
                            {(fields, { add, remove }, { errors }) => (
                                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                    {fields.map((field) => (
                                        <Card
                                            size="small"
                                            title={`Pergunta ${field.name + 1}`}
                                            key={field.key}
                                            extra={<CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                } } />}
                                        >
                                            <Form.Item
                                                label="Título"
                                                name={[field.name, 'title']}
                                                rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                label="Obrigatória"
                                                name={[field.name, 'required']}
                                                rules={[{ required: true, message: 'Por favor, selecione uma alternativa.' }]}
                                            >
                                                <Radio.Group
                                                    options={[
                                                        { value: true, label: 'Sim' },
                                                        { value: false, label: 'Não' }
                                                    ]} />
                                            </Form.Item>

                                            <Form.Item
                                                label="Tipo"
                                                name={[field.name, 'type']}
                                                rules={[{ required: true, message: 'Por favor, selecione um tipo.' }]}
                                            >
                                                <Radio.Group
                                                    options={[
                                                        { value: 'dissertation', label: 'Descritiva' },
                                                        { value: 'objective', label: 'Objetiva' }
                                                    ]} />
                                            </Form.Item>

                                            <Show when={watchedQuestions[field.name]?.type === 'objective'}>
                                                {/* Nest Form.List */}
                                                <Form.Item label="Opções">
                                                    <Form.List name={[field.name, 'options']} rules={[requiredQuestionOptionValidator]}>
                                                        {(subFields, subOpt, subMeta) => (
                                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                                                {subFields.map((subField) => (
                                                                    <Space key={subField.key}>
                                                                        <Form.Item
                                                                            name={[subField.name, 'description']}
                                                                            noStyle
                                                                        >
                                                                            <Input placeholder="Descrição" />
                                                                        </Form.Item>

                                                                        <CloseOutlined
                                                                            onClick={() => subOpt.remove(subField.name)} />
                                                                    </Space>
                                                                ))}

                                                                <Form.Item>
                                                                    <Button
                                                                        type="dashed"
                                                                        onClick={() => subOpt.add()}
                                                                        danger={subMeta.errors.length > 0}
                                                                        block
                                                                    >
                                                                        Nova opção
                                                                    </Button>

                                                                    <Form.ErrorList errors={subMeta.errors} />
                                                                </Form.Item>
                                                            </div>
                                                        )}
                                                    </Form.List>
                                                </Form.Item>
                                            </Show>
                                        </Card>
                                    ))}

                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            danger={errors.length > 0}
                                            block
                                        >
                                            Nova pergunta
                                        </Button>

                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
