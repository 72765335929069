import API from '../lib/API';
import { CreateChatbot, DeleteChatbot,FindChatbot, UpdateChatbot } from './contracts/Chatbot.contract';
const route = '/chatbot';

export function findChatbot(): Promise<FindChatbot.Response> {
    return API.get(route);
};

export function createChatbot(body: CreateChatbot.Body): Promise<CreateChatbot.Response> {
    return API.post(route, body);
};

export function updateChatbot(id: Chatbot.Model['id'], body: UpdateChatbot.Body): Promise<UpdateChatbot.Response> {
    return API.put(`${route}/${id}`, body);
};

export function deleteChatbot(id: Chatbot.Model['id']): Promise<DeleteChatbot.Response> {
    return API.delete(`${route}/${id}`);
};