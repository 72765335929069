import React from 'react';

import { type TableColumnsType,Tag } from 'antd';

import { ConstructionActionsCell } from 'components/Construction/ConstructionActionsCell';
import dayjs from 'dayjs';
import { getConstructionDerivedStatus, getConstructionDerivedStatusTagProps } from 'lib/helpers/Construction.helper';

export const Columns: TableColumnsType<Construction.Model> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
    },

    {
        title: 'Título',
        dataIndex: 'name',
    },

    {
        title: 'Status',
        dataIndex: 'status',
        render: (status, construction) => {
            const derivedStatus = getConstructionDerivedStatus(status, construction.end_date, construction.start_date);
            const props = getConstructionDerivedStatusTagProps(derivedStatus);

            return <Tag {...props} />;
        },
        width: 120
    },

    {
        title: 'Data inicial',
        dataIndex: 'start_date',
        render: (start_date) => (
            start_date
                ? dayjs(start_date).format('DD/MM/YYYY')
                : '-'
        ),
        width: 135,
    },

    {
        title: 'Data esperada',
        dataIndex: 'expected_end_date',
        render: (expected_end_date) => (
            expected_end_date
                ? dayjs(expected_end_date).format('DD/MM/YYYY')
                : '-'
        ),
        width: 130,
    },

    {
        title: 'Data final',
        dataIndex: 'end_date',
        render: (end_date) => (
            end_date
                ? dayjs(end_date).format('DD/MM/YYYY')
                : '-'
        ),
        width: 130,
    },

    {
        title: 'Orçamento aprovado',
        dataIndex: 'approved_budget',
        render: approved_budget => {
            const BRL = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            return BRL.format(approved_budget);
        }
    },

    {
        title: 'Orçamento final',
        dataIndex: 'final_budget',
        render: final_budget => {
            const BRL = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            return BRL.format(final_budget);
        }
    },


    {
        width: '100px',
        align: 'center',
        render: (_, construction) => <ConstructionActionsCell construction={construction} />,
    },
];