import API from '../lib/API';
import { CreateTower, ListTower } from './contracts/TowerServiceContract';

const route = '/tower';

export function listTower(): Promise<ListTower.Response> {
    return API.get(route);
};

export function createTower(body: CreateTower.Body): Promise<CreateTower.Response> {
    return API.post(route, body);
};
