import React, { useEffect, useState } from 'react';

import { Button, Card, Col, Descriptions, Divider, Result, Row, Typography } from 'antd';

import dayjs from 'dayjs';

import { formatBRL } from '../../lib/helpers/BRL';
import { findCurrentBudget } from '../../services/BudgetService';
import Content from '../_Common/_Layout/Content';
import LoadingGcondo from '../_Common/LoadingGcondo';
import IndexMonthBudget from './_IndexMonthBudget';
import FormBudget from './Form';
import FormCostCenter from './FormCostCenter';

const BudgetIndex: React.FC = () => {
    const [isOpen, setOpen] = useState(false);
    const [isOpenCostCenter, setOpenCostCenter] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [budget, setBudget] = useState<Budget.Model | null>();

    function closeForm() {
        setOpen(false);
        setOpenCostCenter(false);
    };

    function calculateAmountCostCenter() {
        let total = 0;

        budget?.cost_center.forEach(costCenter => {
            total += costCenter.amount;
        });

        return formatBRL(total);
    }

    function renderCostCenters() {
        return budget?.cost_center.map(costCenter =>
            <>
                <Descriptions.Item label={costCenter.name} span={2}>{costCenter.calculation_type === 'quota'
                    ? 'Quota parte'
                    : 'Unidade'}
                </Descriptions.Item>
                <Descriptions.Item label="Total">{formatBRL(costCenter.amount)}</Descriptions.Item>
            </>
        );
    }

    async function fetchData() {
        setLoading(true);
        const response = await findCurrentBudget();
        if (response != null && 'status' in response)
            return;

        setBudget(response);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [isOpen, isOpenCostCenter]);

    return (
        <Content>
            <Row className='space-between' align="middle">
                <Typography.Title level={3}>Orçamento do condomínio</Typography.Title>
            </Row>
            {
                isLoading
                    ? <LoadingGcondo />
                    : budget
                        ? <Row>
                            <Col span={10}>
                                <Card>
                                    <Descriptions size="small" title={budget.year} bordered>
                                        <Descriptions.Item label="Período" span={3}>{dayjs(budget.start).format('DD/MM/YYYY')} - {dayjs(budget.end).format('DD/MM/YYYY')}</Descriptions.Item>
                                        <Descriptions.Item label="Orçamento total" span={3}>
                                            {
                                                budget.cost_center.length > 0
                                                    ? calculateAmountCostCenter()
                                                    : 'N/A'
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider />
                                    {
                                        budget.cost_center.length > 0
                                            ? <Descriptions size="small" title="Centros de custo" extra={<Button size='small' type="primary" onClick={() => setOpenCostCenter(true)}>Cadastrar</Button>} bordered>
                                                {renderCostCenters()}
                                            </Descriptions>
                                            : null
                                    }
                                </Card>
                            </Col>
                            {
                                budget.cost_center.length > 0
                                    ? <Col span={14}><IndexMonthBudget budget={budget} /></Col>                                
                                    : <Col span={14}>
                                        <Card>
                                            <Result
                                                status="500"
                                                title="Atenção"
                                                subTitle="Nenhum centro de custo cadastrado."
                                                extra={<Button type="primary" onClick={() => setOpenCostCenter(true)}>Cadastrar</Button>}
                                            />
                                        </Card>
                                    </Col>
                            }
                        </Row>
                        : <Row>
                            <Col span={24}>
                                <Card>
                                    <Result
                                        status="500"
                                        title="Atenção"
                                        subTitle="O condomínio não possui um ano fiscal vigente."
                                        extra={<Button type="primary" onClick={() => setOpen(true)}>Cadastrar</Button>}
                                    />
                                </Card>
                            </Col>
                        </Row>
            }
            <FormBudget key={'new'} isOpen={isOpen} closeForm={closeForm} budget={undefined} />
            <FormCostCenter key={'new-cost_center'} isOpen={isOpenCostCenter} closeForm={closeForm} budgetId={budget?.id} costCenter={undefined} />
        </Content>
    );

};

export default BudgetIndex;