import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import {
    getHomepageRoute,
    hasAuthRouteAccess,
    hasMainLayout,
    hasSecondaryLayout,
    isHomepageRedirectNeeded,
    isValidAuthRoute
} from 'lib/helpers/Router.helper';

import Authentication from '../../../lib/Authentication';
import { MainLayout } from '../_Layout/MainLayout';
import { SecondaryLayout } from '../_Layout/Secondary/SecondaryLayout';
import { AuthRoutes, NonAuthRoutes } from './Routes';

type Props = { children: JSX.Element };

export function ProtectedRoutes({ children }: Props) {
    const { pathname } = useLocation();

    const isLoggedIn = Authentication.isAuthenticated();

    if (!isLoggedIn)
        return <Redirect to={NonAuthRoutes.login} />;

    const isPasswordChangeNeeded = Authentication.getUserNeedChangePassword();

    // Redirect only if has flag enabled and is not in the target page!
    if (isPasswordChangeNeeded && pathname !== AuthRoutes.updatePassword)
        return <Redirect to={AuthRoutes.updatePassword} />;

    const profile = Authentication.getUserProfile();

    if (isHomepageRedirectNeeded(pathname))
        return <Redirect to={getHomepageRoute(profile)} />;

    // Narrow pathname type
    if (!isValidAuthRoute(pathname))
        return <Redirect to={NonAuthRoutes.notFound} />;

    if (!hasAuthRouteAccess(profile, pathname))
        return <Redirect to={NonAuthRoutes.forbidden} />;

    // Some routes doesn't have the main layout or the secondary layout
    if (!hasMainLayout(pathname) && !hasSecondaryLayout(pathname))
        return children;

    // Some routes doesn't have the main layout, but have the secondary layout
    if (!hasMainLayout(pathname) && hasSecondaryLayout(pathname))
        return (
            <SecondaryLayout>
                {children}
            </SecondaryLayout>
        );

    // Doesn't have the secondary layout, but have the main layout
    return (
        <MainLayout>
            {children}
        </MainLayout>
    );
};