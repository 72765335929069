import API from 'lib/API';

import { 
    ListScheduleIssues,
    ListScheduleMaintenances, 
    ListSchedulesCount  
} from './contracts/Schedule.contract';

const route = '/schedule';

/**
 * @example
 * ```js
 * const response = await listSchedulesCount('2024-06-03', '2024-06-09', [1]);
 * ```
 */
export function listSchedulesCount(
    firstDate: string,
    lastDate: string,
    clients: Client.Model['id'][] = []
): Promise<ListSchedulesCount.Response> {
    const params = new URLSearchParams({ first_date: firstDate, last_date: lastDate });

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}/count?${params.toString()}`);
};

/**
 * @example
 * ```js
 * const response = await listScheduleIssues('2024-06-03', [1, 2, 7]);
 * ```
 */
export function listScheduleIssues(date: string, clients: Client.Model['id'][] = []): Promise<ListScheduleIssues.Response> {
    const params = new URLSearchParams({ date });

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}/issues?${params.toString()}`);
};

/**
 * @example
 * ```js
 * const response = await listScheduleMaintenances('2024-06-03', [1, 2, 7]);
 * ```
 */
export function listScheduleMaintenances(date: string, clients: Client.Model['id'][] = []): Promise<ListScheduleMaintenances.Response> {
    const params = new URLSearchParams({ date });

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}/maintenances?${params.toString()}`);
};