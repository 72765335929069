import React, { useCallback, useEffect, useState } from 'react';

import {
    App,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    UploadFile,
} from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

import Authentication from 'lib/Authentication';
import { useNotice } from 'lib/providers/NoticeContextProvider';
import { UploadField } from 'lib/UploadField';

import { Show } from '../../lib/Show';
import { createNotice } from '../../services/Notice.service';
import { SchedulesField } from './SchedulesField';
import { useNoticeOptions } from './useNoticeOptions';

type SendingMethod = 'email' | 'whatsapp' | 'push';

export type Values = {
    title: Notice.Model['title'],
    body: Notice.Model['body'],
    sendingMethods: SendingMethod[],
    recipient: Notice.Model['recipient'],
    towers?: Notice.Model['towers'],
    apartments?: Notice.Model['apartments'],
    immediateDispatch: Notice.Model['immediate_dispatch'],
    schedules?: Array<Notice.ScheduleModel>,
    files?: Array<UploadFile>,
};

export function CreateNoticeModal() {
    const [isSending, setIsSending] = useState(false);

    const { setIsCreateModalVisible, fetchNotices } = useNotice();

    const close = () => setIsCreateModalVisible(false);

    const [form] = Form.useForm<Values>();

    const watchedImmediateDispatch = Form.useWatch('immediateDispatch', form);
    const watchedTowers = Form.useWatch('towers', form);
    const watchedApartments = Form.useWatch('apartments', form);

    const {
        isFetching,
        towerOptions,
        apartmentOptions,
        isApartmentsFieldVisible,
        isTowersFieldRequired,
    } = useNoticeOptions(form);

    useEffect(() => {
        // Validate again when immediate dispatch value is changed
        if (watchedImmediateDispatch !== undefined)
            form.validateFields();
    }, [form, watchedImmediateDispatch]);

    /** @todo Improves performance */
    const filterValidApartments = () => {
        // Clear the field when no towers are selected
        if (!watchedTowers || watchedTowers.length === 0)
            return form.setFieldValue('apartments', []);

        // Skip when no apartments are selected
        if (!watchedApartments || watchedApartments.length === 0)
            return;

        const selectedApartments = apartmentOptions.filter(apartment =>
            watchedApartments.includes(apartment.value as number)
        );

        const validApartments = selectedApartments.filter(apartment =>
            watchedTowers.includes(apartment.towerId)
        );

        form.setFieldValue('apartments', validApartments.map(apartment => apartment.value));
    };

    const memoizedFilterValidApartments = useCallback(
        filterValidApartments,
        [
            watchedTowers,
            watchedApartments,
            apartmentOptions,
            form
        ],
    );

    useEffect(() => {
        memoizedFilterValidApartments();
    }, [memoizedFilterValidApartments]);

    const app = App.useApp();

    const onFinish = async ({
        files,
        sendingMethods,
        schedules,
        towers,
        apartments,
        immediateDispatch,
        ...restValues
    }: Values) => {
        setIsSending(true);

        const parsedFiles = files?.map(file => ({ url: file.response, filename: file.name }));

        const body: Parameters<typeof createNotice>['0'] = {
            files: parsedFiles ?? [],
            sendEmail: sendingMethods.includes('email'),
            sendWhatsApp: sendingMethods.includes('whatsapp'),
            sendPushNotification: sendingMethods.includes('push'),
            schedules: schedules ?? [],
            towers: towers ?? [],
            apartments: apartments ?? [],
            immediate_dispatch: immediateDispatch,
            ...restValues,
        };

        const response = await createNotice(body);

        setIsSending(false);

        app.notification.open(response);

        fetchNotices();

        close();
    };

    const sendingMethodOptions: CheckboxGroupProps['options'] = [
        { label: 'E-mail', value: 'email' },
        // @ts-ignore
        // eslint-disable-next-line eqeqeq
        { label: 'Whatsapp', value: 'whatsapp', disabled: !(Authentication.getCurrentClientId() == 5), title: 'Entre em contato com o suporte para usar essa funcionalidade' },
        { label: 'Push Notification', value: 'push' },
    ];

    return (
        <Modal
            open
            centered
            width={640}
            title="Criar aviso"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Criar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createNotice"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col xs={24} lg={16}>
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="body"
                    label="Conteúdo"
                    rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <UploadField name='files' label='' buttonText='Anexar documentos e imagens' type='picture' />

                <Form.Item
                    name="sendingMethods"
                    label="Método de envio"
                    tooltip="Push Notification: Notificar os moradores diretamente pelo aplicativo Gcondo."
                    rules={[{ required: true, message: 'Por favor, selecione ao menos uma opção.' }]}
                >
                    <Checkbox.Group options={sendingMethodOptions} />
                </Form.Item>

                <Form.Item
                    name="recipient"
                    label="Para quem enviar?"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value="owners">Proprietários</Radio>
                        <Radio value="residents">Moradores</Radio>
                        <Radio value="tenants">Inquilinos</Radio>
                        <Radio value="all">Todas as opções anteriores</Radio>
                    </Radio.Group>
                </Form.Item>

                <Row>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            name="towers"
                            label="Torres"
                            tooltip="Caso queira enviar para todas as torres, deixe este campo em branco."
                            rules={[{ required: isTowersFieldRequired, message: 'Por favor, selecione uma ou mais opções.' }]}
                        >
                            <Select options={towerOptions} loading={isFetching} mode="multiple" />
                        </Form.Item>
                    </Col>

                    <Show when={isApartmentsFieldVisible}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="apartments"
                                label="Apartamentos"
                                tooltip="Caso queira enviar para todos os apartamentos das torres selecionadas, deixe este campo em branco."
                                rules={[{ required: false, message: 'Por favor, selecione uma ou mais opções.' }]}
                            >
                                <Select options={apartmentOptions} loading={isFetching} mode="multiple" />
                            </Form.Item>
                        </Col>
                    </Show>
                </Row>

                <Form.Item
                    name="immediateDispatch"
                    label="Enviar imediatamente?"
                    tooltip="Você ainda possui a possibilidade de agendar."
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value={false}>Não</Radio>
                        <Radio value={true}>Sim</Radio>
                    </Radio.Group>
                </Form.Item>

                <SchedulesField required={watchedImmediateDispatch !== true} />
            </Form>
        </Modal>
    );
}
