import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';

import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { CreateIssueForm } from 'components/Client/CreateIssueForm';
import { Layout } from 'components/Client/Layout';
import { ClientContextProvider, ClientContextProviderProps } from 'lib/providers/ClientContextProvider';

type Params = { url_client: NonNullable<Client.Model['url']> };

type Props = RouteComponentProps<Params>;

export function CreateIssue({ match }: Props) {
    const url = match.params.url_client;

    const children: ClientContextProviderProps['children'] = ({ isLoading, loadClient }) => {
        if (isLoading)
            return <LoadingGcondo />;

        const client = loadClient();
        
        const description = (
            client.client_manager.create_issue_description ??
            'Está com alguma duvida? Encontrou algum problema? Fale com a gente!'
        );

        return (
            <Layout backgroundColor={client.client_manager.secondary_color}>
                <Row style={{ marginBottom: 0 }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <img 
                            src={client.client_manager.logo} 
                            alt={client.client_manager.name}
                            width="196"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Typography.Title 
                            level={3}
                            style={{ 
                                marginTop: 0,
                                marginBottom: 0,
                                color: client.client_manager.primary_color ?? undefined
                            }}
                        >
                            {client.name}
                        </Typography.Title>

                        <Typography.Text type="secondary">
                            {description}
                        </Typography.Text>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <CreateIssueForm />
                    </Col>
                </Row>
            </Layout>
        );
    };

    return (
        <ClientContextProvider url={url}>
            {children}
        </ClientContextProvider>
    );
}
