import React, { useState } from 'react';

import { App, Button, Card, List, Popconfirm, Typography, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';

import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import Authentication from 'lib/Authentication';
import { Show } from 'lib/Show';
import { importClients } from 'services/Setup.service';

export function ClientImportCard() {
    const app = App.useApp();
    const [file, setFile] = useState<UploadFile | null>(null);
    const [result, setResult] = useState<Setup.ImportResult>();

    const handleUpload = async () => {
        if (!file) {
            app.notification.error({ message: 'Por favor, selecione um arquivo primeiro.' });
            return;
        }

        const response = await importClients(file as RcFile);

        if (response.success) {
            app.notification.success({ message: response.message });
            setResult(response.result);
            Authentication.syncAllowedClientsUser();
        }
    };

    const uploadProps = {
        beforeUpload: (file: UploadFile) => {
            setFile(file);
            return false;
        },
        fileList: file ? [file] : [],
    };

    const renderFailedItem = (item: Setup.ResultItem) => (
        <Typography.Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} />
            <Typography.Text strong> Linha {item.line} ({item.client ?? 'Condomínio sem nome'}):</Typography.Text>
            <Typography.Text> {item.reason}</Typography.Text>
        </Typography.Paragraph>
    );

    return (
        <Card>
            <Show when={!result}>
                <Typography.Link href='/assets/sheets/modelo_condominios.xlsx' target='_blank'>Baixar planilha modelo <DownloadOutlined /></Typography.Link>
                <Upload.Dragger {...uploadProps} style={{ marginTop: 16 }}>
                    Realizar upload do arquivo preenchido <UploadOutlined />
                </Upload.Dragger>
                <Popconfirm
                    title="Tem certeza que deseja realizar a importação?"
                    description="Após a confirmação, essa ação não poderá ser cancelada."
                    onConfirm={handleUpload}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button
                        type="primary"
                        style={{ marginTop: 16 }}
                        disabled={!file}
                    >
                        Importar
                    </Button>
                </Popconfirm>
            </Show>
            
            {/* eslint-disable-next-line eqeqeq */}
            <Show when={result != undefined}>
                <div className="desc">
                    <Typography.Paragraph>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Resultado da importação:
                        </Typography.Text>
                    </Typography.Paragraph>

                    {/* eslint-disable-next-line eqeqeq */}
                    <Show when={result !== undefined && result.success.length > 0 && result.failed.length == 0}>
                        <CheckCircleOutlined color='green' /> Todos os condomínios foram importados com sucesso.
                    </Show>

                    <Show when={result !== undefined && result.failed.length > 0}>
                        <List
                            dataSource={result?.failed}
                            renderItem={renderFailedItem}
                        />
                    </Show>

                    <Show when={result !== undefined}>
                        <Typography.Paragraph style={{ marginTop: 16 }}>
                            <Typography.Link onClick={() => setResult(undefined)} strong>Clique aqui para realizar uma nova importação.</Typography.Link>
                        </Typography.Paragraph>
                    </Show>

                </div>
            </Show>
        </Card>
    );
};