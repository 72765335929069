import API from '../lib/API';
import { CreateBudget, FindBudget, FindMonthBudget, GenerateMonthBudget, ListBudget, UpdateBudget } from './contracts/BudgetServiceContract';

const route = '/budget';

export function listBudget(): Promise<ListBudget.Response> {
    return API.get(route);
};

export function findBudget(id: Budget.Model['id']): Promise<FindBudget.Response> {
    return API.get(`${route}/${id}`);
};

export function findCurrentBudget(): Promise<FindBudget.Response> {
    return API.get(`${route}/current`);
};

export function createBudget(body: CreateBudget.Body): Promise<CreateBudget.Response> {
    return API.post(route, body);
};

export function updateBudget(id: Budget.Model['id'], body: UpdateBudget.Body): Promise<UpdateBudget.Response> {
    return API.put(`${route}/${id}`, body);
};

export function generateMonthBudget(id: Budget.Model['id']): Promise<GenerateMonthBudget.Response> {
    return API.post(`${route}/generate/${id}`);
};

export function findMonthBudget(id: Budget.Model['id'], date: string): Promise<FindMonthBudget.Response> {
    return API.post(`${route}/find/${id}`, { date: date });
};

export function removeBudget() {

};