
import React, { useState } from 'react';

import { App, Dropdown, MenuProps } from 'antd';
import { useAppProps } from 'antd/es/app/context';
import type { ColumnsType } from 'antd/es/table';

import { DeleteOutlined, EditOutlined, EyeOutlined, RetweetOutlined } from '@ant-design/icons';
import { renderRecurrenceMaintenance, renderStatusMaintenance } from 'components/Overview/PendingMaintenancesCard';
import dayjs from 'dayjs';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteMaintenance, reopenMaintenance } from 'services/Maintenance.service';

type Props = { maintenance: Maintenance.Model };

/** @factory */
const makeItems = (
    maintenance: Props['maintenance'],
    app: useAppProps,
    handleDetail: () => void,
    handleEdit: () => void,
    handleDelete: () => void,
    handleReopen: () => void,
): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [];

    if (!maintenance.isFinished)
        items.push(
            {
                key: 'details',
                label: 'Detalhes',
                icon: <EyeOutlined />,
                onClick: handleDetail,
            },
            {
                key: 'edit',
                label: 'Editar',
                icon: <EditOutlined />,
                onClick: handleEdit,
            }
        );
    else
        items.push(
            {
                key: 'reopen',
                label: 'Reabrir',
                onClick: () => app.modal.confirm({
                    title: 'Reabrir ',
                    content: 'Tem certeza que deseja reabrir esta manutenção?',
                    okText: 'Sim',
                    okType: 'danger',
                    onOk: handleReopen,
                    cancelText: 'Não',
                }),
                icon: <RetweetOutlined />,
                danger: true,
            },
        );

    items.push({
        key: 'delete',
        label: 'Excluir',
        icon: <DeleteOutlined />,
        onClick: () => app.modal.confirm({
            title: 'Excluir manutenção',
            content: 'Tem certeza que deseja excluir esta manutenção?',
            okText: 'Sim',
            okType: 'danger',
            onOk: handleDelete,
            cancelText: 'Não',
        }),
        danger: true,
    });

    return items;
};

const Actions = ({ maintenance }: Props) => {
    const [isSending, setIsSending] = useState(false);

    const {
        setIsDetailModalVisible,
        setIsFinishMaintenanceModal,
        setIsEditModalVisible,
        setMaintenanceId,
        fetchMaintenances
    } = useMaintenance();

    const app = App.useApp();

    const handleDetail = () => {
        setMaintenanceId(maintenance.id);
        setIsDetailModalVisible(true);
    };

    const handleEdit = () => {
        setMaintenanceId(maintenance.id);
        setIsEditModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteMaintenance(maintenance.id);

        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        fetchMaintenances();
    };

    const handleFinish = () => {
        setMaintenanceId(maintenance.id);
        setIsFinishMaintenanceModal(true);
    };

    const handleReopen = async () => {
        setIsSending(true);

        const response = await reopenMaintenance(maintenance.id);

        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        fetchMaintenances();
    };

    const items = makeItems(
        maintenance,
        app,
        handleDetail,
        handleEdit,
        handleDelete,
        handleReopen,
    );

    return (
        <Dropdown.Button
            trigger={['click']}
            menu={{ items, disabled: isSending }}
            onClick={maintenance.isFinished ? handleDetail : handleFinish}
            loading={isSending}
        >
            {maintenance.isFinished ? 'Detalhes' : 'Finalizar'}
        </Dropdown.Button>
    );
};


export const Columns: ColumnsType<Maintenance.Model> = [
    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Status',
        key: 'status',
        render: (_, maintenance) => renderStatusMaintenance(maintenance),
        width: 120,
    },

    {
        title: 'Data estimada',
        dataIndex: 'estimatedDate',
        render: (estimatedDate) => (
            estimatedDate
                ? dayjs(estimatedDate).format('DD/MM/YYYY')
                : '-'
        ),
        width: 135,
    },

    {
        title: 'Data realizada',
        dataIndex: 'completionDate',
        render: (completionDate) => (
            completionDate
                ? dayjs(completionDate).format('DD/MM/YYYY')
                : '-'
        ),
        width: 130,
    },

    {
        title: 'Categoria',
        dataIndex: 'maintenance_type',
        render: (type, { description }) => type?.name ?? description,
    },

    {
        title: 'Recorrência', dataIndex: 'type', key: 'type',
        render: (_, maintenance) => renderRecurrenceMaintenance(maintenance)
    },

    {
        title: 'Fornecedor',
        dataIndex: 'supplier',
        render: supplier => supplier?.name ?? '-'
    },

    {
        render: (_, maintenance) => <Actions maintenance={maintenance} />,
    },
];