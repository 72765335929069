import API from '../lib/API';
import { CreateSupplier, FindSupplier, ListSuppliers, RemoveSupplier, UpdateSupplier } from './contracts/Supplier.contract';

const route = '/supplier';

/**
 * @route `supplier?clients[]=7&clients[]=8
 * @route `supplier
 */
export function listSuppliers(clients: Client.Model['id'][] = []): Promise<ListSuppliers.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
}

export function findSupplier(id: Supplier.Model['id']): Promise<FindSupplier.Response> {
    return API.get(`${route}/${id}`);
};

export function createSupplier(body: CreateSupplier.Body): Promise<CreateSupplier.Response> {
    return API.post(route, body);
};

export function updateSupplier(id: Supplier.Model['id'], body: UpdateSupplier.Body): Promise<UpdateSupplier.Response> {
    return API.put(`${route}/${id}`, body);
};

export function removeSupplier(id: Supplier.Model['id']): Promise<RemoveSupplier.Response> {
    return API.delete(`${route}/${id}`);
};