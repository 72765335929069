import React, { } from 'react';

import { Card } from 'antd';

import { Column } from '@ant-design/plots';
import { useStats } from 'lib/providers/StatsContextProvider';


export function ChartIssuesPerMonthAndYear (){
    const { stats, isLoading } = useStats();

    const data = stats.total_issues_per_month_and_year ?? [];
 
    const config = {
        data: data,
        isGroup: true,
        xField: 'date',
        yField: 'value',
        seriesField: 'name'
    };

    return <Card title="Volume de chamados mensais">
        <Column {...config} loading={isLoading} />
    </Card>;
};