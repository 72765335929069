import React, { useEffect, useState } from 'react';

import { Card, Table } from 'antd';

import API from '../../../lib/API';
import { ReserveInterface } from '../../_Common/_Interfaces/Reserve';

const ReserveToday: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [reserves, setReserves] = useState(Array<ReserveInterface>);
    const reserveColumns = [
        {
            title: 'Ambiente',
            key: 'ambient',
            render: (_: any, reserve: ReserveInterface) => reserve.ambient.name
        },

        {
            title: 'Unidade',
            key: 'apartment',
            render: (_: any, reserve: ReserveInterface) => `${reserve.apartment.tower.name} - ${reserve.apartment.name}`,
        },
    ];

    async function fetchData() {
        const reserves = await API.get('/reserve/today');

        setReserves(reserves);
        setLoading(false);
    };

    useEffect(() => { fetchData(); }, []);

    return (
        <Card title="Reservas do dia">
            <Table
                size='small'
                loading={loading}
                pagination={false}
                columns={reserveColumns}
                dataSource={reserves}
            />
        </Card>
    );
};

export default ReserveToday;