import React from 'react';

import {
    Alert,
    Drawer,
} from 'antd';

import { FilesGalery } from 'components/_Common/FilesGalery';
import { useIssue } from 'lib/providers/IssueContextProvider';

export default function ViewTaskModal() {
    const { task, setViewTaskModal, viewTaskModal } = useIssue();

    return (
        <Drawer
            open={viewTaskModal}
            destroyOnClose
            title={task?.description}
            placement="right"
            onClose={() => setViewTaskModal(false)}
        >
            <Alert
                message="Justificativa:"
                description={task?.justification && task.justification.length > 0 ? task.justification : 'Não foi declarada nenhuma justificativa ao finalizar está tarefa!'}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
            />

            <FilesGalery files={task?.files}/>
        </Drawer>
    );
}
