import React from 'react';

import { Button, Upload, UploadProps } from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import API from '../../lib/API';

type Props = {
    addFile: Function,
    multiple?: boolean,
    maxCount?: number
};

const UploadGcondo: React.FC<Props> = (props: Props) => {
    const URL_UPLOAD = `${API.api_url}/file/upload`;
    const uploadProps: UploadProps = {
        name: 'file',
        multiple: props.multiple ?? true,
        maxCount: props.maxCount ?? undefined,
        action: URL_UPLOAD,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done')
                props.addFile(info.fileList);
        }
    };
    
    return <Upload {...uploadProps} >
        <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>;
};

export default UploadGcondo;