import React, { useEffect, useState } from 'react';

import {
    App,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    SelectProps,
} from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { sleep } from 'lib/Sleep';
import { updateConstruction } from 'services/Construction.service';

type Values = {
    name: string,
    description: Construction.Model['description'],
    status: Construction.Status,
    start_date: Construction.Model['start_date'] | Dayjs,
    expected_end_date: Construction.Model['expected_end_date'] | Dayjs,
    end_date: Construction.Model['end_date'] | Dayjs,
    approved_budget: Construction.Model['approved_budget'],
    final_budget: Construction.Model['final_budget'],
    created_by_user_id: Construction.Model['created_by_user_id'],
    client_id: number,
};

type SelectOptions = NonNullable<SelectProps['options']>;

export function EditConstructionModal() {
    const [isSending, setIsSending] = useState(false);
    const {
        construction,
        setIsEditModalVisible,
        setConstructionId,
        fetchConstructions
    } = useConstruction();

    if (!construction)
        throw new Error('Value of the `document` property is unknown');

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    useEffect(() => {
        const {
            name,
            description,
            client_id,
            status,
            start_date,
            expected_end_date,
            end_date,
            approved_budget,
            final_budget,
        } = construction;

        form.setFieldsValue({
            name,
            description,
            client_id,
            status,
            start_date: start_date ? dayjs(start_date) : null,
            expected_end_date: expected_end_date ? dayjs(expected_end_date) : null,
            end_date: end_date ? dayjs(end_date) : null,
            approved_budget,
            final_budget,
        });
    }, [construction, form]);

    const close = () => {
        setIsEditModalVisible(false);
        setConstructionId(null);
    };

    const onFinish = async ({
        name,
        description,
        start_date,
        expected_end_date,
        end_date,
        approved_budget,
        final_budget,
        client_id, }: Values) => {
        setIsSending(true);

        const response = await updateConstruction(construction.id, {
            name,
            description,
            start_date: start_date?.toString(),
            expected_end_date: expected_end_date?.toString(),
            end_date: end_date?.toString(),
            approved_budget,
            final_budget,
            client_id,
        });
        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: 'Sucesso',
            description: response.message,
        });

        close();
        fetchConstructions();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();

    return (
        <Modal
            title="Editar Obra"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editConstruction"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Insira uma descrição"
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="client_id"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select options={clients} filterOption={filterOption} />
                </Form.Item>

                <Form.Item
                    name="start_date"
                    label="Data inicial"
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>

                <Form.Item
                    name="expected_end_date"
                    label="Data esperada"
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>

                <Form.Item
                    name="end_date"
                    label="Data final"
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>

                <Form.Item
                    name="approved_budget"
                    label="Orçamento aprovado"
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name="final_budget"
                    label="Orçamento final"
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

