import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { App, Button, Form, FormRule, Input, Typography } from 'antd';
import { MaskedInput } from 'antd-mask-input';

import { FileProtectOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { ReCAPTCHA } from 'lib/ReCAPTCHA';
import { recoverResidentPassword } from 'services/External.service';

type Values = {
    cpf: User.Model['cpf'],
    email: User.Model['email'],
    password: string,
    passwordConfirmation: string,
};

/** @factory */
const makeCpfRules = (): FormRule[] => [
    { required: true, message: 'Por favor, digite seu CPF.' },
    { pattern: new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/), message: 'Por favor, digite um CPF válido.'},
];

/** @factory */
const makePasswordRules = (): FormRule[] => [
    { required: true, message: 'Por favor, digite sua nova senha.' },

    { min: 8, message: 'Por favor, digite ao menos 8 caracteres.'},

    { pattern: new RegExp(/[A-Z]/), message: 'Por favor, digite ao menos uma letra maiúscula.'},

    { pattern: new RegExp(/\d/), message: 'Por favor, digite ao menos um digíto.'},
];

/** @factory */
const makePasswordConfirmationRules = (): FormRule[] => [
    { required: true, message: 'Por favor, confirme sua senha.' },

    ({ getFieldValue }) => ({
        // Do the passwords match?
        validator: (_, value) => (!value || getFieldValue('password') === value)
            ? Promise.resolve()
            : Promise.reject('As senhas não correspondem!')
    }),
];

/** @factory */
const makeMessage = () => (message: string): string => {
    if (message === 'The resident was not found by the provided CPF')
        return 'Não foi possível encontrar um morador com os dados fornecidos.';

    if (message === 'The found resident email don\'t match the provided email')
        return 'Não foi possível encontrar um morador com os dados fornecidos.';

    return message;
};

const wl = makeWhiteLabel();

export function RecoverResidentPassword(_: RouteComponentProps) {
    const [token, setToken] = useState<string | null>(null);
    const [isSending, setIsSending] = useState(false);
    const [form] = Form.useForm<Values>();

    const app = App.useApp();
    const message = makeMessage();

    const onFinish = async (values: Values) => {
        setIsSending(true);

        const response = await recoverResidentPassword(values);

        setIsSending(false);

        if (!response.success)
            return handleServiceError(app, response, { message });

        app.notification.success({ 
            message: 'Sucesso!',
            description: 'Senha recuperada.'
        });
    };

    const cpfRules = makeCpfRules();
    const passwordRules = makePasswordRules();
    const passwordConfirmationRules = makePasswordConfirmationRules();

    return (
        <div className="screen-container">
            <div className="form-container">
                <Form
                    form={form}
                    onFinish={onFinish}
                    name="recoverResidentPassword"
                    layout="vertical"
                    autoComplete="off"
                >
                    <div style={{ textAlign: 'center', padding: 20 }}>
                        <img src={wl.logo} alt="Gcondo logo" style={{ width: '80%' }} />
                    </div>
                    <Typography.Title style={{ marginTop: 0 }} level={5}>
                        Recuperar senha do aplicativo
                    </Typography.Title>

                    <Form.Item
                        name="cpf"
                        label="CPF"
                        rules={cpfRules}
                    >
                        <MaskedInput
                            mask="000.000.000-00"
                            prefix={<FileProtectOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[{ required: true, type: 'email', message: 'Por favor, digite seu e-mail.' }]}
                    >
                        <Input
                            type="email"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Digite seu e-mail"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        validateFirst
                        rules={passwordRules}
                        label="Nova senha"
                        tooltip="A senha deve possuir no mínimo 8 caracteres. Além disso, deve conter ao menos uma letra maiúscula e um digíto."
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Digite a sua nova senha"
                        />
                    </Form.Item>

                    <Form.Item
                        name="passwordConfirmation"
                        validateFirst
                        rules={passwordConfirmationRules}
                        dependencies={['password']}
                        label="Confirme a senha"
                        tooltip="A senha deve ser igual a anterior."
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Repita a senha" />
                    </Form.Item>

                    <ReCAPTCHA onChange={value => setToken(value)}/>

                    <hr />

                    <div className="flex align-center space-between">
                        <Button
                            loading={isSending}
                            type="primary"
                            htmlType="submit"
                            disabled={token === null}
                        >
                            {isSending ? 'Carregando...' : 'Recuperar'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
