import API from '../lib/API';
import { 
    CreateDocument,
    DeleteDocument, 
    ListDocuments,
    ListDocumentTypes,
    UpdateDocument,
} from './contracts/Document.contract';

const route = '/document';

export function listDocuments(clients: Client.Model['id'][] = []): Promise<ListDocuments.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
};

export function listDocumentTypes(): Promise<ListDocumentTypes.Response> {
    return API.get(`${route}/types`);
};

export function createDocument(body: CreateDocument.Body): Promise<CreateDocument.Response> {
    return API.post(route, body);
};

export function updateDocument(id: Document.Model['id'], body: UpdateDocument.Body): Promise<UpdateDocument.Response> {
    return API.put(`${route}/${id}`, body);
};

export function deleteDocument(id: Document.Model['id']): Promise<DeleteDocument.Response> {
    return API.delete(`${route}/${id}`);
};