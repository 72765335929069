import React, { useState } from 'react';

import {
    App,
    Checkbox,
    Flex,
    Form,
    List,
    Modal,
    Space,
    Typography
} from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { LinkedTasksDisapproved } from 'services/contracts/Issue.contract';
import { finishIssue, linkedTasksDisapproved, uploadIssueFile } from 'services/Issue.service';

export default function ViewTasksDisapprovedModal() {
    const [form] = Form.useForm();

    const [isSending, setIsSending] = useState(false);
    const {
        tasks,
        setViewTasksDisapprovedModal,
        issue,
        viewTasksDisapprovedModal,
        setTasks,
        fetchIssues,
        bodyFinishIssue,
        setIsFinishIssueModal,
        filesIssue
    } = useIssue();

    const app = App.useApp();

    const onFinish = async () => {
        setIsSending(true);

        const values = await form.validateFields();

        if (!issue)
            throw new Error('Requested issue not found!');

        if (values.tasks) {
            const body: LinkedTasksDisapproved.Body = {
                issueId: issue?.id,
                tasks: values.tasks,
            };

            await linkedTasksDisapproved(body);
        }

        if (bodyFinishIssue) {
            const response = await finishIssue(issue.id, bodyFinishIssue);

            if ('success' in response) {
                setIsSending(false);
                return handleServiceError(app, response);
            }

            const parsedFiles = filesIssue?.map(file => ({
                filename: file.name,
                url: file.response ?? file.url,
                issueId: response.id,
            })) ?? [];

            const promises = parsedFiles.map(file => uploadIssueFile(file));
            const files = await Promise.all(promises);
            const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

            if (firstErrorFile !== undefined)
                return handleServiceError(app, firstErrorFile);
        }

        setViewTasksDisapprovedModal(false);
        setIsFinishIssueModal(false);
        setIsSending(false);
        setTasks([]);
        fetchIssues();
    };

    return (
        <Modal
            centered
            title="Tarefas pendentes"
            destroyOnClose={true}
            okText="Salvar"
            open={viewTasksDisapprovedModal}
            confirmLoading={isSending}
            onOk={onFinish}
            onCancel={onFinish}
        >

            <Space direction='vertical'>
                <Typography.Text>
                    Constatamos que o chamado em questão contém tarefas pendentes de conclusão.
                </Typography.Text>

                <Typography.Text>
                    Selecione as tarefas das quais deseja criar novos chamados:
                </Typography.Text>
            </Space>

            <Form form={form} onFinish={onFinish}>
                <Form.Item name="tasks">
                    <Checkbox.Group>
                        <List dataSource={tasks} renderItem={task => (
                            <Flex vertical>
                                <Checkbox key={task.id} value={task.id}>
                                    {task.description}
                                </Checkbox>
                            </Flex>
                        )}>
                        </List>
                    </Checkbox.Group>

                </Form.Item>
            </Form>

        </Modal >
    );
}
