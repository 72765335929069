import React, { useEffect, useState } from 'react';

import {
    App,
    DatePicker,
    Form,
    Modal,
    Typography,
    UploadFile,
} from 'antd';

import dayjs from 'dayjs';
import { DATE_SERVER_FORMAT } from 'internal-constants';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { usePayment } from 'lib/providers/PaymentContextProvider';
import { UploadField } from 'lib/UploadField';
import { paymentMade, uploadFile } from 'services/Payment.service';

type Values = {
    files: UploadFile[],
    payment_date: dayjs.Dayjs,
};

export default function PaymentMadeModal() {
    const [form] = Form.useForm();
    const { setIsPaymentMadeModalVisible, fetchPayments, paymentId, setPaymentId, payment } = usePayment();
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();


    const close = () => {
        setIsPaymentMadeModalVisible(false);
    };

    useEffect(() => {
        const files: UploadFile[] = payment !== null
            ? payment?.files.map(file => ({ uid: file.url, name: file.filename, url: file.url, status: 'done' }))
            : [];

        form.setFieldsValue({
            files: files,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment]);

    const onFinish = async (values: Values) => {
        if (paymentId === undefined) {
            throw new Error('Requested payment not found!');
        }

        setIsSending(true);

        const response = await paymentMade(paymentId, values.payment_date.format(DATE_SERVER_FORMAT));

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles = values.files?.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
            payment_id: paymentId,
        })) ?? [];

        parsedFiles.map(file => uploadFile(file));

        close();
        setIsSending(false);

        fetchPayments();
        setPaymentId(undefined);
    };

    return (
        <Modal
            centered
            title="Realizar pagamento"
            destroyOnClose={true}
            onCancel={close}
            confirmLoading={isSending}
            okText="Salvar"
            open={true}
            onOk={form.submit}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item name="payment_date" label="Data de pagamento" rules={[{ required: true, message: 'Selecione a data de pagamento' }]}>
                    <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                </Form.Item>

                <Typography.Paragraph>
                    Por favor, anexe as notas fiscais e os comprovantes de pagamento.
                </Typography.Paragraph>

                <UploadField name='files' type='picture' multiple />

            </Form >
        </Modal >
    );
}