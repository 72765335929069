import React, { CSSProperties } from 'react';

import { Col, Row, Typography } from 'antd';

import { ClientCreateModal } from 'components/Account/Client/ClientCreateModal';
import { ClientEditModal } from 'components/Account/Client/ClientEditModal';
import { ClientManagerCardClients } from 'components/Account/Client/ClientManagerCardClients';
import { ClientQrCodeModal } from 'components/Account/Client/ClientQrCodeModal';
import { ClientManagerCard } from 'components/Account/ClientManagerCard';
import { ClientManagerCardPlan } from 'components/Account/ClientManagerCardPlan';
import { Layout } from 'components/Account/Layout';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { AccountContextProvider } from 'lib/providers/AccountContextProvider';
import { Show } from 'lib/Show';

const wl = makeWhiteLabel();
const style: CSSProperties = { color: wl.colorSecondary };

export function Account() {
    return (
        <AccountContextProvider>
            {({
                isCreateClientModalVisible,
                isEditClientModalVisible,
                isQrCodeModalVisible
            }) => (
                <Layout>
                    <Row>
                        <Col span={24}>
                            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                                Minha conta
                            </Typography.Title>

                            <Typography.Text type="secondary">
                                Visualize as informações da sua <b style={style}>empresa</b>, detalhes do <b style={style}>plano contratado</b> e a lista de <b style={style}>condomínios</b> que você administra.
                            </Typography.Text>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <ClientManagerCard />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={8}>
                            <ClientManagerCardPlan />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <ClientManagerCardClients />
                        </Col>
                    </Row>

                    <Show when={isCreateClientModalVisible}>
                        <ClientCreateModal />
                    </Show>

                    <Show when={isEditClientModalVisible}>
                        <ClientEditModal />
                    </Show>

                    <Show when={isQrCodeModalVisible}>
                        <ClientQrCodeModal />
                    </Show>
                </Layout>
            )}
        </AccountContextProvider>
    );
}
