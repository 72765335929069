import React, { Component, ReactNode } from 'react';

import { InternalServerError } from 'components/_Common/InternalServerError';

type Props = { children: ReactNode };

type State = { error: Error | null };

export class AuthenticationErrorBoundary extends Component<Props, State> {
    state: State = { error: null };

    static getDerivedStateFromError(error: Error) {
        const regex = new RegExp(/Something went wrong! The "\w*" key does not exist in local storage./);

        if (regex.test(error.message))
            return { error };
    }

    render() {
        if (this.state.error !== null)
            return <InternalServerError error={this.state.error} />;

        return this.props.children;
    }
}