import dayjs, { Dayjs } from 'dayjs';

export const getCalendarFirstDate = (date: Dayjs) => {
    const weekFirstDay = dayjs().startOf('week').day();
    
    const monthStartDate = date.startOf('month');
    const startDateWeekDay = monthStartDate.day();

    let alignStartDate = monthStartDate.add(weekFirstDay - startDateWeekDay, 'day');

    if (alignStartDate.month() === date.month() && alignStartDate.date() > 1)
        alignStartDate = alignStartDate.subtract(7, 'day');

    return alignStartDate;
};

export const getCalendarLastDate = (firstDate: Dayjs) => {
    const lastDate = firstDate.add(41, 'day');

    return lastDate;
};