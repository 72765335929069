import React, { useEffect,useState } from 'react';

import { Card } from 'antd';

import { Column } from '@ant-design/plots';

import { getTotalIssuePerMonth } from '../../services/Issue.service';

const IssuePerMonthChart: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [issuesPerMonth, setIssuesPerMonth] = useState<Array<Issue.ChartMonth>>([]);

    async function fetchData() {
        const promises = [];
        setLoading(true);

        promises.push(getTotalIssuePerMonth().then((response) => {
            if ('status' in response)
                return;
            setIssuesPerMonth(response);
        }));

        Promise.all(promises).then(() => setLoading(false));
    }

    useEffect(() => {
        fetchData();

    }, []);

    const config = {
        data: issuesPerMonth,
        isGroup: true,
        xField: 'date',
        yField: 'total',
        seriesField: 'status'
    };

    return <Card title="Volume de Chamados mensais" loading={loading}>
        <Column {...config} />
    </Card>;
};

export default IssuePerMonthChart;