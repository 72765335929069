import React, { useEffect, useState } from 'react';

import {
    App, 
    Checkbox,
    Collapse,
    DatePicker,
    Flex,
    Form,
    Input,
    Modal,
    UploadFile
} from 'antd';
import { CollapseProps } from 'antd/lib/collapse';

import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';
import { UploadField } from 'lib/UploadField';
import { finishIssue, uploadIssueFile } from 'services/Issue.service';

import FinishCheckboxModal from './FinishCheckboxModal';
import ViewTasksDisapprovedModal from './ViewTasksDisapprovedModal';

type Values = {
    status: Issue.Model['status'],
    finalization: Issue.Model['finalization'],
    resolutionDate: Issue.Model['resolutionDate'],
    files: UploadFile[] | undefined,
    userResolvedId: Issue.Model['userResolvedId'] | undefined,
};

type Body = Parameters<typeof finishIssue>['1'];

export default function FinishIssueFormModal() {
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();

    const {
        issue,
        setIsFinishIssueModal,
        fetchIssues,
        setFinishCheckboxModal,
        finishCheckboxModal,
        setTask,
        setTasks,
        setBodyFinishIssue,
        setViewTasksDisapprovedModal,
        viewTasksDisapprovedModal,
        setFilesIssue,
    } = useIssue();

    if (!issue)
        throw new Error('Value of the `document` property is unknown');

    const app = App.useApp();

    const close = () => {
        setIsFinishIssueModal(false);
    };

    const checkForPendingTasks = (checklists: Issue.Checklist[]) => {
        return checklists.some(checklist => checklist.tasks.some(task => task.type === 'pending'));
    };

    const getAllDisapprovedTasks = (checklists: Issue.Checklist[]) => {
        return checklists.flatMap(checklist => checklist.tasks.filter(task => task.type === 'disapproved'));
    };

    const onFinish = async (values: Values) => {
        if (checkForPendingTasks(issue.issue_checklist)) {
            return app.notification.error({ message: 'Atenção', description: 'Necessário finalizar todas as tarefas para concluir o chamado!' });
        }

        setIsSending(true);

        const body: Body = {
            status: 'closed',
            userResolvedId: Authentication.getUserId(),
            finalization: values.finalization,
            resolutionDate: values.resolutionDate
        };

        const tasksDisapproved = getAllDisapprovedTasks(issue.issue_checklist);
        if (tasksDisapproved.length > 0) {
            setTasks(tasksDisapproved);
            setViewTasksDisapprovedModal(true);
            setBodyFinishIssue(body);
            setFilesIssue(values.files);

            return;
        }

        const response = await finishIssue(issue.id, body);

        if ('success' in response) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles = values.files?.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
            issueId: response.id,
        })) ?? [];

        const promises = parsedFiles.map(file => uploadIssueFile(file));

        const files = await Promise.all(promises);

        // Maybe we have more errors, but it'is not so important in this context
        const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

        if (firstErrorFile !== undefined)
            return handleServiceError(app, firstErrorFile);

        setIsSending(false);
        fetchIssues();
        close();
    };

    useEffect(() => {
        const files: UploadFile[] = issue.issue_file.map(file => (
            {
                uid: file.url,
                name: file.filename,
                url: file.url,
                status: 'done',
            }
        ));

        form.setFieldsValue({
            files
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (option: Issue.TaskOption, isChecked: boolean) => {
        if (isChecked) {
            setTask({ id: option.value, description: option.label });
            setFinishCheckboxModal(true);
        }
    };

    const optionTasks = (checklist: Issue.Checklist) => {
        const options : Issue.TaskOption[] = checklist.tasks.map((task) => (
            { label: task.description, value: task.id, type: task.type }
        ));

        return (
            <Flex vertical>
                {options.map((option) => (
                    <Checkbox
                        key={option.value}
                        value={option.value}
                        onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                        checked={option.type !== 'pending'}
                        disabled={option.type !== 'pending'}
                    >
                        {option.label}
                    </Checkbox>
                ))}
            </Flex>
        );
    };

    const checklistsAndTasks: CollapseProps['items'] = issue.issue_checklist.map((checklist) => ({
        key: checklist.name,
        label: checklist.name,
        children: optionTasks(checklist),
    }));

    const isChecklist = issue.type === 'checklist';

    return (
        <Modal
            centered
            title="Finalizar chamado"
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            onOk={form.submit}
            confirmLoading={isSending}
            open
        >
            <Form
                form={form}
                onFinish={onFinish}
                name="finishIssue"
                layout="vertical"
                autoComplete="off"
            >
                <Show when={!isChecklist}>

                    <Form.Item<Values>
                        name="finalization"
                        label="Descrição"
                        rules={[{ required: true, message: 'Por favor, digite uma descrição.' }]}
                    >
                        <Input.TextArea rows={5} />
                    </Form.Item>
                </Show>

                <Show when={isChecklist}>
                    <Form.Item
                        name="checklist"
                        label='Checklists:'
                    >
                        <Form.Item name="checklists">
                            <Collapse accordion items={checklistsAndTasks} />
                        </Form.Item>
                    </Form.Item>
                </Show>

                <Show when={finishCheckboxModal}>
                    <FinishCheckboxModal />
                </Show>

                <Form.Item<Values>
                    name="resolutionDate"
                    label="Data de finalização"
                    initialValue={dayjs()}
                    rules={[{ required: true, message: 'Selecione uma data' }]}
                >
                    <DatePicker
                        // Can not select days before today
                        placeholder=""
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                    />
                </Form.Item>

                <UploadField 
                    name="files" 
                    type="picture"
                    multiple
                />

                <Show when={viewTasksDisapprovedModal}>
                    <ViewTasksDisapprovedModal />
                </Show>
            </Form>
        </Modal>
    );
}
