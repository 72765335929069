import React, { useMemo } from 'react';

import { Badge, Calendar,CalendarProps, Card } from 'antd';

import { Dayjs } from 'dayjs';
import { DATE_SERVER_FORMAT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { useSchedule } from 'lib/providers/ScheduleContextProvider';
import { Show } from 'lib/Show';
import { ListSchedulesCount } from 'services/contracts/Schedule.contract';

type CellRender = NonNullable<CalendarProps<Dayjs>['cellRender']>;

type MakeCellRender = (issues: ListSchedulesCount.Schedules | null) => CellRender;

/** @factory */
const makeIssuesText = (issueCount: number) => {
    if (issueCount > 1)
        return `${issueCount} chamados`;

    return `${issueCount} chamado`;
};

/** @factory */
const makeMaintenancesText = (maintenaceCount: number) => {
    if (maintenaceCount > 1)
        return `${maintenaceCount} manutenções`;

    return `${maintenaceCount} manutenção`;
};

/** @factory */
const makeCellRender: MakeCellRender = (schedules) => (date) => {
    if (!schedules)
        return null;

    const parsedDate = date.format(DATE_SERVER_FORMAT);
    
    const schedule = schedules[parsedDate];

    if (!schedule)
        return null;

    const { issues, maintenances } = schedule;

    const profile = Authentication.getUserProfile();

    return (
        <ul className="calendar-cell-events">
            <Show when={issues > 0}>
                <li>
                    <Badge status="warning" text={makeIssuesText(issues)} />
                </li>
            </Show>

            <Show when={profile !== 'issue' && maintenances > 0}>
                <li>
                    <Badge status="success" text={makeMaintenancesText(maintenances)} />
                </li>
            </Show>
        </ul>
    );
};

export function ScheduleCalendar() {
    const { schedules, date, setDate } = useSchedule();

    const cellRender: CellRender = useMemo(() => makeCellRender(schedules),[schedules]);

    return (
        <Card>
            <Calendar 
                mode="month" 
                className="calendar-without-mode-switch"
                cellRender={cellRender}
                value={date}
                onChange={setDate}
            />
        </Card>
    );
}
