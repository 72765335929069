import React, { useEffect, useState } from 'react';

import { Button, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import FormUser from 'components/User/Form';
import Authentication from 'lib/Authentication';
import { listUser } from 'services/UserService';

import { getColumns } from './Columns';

export function Users() {
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFinishing, setFinishing] = useState(false);
    const [userId, setUserId] = useState<number | null>(null);
    const [users, setUsers] = useState<Array<User.Model>>([]);

    const user = users.find((m) => m.id === userId);

    const closeForm = () => {
        setOpen(false);
        setFinishing(false);
        setUserId(null);
    };

    const fetchData = async () => {
        setLoading(true);

        const clients = Authentication.getClients().map(client => client.id);

        const response = await listUser(clients);

        if (!response.success)
            return;

        setUsers(response.users);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [isOpen, isFinishing]);

    const columns = getColumns(setOpen, setUserId, fetchData);

    return (
        <Content>
            <Row className="space-between" align="middle">
                <Typography.Title level={3}>
                    Usuários
                </Typography.Title>

                <Button 
                    className="right"
                    type="primary" 
                    onClick={() => setOpen(true)}
                >
                    Cadastrar
                </Button>
            </Row>

            <List 
                data={users}
                columns={columns} 
                loading={isLoading} 
            />

            <FormUser
                key={`form-${userId}`}
                user={user} 
                isOpen={isOpen}
                closeForm={closeForm}
            />
        </Content>
    );

}