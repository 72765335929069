import React from 'react';

import { Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';

type Props = {
    data: any[];
    columns: ColumnsType<any>;
    loading?: boolean;
    showHeader?: boolean,
    showPagination?: boolean,
    /** @default 'middle' */
    size?: TableProps['size'],
};

function List({ 
    columns,
    data, 
    loading = false,
    showHeader = true,
    showPagination = true,
    size = 'middle',
}: Props) {
    const pagination = showPagination
        ? { defaultPageSize: 5, hideOnSinglePage: true }
        : false;
    
    return (
        <Table
            rowKey={row => row.id}
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            showHeader={showHeader}
            size={size}
            rootClassName="table-horizontal-scroll"
        />
    );
};

export default List;
