import React from 'react';

import { App, Button, Card, Row, Space, TableColumnsType, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import { DeleteOutlined, EditOutlined, QrcodeOutlined } from '@ant-design/icons';
import List from 'components/_Common/List';
import Authentication from 'lib/Authentication';
import CobreFacil from 'lib/CobreFacil';
import { clientURL } from 'lib/helpers/Contratc.helper';
import { makeCnpj } from 'lib/helpers/Text.helper';
import { useAccount } from 'lib/providers/AccountContextProvider';
import { deleteAccountClient } from 'services/Account.service';

export function ClientManagerCardClients() {
    const {
        clients,
        isLoading,
        setIsCreateClientModalVisible,
        setIsEditClientModalVisible,
        setIsQrCodeModalVisible,
        setClientId,
        subscription,
        clientManager,
        fetchClients
    } = useAccount();

    const app = App.useApp();

    const handleEdit = (clientId: Client.Model['id']) => {
        setClientId(clientId);
        setIsEditClientModalVisible(true);
    };

    const handleQrCode = (clientId: Client.Model['id']) => {
        setClientId(clientId);
        setIsQrCodeModalVisible(true);
    };

    const handleDelete = async (clientId: Client.Model['id']) => {
        const response = await deleteAccountClient(clientId);

        if (!response.success)
            return app.notification.error({ message: response.message });

        const { message, description } = response;
        app.notification.success({ message, description });

        fetchClients();
    };

    const COLUMNS: TableColumnsType<Client.Model> = [
        {
            title: 'Nome',
            dataIndex: 'name',
        },

        {
            title: 'CNPJ',
            dataIndex: 'cnpj',
            render: (value) => value !== null ? makeCnpj(value) : '-',
        },

        {
            title: 'Email',
            dataIndex: 'email',
        },

        {
            title: 'Telefone',
            dataIndex: 'phone',
        },

        {
            title: 'URL',
            dataIndex: 'url',
            render: (value) => value
                ? <Link href={`${clientURL()}/${value}`} target='_blank'>
                    {clientURL()}/{value}
                </Link>
                : '-'
        },

        {
            title: '',
            key: 'actions',
            dataIndex: 'id',
            render: (value) =>
                Authentication.getClients().find(client => client.id === value)
                && <Space>
                    <QrcodeOutlined onClick={() => handleQrCode(value)} title="QrCode" />
                    <EditOutlined onClick={() => handleEdit(value)} title="Editar" />
                    <DeleteOutlined onClick={() => app.modal.confirm({
                        title: 'Excluir condomínio',
                        content: 'Tem certeza que deseja excluir este condomínio?',
                        okText: 'Sim',
                        okType: 'danger',
                        onOk: async () => await handleDelete(value),
                        cancelText: 'Não',
                    })} title="Delete" />
                </Space>
        }
    ];

    const isLimitReaching = (): boolean => {

        if (['trial', 'trial_sindigest'].includes(clientManager.contract))
            return false;

        if (subscription?.plans_id) {
            const planObj = CobreFacil.findPlan(subscription.plans_id);

            if (planObj)
                return clients.length >= planObj.limit;
        }
        return true;
    };

    return (
        <Card
            title={
                <Row justify="space-between" align="middle">
                    <Typography.Title level={5}>Condomínios administrados</Typography.Title>
                    <Button disabled={isLimitReaching()} type='primary' onClick={() => setIsCreateClientModalVisible(true)}>Cadastrar</Button>
                </Row>
            }
            loading={isLoading}>
            <List showHeader={false} columns={COLUMNS} data={clients} />
        </Card>
    );
};