import React, { 
    createContext, 
    Dispatch, 
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listDocuments, listDocumentTypes } from 'services/Document.service';

type Value = {
    isLoading: boolean,
    documents: Document.With<'client' | 'document_type' | 'files'>[],
    setDocuments: Dispatch<SetStateAction<Value['documents']>>,
    document: Document.With<'client' | 'document_type' | 'files'> | null,
    documentId: Document.Model['id'] | null,
    setDocumentId: Dispatch<SetStateAction<Value['documentId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    fetchDocuments: () => Promise<void>,
    isOverviewModule: boolean,
    types: Document.Type[],
    fetchTypes: () => Promise<void>,
};

type Props = { 
    isOverviewModule: boolean,
    children: (value: Value) => ReactNode,
};

const DocumentContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function DocumentContextProvider({ isOverviewModule, children }: Props) {
    const [isLoading, setIsLoading] = useState(true);

    const [documents, setDocuments] = useState<Value['documents']>([]);
    const [documentId, setDocumentId] = useState<Value['documentId']>(null);

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const [types, setTypes] = useState<Value['types']>([]);
    const [isTypesLoading, setIsTypesLoading] = useState(true);

    const app = App.useApp();

    const fetchDocuments = async () => {
        setIsLoading(true);

        const clients = isOverviewModule
            ? Authentication.getClients().map(client => client.id)
            : [Authentication.getCurrentClientId()];

        const response = await listDocuments(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setDocuments(response.documents);
    };

    const memoizedFetchDocuments = useCallback(fetchDocuments, [isOverviewModule, app]);

    useEffect(() => {
        memoizedFetchDocuments();
    }, [memoizedFetchDocuments]);

    const fetchTypes = async () => {
        setIsTypesLoading(true);

        const response = await listDocumentTypes();

        setIsTypesLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setTypes(response.types);
    };

    const memoizedFetchTypes = useCallback(fetchTypes, [app]);

    useEffect(() => {
        memoizedFetchTypes();
    }, [memoizedFetchTypes]);

    const document = useMemo(() => {
        if (!documentId)
            return null;

        const found = documents.find(document => document.id === documentId);

        if (!found)
            throw new Error(`Could not find a document with id ${documentId}`);

        return found;

    }, [documents, documentId]);

    const value: Value = { 
        isLoading: isLoading || isTypesLoading, 
        documents,
        setDocuments,
        document,
        documentId,
        setDocumentId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        fetchDocuments: memoizedFetchDocuments,
        isOverviewModule,
        types,
        fetchTypes: memoizedFetchTypes,
    };

    return (
        <DocumentContext.Provider value={value}>
            {children(value)}
        </DocumentContext.Provider>
    );
}

export function useDocument() {
    const context = useContext(DocumentContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `DocumentContextProvider`.');

    return context;
}