import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { App, Button, Divider, Modal, Space } from 'antd';

import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { listApartmentTypes } from 'services/ApartmentService';

import List from '../../_Common/List';
import { getColumns } from './Columns';
import { CreateTypeModal } from './CreateTypeModal';
import { EditTypeModal } from './EditTypeModal';

type Props = {
    onClose: () => void,
}

export function TypesModal({ onClose }: Props) {
    const [isFetching, setIsFetching] = useState(false);
    const [isCreateTypeModalVisible, setIsCreateTypeModalVisible] = useState(false);
    const [isEditTypeModalVisible, setIsEditTypeModalVisible] = useState(false);
    const [typeId, setTypeId] = useState<Apartment.Type['id'] | null>(null);
    const [types, setTypes] = useState<Apartment.Type[]>([]);

    const app = App.useApp();

    const fetchTypes = useCallback(async () => {
        setIsFetching(true);

        const response = await listApartmentTypes();

        // Slowed down a bit to improve UX
        await sleep(1000);

        setIsFetching(false);

        if ('type' in response)
            return app.notification.open(response);

        setTypes(response.types);
    }, [app]);

    useEffect(() => {
        fetchTypes();
    }, [fetchTypes]);

    const columns = useMemo(() => getColumns({ fetchTypes, setIsEditTypeModalVisible, setTypeId }), [fetchTypes]);

    return (
        <Modal
            open
            width={610}
            title="Tipos de unidade"
            footer={null}
            onCancel={onClose}
        >
            <Divider />

            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Button
                    type="primary"
                    block
                    onClick={() => setIsCreateTypeModalVisible(true)}
                >
                    Cadastrar novo tipo
                </Button>

                <List
                    columns={columns}
                    data={types}
                    loading={isFetching}
                    showPagination={false}
                />
            </Space>

            <Show when={isCreateTypeModalVisible}>
                <CreateTypeModal
                    close={() => setIsCreateTypeModalVisible(false)}
                    fetchTypes={fetchTypes}
                />
            </Show>

            <Show when={isEditTypeModalVisible}>
                <EditTypeModal
                    close={() => setIsEditTypeModalVisible(false)}
                    typeId={typeId!}
                    fetchTypes={fetchTypes}
                />
            </Show>
        </Modal>
    );
}
