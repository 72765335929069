import React from 'react';

import { Col, Form, Input, Row, Select, SelectProps, Space } from 'antd';

import { makeFilterOption } from 'lib/helpers/Form.helper';

import { Values } from './CreateIssueForm';

type Props = { 
    apartments: Apartment.Model[],
    isLoading: boolean,
};

type SelectOptions = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function ApartmentField({ apartments, isLoading }: Props) {
    if (!isLoading && apartments.length === 0) {
        const tooltip = (
            <Space direction="vertical">
                <strong>Exemplos</strong>

                <ul style={{ padding: 16, margin: 0 }}>
                    <li>Bloco 2 - Unidade 101</li>
                    <li>Torre 2 - 101</li>
                    <li>Unidade 101 (Torre 2)</li>
                </ul>
            </Space>
        );

        return (
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="apartment"
                        label="Unidade"
                        tooltip={tooltip}
                        rules={[{ required: true, message: 'Por favor, digite uma unidade.' }]}
                    >
                        <Input placeholder="Qual é a sua unidade?"/>
                    </Form.Item>
                </Col>
            </Row>  
        );
    }
    
    const parsedApartments: SelectOptions = apartments.map(({ tower, name }) => ({
        value: `${tower.name} - ${name}`,
        label: `${tower.name} - ${name}`
    }));

    return (
        <Form.Item<Values>
            name="apartment"
            label="Apartamento"
            rules={[{ required: true, message: 'Por favor, selecione um apartamento.' }]}
        >
            <Select
                options={parsedApartments}
                loading={isLoading}
                filterOption={filterOption}
                placeholder="Qual é o seu apartamento?"
                showSearch
                allowClear
            />
        </Form.Item>
    );
}
