import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useSearchParams(): URLSearchParams {
    const location = useLocation();

    const { search } = location;

    const params = useMemo(() => new URLSearchParams(search), [search]);

    return params;
}