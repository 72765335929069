import React from 'react';

import { Col, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import { CustomCard } from 'components/Report/CustomCard';
import { ModelCard } from 'components/Report/ModelCard';
import { ReportContextProvider } from 'lib/providers/ReportContextProvider';

export function Report() {

    return (
        <ReportContextProvider>
            {() => (
                <Content>
                    <Row>
                        <Col span={24}>
                            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                                Relatórios
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <ModelCard />
                        </Col>
                        <Col span={12}>
                            <CustomCard />
                        </Col>
                    </Row>
                </Content>
            )}
        </ReportContextProvider>
    );
}
