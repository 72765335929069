import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';

import { App, StepProps } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { steps } from 'pages/setup/Steps';
import { findClientManagerStep, listSteps, saveStep, updateStep } from 'services/Setup.service';

type Value = {
    isLoading: boolean,
    items: any,
    setupSteps: Setup.Step[],
    allowedSteps: Setup.CustomStep[],
    step: Setup.ClientManagerStep | null,
    current: number,
    setCurrent: Dispatch<SetStateAction<Value['current']>>,
    next: Function,
    prev: Function,
};

type Props = { children: (value: Value) => ReactNode };

const SetupContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function SetupContextProvider({ children }: Props) {
    const [current, setCurrent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<StepProps[]>([]);
    const [setupSteps, setSetupSteps] = useState<Setup.Step[]>([]);
    const [allowedSteps, setAllowedSteps] = useState<Setup.CustomStep[]>([]);
    const [step, setStep] = useState<Setup.ClientManagerStep | null>(null);

    const app = App.useApp();

    const next = async (finish: boolean) => {
        setIsLoading(true);

        if (step && step.status !== 'finished') {
            const status = finish ? 'finished' : 'in_progress';
            await updateStep(step.id, { status });
        }

        setIsLoading(false);
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const setCurrentStep = async () => {
        if (setupSteps.length <= 0)
            return;

        setIsLoading(true);

        const stepId = setupSteps[current].id;
        const response = await findClientManagerStep(stepId);

        if (response.success) {
            if (response.client_manager_step === null) {
                const responseSave = await saveStep({ setup_step_id: stepId });

                if (responseSave.success)
                    setCurrentStep();

            } else {
                setStep(response.client_manager_step);
            }
        }

        setIsLoading(false);
    };

    const fetchSteps = () => {
        setIsLoading(true);
        // eslint-disable-next-line eqeqeq
        const filteredSteps = steps.filter(step => setupSteps.some(setupStep => setupStep.key == step.key));

        setAllowedSteps(filteredSteps);

        const items = filteredSteps.map((item) => ({
            key: item.key,
            title: item.title,
            description: item.description
        }));

        setItems(items);
        setIsLoading(false);
    };

    const fetchSetupSteps = async () => {
        setIsLoading(true);

        const response = await listSteps();

        if (!response.success)
            return handleServiceError(app, response);

        setSetupSteps(response.steps);
        setIsLoading(false);
    };

    const memoizedFetchSteps = useCallback(fetchSetupSteps, [app]);

    useEffect(() => {
        memoizedFetchSteps();
    }, [memoizedFetchSteps]);

    useEffect(() => {
        fetchSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setupSteps]);

    useEffect(() => {
        setCurrentStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, setupSteps]);

    const value: Value = {
        isLoading,
        setupSteps,
        allowedSteps,
        items,
        step,
        current,
        setCurrent,
        next,
        prev
    };

    return (
        <SetupContext.Provider value={value}>
            {children(value)}
        </SetupContext.Provider>
    );
}

export function useSetup() {
    const context = useContext(SetupContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `SetupContextProvider`.');

    return context;
}