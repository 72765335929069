import React from 'react';

import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { Show } from 'lib/Show';
import { Filter } from 'pages/maintenance/Filters';

Font.register({ family: 'Montserrat', src: '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf' });
Font.register({ family: 'Montserrat Bold', src: '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf' });

const wl = makeWhiteLabel();

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        flexDirection: 'column', // Define o fluxo das páginas para "coluna"
        backgroundColor: '#ffffff',
    },
    section: {
        margin: 5,
        marginLeft: 20,
        padding: 5,
    },
    title: {
        fontSize: 16,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    subtitle: {
        fontSize: 12,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    subtitle2: {
        fontSize: 10,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    image: {
        width: 150
    },
    imageContainer: {
        width: '48%',
        margin: 2,
    },
    imageMaintenance: {
        width: '100%',
        height: 'auto',
    },
    paragraph: {
        fontSize: 10,
        marginBottom: 5,
    },
});

const pageProps = {
    title: 'Relatório de manutenções',
    author: 'Gcondo',
    subject: 'Gerado automaticamente via sistema Gcondo'
};

type Props = {
    maintenances: Maintenance.Model[],
    filters: Filter
};

export function MaintenancePDF({ maintenances, filters }: Props) {
    const withImage = (file: Maintenance.File) => ['.png', '.jpg', '.jpeg'].some(type => file.url && file.url.includes(type));
    const client = Authentication.getClients().find(client => client.id === Authentication.getCurrentClientId());
    const maintenancesClosed = maintenances.filter(maintenance => maintenance.isFinished);
    const maintenancesOpened = maintenances.filter(maintenance => !maintenance.isFinished);

    function Logos() {
        return <View style={{
            ...styles.section,
            marginTop: 0,
            marginBottom: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center'
        }}>
            <View>
                <Show when={client?.client_manager.logo === null || client?.client_manager.logo === undefined}>
                    <Image source={wl.icon} style={styles.image} />
                </Show>
                <Show when={client?.client_manager.logo !== null}>
                    <Image source={client?.client_manager.logo} style={styles.image} />
                </Show>
            </View>
        </View>;
    };

    function Header() {
        return <View style={styles.section}>
            <Text style={styles.title}>{client?.name} - Relatório de manutenções</Text>
            <Text style={styles.paragraph}>
                Período completo
            </Text>
        </View>;
    };

    function renderMaintenancesClosed() {
        if (maintenancesClosed.length === 0)
            return <Text style={styles.paragraph}>Nenhuma manutenção finalizada neste período.</Text>;

        return maintenancesClosed.map((maintenance, index) =>
            <View break={maintenance.files.filter(withImage).length > 0 && index > 0} style={{ marginBottom: 15, marginTop: 15, borderBottom: 1, borderColor: 'gray' }}>
                <Text style={styles.subtitle}>{maintenance.maintenance_type ? `${maintenance.maintenance_type.name} ` : null}#{maintenance.id}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Data de abertura: </Text>{dayjs(maintenance.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Categoria: </Text>{maintenance.maintenance_type ? maintenance.maintenance_type.name : '-'}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Descrição: </Text>{maintenance.description ? maintenance.description.replace(/(<([^>]+)>)/gi, '') : '-'}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Data de finalização: </Text>{dayjs(maintenance.completionDate).format('DD/MM/YYYY HH:mm')}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Solução: </Text>{maintenance.solution ? maintenance.solution.replace(/(<([^>]+)>)/gi, '') : '-'}</Text>
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    {
                        maintenance.files.filter(withImage).map(file =>
                            <View key={file.id} style={styles.imageContainer}>
                                <Image src={file.url} style={styles.imageMaintenance} />
                            </View>
                        )
                    }
                </View>
            </View>);
    };

    function renderMaintenancesOpened() {
        if (maintenancesOpened.length === 0)
            return <Text style={styles.paragraph}>Nenhuma manutenção pendente.</Text>;

        return maintenancesOpened.map((maintenance, index) =>
            <View break={maintenance.files.filter(withImage).length > 0 && index > 0} style={{ marginBottom: 15, marginTop: 15, borderBottom: 1, borderColor: 'gray' }}>
                <Text style={styles.subtitle}>{maintenance.maintenance_type ? `${maintenance.maintenance_type.name} ` : null}#{maintenance.id}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Data de abertura: </Text>{dayjs(maintenance.created_at).format('DD/MM/YYYY HH:mm')}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Categoria: </Text>{maintenance.maintenance_type ? maintenance.maintenance_type.name : '-'}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Descrição: </Text>{maintenance.description ? maintenance.description.replace(/(<([^>]+)>)/gi, '') : '-'}</Text>
                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Pendente: </Text>{dayjs().diff(dayjs(maintenance.created_at), 'day')} dia(s).</Text>
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    {
                        maintenance.files.filter(withImage).map(file =>
                            <View key={file.id} style={styles.imageContainer}>
                                <Image src={file.url} style={styles.imageMaintenance} />
                            </View>
                        )
                    }
                </View>
            </View>);
    };

    return <PDFViewer style={{ width: '100%', height: '500px' }}>
        <Document>
            {
                maintenances.filter(maintenance => maintenance.isFinished).length > 0
                    ? <Page {...pageProps} size="A4" wrap style={styles.page}>
                        <Logos />
                        <Header />
                        <View style={styles.section}>
                            <Text style={{ ...styles.title, textAlign: 'center', border: 1 }}>Manutenções finalizadas</Text>
                            {renderMaintenancesClosed()}
                        </View>
                    </Page>
                    : null
            }
            {
                maintenances.filter(maintenance => !maintenance.isFinished).length > 0
                    ? <Page {...pageProps} size="A4" wrap style={styles.page}>
                        <Logos />
                        <Header />
                        <View style={styles.section}>
                            <Text style={{ ...styles.title, textAlign: 'center', border: 1 }}>Manutenções pendentes</Text>
                            {renderMaintenancesOpened()}
                        </View>
                    </Page>
                    : null
            }
        </Document>
    </PDFViewer>;
};