import Authentication from 'lib/Authentication';

export const clientManagerURL = () => {
    const clientManager = Authentication.getClientManager();

    return ['unit', 'plan', 'trial'].includes(clientManager.contract)
        ? 'https://app.gcondo.com.br/gc'
        : 'https://sindigest.com.br/gc';
};

export const clientURL = () => {
    const clientManager = Authentication.getClientManager();

    return ['unit', 'plan', 'trial'].includes(clientManager.contract)
        ? 'https://app.gcondo.com.br/primeiro-acesso'
        : 'https://sindigest.com.br/primeiro-acesso';
};