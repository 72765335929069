import React, { 
    createContext, 
    Dispatch, 
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { findChatbot } from 'services/Chatbot.service';

type Value = {
    isLoading: boolean,
    chatbot: Chatbot.Model[],
    chatbotSelected: Chatbot.Model | null,
    chatbotId: Chatbot.Model['id'] | null,
    setChatbotId: Dispatch<SetStateAction<Value['chatbotId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    fetchChatbot: () => Promise<void>,
};

type Props = { children: (value: Value) => ReactNode };

const ChatbotContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ChatbotContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [chatbot, setChatbot] = useState<Chatbot.Model[]>([]);
    const [chatbotId, setChatbotId] = useState<Chatbot.Model['id'] | null>(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const app = App.useApp();

    const fetchChatbot = async () => {
        setIsLoading(true);

        const response = await findChatbot();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setChatbot(response.chatbot);
    };

    const chatbotSelected = useMemo(() => {
        if (!chatbotId)
            return null;

        const found = chatbot.find(chatbot => chatbot.id === chatbotId);

        if (!found)
            throw new Error(`Could not find a chatbot with id ${chatbotId}`);

        return found;

    }, [chatbot, chatbotId]);

    const value: Value = { 
        isLoading, 
        chatbot,
        chatbotSelected,
        chatbotId,
        setChatbotId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        fetchChatbot: fetchChatbot,
    };

    useEffect(() => {
        fetchChatbot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ChatbotContext.Provider value={value}>
            {children(value)}
        </ChatbotContext.Provider>
    );
}

export function useChatbot() {
    const context = useContext(ChatbotContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ChatbotContextProvider`.');

    return context;
}