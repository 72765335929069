import React, { useState } from 'react';

import { App, Popconfirm, Tag } from 'antd';

import { sleep } from '../../lib/Sleep';
import { updateReserve } from '../../services/Reserve.service';
import { useSchedule } from './useSchedule';

type Props = { record: Reserve.Model };

export function ReserveActionsCell({ record }: Props) {
    const [sending, setSending] = useState<'approve' | 'refuse' | null>(null);
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);

    const { fetchReserves } = useSchedule();

    const app = App.useApp();

    const handleApproval = async (approved: boolean) => {
        setSending(approved ? 'approve' : 'refuse');

        const response = await updateReserve(record.id, { approved });

        // Improves UX
        await sleep(1000);

        setSending(null);

        if (!response)
            return app.notification.error({ message: 'Algo deu errado', description: 'Tente novamente, por favor' });

        if ('status' in response)
            return app.notification.error({ message: 'Algo deu errado', description: response.message });

        app.notification.success({ message: 'Sucesso', description: 'Reserva atualizada com sucesso' });
        setIsPopconfirmVisible(false);
        fetchReserves();
    };

    if (record.approved !== null) {
        return (
            <Tag color={record.approved ? 'green' : 'red'}>
                {record.approved ? 'Aprovado' : 'Recusado'}
            </Tag>
        );
    }

    return (
        <Popconfirm
            title="Reserva pendente"
            open={isPopconfirmVisible}
            placement="top"
            okText="Recusar"
            cancelText="Aprovar"
            okButtonProps={{
                loading: sending === 'refuse',
                disabled: sending !== null && sending !== 'refuse',
                type: 'default',
                danger: true,
            }}
            cancelButtonProps={{
                loading: sending === 'approve',
                disabled: sending !== null && sending !== 'approve',
                type: 'primary',
            }}
            onConfirm={() => handleApproval(false)}
            onCancel={() => handleApproval(true)}
        >
            <Tag
                color="orange"
                onClick={() => setIsPopconfirmVisible(prevState => !prevState)}
                style={{ cursor: 'pointer', userSelect: 'none', fontSize: '12px' }}
            >
                Pendente
            </Tag>
        </Popconfirm>

    );
}
