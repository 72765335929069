import React, { Component } from 'react';

import { Divider, Modal, Popconfirm, Typography } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import API from 'lib/API';

import { ApartmentInterface } from '../../_Common/_Interfaces/Apartment';
import { PetInterface } from '../../_Common/_Interfaces/Pet';
import { ResidentInterface } from '../../_Common/_Interfaces/Resident';
import List from '../../_Common/List';
import FormPet from './FormPet';

type State = {
    pets: Array<PetInterface>;
    pet: PetInterface;
    apartments: Array<ApartmentInterface>;
    showFormPet: boolean;
    loading: boolean;
};

type Props = {
    resident: ResidentInterface;
    isVisible: boolean;
    hideModalForm: Function;
};

class ListPet extends Component<Props, State> {
    state: State = {
        pets: [],
        pet: {} as PetInterface,
        apartments: [],
        loading: true,
        showFormPet: false
    };

    columns = [
        { title: 'Nome', key: 'name', dataIndex: 'name' },
        {
            title: 'Tipo', key: 'type', dataIndex: 'type', render: (_: any, pet: PetInterface) => {
                if (pet.type === 'dog')
                    return 'Cachorro';
                else if (pet.type === 'cat')
                    return 'Gato';

                return 'Outro';
            }
        },
        {
            key: 'action',
            render: (_: any, pet: PetInterface) => (
                <>
                    <EditOutlined onClick={() => this.setState({ pet: pet, showFormPet: true })} />
                    <Divider type="vertical" />
                    <Popconfirm title={'Deseja remover?'}
                        placement="top"
                        onConfirm={() => this.onDelete(pet)}
                        cancelText={'Cancelar'}
                        okText={'Deletar'}
                        okType={'danger'}>
                        <DeleteOutlined />
                    </Popconfirm>
                </>)
        }
    ];

    onCancel = () => {
        this.setState({ pets: [], pet: {} as PetInterface });
        this.props.hideModalForm();
    };

    fetchData = async (apartmentId: number) => {
        this.setState({ loading: true });
        const pets = await API.get(`/pet/${apartmentId}`);
        this.setState({ pets, loading: false });
    };

    onDelete = async (pet: PetInterface) => {
        this.setState({ loading: true });
        await API.delete(`/default/pet/${pet.id}`);
        await this.fetchData(pet.apartmentId);
    };

    componentDidUpdate(prevProps: Props) {
        const { resident } = this.props;
        // eslint-disable-next-line eqeqeq
        if (resident != prevProps.resident && resident.id) {
            this.fetchData(resident.apartmentId);
        }
    };

    render() {
        const { isVisible, resident } = this.props;
        const { pets, pet, loading, showFormPet } = this.state;

        return (
            <>
                <Modal
                    key={resident.apartmentId}
                    centered
                    title={`Pet(s) do morador ${resident.name}`}
                    destroyOnClose={true}
                    open={isVisible}
                    onCancel={this.onCancel}
                    footer={null}
                >
                    <Typography.Link className="right" onClick={() => this.setState({ pet: {} as PetInterface, showFormPet: true })}>
                        Clique aqui para adicionar um pet
                    </Typography.Link>
                    <List columns={this.columns} data={pets} loading={loading} />
                </Modal>
                <FormPet apartmentId={resident.apartmentId}
                    pet={pet}
                    isVisible={showFormPet}
                    hideModalForm={() => {
                        this.setState({ showFormPet: false });
                        this.fetchData(resident.apartmentId);
                    }} />
            </>
        );
    }
}

export default ListPet;
