import React, { useEffect, useState } from 'react';

import { Divider, Form, InputNumber, Modal, notification, Radio, Select } from 'antd';

import { listSuppliers } from 'services/Supplier.service';

import { useConsumption } from '../../lib/hooks/useConsumption';
import { createConsumption } from '../../services/Consumption.service';

export type Values = {
    type_consumption_id: Consumption.Model['type_consumption_id'],
    measure: Consumption.Model['measure'],
    supplier_id: Consumption.Model['supplier_id'],
    price: Consumption.Model['price']
};

export function FormConsumptionModal() {
    const [isSending, setIsSending] = useState(false);
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);

    const [form] = Form.useForm<Values>();

    const { setIsFormConsumptionModalVisible, fetchConsumptions, typeConsumption } = useConsumption();

    const onFinish = async () => {
        setIsSending(true);
        const body = await form.validateFields();
        const response = await createConsumption(body);

        setIsSending(false);

        if ('status' in response)
            return;

        notification.open(response);
        setIsFormConsumptionModalVisible(false);
        fetchConsumptions();
    };

    useEffect(() => {
        listSuppliers().then((response) => {
            if (!response.success)
                return;

            setSuppliers(response.suppliers);
        });
    }, []);

    return (
        <Modal
            open
            title="Definição consumo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Definir"
            onCancel={() => setIsFormConsumptionModalVisible(false)}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createConsumption"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="type_consumption_id"
                    label="Tipo de consumo"
                    rules={[{ required: true, message: 'Por favor, selecione o tipo de consumo que deseja definir.' }]}
                >
                    <Select>
                        {
                            typeConsumption.map(type =>
                                <Select.Option value={type.id} >{type.name}</Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="measure"
                    label="Forma de medição"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value='unit'>Por unidade</Radio>
                        <Radio value='aggregate'>Coletiva</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="supplier_id"
                    label="Fornecedor"
                    rules={[{ required: true, message: 'Por favor, selecione o fornecedor.' }]}
                >
                    <Select>
                        {
                            suppliers.map(supplier =>
                                <Select.Option value={supplier.id} >{supplier.name}</Select.Option>
                            )
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Preço"
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}
