import React from 'react';

import { Modal, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import List from 'components/_Common/List';
import { formatBRL } from 'lib/helpers/BRL';

import { useConsumption } from '../../lib/hooks/useConsumption';

export function ConsumptionListModal() {
    const { setIsConsumptionListModalVisible, setIsFormConsumptionModalVisible, consumptions, isFetching } = useConsumption();
    const columns: ColumnsType<Consumption.Model> = [
        { title: 'Consumo', key: 'type_consumption_id', render: (_, row) => row.type_consumption.name },
        { title: 'Medição por', key: 'measure', dataIndex: 'measure', render: (measure) => measure === 'unit' ? 'Unidade' : 'Agrupado' },
        { title: 'Fornecedor', key: 'supplier_id', render: (_, row) => row.supplier.name },
        { title: 'Preço', key: 'price', dataIndex: 'price', render: (price) => formatBRL(price) }
    ];

    return (
        <Modal
            open
            footer={false}
            title="Definições de consumo"
            onCancel={() => setIsConsumptionListModalVisible(false)}
        >
            <Typography.Link className="right" onClick={() => setIsFormConsumptionModalVisible(true)}>
                Clique aqui para definir
            </Typography.Link>
            
            <List
                columns={columns}
                loading={isFetching}
                data={consumptions}
                showHeader={true}
                showPagination={false} />

        </Modal>
    );
}
