import React, { useState } from 'react';

import { App, Button, Space } from 'antd';

import { sleep } from '../../lib/Sleep';
import { updateReserve } from '../../services/Reserve.service';

type Props = {
    record: Reserve.Model
    fetchPendingReserves: () => void,
};

export function PendingActionsCell({ record, fetchPendingReserves }: Props) {
    const [sending, setSending] = useState<'approve' | 'refuse' | null>(null);

    const app = App.useApp();

    const handleApproval = async (approved: boolean) => {
        setSending(approved ? 'approve' : 'refuse');

        const response = await updateReserve(record.id, { approved });

        // Improves UX
        await sleep(1000);

        setSending(null);

        if (!response)
            return app.notification.error({ message: 'Algo deu errado', description: 'Tente novamente, por favor' });

        if ('status' in response)
            return app.notification.error({ message: 'Algo deu errado', description: response.message });

        fetchPendingReserves();
    };

    return (
        <Space size="middle">
            <Button
                type="primary"
                size="small"
                loading={ sending === 'approve'}
                disabled={ sending !== null && sending !== 'approve' }
                onClick={() => handleApproval(true)}
            >
                Aprovar
            </Button>

            <Button
                danger
                size="small"
                loading={ sending === 'refuse'}
                disabled={ sending !== null && sending !== 'refuse' }
                onClick={() => handleApproval(false)}
            >
                Recusar
            </Button>
        </Space>
    );
}
