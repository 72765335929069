import config from 'config';
import Authentication from 'lib/Authentication';

import API from '../lib/API';
import { FindClientManagerStep, ImportClients, ListSteps, SaveStep, UpdateStep } from './contracts/Setup.contract';

export const importClients = async (file: File): Promise<ImportClients.Response> => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const response = await fetch(config.api + '/setup/import_clients', {
        body: formData,
        headers: {
            'x-authorization-gcondo': Authentication.getToken(),
            'x-client-gcondo': Authentication.getCurrentClientId().toString(),
        },
        method: 'POST'
    });

    return response.json();
};

export const importResidents = async (client_id: string, file: File): Promise<ImportClients.Response> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('client_id', client_id);

    const response = await fetch(config.api + '/setup/import_residents', {
        body: formData,
        headers: {
            'x-authorization-gcondo': Authentication.getToken(),
            'x-client-gcondo': Authentication.getCurrentClientId().toString(),
        },
        method: 'POST'
    });

    return response.json();
};

export function listSteps(): Promise<ListSteps.Response> {
    return API.get('/setup/steps');
};

export function saveStep(body: SaveStep.Body): Promise<SaveStep.Response> {
    return API.post('/setup/step', body);
};

export function updateStep(id: Setup.ClientManagerStep['id'], body: UpdateStep.Body): Promise<UpdateStep.Response> {
    return API.put(`/setup/step/${id}`, body);
};

export function findClientManagerStep(step_id: Setup.Step['id']): Promise<FindClientManagerStep.Response> {
    return API.get(`/setup/step/${step_id}`);
};