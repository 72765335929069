import React, { Fragment, useRef, useState } from 'react';
import type ReCAPTCHAType  from 'react-google-recaptcha';

import { 
    App, 
    Button, 
    Card, 
    Form,
    Input,
    Select, 
    Typography 
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { ReCAPTCHA } from 'lib/ReCAPTCHA';
import { createGeneralMeetingQuestionAnswerCollectionWithoutAuthentication as createQuestionAnswerCollection } from 'services/GeneralMeeting.service';
import type { OptionalField } from 'types/packages/antd';

type Props = {
    id: GeneralMeeting.Model['id'],
    title: GeneralMeeting.Model['title'],
    description: GeneralMeeting.Model['description'],
    questions: GeneralMeeting.Model['questions'],
}

type Values = Record<
    GeneralMeeting.Question['id'],
    OptionalField<string | number>
>;

type Body = Parameters<typeof createQuestionAnswerCollection>[1];

const filterOption = makeFilterOption();

export function AnswerGeneralMeetingForm({ 
    id, 
    title, 
    description,
    questions,
}: Props) {
    const [isSending, setIsSending] = useState(false);
    const [token, setToken] = useState<string | null>(null);

    const tokenRef = useRef<ReCAPTCHAType>(null);

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    const onFinish = async (values: Values) => {
        setIsSending(true);

        const parsedValues = Object
            .entries(values)
            .map(([key, value]) => isFilledField<string | number>(value) ? [key, value] : [key, null]);

        const body: Body = { answers: Object.fromEntries(parsedValues) };

        const response = await createQuestionAnswerCollection(id, body);

        setIsSending(false);

        app.notification.open(response);

        form.resetFields();
        tokenRef.current?.reset();
    };

    const parsedTitle = (
        <Fragment>
            {title}

            <Typography.Text 
                style={{ display: 'block', fontWeight: '400' }}
                type="secondary"
            >
                {description}
            </Typography.Text>
        </Fragment>
    );

    const items = questions.map(question => {
        if (question.type === 'dissertation') {
            const required = { required: true, message: 'Por favor, preencha este campo.' };

            return (
                <Form.Item<Values>
                    key={question.id.toString()}
                    name={question.id}
                    label={question.title}
                    rules={question.required ? [required] : undefined}
                >
                    <TextArea rows={1} autoSize/>
                </Form.Item>
            );
        }

        const required = { required: true, message: 'Por favor, selecione uma opção.' };

        const parsedOptions = question.options.map(option => ({ 
            value: option.id,
            label: option.description
        }));

        return (
            <Form.Item<Values>
                key={question.id.toString()}
                name={question.id}
                label={question.title}
                rules={question.required ? [required] : undefined}
            >
                <Select
                    placeholder="Selecione aqui"
                    options={parsedOptions}
                    filterOption={filterOption}
                    showSearch
                    allowClear
                />
            </Form.Item>
        );
    });

    return (
        <Card title={parsedTitle}>
            <Form
                form={form}
                onFinish={onFinish}
                name="answerGeneralMeeting"
                layout="vertical"
                autoComplete="off"
            >
                {items}

                <ReCAPTCHA
                    ref={tokenRef}
                    onChange={value => setToken(value)}
                />

                <Button
                    style={{ marginTop: 24 }}
                    disabled={token === null}
                    loading={isSending}
                    type="primary"
                    htmlType="submit"
                >
                    Responder
                </Button>
            </Form>
        </Card>
    );
}
