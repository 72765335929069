import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type Value = {
    ambients: Ambient.SimpleModel[],
    ambient: Ambient.Model | null,

    ambientId: number | null,
    setAmbientId: Dispatch<SetStateAction<Value['ambientId']>>,

    fetchAmbients: () => Promise<void>,

    isCreateAmbientModalVisible: boolean,
    setIsCreateAmbientModalVisible: Dispatch<SetStateAction<Value['isCreateAmbientModalVisible']>>,

    isEditAmbientModalVisible: boolean,
    setIsEditAmbientModalVisible: Dispatch<SetStateAction<Value['isEditAmbientModalVisible']>>,

    isAmbientDatesModalVisible: boolean,
    setIsAmbientDatesModalVisible: Dispatch<SetStateAction<Value['isAmbientDatesModalVisible']>>,
};

export const AmbientContext = createContext<Value>(null!);

export const useAmbient = () => useContext(AmbientContext);