import React, { useEffect, useState } from 'react';

import {
    AutoComplete,
    Col,
    ColProps,
    Divider,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Select
} from 'antd';
import { MaskedInput } from 'antd-mask-input';

import { ClientInterface } from 'components/_Common/_Interfaces/Client';
import Authentication from 'lib/Authentication';
import { ClientsField } from 'lib/ClientsField';
import { listServiceCategory } from 'services/ServiceCategory.service';
import { createSupplier, updateSupplier } from 'services/Supplier.service';

type Props = {
    isOpen: boolean,
    closeForm: Function,
    supplier: Supplier.Model | undefined
};

export type Values = {
    name: Supplier.Model['name'],
    cnpj?: Supplier.Model['cnpj'],
    email?: Supplier.Model['email'],
    accountable?: Supplier.Model['accountable'],
    phone_1?: Supplier.Model['phone_1'],
    phone_2?: Supplier.Model['phone_2'],
    website?: Supplier.Model['website'],
    clientId?: Supplier.Model['clientId'],
    clientsToSync?: number[],
};

const FormSupplier: React.FC<Props> = (props: Props) => {

    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);
    const [serviceCategories, setServiceCategories] = useState<ServiceCategory.Model[]>();
    const clients = Authentication.getClients().map((client: ClientInterface) => ({ value: client.id, label: client.name }));
    const clientsCurrent = props.supplier
        ? props.supplier.clients.map((c: ClientInterface) => c.id)
        : clients.map(c => c.value);

    const onWebsiteChange = (value: string) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.com.br', '.net'].map((domain) => `${value}${domain}`));
        }
    };

    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));

    const colSmallSizes: ColProps = {
        xs: 24,
        sm: 24,
        md: 24
    };

    async function fetchData() {
        setLoading(true);
        const response = await listServiceCategory();

        if ('status' in response)
            return;

        setServiceCategories(response);
        form.setFieldValue('supplier_service_category',
            props.supplier?.supplier_service_category.map(s => s.service_category_id));

        setLoading(false);
    };

    async function onFinish() {
        try {
            setSaving(true);

            const supplier = await form.validateFields();
            const response = props.supplier?.id
                ? await updateSupplier(props.supplier?.id, supplier)
                : await createSupplier(supplier);

            notification.open(response);
            setSaving(false);
            props.closeForm();
        } catch (error) {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            width={1000}
            centered
            title={props.supplier?.id ? 'Editar fornecedor' : 'Novo fornecedor'}
            destroyOnClose={true}
            open={props.isOpen}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={() => props.closeForm()}
            afterClose={() => form.resetFields()}
            okText="Salvar"
        >
            <Divider />
            <Form form={form} layout="vertical" initialValues={props.supplier} onFinish={onFinish}>
                <Row>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Descreva' }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item name="cnpj" label="CNPJ">
                            <MaskedInput mask={'00.000.000/0000-00'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item name="email" label="Email">
                            <Input type='email' />
                        </Form.Item>
                    </Col>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item name="accountable" label="Nome do responsável (ponto focal)">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item
                            name="phone_1" 
                            label="Telefone celular"
                            tooltip="Preferencialmente com WhatsApp"
                            rules={[{ required: true, message: 'Digite o número' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item name="phone_2" label="Telefone fixo">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item
                            name="website"
                            label="Site"
                        >
                            <AutoComplete options={websiteOptions} onChange={onWebsiteChange}>
                                <Input />
                            </AutoComplete>
                        </Form.Item>
                    </Col>
                    <Col {...colSmallSizes} lg={12}>
                        <Form.Item
                            name="supplier_service_category"
                            label="Tipos de serviço"
                            tooltip="Selecione todos os tipos de serviço o fornecedor realiza.">
                            <Select
                                loading={isLoading}
                                mode="multiple"
                                allowClear
                                showSearch
                                filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{ width: '100%' }}
                            >
                                {
                                    serviceCategories?.map(category =>
                                        <Select.Option key={category.id} value={category.id}>
                                            {category.name}
                                        </Select.Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <ClientsField<Values>
                    name="clientsToSync"
                    label="Condomínios que o fornecedor deve ser compartilhado"
                    rules={[{ required: true, message: 'Por favor, selecione ao menos um condomínio.' }]}
                    initialValue={clientsCurrent}
                    disabled={isLoading}
                    loading={isLoading}
                    options={clients}
                    form={form}
                />
            </Form>
        </Modal >
    );

};

export default FormSupplier;