import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Button, Form, Input, Typography } from 'antd';
import { Rule } from 'antd/es/form';

import { LockOutlined } from '@ant-design/icons';
import Authentication from 'lib/Authentication';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { updateUserPassword } from 'services/AuthenticationService';

export type Values = {
    password: string,
    passwordConfirmation: string,
};

const wl = makeWhiteLabel();

export function UpdatePassword(props: RouteComponentProps) {
    const [isSending, setIsSending] = useState(false);
    const [form] = Form.useForm<Values>();
    const history = useHistory();

    const onFinish = async (values: Values) => {
        setIsSending(true);

        const userId = Authentication.getUserId();

        await updateUserPassword(userId, values);
    
        setIsSending(false);

        Authentication.setUserNeedChangePassword(false);
        history.push('/');
    };

    const passwordRules: Rule[] = [
        { required: true, message: 'Por favor, digite sua nova senha.' },

        { min: 8, message: 'Por favor, digite ao menos 8 caracteres.'},

        { pattern: new RegExp(/[A-Z]/), message: 'Por favor, digite ao menos uma letra maiúscula.'},

        { pattern: new RegExp(/\d/), message: 'Por favor, digite ao menos um digíto.'},
    ];

    const passwordConfirmationRules: Rule[] = [
        { required: true, message: 'Por favor, confirme sua senha.' },

        ({ getFieldValue }) => ({
            // Do the passwords match?
            validator: (_, value) => (!value || getFieldValue('password') === value)
                ? Promise.resolve()
                : Promise.reject('As senhas não correspondem!')
        }),
    ];

    return (
        <div className="screen-container">
            <div className="form-container">
                <Form
                    form={form}
                    onFinish={onFinish}
                    name="updatePassword"
                    layout="vertical"
                    autoComplete="off"
                >
                    <div style={{ textAlign: 'center', padding: 20 }}>
                        <img src={wl.logo} alt="Gcondo logo" style={{ width: '80%' }} />
                    </div>

                    <Typography.Title style={{ marginTop: 0 }} level={4}>
                        Olá, {Authentication.getUserName()}
                    </Typography.Title>

                    <Typography.Title style={{ marginTop: 0 }} level={5}>
                        Atualizar senha
                    </Typography.Title>

                    <Form.Item
                        name="password"
                        hasFeedback
                        validateFirst
                        rules={passwordRules}
                        label="Nova senha"
                        tooltip="A senha deve possuir no mínimo 8 caracteres. Além disso, deve conter ao menos uma letra maiúscula e um digíto."
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Digite a sua nova senha"
                        />
                    </Form.Item>

                    <Form.Item
                        name="passwordConfirmation"
                        hasFeedback
                        validateFirst
                        rules={passwordConfirmationRules}
                        dependencies={['password']}
                        label="Confirme a senha"
                        tooltip="A senha deve ser igual a anterior."
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="Repita a senha" />
                    </Form.Item>

                    <hr />

                    <div className="flex align-center space-between">
                        <Button
                            loading={isSending}
                            type="primary"
                            htmlType="submit"
                        >
                            {isSending ? 'Carregando...': 'Confirmar'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
