import { createElement } from 'react';

import { TagProps } from 'antd';

import { BulbOutlined, CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined, PauseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type DerivedStatusTagProps = Pick<TagProps, 'color' | 'icon' | 'children'>;

export function getConstructionDerivedStatus(
    status: Construction.Model['status'], 
    end_date: Construction.Model['end_date'], 
    start_date: Construction.Model['start_date'],
): Construction.Status {

    if (status === 'planning' && start_date === null) {
        return 'planning';
    }
    
    if (status === 'finished') {
        return 'finished';
    }

    if (status === 'paralyzed') {
        return 'paralyzed';
    }

    if (status === 'in_progress') {
        if (end_date === null) {
            return 'in_progress';
        }
        if (dayjs().isAfter(dayjs(end_date), 'day')) {
            return 'late';
        }
        return 'in_progress';
    }

    return 'in_progress';
}

export function getConstructionDerivedStatusTagProps(derivedStatus: Construction.Status): DerivedStatusTagProps {
    switch (derivedStatus) {
    case 'planning':
        return { color: 'geekblue', icon: createElement(BulbOutlined), children: 'Em planejamento' };
    case 'finished':
        return { color: 'success', icon: createElement(CheckCircleOutlined), children: 'Finalizada' };
    case 'paralyzed':
        return { color: 'gold', icon: createElement(PauseCircleOutlined), children: 'Paralisada' };
    case 'late':
        return { color: 'error', icon: createElement(CloseCircleOutlined), children: 'Atrasada' };
    default:
        return { color: 'processing', icon: createElement(HourglassOutlined), children: 'Em andamento' };
    }
}