import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
    Col,
    Row,
    Select,
    Typography
} from 'antd';

import Content from 'components/_Common/_Layout/Content';
import { ScheduleCalendar } from 'components/Schedule/ScheduleCalendar';
import { TodaySchedule } from 'components/Schedule/TodaySchedule';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { ScheduleContextProvider } from 'lib/providers/ScheduleContextProvider';
import { Show } from 'lib/Show';
import { makeClientOptions } from 'pages/issue/Filters';

const filterOption = makeFilterOption();

export function Schedule({ location }: RouteComponentProps) {
    const isOverviewModule = location.pathname.includes('/overview');

    const clients = makeClientOptions();

    return (
        <ScheduleContextProvider isOverviewModule={isOverviewModule}>
            {({ clientId, setClientId }) => (
                <Content>
                    <Row justify="space-between" align="middle">
                        <Typography.Title level={3}>
                            Cronograma
                        </Typography.Title>

                        <Show when={isOverviewModule}>
                            <Select 
                                placeholder="Condomínio"
                                options={clients} 
                                filterOption={filterOption}
                                allowClear
                                showSearch
                                style={{ width: '240px' }}
                                value={clientId}
                                onSelect={(id) => setClientId(id)}
                            />
                        </Show>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={14} lg={14}>
                            <ScheduleCalendar />
                        </Col>

                        <Col xs={24} sm={24} md={10} lg={10}>
                            <TodaySchedule />
                        </Col>
                    </Row>
                </Content>
            )}
        </ScheduleContextProvider>
    );
}
