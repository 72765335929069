import { Values } from 'components/Issue/FollowUpCreateModal';

import { FileFormat } from './File.contract';

export namespace CreateIssue {
    export type Body = {
        creation_date: Issue.Model['creation_date'],
        userId: Issue.Model['userId'],
        clientId?: Issue.Model['clientId'],
        issueTypeId: Issue.Model['issueTypeId'],
        subject: Issue.Model['subject'],
        description: Issue.Model['description'],
        user_responsible_id: Issue.Model['user_responsible_id'],
        supplier_id: Issue.Model['supplier_id'],
        deadline: Issue.Model['deadline'],
        issue_checklists: Issue.Checklist[],
        type: Issue.EnumType,
        is_recurrent: boolean,
        recurrence?: number,
    };

    export type Response =
        | Issue.Model
        | Service.ExceptionResponse;
};

export namespace LinkedTasksDisapproved {
    export type Body = {
        issueId: number,
        tasks: number[],
    };

    export type Response =
        | Service.DefaultResponse
        | Service.ExceptionResponse;
};

export namespace FinishTask {
    export type Body = {
        id: Issue.Tasks['id'],
        justification?: string | undefined,
        type: TaskType
    };

    export type BodyTaskFile = {
        filename: Issue.IssueFile['filename'],
        url: Issue.IssueFile['url'],
        issue_checklist_task_id: Issue.Tasks['id'],
    };

    export enum TaskType {
        approved = 'approved',
        disapproved = 'disapproved',
    }

    export type Response =
        | Service.DefaultResponse
        | Service.ExceptionResponse;
};

export namespace UpdateIssue {
    export type Body = {
        creation_date: Issue.Model['creation_date'],
        issueTypeId: Issue.Model['issueTypeId'],
        subject: Issue.Model['subject'],
        description: Issue.Model['description'],
        user_responsible_id: Issue.Model['user_responsible_id'],
        supplier_id: Issue.Model['supplier_id'],
        deadline: Issue.Model['deadline'],
        issue_checklists: Issue.Checklist[],
        type: Issue.EnumType,
        is_recurrent: boolean,
        recurrence?: number,
    };

    export type Response =
        | Issue.Model
        | Service.ExceptionResponse;
};

export namespace ReopenIssue {
    export type Response = Service.Response | Service.ErrorResponse;
};

export namespace FinishIssue {
    export type Body = {
        status: Issue.Model['status'],
        userResolvedId: Issue.Model['userResolvedId'],
        finalization: Issue.Model['finalization'],
        resolutionDate: Issue.Model['resolutionDate']
    };

    export type Response =
        | Issue.Model
        | Service.ExceptionResponse;
};

export namespace UploadIssueFile {
    export type Body = {
        filename: Issue.IssueFile['filename'],
        url: Issue.IssueFile['url'],
        issueId: Issue.IssueFile['issueId'],
    };

    export type Response =
        | Issue.IssueFile
        | Service.ExceptionResponse;
};

export namespace ListIssue {
    export type Response =
        | Service.DefaultResponse<Issue.With<
            | 'user'
            | 'user_who_resolved'
            | 'user_responsible'
            | 'supplier'
            | 'issueType'
            | 'client'>[], 'issues'>
        | Service.ExceptionResponse;
};

export namespace ListPendingIssuesByClientManager {
    type PendingIssue = Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client',
        Issue.PendingModel
    >;

    export type Response =
        | Service.DefaultResponse<PendingIssue[], 'issues'>
        | Service.ExceptionResponse;
};

export namespace ShareIssueWithResident {
    export type Body = {
        share_with_resident: boolean
    };
    export type Response = Service.Response | Service.ErrorResponse;
};

export namespace ListIssueType {
    export type Response =
        | Service.DefaultResponse<Issue.Type[], 'issue_types'>
        | Service.ExceptionResponse;
};

export namespace CreateIssueType {
    export type Body = Pick<Issue.Type, 'name'>;

    export type Response = Service.AlertResponse & { id?: Issue.Type['id'] };
};

export namespace FindIssue {
    type RelationModel =
        | 'user'
        | 'user_responsible'
        | 'supplier'
        | 'user_who_resolved'
        | 'issueType';

    export type Response =
        | Issue.With<RelationModel>
        | Service.Response;
};

export namespace ListFollowUpByIssueId {
    export type Response = Issue.IssueFollowUp[] | Service.Response;
};

export namespace DeleteIssueFollowUp {
    export type Response = Service.DefaultResponse;
};
export namespace CreateIssueFollowUp {
    export type Body = Omit<Values, 'files'>;

    export type BodyFile = {
        filename: string,
        url: string,
        issue_follow_up_id: number,
    };

    export type Response = Service.DefaultResponse<number, 'id'>;
};

export interface ParsedFile {
    filename: string;
    url: string;
    issue_follow_up_id: number;
}

export namespace GetTotalIssueByType {
    type Data = Array<Issue.Chart>;
    export type Response = Data | Service.ErrorResponse;
};

export namespace GetTotalIssuePerMonth {
    type Data = Array<Issue.ChartMonth>;
    export type Response = Data | Service.ErrorResponse;
};

export namespace GetIssueEligibleResponsibleUsers {
    export type Response =
        | Service.DefaultResponse<User.Model[], 'users'>
        | Service.ExceptionResponse;
};

export namespace ShareIssueToSupplier {
    export type Response =
        | Service.DefaultResponse
        | Service.ExceptionResponse;
};

export namespace DeleteIssue {
    export type Response = Service.AlertResponse;
};

export namespace GenerateReportIssue {
    export type Body = {
        ids: number[],
        format: FileFormat
    };

    export type Response =
        | Service.DefaultResponse<string, 'url'>
        | Service.ExceptionResponse;
};