import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import * as XLSX from 'xlsx';

type ReserveExcel = Array<{}>;

export const prepareToDownload = (reserves: Reserve.Model[]) => {

    const data = reserves.map(reserve => ({
        'Ambiente': reserve.ambient.name,
        'Unidade': `${reserve.apartment.tower.name} - ${reserve.apartment.name}`,
        'Data da reserva': dayjs(reserve.bookingDate).format('DD/MM/YYYY')

    }));

    download(data);
};

const download = (data: ReserveExcel) => {
    const client = Authentication.getClients().find(client => client.id === Authentication.getCurrentClientId());
    const workbook = XLSX.utils.book_new();

    const worksheetClosed = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheetClosed, 'Reservas');


    const excelBase64 = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelBase64;
    link.download = `${client?.name} - Relatório de reservas.xlsx`;
    link.target = '_blank';

    link.click();
};