import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState
} from 'react';

import { App } from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { DATE_SERVER_FORMAT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { getCalendarFirstDate, getCalendarLastDate } from 'lib/helpers/Schedule.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { ListSchedulesCount } from 'services/contracts/Schedule.contract';
import { listScheduleIssues, listScheduleMaintenances, listSchedulesCount } from 'services/Schedule.service';

export type ScheduleIssue = Issue.With<
    | 'client'
    | 'issueType'
    | 'user_responsible'
>;

export type ScheduleMaintenance = Maintenance.With<
    | 'client'
    | 'maintenance_type'
    | 'supplier'
>;

type Value = {
    isLoading: boolean,
    date: Dayjs,
    setDate: Utility.SetState<Value['date']>,
    schedules: ListSchedulesCount.Schedules | null,
    issues: ScheduleIssue[],
    maintenances: ScheduleMaintenance[],
    clientId: Client.Model['id'] | null,
    setClientId: Utility.SetState<Value['clientId']>,
    isOverviewModule: boolean,
};

type Props = {
    isOverviewModule: boolean,
    children: (value: Value) => ReactNode
};

const ScheduleContext = createContext<Value | null>(null);

/** @factory */
const makeClients = (
    isOverviewModule: Props['isOverviewModule'],
    clientId: Value['clientId']
) => {
    if (clientId !== null)
        return [clientId];

    const clients = isOverviewModule
        ? Authentication.getClients().map(client => client.id)
        : [Authentication.getCurrentClientId()];

    return clients;
};

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ScheduleContextProvider({ isOverviewModule, children }: Props) {
    const [isLoadingSchedules, setIsLoadingSchedules] = useState<Value['isLoading']>(true);
    const [isLoadingIssues, setIsLoadingIssues] = useState<Value['isLoading']>(true);
    const [isLoadingMaintenances, setIsLoadingMaintenances] = useState<Value['isLoading']>(true);

    const [date, setDate] = useState<Value['date']>(dayjs);

    const [schedules, setSchedules] = useState<Value['schedules']>(null);
    const [issues, setIssues] = useState<Value['issues']>([]);
    const [maintenances, setMaintenances] = useState<Value['maintenances']>([]);

    const [clientId, setClientId] = useState<Value['clientId']>(null);

    const app = App.useApp();

    useEffect(() => {
        const clients = makeClients(isOverviewModule, clientId);

        const parsedDate = date.format(DATE_SERVER_FORMAT);

        const fetchSchedules = async () => {
            setIsLoadingSchedules(true);

            const firstDate = getCalendarFirstDate(date);
            const lastDate = getCalendarLastDate(firstDate);

            const parsedFirstDate = firstDate.format(DATE_SERVER_FORMAT);
            const parsedLastDate = lastDate.format(DATE_SERVER_FORMAT);

            const response = await listSchedulesCount(parsedFirstDate, parsedLastDate, clients);
    
            setIsLoadingSchedules(false);
    
            if (!response.success)
                return handleServiceError(app, response);
    
            setSchedules(response.schedules);
        };

        const fetchIssues = async () => {   
            setIsLoadingIssues(true);

            const response = await listScheduleIssues(parsedDate, clients);
    
            setIsLoadingIssues(false);
    
            if (!response.success)
                return handleServiceError(app, response);
    
            setIssues(response.issues);
        };

        const fetchMaintenances = async () => {
            setIsLoadingMaintenances(true);

            if (Authentication.getUserProfile() === 'issue')
                return setIsLoadingMaintenances(false);

            const response = await listScheduleMaintenances(parsedDate, clients);
    
            setIsLoadingMaintenances(false);
    
            if (!response.success)
                return handleServiceError(app, response);
    
            setMaintenances(response.maintenances);
        };

        fetchSchedules();
        fetchIssues();
        fetchMaintenances();
    }, [app, date, isOverviewModule, clientId]);

    const value: Value = { 
        isLoading: isLoadingSchedules || isLoadingIssues || isLoadingMaintenances, 
        date,
        setDate,
        schedules,
        issues,
        maintenances,
        clientId,
        setClientId,
        isOverviewModule,
    };

    return (
        <ScheduleContext.Provider value={value}>
            {children(value)}
        </ScheduleContext.Provider>
    );
}

export function useSchedule() {
    const context = useContext(ScheduleContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ScheduleContextProvider`.');

    return context;
}