import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import ReactQuill from 'react-quill';

import { Modal, Typography } from 'antd';

import { findClientUserNotes, saveClientUserNotes } from 'services/ClientUserNotes.service';

type Props = {
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
};

const NoteModal = ({ isOpen, setIsOpen }: Props) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [editorContent, setEditorContent] = useState('');
    const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const draggleRef = useRef<HTMLDivElement>(null);

    const saveContent = async (notes: string) => {
        await saveClientUserNotes({ notes });
    };

    const findClient = async () => {
        setLoading(true);

        const response = await findClientUserNotes();

        if (response.success) {
            setEditorContent(response.notes);
        }

        setLoading(false);
    };

    const handleEditorChange = (content: string) => {
        setEditorContent(content);

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            saveContent(content);
        }, 1000); // Ajuste o tempo de debounce conforme necessário (em milissegundos)
    };

    useEffect(() => {
        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        findClient();
    }, []);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal
            title={
                <div
                    style={{ width: '100%', cursor: 'move' }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    <Typography.Title style={{ marginTop: 0 }} level={4}>Notas</Typography.Title>
                </div>
            }
            width={700}
            onCancel={() => setIsOpen(false)}
            open={isOpen}
            footer={null}
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    nodeRef={draggleRef}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <ReactQuill readOnly={loading} value={editorContent} onChange={handleEditorChange} theme='snow' />

        </Modal>
    );
};

export default NoteModal;