import Request from 'lib/Request';

import API from '../lib/API';
import { CreateUser, FindUser, GetAllowedClients, ListUser, RemoveUser, ResetPasswordByEmail, ResetUserPassword, UpdateUser,  } from './contracts/UserServiceContract';

const route = '/user';

export function listUser(clients: Client.Model['id'][] = []): Promise<ListUser.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
};

export function findUser(id: User.Model['id']): Promise<FindUser.Response> {
    return API.get(`${route}/${id}`);
};

export function createUser(body: CreateUser.Body): Promise<CreateUser.Response> {
    return API.post(route, body);
};

export function resetPasswordByEmail(email: User.Model['email']): Promise<ResetPasswordByEmail.Response> {
    return Request.post(`/reset-password/${email}`);
}

export function updateUser(id: User.Model['id'], body: UpdateUser.Body): Promise<UpdateUser.Response> {
    return API.put(`${route}/${id}`, body);
};

export function removeUser(id: User.Model['id']): Promise<RemoveUser.Response> {
    return API.delete(`${route}/${id}`);
};

export function resetUserPassword(id: User.Model['id']): Promise<ResetUserPassword.Response> {
    return API.post(`${route}/${id}/reset-password`, null);
}


export function getAllowedClients(): Promise<GetAllowedClients.Response> {
    return API.get(`${route}/clients`);
}