import React, { useEffect, useState } from 'react';

import { Button, Row, Space, Typography } from 'antd';

import Authentication from 'lib/Authentication';
import { Show } from 'lib/Show';

import { listApartment } from '../../services/ApartmentService';
import Content from '../_Common/_Layout/Content';
import List from '../_Common/List';
import { getColumns } from './Columns';
import FormApartment from './Form';
import { ModalReconcile } from './ModalReconcileUnitsWithDebit';
import { CreateTowerModal } from './Tower/CreateTowerModal';
import { TypesModal } from './Types/TypesModal';

const ApartmentIndex: React.FC = () => {
    const [isOpen, setOpen] = useState(false);
    const [isTowerModalVisible, setIsTowerModalVisible] = useState(false);
    const [isTypesModalVisible, setIsTypesModalVisible] = useState(false);
    const [isReconcileModalVisible, setIsReconcileModalVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFinishing, setFinishing] = useState(false);
    const [apartmentId, setApartmentId] = useState<number | null>(null);
    const [apartments, setApartments] = useState<Array<Apartment.Model>>([]);

    const apartment = apartments.find((m) => m.id === apartmentId);
    const columns = getColumns(setOpen, setApartmentId);

    function closeForm() {
        setOpen(false);
        setFinishing(false);
        setApartmentId(null);
    };

    async function fetchData() {
        setLoading(true);

        const apartmentResponse = await listApartment();
        if ('status' in apartmentResponse)
            return;

        setApartments(apartmentResponse);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [isOpen, isFinishing, isTowerModalVisible]);

    return (
        <Content>
            <Row className="space-between" align="middle">
                <Typography.Title level={3}>Unidades</Typography.Title>

                <Space>
                    <Show when={Authentication.getCurrentClientId() === 5}>
                        <Button
                            type="primary"
                            onClick={() => setIsReconcileModalVisible(true)}
                        >
                            Atualizar inadimplentes
                        </Button>
                    </Show>
                    <Button
                        type="dashed"
                        onClick={() => setIsTypesModalVisible(true)}
                    >
                        Gerenciar tipos
                    </Button>

                    <Button
                        type="default"
                        onClick={() => setIsTowerModalVisible(true)}
                    >
                        Cadastrar em massa
                    </Button>

                    <Button
                        type="primary"
                        onClick={() => setOpen(true)}
                    >
                        Cadastrar
                    </Button>
                </Space>
            </Row>

            <List
                data={apartments}
                columns={columns}
                loading={isLoading}
            />

            <FormApartment
                key={`form-${apartment?.id}`}
                apartment={apartment}
                isOpen={isOpen}
                closeForm={closeForm}
            />

            <Show when={isTypesModalVisible}>
                <TypesModal onClose={() => setIsTypesModalVisible(false)} />
            </Show>
            <Show when={isTowerModalVisible}>
                <CreateTowerModal close={() => setIsTowerModalVisible(false)} />
            </Show>
            <Show when={isReconcileModalVisible}>
                <ModalReconcile close={() => setIsReconcileModalVisible(false)} />
            </Show>
        </Content>
    );

};

export default ApartmentIndex;