import API from '../lib/API';
import {
    CreateReserve,
    DeleteReserve,
    ListPendingReservesTodayAndAfter,
    ListReserves,
    ListReservesTodayAndAfter,
    UpdateReserve
} from './contracts/Reserve.contract';

export const createReserve = (body: CreateReserve.Body): Promise<CreateReserve.Response> => API.post('/reserve', body);

export const deleteReserve = (id: Reserve.Model['id']): Promise<DeleteReserve.Response> => API.delete(`/reserve/${id}`);

export const listReserves = (): Promise<ListReserves.Response> => API.get('/reserve');

export const listReservesTodayAndAfter = (): Promise<ListReservesTodayAndAfter.Response> => API.get('/reserve/dashboard');

export const listPendingReservesTodayAndAfter = (): Promise<ListPendingReservesTodayAndAfter.Response> => API.get('/reserve/pending');

export const updateReserve = (id: Reserve.Model['id'], body: UpdateReserve.Body): Promise<UpdateReserve.Response> => API.put(`/reserve/${id}`, body);