import * as XLSX from 'xlsx';

type SupplierExcel = {
    Nome: string;
    CNPJ: string;
    Representante: string;
    Email: string;
    Celular: string;
    Telefone: string;
    Serviços: string;
    Condomínios: string;
};

export const downloadSupplierReport = (data: Supplier.Model[]) => {
    const suppliers: SupplierExcel[] = data
        .map(supplier => ({
            'Nome': supplier.name,
            'CNPJ': supplier.cnpj,
            'Representante': supplier.accountable,
            'Email': supplier.email,
            'Celular': supplier.phone_1,
            'Telefone': supplier.phone_2,
            'Serviços': supplier.supplier_service_category.map(category => category.service_category.name).join(', '),
            'Condomínios': supplier.clients.map(client => client.name).join(', ')
        }));

    download(suppliers);
};

const download = (suppliers: SupplierExcel[]) => {
    const workbook = XLSX.utils.book_new();

    const worksheetClosed = XLSX.utils.json_to_sheet(suppliers);
    XLSX.utils.book_append_sheet(workbook, worksheetClosed, 'Base de dados');

    const excelBase64 = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelBase64;
    link.download = 'Relatório de fornecedores.xlsx';
    link.target = '_blank';

    link.click();
};