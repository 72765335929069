import React, { Dispatch, SetStateAction } from 'react';

import { ColumnsType } from 'antd/es/table';

import { TypeActionsCell } from './TypeActionsCell';

type Parameters = {
    fetchTypes: () => void,
    setTypeId: Dispatch<SetStateAction<Apartment.Type['id'] | null>>,
    setIsEditTypeModalVisible: Dispatch<SetStateAction<boolean>>,
};

export const getColumns = ({ fetchTypes, setTypeId, setIsEditTypeModalVisible }: Parameters): ColumnsType<Apartment.Type> => [
    {
        title: 'Nome',
        dataIndex: 'name',
    },

    {
        title: 'Fração ideal',
        dataIndex: 'idealFraction',
    },

    {
        width: '100px',
        align: 'center',
        render: (_, type) => (
            <TypeActionsCell
                type={type}
                setTypeId={setTypeId}
                setIsEditTypeModalVisible={setIsEditTypeModalVisible}
                fetchTypes={fetchTypes}
            />
        ),
    },
];