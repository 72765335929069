import React, { useState } from 'react';

import { App, Form, Modal, notification, Select, UploadFile } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { UploadField } from 'lib/UploadField';
import { createConstructionFollowUp, createConstructionFollowUpFile } from 'services/Construction.service';

export type Values = {
    description: Construction.FollowUp.Model['description'],
    new_status: Construction.Status,
    creation_date: Construction.FollowUp.Model['creation_date'],
    files: UploadFile[] | undefined,
};

export default function FollowUpCreateModal() {
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);

    const {
        construction,
        setIsFollowUpCreateModal,
        fetchConstructions
    } = useConstruction();

    const app = App.useApp();

    if (!construction)
        throw new Error('Value of the `issue` property is unknown');

    const close = () => {
        setIsFollowUpCreateModal(false);
        setSaving(false);
        form.resetFields();
    };

    const { id } = construction;


    async function onFinish() {
        try {
            setSaving(true);

            const values = await form.validateFields();

            const { files = [], ...restValues } = values;

            const response = await createConstructionFollowUp({ construction_id: id, ...restValues });

            if (!response.success) {
                setSaving(false);
                return handleServiceError(app, response);
            }

            const parsedFiles = files.map(file => ({
                filename: file.name,
                url: file.response ?? file.url,
                construction_follow_up_id: response.construction_follow_up.id,
            }));
            
            if (parsedFiles.length > 0) {
                try {
                    const filesResponse = await createConstructionFollowUpFile(parsedFiles);
                    if (!filesResponse.success) {
                        setSaving(false);
                        return handleServiceError(app, filesResponse);
                    }

                } catch (fileUploadError) {
                    setSaving(false);
                    notification.error({
                        message: 'Erro no upload de arquivos',
                        description: 'Ocorreu um erro ao salvar os arquivos. Por favor, tente novamente.',
                    });
                    return;
                }
            }
            
            notification.success({ 
                message: 'Sucesso',
                description: 'Acompanhamento enviado com sucesso'
            });
            
            await fetchConstructions();
            setSaving(false);
            close();
        } catch (error) {
            setSaving(false);
        }
    };

    return (
        <Modal
            centered
            title="Novo acompanhamento"
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={close}
            afterClose={() => form.resetFields()}
            okText="Salvar"
            open
        >
            <Form 
                form={form}
                layout="vertical" 
                onFinish={onFinish}
            >
                <Form.Item 
                    label="Atualizar status da construção" 
                    name="new_status" 
                    initialValue={construction.status === 'planning' ? undefined : construction.status}
                >
                    <Select>
                        <Select.Option value="paralyzed">Paralisada</Select.Option>
                        <Select.Option value="in_progress">Em andamento</Select.Option>
                        <Select.Option value="finished">Finalizada</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item 
                    name="description" 
                    label="Descreva" 
                    rules={[{ required: true, message: 'Descreva' }]}>
                    <TextArea rows={5} allowClear />
                </Form.Item>

                <UploadField
                    name="files" 
                    type="picture" 
                    multiple 
                />
            </Form>
        </Modal >
    );

};