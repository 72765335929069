import React, { useState } from 'react';

import { Button, Modal, Typography } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { Show } from 'lib/Show';
import { Filter } from 'pages/maintenance/Filters';

import { MaintenancePDF } from './PDF';

export default function ReportMaintenanceModal() {
    const [isTypePDF, setIsTypePDF] = useState<boolean>(false);

    const {
        maintenancesFiltered,
        setIsReportMaintenanceModal
    } = useMaintenance();

    const close = () => {
        setIsReportMaintenanceModal(false);
    };

    const closeModalPDF = () => {
        setIsTypePDF(false);
    };

    const handleDownload = () => {
        setIsTypePDF(true);
    };

    return (
        <Modal
            open
            centered
            footer={null}
            onCancel={close}
            destroyOnClose={true}
            title="Relatório de manutenções"
        >
            <Typography.Paragraph>
                <Typography.Text>Os dados do relatório são gerados de acordo com os filtros aplicados.</Typography.Text>
            </Typography.Paragraph>
            <Button
                size='middle'
                type='primary'
                icon={<CloudDownloadOutlined />}
                onClick={handleDownload} block>
                Baixar relatório
            </Button>
            <Show when={isTypePDF}>
                <Modal
                    onCancel={closeModalPDF}
                    title='Relatório'
                    footer={null}
                    width={1000}
                    open
                >
                    <MaintenancePDF maintenances={maintenancesFiltered} filters={{} as Filter} />
                </Modal>
            </Show>
        </Modal >
    );

};