import React, { useEffect, useState } from 'react';

import { Alert, App, Button, Form, Input, Radio, Row, Select, Upload, UploadFile } from 'antd';
import AntdImgCrop from 'antd-img-crop';
import { MaskedInput } from 'antd-mask-input';

import { LockOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { normFile } from 'lib/helpers/File.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useResidentFirstAccess } from 'lib/providers/ResidentFirstAccessContextProvider';
import { Show } from 'lib/Show';
import { saveResident } from 'services/Client.service';

import API from '../../lib/API';

export type Values = {
    id: Apartment.Resident['id'] | undefined,
    picture: Apartment.Resident['picture'] | null,
    name: Apartment.Resident['name'],
    birthdate: Apartment.Resident['birthdate'],
    sex: Apartment.Resident['sex'],
    email: Apartment.Resident['email'],
    cellphone: Apartment.Resident['cellphone'],
    cpf: Apartment.Resident['cpf'],
    apartmentId: Apartment.Resident['apartmentId'],
    password: Apartment.Resident['password'],
    files: UploadFile[],
};

export function FormResidentExternal() {
    const [type, setType] = useState<string>();
    const [picture, setPicture] = useState<string | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();
    const { client, resident, apartments, setIsFormResidentExternal, setIsSuccessPage } = useResidentFirstAccess();

    const app = App.useApp();

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
    };

    const onChange = (file: any) => {
        if (file.file.status === 'done') {
            form.setFieldsValue({ picture: file.file.response });
            setPicture(file.file.response);
        }


        setFileList(file.fileList);
    };

    const onRemove = () => form.setFieldsValue({ picture: null });

    const onFinish = async ({ files, ...restValues }: Values) => {
        setIsSending(true);

        restValues.picture = picture;
     
        const parsedFiles = files ? files.map(file => ({ url: file.response ? file.response : file.url, filename: file.name })): [];

        let response = null;

        if (!client)
            throw new Error('The `client` property must be a valid `Client` model!');

        if (resident?.id)
            response = await saveResident({ ...restValues, client_id: client.id, id: resident.id, files: parsedFiles });
        else
            response = await saveResident({ ...restValues, client_id: client.id, files: parsedFiles });

        setIsSending(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIsFormResidentExternal(false);
        setIsSuccessPage(true);

    };

    useEffect(() => {
        if (resident?.id) {
            setType(resident.type);
            setPicture(resident.picture);
            setFileList([
                {
                    uid: resident.id.toString(),
                    name: resident.picture,
                    url: resident.picture,
                    status: 'done'
                }
            ]);
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const messageAlert = resident === null
        ? 'Não encontramos seu registro em nosso sistema. Não se preocupe, basta preencher seus dados e cadastrar uma senha para começar a utilizar o aplicativo.'
        : `Olá ${resident?.name}, notamos que suas informações já estão registradas em nosso sistema. Caso haja necessidade, por favor, verifique e atualize seus dados conforme desejado.`;

    const files: UploadFile[] = resident?.external_resident_creation_files.map(({ url, filename }) => ({
        uid: url,
        url: url,
        name: filename,
        status: 'done',
    })) ?? [];

    const initialValues = resident !== undefined && resident !== null
        ? { ...resident, files }
        : undefined;

    return (
        <Row>
            <Alert
                style={{ width: '100%', fontSize: 16 }}
                description={messageAlert}
                type="info"
                showIcon
            />
            <div className="form-container" style={{ width: '100%', marginTop: 15 }}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    name="residentIdentification"
                    layout="vertical"
                    autoComplete="off"
                >
                    <AntdImgCrop>
                        <Upload
                            action={`${API.api_url}/file/upload`}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onRemove={onRemove}
                            onPreview={onPreview}
                            multiple={false}
                            maxCount={1}
                        >
                            Foto
                        </Upload>
                    </AntdImgCrop>

                    <Form.Item label="Nome completo" name="name" rules={[{ required: true, message: 'Por favor, digite seu nome' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name="birthdate" label="Data de nascimento">
                        <Input type="date" />
                    </Form.Item>

                    <Form.Item name="sex" label="Sexo">
                        <Select>
                            <Select.Option value="feminine">Feminino</Select.Option>
                            <Select.Option value="masculine">Masculino</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Por favor, digite seu e-mail' }]}>
                        <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item name="cellphone" label="Telefone">
                        <Input placeholder='DDD+999999999' />
                    </Form.Item>

                    <Form.Item name="cpf" label="CPF" rules={[{ required: true, message: 'Digite o CPF' }]}>
                        <MaskedInput mask={'000.000.000-00'} />
                    </Form.Item>

                    <Form.Item name="type" label="Proprietário ou morador?" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                        <Radio.Group onChange={(e) => setType(e.target.value)}>
                            <Radio value="resident" >Morador</Radio>
                            <Radio value="tenant" >Inquilino</Radio>
                            <Radio value="owner" >Proprietário</Radio>
                            <Radio value="authorized_visitor" >Visitante autorizado</Radio>
                            <Radio value="local_employee" >Funcionário do local</Radio>
                            <Radio value="emergency_contact" >Contato de emergência</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Show when={type === 'owner'}>
                        <Form.Item name="isResident" label="Reside na unidade?" rules={[{ required: true, message: 'Selecione uma opção' }]}>
                            <Radio.Group>
                                <Radio value={1} >Sim</Radio>
                                <Radio value={0} >Não</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Show>

                    <Form.Item name="apartmentId" label="Unidade (apartamento/casa/lote)" rules={[{ required: true, message: 'Selecione uma unidade' }]} >
                        <Select
                            disabled={apartments.length <= 0}
                            loading={apartments.length <= 0}
                            showSearch
                            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {apartments.map((apartment, key: number) =>
                                <Select.Option key={key} value={apartment.id}>
                                    {`${apartment.tower.name} - ${apartment.name}`}
                                </Select.Option>)}
                        </Select>
                    </Form.Item>

                    <div style={{ whiteSpace: 'pre-line' }}>{client?.external_resident_creation_document_label}</div>

                    <Form.Item
                        name="files"
                        label={'Documentos'}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: client?.external_resident_creation_document_label !== null && client?.external_resident_creation_document_label !== '', message: 'Por favor, insira um arquivo.' }]}
                    >
                        <Upload action={`${API.api_url}/file/upload`} listType="picture">
                            <Button icon={<UploadOutlined />}>
                                Carregar
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="Senha para acessar o aplicativo" name="password" rules={[{ required: true, message: 'Por favor, digite sua senha' }]}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
                    </Form.Item>

                    <Form.Item style={{ marginTop: 10 }}>
                        <Button
                            loading={isSending}
                            size='large'
                            style={{ backgroundColor: client?.client_manager.primary_color ?? 'unset' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Row>
    );
}
