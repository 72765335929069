import React, { ReactNode } from 'react';

import { Layout } from 'antd';

import { COPYRIGHT } from 'internal-constants';

import { expiredTrial } from '../MainLayout';
import { SecondaryHeader } from './SecondaryHeader';
import { SecondarySider } from './SecondarySider';

type Props = { children: ReactNode }

export function SecondaryLayout({ children }: Props) {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const contentStyle = {
        margin: isMobile ? '0px 10px' : '0px 16px',
        padding: isMobile ? 'unset' : '10px 24px',
        minHeight: 280
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <SecondarySider />

            <Layout>
                <SecondaryHeader />

                {expiredTrial()}

                <Layout.Content style={contentStyle}>
                    {children}
                </Layout.Content>

                <Layout.Footer style={{ textAlign: 'center' }}>
                    {COPYRIGHT}
                </Layout.Footer>
            </Layout>
        </Layout>
    );
}
