import dayjs from 'dayjs';
import { DATE_CLIENT_FORMAT } from 'internal-constants';
import { isBeforeToday } from 'pages/payment/Columns';
import * as XLSX from 'xlsx';

type PaymentExcel = {
    'Título': string;
    'Condomínio': string;
    'Status': string;
    'Categoria': string;
    'Tipo': string;
    'Data de vencimento': string;
    'Valor': string;
    'Data de pagamento': string;
};

const translateStatus = (status: Payment.Model['status'], dueDate: Payment.Model['due_date']) => {
    if (status === 'payment_made')
        return 'Realizado';

    if (isBeforeToday(dueDate))
        return 'Atrasado';

    return 'Pendente';
};

const translateType = (type: Payment.Model['type']) => {
    if (type === 'single')
        return 'Simples';

    if (type === 'immediate_recurrent')
        return 'Recorrente imediato';

    return 'Recorrente';
};


export const downloadPaymentReport = (data: Payment.With<'category' | 'client'>[]) => {
    const payments: PaymentExcel[] = data
        .map(payment => ({
            'Título': payment.name,
            'Condomínio': payment.client.name,
            'Status': translateStatus(payment.status, payment.due_date),
            'Categoria': payment.category.name,
            'Tipo': translateType(payment.type),
            'Data de vencimento': dayjs(payment.due_date).format(DATE_CLIENT_FORMAT),
            'Valor': new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.value),
            'Data de pagamento': payment.payment_date !== null ? dayjs(payment.payment_date).format(DATE_CLIENT_FORMAT) : '',
        }));

    download(payments);
};

const download = (payments: PaymentExcel[]) => {
    const workbook = XLSX.utils.book_new();

    const worksheetClosed = XLSX.utils.json_to_sheet(payments);
    XLSX.utils.book_append_sheet(workbook, worksheetClosed, 'Base de dados');

    const excelBase64 = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelBase64;
    link.download = 'Relatório de pagamentos.xlsx';
    link.target = '_blank';

    link.click();
};