import React, {  } from 'react';

import { Card, List, Typography } from 'antd';

import CobreFacil from 'lib/CobreFacil';
import { formatBRL } from 'lib/helpers/BRL';
import { useAccount } from 'lib/providers/AccountContextProvider';
import { Show } from 'lib/Show';

export function ClientManagerCardPlan() {
    const { clientManager, subscription, isLoading } = useAccount();

    const supportNumber = clientManager.contract === 'trial'
        ? 'https://wa.me/555191639123'
        : 'https://wa.me/5551991503329';

    const subscriptionItem = (item: CobreFacil.Item) => {
        return <List.Item>
            <Typography.Text>
                {item.description}: {formatBRL(parseFloat(item.price))} {
                    item.number_installments > 1
                        ? `parcela ${item.generated_installments} de ${item.number_installments}`
                        : null
                }
            </Typography.Text>
        </List.Item>;
    };

    const listSubscription = () => {
        if (!subscription)
            return null;

        const total = subscription.items.reduce((acc, obj) => acc + (parseFloat(obj.price) || 0), 0);

        subscription.items.map(item => parseFloat(item.price));

        return <List
            bordered
            dataSource={subscription.items}
            renderItem={subscriptionItem}
            footer={<Typography.Text strong>Total: {formatBRL(total)}</Typography.Text>}
        />;
    };

    return (
        <Card
            title={
                subscription?.plans_id
                    ? CobreFacil.findPlan(subscription.plans_id)?.name
                    : 'Plano'
            }
            loading={isLoading}
        >
            <Show when={subscription === null}>
                <Typography.Text style={{ marginTop: 0 }}>
                    Você ainda não contratou nenhum plano. <Typography.Link href={supportNumber} target='_blank' strong>Clique aqui e entre em contato conosco</Typography.Link> para encontrar o plano ideal.
                </Typography.Text>
            </Show >
            {listSubscription()}
        </Card>
    );
};