import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { App, Col, Result, Row, Typography } from 'antd';

import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { Layout } from 'components/Client/Layout';
import { AnswerGeneralMeetingForm } from 'components/GeneralMeeting/AnswerGeneralMeetingForm';
import dayjs from 'dayjs';
import { DATE_CLIENT_FORMAT } from 'internal-constants';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { Show } from 'lib/Show';
import { findGeneralMeetingWithoutAuthentication as findGeneralMeeting } from 'services/GeneralMeeting.service';

type Params = { id: string };

type Props = RouteComponentProps<Params>;

export function ExternalGeneralMeeting({ match: { params }}: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [generalMeeting, setGeneralMeeting] = useState<GeneralMeeting.With<'questions' | 'client'> | null>(null);

    const app = App.useApp();

    const parsedId = parseInt(params.id, 10);

    if (Number.isNaN(parsedId))
        throw new Error(`The \`id\` router parameter must be a numeric string and not ${params.id}.`);
    
    useEffect(() => {
        const fetchGeneralMeeting = async () => {
            setIsLoading(true);

            const response = await findGeneralMeeting(parsedId);

            if (!response.success)
                return handleServiceError(app, response);

            setIsLoading(false);

            setGeneralMeeting(response.general_meeting);
        };

        fetchGeneralMeeting();
    }, [app, parsedId]);

    if (isLoading || generalMeeting === null)
        return <LoadingGcondo />;

    const { client } = generalMeeting;

    const today = dayjs();
    const parsedClosingDate = dayjs(generalMeeting.closing_date);
    const isExpired = today.isAfter(parsedClosingDate, 'day');

    return (
        <Layout backgroundColor={client.client_manager.secondary_color}>
            <Row style={{ marginBottom: 0 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <img 
                        src={client.client_manager.logo} 
                        alt={client.client_manager.name}
                        width="196"
                    />
                </Col>
            </Row>

            <Show when={generalMeeting.available === 1 && !isExpired}>
                <Row>
                    <Col span={24}>
                        <Typography.Title 
                            level={3}
                            style={{ 
                                marginTop: 0,
                                marginBottom: 0,
                                color: client.client_manager.primary_color ?? undefined
                            }}
                        >
                            {client.name}
                        </Typography.Title>

                        <Typography.Text type="secondary">
                        Uma enquete foi disponiblizada pelo seu condomínio.
                        </Typography.Text>
                    </Col>
                </Row>
            </Show>

            <Row>
                <Col span={24}>
                    <Show when={generalMeeting.available === 1 && !isExpired}>
                        <AnswerGeneralMeetingForm
                            id={generalMeeting.id}
                            title={generalMeeting.title}
                            description={generalMeeting.description}
                            questions={generalMeeting.questions}
                        />
                    </Show>

                    <Show when={generalMeeting.available === 0}>
                        <Result
                            status="403"
                            title="Enquete indisponível"
                            subTitle="Talvez ela não tenha sido liberada ainda? Tente novamente mais tarde."
                        />
                    </Show>

                    <Show when={isExpired}>
                        <Result
                            status="403"
                            title="Enquete expirada"
                            subTitle={`O prazo para responder esta enquete foi encerrado em ${parsedClosingDate.format(DATE_CLIENT_FORMAT)}.`}
                        />
                    </Show>
                </Col>
            </Row>
        </Layout>
    );
}
