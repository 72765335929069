const NonAuthRoutes = {
    login: '/login',
    recoverResidentPassword: '/morador/recuperar-senha',
    resetPassword: '/recuperar-senha',
    client_manager_page: '/gc/:url_client_manager',
    default: '/',
    forbidden: '/forbidden',
    notFound: '/not-found',

    externalGeneralMeetingView: '/general_meeting/:id/external',

    client: '/client/:url_client',
    clientIssue: '/client/:url_client/issue',
    clientIssueList: '/client/:url_client/issue/list',
    clientFirstAccess: '/primeiro-acesso/:url_client',
} as const;

const AuthRoutes = {
    overview: '/overview',
    dashboard: '/dashboard',
    apartment: '/apartment',
    apartmentView: '/apartment/:id',
    resident: '/resident',
    reserve: '/reserve',
    issue: '/issue',
    device: '/device',
    concierge: '/concierge',
    maintenance: '/maintenance',
    updatePassword: '/update-password',
    notice: '/notice',
    ambient: '/ambient',
    chatbot: '/chatbot',
    budget: '/budget',
    document: '/document',
    construction: '/construction',
    consumption: '/consumption',
    general_meeting: '/general_meeting',
    general_meeting_report: '/general_meeting_report/:id',
    pending_payment: '/pending_payment',
    account: '/account',
    setup: '/setup',
    overviewIssue: '/overview/issue',
    overviewIssueStats: '/overview/issue/stats',
    overviewConstruction: '/overview/construction',
    overviewMaintenance: '/overview/maintenance',
    overviewSchedule: '/overview/schedule',
    overviewReport: '/overview/report',
    overviewPayment: '/overview/payment',
    overviewUser: '/overview/user',
    overviewSupplier: '/overview/supplier',
    overviewDocument: '/overview/document',
    schedule: '/schedule',
} as const;

export type NonAuthRoute = typeof NonAuthRoutes[keyof typeof NonAuthRoutes];
export type AuthRoute = typeof AuthRoutes[keyof typeof AuthRoutes];

export type Route = NonAuthRoute | AuthRoute;

export { AuthRoutes, NonAuthRoutes };
