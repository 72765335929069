import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listPaymentCategories, listPayments } from 'services/Payment.service';

type Value = {
    isLoading: boolean,
    payments: Payment.With<'category' | 'client'>[],
    setPayments: Dispatch<SetStateAction<Value['payments']>>,
    fetchPayments: () => Promise<void>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    isPaymentMadeModalVisible: boolean,
    setIsPaymentMadeModalVisible: Dispatch<SetStateAction<Value['isPaymentMadeModalVisible']>>,
    isViewModalVisible: boolean,
    setIsViewModalVisible: Dispatch<SetStateAction<Value['isViewModalVisible']>>,
    paymentId?: Payment.Model['id'],
    setPaymentId: Dispatch<SetStateAction<Value['paymentId']>>,
    payment: Payment.Model | null,
    categories: Payment.Category[],
    fetchCategories: () => Promise<Payment.Category[]>,
};

type Props = { children: (value: Value) => ReactNode };

const PaymentContext = createContext<Value | null>(null);

export function PaymentContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [payments, setPayments] = useState<Value['payments']>([]);

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isPaymentMadeModalVisible, setIsPaymentMadeModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const [paymentId, setPaymentId] = useState<Payment.Model['id'] | undefined>(undefined);

    const [categories, setCategories] = useState<Payment.Category[]>([]);
    const [isLoadingCategories, setIsLoadingCategories] = useState(true);

    const app = App.useApp();

    const fetchPayments = async () => {
        setIsLoading(true);

        const response = await listPayments();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setPayments(response.payments);
    };

    const memoizedFetchPayments = useCallback(fetchPayments, [app]);

    useEffect(() => {
        memoizedFetchPayments();
    }, [memoizedFetchPayments]);


    const fetchCategories = async () => {
        setIsLoadingCategories(true);

        const response = await listPaymentCategories();

        setIsLoadingCategories(false);

        if (!response.success)
            return [];

        setCategories(response.categories);

        return response.categories;
    };

    const memoizedFetchCategories = useCallback(fetchCategories, []);

    useEffect(() => {
        memoizedFetchCategories();
    }, [memoizedFetchCategories]);

    const payment = useMemo(() => {
        if (!paymentId || payments.length === 0)
            return null;

        const found = payments.find(payment => payment.id === paymentId);

        if (!found)
            throw new Error(`Could not find a payment with id ${paymentId}`);

        return found;
    }, [payments, paymentId]);

    const value: Value = {
        isLoading: isLoading || isLoadingCategories,
        payments,
        setPayments,
        isCreateModalVisible: isCreateModalVisible,
        setIsCreateModalVisible: setIsCreateModalVisible,
        isEditModalVisible: isEditModalVisible,
        setIsEditModalVisible: setIsEditModalVisible,
        paymentId: paymentId,
        setPaymentId: setPaymentId,
        payment: payment,
        setIsPaymentMadeModalVisible: setIsPaymentMadeModalVisible,
        isPaymentMadeModalVisible: isPaymentMadeModalVisible,
        setIsViewModalVisible: setIsViewModalVisible,
        isViewModalVisible: isViewModalVisible,
        fetchPayments: memoizedFetchPayments,
        categories,
        fetchCategories: memoizedFetchCategories,
    };

    return (
        <PaymentContext.Provider value={value}>
            {children(value)}
        </PaymentContext.Provider>
    );
}

export function usePayment() {
    const context = useContext(PaymentContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `PaymentContextProvider`.');

    return context;
}