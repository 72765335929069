import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Avatar, Card, Col, Layout, List, Row, Typography } from 'antd';

import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { ClientManagerPageContextProvider } from 'lib/providers/ClientManagerPageContextProvider';

const wl = makeWhiteLabel();

export function ClientManagerPage(props: RouteComponentProps<{ url_client_manager: string }>) {

    return (
        <ClientManagerPageContextProvider url={props.match.params.url_client_manager}>
            {({
                isLoading,
                client_manager
            }) => (
                <Fragment>
                    {
                        isLoading
                            ? <LoadingGcondo />
                            : <Layout className="layout" style={{ alignItems: 'center', backgroundColor: client_manager?.secondary_color ?? '#FAFAFA' }}>
                                <Layout.Content className='form-resident' style={{ width: '60%', padding: 20 }}>
                                    <Row style={{ alignItems: 'center', textAlign: 'center' }}>
                                        <Col xs={24} sm={24} md={12} lg={12}>
                                            <img src={client_manager?.logo} alt='logo' width="300" />
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12}>
                                            {
                                                client_manager?.picture
                                                    ? <Avatar
                                                        size={200}
                                                        src={client_manager?.picture}
                                                    />
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Typography.Title level={3} style={{ color: client_manager?.secondary_color ? 'white' : 'black' }}>Seja bem-vindo a página <span style={{ color: client_manager?.primary_color ?? 'black' }}>{client_manager?.name}</span>!</Typography.Title>
                                        <Typography.Title level={4} style={{ color: client_manager?.secondary_color ? 'white' : 'black' }}>
                                            Escolha abaixo o <span style={{ color: client_manager?.primary_color ?? 'black' }}>seu condomínio</span> para acessar informações exclusivas, participar ativamente e fazer o <span style={{ color: client_manager?.primary_color ?? 'black' }}>download do nosso aplicativo</span> para uma gestão ainda mais eficiente e transparente. <br /><br /><span style={{ color: client_manager?.primary_color ?? 'black' }}>Estamos aqui para tornar a sua experiência ainda melhor!</span></Typography.Title>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Card>
                                                <List
                                                    style={{ cursor: 'pointer' }}
                                                    size='large'
                                                    bordered
                                                    dataSource={client_manager?.client}
                                                    renderItem={(client) => (
                                                        <List.Item
                                                            onClick={() => {
                                                                if (client.url)
                                                                    window.location.href = `${wl.defaultUrl}/client/${client.url}`;
                                                            }}>
                                                            <Typography.Text style={{ fontSize: 18 }}>{client.name}</Typography.Text>
                                                        </List.Item>
                                                    )}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Layout.Content>
                            </Layout>
                    }
                </Fragment>
            )}
        </ClientManagerPageContextProvider>
    );
}
