import React, { } from 'react';

import { Card } from 'antd';

import { Bar } from '@ant-design/plots';
import { useStats } from 'lib/providers/StatsContextProvider';

export function ChartIssuesClosedByUser() {
    const { stats, isLoading } = useStats();

    const data = stats.total_issues_closed_by_user ?? [];
    const config = {
        data,
        xField: 'value',
        yField: 'name',
        seriesField: 'name'
    };

    return <Card title="Usuários que resolveram chamados">
        <Bar {...config} loading={isLoading} />
    </Card>;
};