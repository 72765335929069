import React, { useState } from 'react';

import { App, Divider, Form, Input, Modal, Radio, } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { MaskedInput } from 'antd-mask-input';

import { makeRawCnpj } from 'lib/helpers/Text.helper';
import { useAccount } from 'lib/providers/AccountContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { createAccountClient } from 'services/Account.service';

type Values = {
    name: string,
    email: string,
    phone: string,
    cnpj: string,
    url: string,
    external_resident_creation_workflow_type: 'auto' | 'approval',
    external_resident_creation_document_label: string,
}

export function ClientCreateModal() {
    const [isSending, setIsSending] = useState(false);

    const { setIsCreateClientModalVisible, fetchClients, clientManager } = useAccount();

    const [form] = Form.useForm<Values>();

    const watchedExternalResidentCreationWorkflowType = Form.useWatch('external_resident_creation_workflow_type', form);

    const app = App.useApp();

    const close = () => setIsCreateClientModalVisible(false);

    const onFinish = async ({
        phone,
        cnpj,
        external_resident_creation_document_label,
        ...values
    }: Values) => {
        setIsSending(true);

        const response = await createAccountClient({
            name: values.name,
            email: values.email,

            phone: phone !== undefined && phone !== ''
                ? phone
                : null,

            cnpj: cnpj !== undefined && cnpj !== ''
                ? makeRawCnpj(cnpj)
                : null,

            url: values.url,

            client_manager_id: clientManager.id,

            external_resident_creation_workflow_type: values.external_resident_creation_workflow_type,

            external_resident_creation_document_label: external_resident_creation_document_label !== undefined && external_resident_creation_document_label !== ''
                ? external_resident_creation_document_label
                : null,
        });

        setIsSending(false);

        if (response.success)
            app.notification.success({ message: response.message, description: response.description });
        else
            app.notification.error({ message: response.message });

        await sleep(1000);

        close();
        fetchClients();

        await sleep(2000);

        app.notification.info({
            message: 'Importante',
            description: 'Lembre-se que após criar um condomínio novo, você deve habilitar o acesso de sua equipe no módulo de "Usuários".',
            placement: 'top',
            duration: 5
        });
    };

    return (
        <Modal
            open
            title="Cadastrar condomínio"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Cadastrar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createClient"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, digite um nome.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="cnpj"
                    label="CNPJ"
                >
                    <MaskedInput mask="00.000.000/0000-00" />
                </Form.Item>


                <Form.Item
                    name="email"
                    label="E-mail"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Telefone"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="url"
                    label="URL"
                    rules={[{ required: true, message: 'Por favor, insira uma URL' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="external_resident_creation_workflow_type"
                    label="Tipo de fluxo para criação de morador"
                    rules={[{ required: true, message: 'Por favor, selecione um tipo.' }]}
                >
                    <Radio.Group>
                        <Radio value="auto">Automático</Radio>
                        <Radio value="approval">Aprovação</Radio>
                    </Radio.Group>
                </Form.Item>

                <Show when={watchedExternalResidentCreationWorkflowType === 'approval'}>
                    <Form.Item
                        name="external_resident_creation_document_label"
                        label="Legenda do campo de documentos na criação de morador"
                        rules={[{ required: true, message: 'Por favor, digite uma legenda.' }]}
                        help={'Quando o fluxo para criação de morador for "Aprovação", um campo para envio de documentos será exibido.'}
                    >
                        <TextArea rows={5} />
                    </Form.Item>
                </Show>
            </Form>
        </Modal>
    );
}

