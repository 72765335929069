import React from 'react';

import { Typography } from 'antd';

import { ClientManagerCard } from 'components/Account/ClientManagerCard';
import { ClientImportCard } from 'components/Setup/ClientImportCard';
import { ResidentImportCard } from 'components/Setup/ResidentImportCard';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { AccountContextProvider } from 'lib/providers/AccountContextProvider';
import { Users } from 'pages/overview/user/Users.page';

const wl = makeWhiteLabel();

export const steps: Setup.CustomStep[] = [
    {
        key: 'update_company_data',
        title: 'Atualizar dados da empresa',
        description: <Typography.Text type='secondary'>Preencha os dados da empresa e adicione o logotipo</Typography.Text>,

        content_finished: {
            description: <Typography.Text>Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo <Typography.Link href='/account' strong>Minha conta</Typography.Link>.</Typography.Text>
        },

        content: {
            description: 'Nesta etapa, você preenche os dados da sua empresa, incluindo informações básicas como nome, endereço e contato. Além disso, você adiciona o logotipo da sua empresa, que é utilizado nos relatórios e outras comunicações oficiais.',
            component: <AccountContextProvider>
                {() => <ClientManagerCard />}
            </AccountContextProvider>,
            steps: [
                'Você deve preencher todos os campos obrigatórios marcados com *.',
                'Você precisa fazer o upload do logotipo da sua empresa em formato PNG com tamanho máximo de 2MB.',
                'Você deve verificar se todos os dados estão corretos antes de prosseguir.'
            ],
            tip: 'Manter os dados da sua empresa atualizados garante que todas as comunicações e relatórios reflitam corretamente a sua identidade corporativa.'
        }
    },
    {
        key: 'contract_acceptance_gcondo',
        title: 'Aceite do contrato',
        description: <Typography.Text type='secondary'>Contrate nossos serviços agora ou em até 30 dias</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: 'Nesta etapa, você pode contratar nossos serviços imediatamente, a qualquer momento ou aproveitar um período de 30 dias para tomar sua decisão. Durante esses 30 dias, você usufrui de todos os recursos da plataforma para avaliar como ela atende às suas necessidades.',
            steps: [
                'Você deve ler o contrato atentamente antes de tomar sua decisão.',
                'Você pode optar por contratar agora, em qualquer momento dentro dos próximos 30 dias.',
                'Você precisa clicar no botão \'Aceitar Contrato\' ao final da página para contratar.'
            ],
            note: 'O aceite do contrato é necessário para continuar usando todas as funcionalidades da plataforma após o período de avaliação.'
        }
    },

    {
        key: 'contract_acceptance_sindigest',
        title: 'Aceite do contrato SindiGest',
        description: <Typography.Text type='secondary'>Contrate nossos serviços agora ou em até 30 dias</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: 'Nesta etapa, você pode contratar nossos serviços imediatamente, a qualquer momento ou aproveitar um período de 30 dias para tomar sua decisão. Durante esses 30 dias, você usufrui de todos os recursos da plataforma para avaliar como ela atende às suas necessidades.',
            steps: [
                'Você deve ler o contrato atentamente antes de tomar sua decisão.',
                'Você pode optar por contratar agora, em qualquer momento dentro dos próximos 30 dias.',
                'Você precisa clicar no botão \'Aceitar Contrato\' ao final da página para contratar.'
            ],
            note: 'O aceite do contrato é necessário para continuar usando todas as funcionalidades da plataforma após o período de avaliação.'
        }
    },
    {
        key: 'condo_import',
        title: 'Importação de condomínios',
        description: <Typography.Text type='secondary'>Baixe e preencha a planilha modelo para importar os condomínios</Typography.Text>,
        content_finished: {
            description: <Typography.Text>Você já finalizou a importação dos condomínios, mas caso precise criar novos condomínios ou atualizar qualquer informação, você pode usar o módulo <Typography.Link href='/account' strong>Minha conta</Typography.Link>.</Typography.Text>
        },
        content: {
            description: 'Nesta etapa, você importa os dados dos condomínios que gerencia. Primeiro, você deve baixar a planilha modelo, preenchê-la com os dados necessários e depois fazer o upload.',
            component: <ClientImportCard />,
            steps: [
                'Você deve clicar em \'Baixar planilha modelo\' para fazer o download da planilha.',
                'Você precisa preencher todos os campos obrigatórios na planilha.',
                'Você deve salvar a planilha preenchida no seu computador.',
                'Você precisa fazer o upload da planilha preenchida na plataforma clicando em \'Realizar upload do arquivo preenchido\'.'
            ],
            tip: 'Certifique-se de que todos os dados estejam corretos e completos para evitar erros na importação.'
        }
    },
    {
        key: 'create_users',
        title: 'Cadastre os membros da sua equipe',
        description: <Typography.Text type='secondary'>Adicione usuários e atribua seus respectivos condomínios</Typography.Text>,
        content_finished: {
            description: <Typography.Text>Você já finalizou a criação dos usuários, mas caso precise adicionar ou atualizar um usuário, você pode usar o módulo <Typography.Link href='/account' strong>Minha conta</Typography.Link> ou <Typography.Link href='/user' strong>Usuários</Typography.Link>.</Typography.Text>
        },
        content: {
            description: 'Agora, você pode criar usuários para sua equipe e dar acesso aos condomínios que eles irão gerenciar.',
            steps: [
                'Você deve clicar no botão \'Adicionar usuário\' e preencher os dados solicitados.',
                'Para cada usuário, você precisa atribuir um perfil e selecionar os condomínios que ele poderá acessar.',
            ],
            component: <Users />,
            tip: 'Organize sua equipe de forma eficiente atribuindo acessos de acordo com as responsabilidades de cada membro.'
        }
    },
    {
        key: 'resident_import',
        title: 'Importação de condôminos',
        description: <Typography.Text type='secondary'>Baixe e preencha a planilha modelo para importar os dados dos condôminos</Typography.Text>,
        content_finished: {
            description: <Typography.Text>Você já finalizou a importação dos condôminos, mas caso precise realizar uma nova importação ou atualizar qualquer informação, você pode usar o módulo <Typography.Link href='/account' strong>Minha conta</Typography.Link> ou <Typography.Link href='/resident' strong>Condôminos</Typography.Link>.</Typography.Text>
        },
        content: {
            description: 'Semelhante à importação de condomínios, você importa os dados dos condôminos. Primeiro, você deve baixar a planilha modelo, preenchê-la e depois fazer o upload.',
            steps: [
                'Você deve baixar a planilha modelo clicando no botão \'Baixar Planilha Modelo\'.',
                'Você precisa inserir os dados dos condôminos conforme as instruções na planilha.',
                'Você deve salvar a planilha preenchida e fazer o upload clicando em \'Importar Planilha\'.'
            ],
            component: <ResidentImportCard />,
            tip: 'Verifique os dados dos condôminos para garantir que não haja informações incorretas ou incompletas.'
        }
    },
    {
        key: 'app_download_instructions_gcondo',
        title: 'Instrução para baixar aplicativos',
        description: <Typography.Text type='secondary'>Baixe os aplicativos para condôminos, síndicos e equipe de gestão</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: 'Nesta etapa, você recebe instruções sobre como baixar e instalar os aplicativos para condôminos, síndicos e a equipe de gestão.',
            steps: [
                'Você deve baixar o aplicativo para condôminos no Google Play ou App Store.',
                'Você precisa baixar o aplicativo para síndicos e equipe de gestão no Google Play ou App Store.',
                'Você deve seguir as instruções de instalação fornecidas na loja de aplicativos.'
            ],
            tip: 'Incentive os condôminos e a equipe a baixarem os aplicativos para facilitar a comunicação e a gestão do condomínio.'
        }
    },
    {
        key: 'app_download_instructions_sindigest',
        title: 'Instrução para baixar aplicativos SindiGest',
        description: <Typography.Text type='secondary'>Baixe os aplicativos para condôminos, síndicos e equipe de gestão</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: 'Nesta etapa, você recebe instruções sobre como baixar e instalar os aplicativos para condôminos, síndicos e a equipe de gestão.',
            steps: [
                'Você deve baixar o aplicativo para condôminos no Google Play ou App Store.',
                'Você precisa baixar o aplicativo para síndicos e equipe de gestão no Google Play ou App Store.',
                'Você deve seguir as instruções de instalação fornecidas na loja de aplicativos.'
            ],
            tip: 'Incentive os condôminos e a equipe a baixarem os aplicativos para facilitar a comunicação e a gestão do condomínio.'
        }
    },
    {
        key: 'training_resources_gcondo',
        title: 'Link das aulas e possibilidade de agendar treinamento',
        description: <Typography.Text type='secondary'>Aulas online e agendamento de treinamento</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: `Oferecemos aulas online para ajudar você e sua equipe a aproveitarem ao máximo a plataforma ${wl.name}. Se você gerencia menos de 10 condomínios, o treinamento deve ser pago.`,
            steps: [
                'Você deve acessar as aulas online clicando [aqui].',
                'Para agendar um treinamento personalizado, você precisa clicar [aqui].',
                'Se você gerencia menos de 10 condomínios, você deve verificar os detalhes sobre os custos do treinamento.'
            ],
            tip: 'O treinamento é uma excelente oportunidade para tirar dúvidas e aprender a utilizar todas as funcionalidades da plataforma de forma eficaz.'
        }
    },
    {
        key: 'training_resources_sindigest',
        title: 'Link das aulas e possibilidade de agendar treinamento SindiGest',
        description: <Typography.Text type='secondary'>Aulas online e agendamento de treinamento</Typography.Text>,
        content_finished: {
            description: `Você já finalizou a atualização dos dados da sua empresa, mas caso precise atualizar qualquer informação, você pode usar o módulo ${<Typography.Link href='/account'>Minha conta</Typography.Link>}`
        },
        content: {
            description: `Oferecemos aulas online para ajudar você e sua equipe a aproveitarem ao máximo a plataforma ${wl.name}. Se você gerencia menos de 10 condomínios, o treinamento deve ser pago.`,
            steps: [
                'Você deve acessar as aulas online clicando [aqui].',
                'Para agendar um treinamento personalizado, você precisa clicar [aqui].',
                'Se você gerencia menos de 10 condomínios, você deve verificar os detalhes sobre os custos do treinamento.'
            ],
            tip: 'O treinamento é uma excelente oportunidade para tirar dúvidas e aprender a utilizar todas as funcionalidades da plataforma de forma eficaz.'
        }
    }
];
