import React, { Fragment, useState } from 'react';

import {
    Button,
    ButtonProps,
    Divider,
    Input as AntdInput,
    InputProps as AntdInputProps,
    notification,
    Select,
    SelectProps
} from 'antd';

type Dropdown = {
    /** Invoked when a new option is created in the custom dropdown */
    onNewOption: (option: string) => Promise<void>,
    /** Custom dropdown input placeholder */
    placeholder?: Input['placeholder'],
};

type Props = {
    /** Custom dropdown extra props */
    dropdown: Dropdown,
} & Omit<SelectProps, 'showSearch' | 'filterOption' | 'open' | 'onDropdownVisibleChange' | 'dropdownRender'>;

type Action = {
    onClick: ButtonProps['onClick'],
};

type Input = {
    placeholder?: AntdInputProps['placeholder'],
    value: AntdInputProps['value'],
    onChange: AntdInputProps['onChange'],
};

type MakeDropdownRender = (
    action: Action,
    input: Input,
    isLoading: boolean,
    status: 'error' | undefined
) => SelectProps['dropdownRender'];

/** @factory */
const makeDropdownRender: MakeDropdownRender = (action, input, isLoading, status) => (menu) => (
    <Fragment>
        {menu}

        <Divider style={{ margin: '8px 0' }} />

        <span style={{ display: 'flex', gap: '8px', padding: '0 8px 4px' }}>
            <AntdInput
                placeholder={input.placeholder}
                value={input.value}
                onChange={input.onChange}
                onKeyDown={event => event.stopPropagation()}
                style={{ width: '100%' }}
                disabled={isLoading}
                status={status}
            />

            <Button
                type="primary"
                htmlType="button"
                onClick={action.onClick}
                loading={isLoading}
            >
                Cadastrar
            </Button>
        </span>
    </Fragment>
);

export function DynamicSelect({ dropdown, ...restProps }: Props) {
    const [status, setStatus] = useState<'error' | undefined>();
    const [newOption, setNewOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleFilterOption = (input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleNewOption: Action['onClick'] = async (event) => {
        event.preventDefault();

        if (!newOption) {
            setStatus('error');
            return notification.warning({ message: 'Atenção', description: 'Você precisa descrever o que deseja cadastrar.' });
        }

        setIsLoading(true);
        setStatus(undefined);

        await dropdown.onNewOption(newOption);

        setNewOption('');

        setIsLoading(false);

        setIsDropdownOpen(false);
    };

    const action: Action = { onClick: handleNewOption };

    const input: Input = {
        placeholder: dropdown.placeholder,
        value: newOption,
        onChange: event => setNewOption(event.target.value),
    };

    const dropdownRender = makeDropdownRender(action, input, isLoading, status);

    return (
        <Select
            {...restProps}
            showSearch
            filterOption={handleFilterOption}
            open={isDropdownOpen}
            onDropdownVisibleChange={setIsDropdownOpen}
            dropdownRender={dropdownRender}
        />
    );
}
