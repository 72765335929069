const { version } = require('../package.json');

const dev = {
    api: 'http://localhost:8080',
    api_cobre_facil: 'https://api.cobrefacil.com.br/v1',
    file: {
        upload: 'http://localhost:8080/file/upload'
    }
};

const prod = {
    api: 'https://api.gcondo.com.br',
    api_cobre_facil: 'https://api.cobrefacil.com.br/v1',
    file: {
        upload: 'https://api.gcondo.com.br/file/upload'
    }
};

const config = (process.env.NODE_ENV === 'development' ? dev : prod);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    project_title: 'GCONDO',
    defaultLanguage: 'PT',
    version,

    // iugu: {
    //     script: "https://js.iugu.com/v2",
    //     accountId: "F94528CCF6574F4AAC38FB1DA8ABC69B",
    //     debug: process.env.NODE_ENV === "development"
    // },

    ...config
};