import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { findClientByURL, findResidentByParams, listApartmentsByClient } from 'services/Client.service';

type ClientResponse = Awaited<ReturnType<typeof findClientByURL>>;

type Client = Extract<ClientResponse, { client: any }>['client'];

type ResidentResponse = Awaited<ReturnType<typeof findResidentByParams>>;

type Resident = Extract<ResidentResponse, { resident: any }>['resident'];

type Value = {
    isLoading: boolean,
    client: Client| undefined,
    apartments: Apartment.Model[],
    resident: Resident | undefined | null,
    setResident: Dispatch<SetStateAction<Value['resident']>>,
    isFormResidentIdentification: boolean,
    setIsFormResidentIdentification: Dispatch<SetStateAction<Value['isFormResidentIdentification']>>,
    isFormResidentExternal: boolean,
    setIsFormResidentExternal: Dispatch<SetStateAction<Value['isFormResidentExternal']>>,
    isSuccessPage: boolean,
    setIsSuccessPage: Dispatch<SetStateAction<Value['isSuccessPage']>>,
    fetchClient: () => Promise<void>
};

type Props = { children: (value: Value) => ReactNode, url: Client.Model['url'] };

const ResidentFirstAccessContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ResidentFirstAccessContextProvider({ children, url }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState<Client>();
    const [apartments, setApartments] = useState<Apartment.Model[]>([]);
    const [resident, setResident] = useState<Resident | null>();
    const [isFormResidentIdentification, setIsFormResidentIdentification] = useState<boolean>(true);
    const [isFormResidentExternal, setIsFormResidentExternal] = useState<boolean>(false);
    const [isSuccessPage, setIsSuccessPage] = useState<boolean>(false);

    const app = App.useApp();

    const fetchClient = async () => {
        setIsLoading(true);

        const clientResponse = await findClientByURL(url);

        if (!clientResponse.success)
            return handleServiceError(app, clientResponse);

        const apartmentResponse = await listApartmentsByClient(clientResponse.client.id);

        if (!apartmentResponse.success)
            return handleServiceError(app, apartmentResponse);

        setIsLoading(false);
        setClient(clientResponse.client);
        setApartments(apartmentResponse.apartments);
    };


    const value: Value = {
        isLoading,
        client,
        apartments,
        resident,
        setResident,
        isFormResidentIdentification,
        setIsFormResidentIdentification,
        isFormResidentExternal,
        setIsFormResidentExternal,
        isSuccessPage,
        setIsSuccessPage,
        fetchClient
    };

    useEffect(() => {
        fetchClient();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ResidentFirstAccessContext.Provider value={value}>
            {children(value)}
        </ResidentFirstAccessContext.Provider>
    );
}

export function useResidentFirstAccess() {
    const context = useContext(ResidentFirstAccessContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ResidentFirstAccessContextProvider`.');

    return context;
}