import React, { Fragment, ReactNode } from 'react';

import { Alert, Button, Card, Col, message, Popconfirm, Row, Steps, Typography } from 'antd';

import { Layout } from 'components/Account/Layout';
import Authentication from 'lib/Authentication';
import { SetupContextProvider } from 'lib/providers/SetupContextProvider';
import { Show } from 'lib/Show';

export function Setup() {
    const userName = Authentication.getUserName().split(' ')[0];

    const renderContent = (item: {
        description: string,
        component?: ReactNode,
        steps?: string[],
        tip?: string
    }) => {
        return <Typography.Paragraph>
            <Typography.Paragraph>
                <Typography.Text strong>{item.description}</Typography.Text>
            </Typography.Paragraph>
            {
                item.steps && item.steps.length > 0
                    ? <Typography.Paragraph>
                        <Typography.Text strong>Passo a passo: </Typography.Text>
                        <ul>
                            {
                                item.steps.map(step => <li>{step}</li>)
                            }
                        </ul>
                    </Typography.Paragraph>
                    : null
            }
            {
                item.tip
                    ? <Typography.Paragraph style={{ paddingTop: 10 }}>
                        <Alert message={item.tip} type="info" showIcon />
                    </Typography.Paragraph>
                    : null
            }
            {item.component}
        </Typography.Paragraph>;
    };

    const renderContentFinished = (item: { description: ReactNode }) => {
        return <Typography.Paragraph>
            {item.description}
        </Typography.Paragraph>;
    };

    return (
        <SetupContextProvider>
            {({
                isLoading,
                allowedSteps,
                items,
                current,
                step,
                next,
                prev
            }) => (
                <Layout>
                    <Row>
                        <Col span={24}>
                            <Typography.Title level={3} style={{ marginBottom: 0 }}>
                                Bem vindo, {userName}!
                            </Typography.Title>
                            <Typography.Title level={5} type="secondary">
                                Estamos muito felizes em ter você conosco. Vamos começar configurando sua conta para que você possa aproveitar ao máximo todas as funcionalidades que oferecemos. Se precisar de ajuda em qualquer etapa, nossa equipe está à disposição para auxiliá-lo.
                            </Typography.Title>
                        </Col>
                    </Row>

                    {
                        allowedSteps.length > 0

                            ? <Row>
                                <Col xs={24} sm={24} md={6} lg={6}>
                                    <Steps
                                        size='small'
                                        direction='vertical'
                                        current={current}
                                        items={items}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={18} lg={18}>
                                    <Card loading={isLoading}>
                                        {
                                            step?.status === 'finished'
                                                ? renderContentFinished(allowedSteps[current].content_finished)
                                                : renderContent(allowedSteps[current].content)
                                        }
                                        {current === allowedSteps.length - 1 && step?.status === 'finished' && (
                                            <Typography.Paragraph>
                                                <Typography.Title level={5}>Parabéns! Você finalizou o processo de implantação.</Typography.Title>
                                                <Typography.Link href='/overview' strong> Clique aqui e navegue para a página inicial</Typography.Link>
                                            </Typography.Paragraph>
                                        )}
                                    </Card>
                                    <div style={{ marginTop: 24 }}>
                                        {current > 0 && current !== allowedSteps.length - 1 && (
                                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                Anterior
                                            </Button>
                                        )}
                                        {current < allowedSteps.length - 1 && (
                                            <Fragment>
                                                <Show when={step?.status !== 'finished'}>
                                                    <Popconfirm
                                                        placement="bottom"
                                                        title="Tem certeza que deseja avançar para a próxima etapa?"
                                                        description="Avançando para a próxima etapa, esta será bloqueada para edições. Verifique as informações antes de prosseguir."
                                                        okText="Sim, tenho certeza"
                                                        cancelText="Continuar depois"
                                                        onConfirm={() => next(true)}
                                                        onCancel={() => next(false)}

                                                    >
                                                        <Button type="primary">
                                                            Próxima etapa
                                                        </Button>
                                                    </Popconfirm>
                                                </Show>
                                                <Show when={step?.status === 'finished'}>
                                                    <Button type="primary" onClick={() => next(false)}>
                                                        Próxima etapa
                                                    </Button>
                                                </Show>
                                            </Fragment>
                                        )}
                                        {current === allowedSteps.length - 1 && step?.status !== 'finished' && (
                                            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                                                Finalizar
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            : <Card loading />
                    }
                </Layout>
            )}
        </SetupContextProvider>
    );
};