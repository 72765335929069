import React, { useEffect, useState } from 'react';

import {
    App,
    Button,
    Divider,
    Form,
    FormItemProps,
    Input,
    Modal,
    Popconfirm,
    Select,
    SelectProps,
    Upload,
    UploadFile
} from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { UploadOutlined } from '@ant-design/icons';
import API from 'lib/API';
import { useChatbot } from 'lib/providers/ChatbotContextProvider';
import { deleteChatbot, updateChatbot } from 'services/Chatbot.service';

type Values = {
    parent_id: Chatbot.Model['parent_id'],
    number: Chatbot.Model['number'],
    type: Chatbot.Model['type'],
    title: Chatbot.Model['title'],
    body: Chatbot.Model['body'],
    phone: Chatbot.Model['phone'],
    phone_name: Chatbot.Model['phone_name'],
    help_text: Chatbot.Model['help_text'],
    upload: [UploadFile],
};

type Options = NonNullable<SelectProps['options']>;

export function EditChatbotModal() {
    const [type, setType] = useState<string>();
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const { chatbot, chatbotSelected, setChatbotId, setIsEditModalVisible, fetchChatbot } = useChatbot();

    if (!chatbotSelected)
        throw new Error('Value of the `chatbotSelected` property is unknown');

    const app = App.useApp();
    const [form] = Form.useForm<Values>();

    const close = () => {
        setIsEditModalVisible(false);
        setChatbotId(null);
        fetchChatbot();
    };

    const onDelete = async () => {
        setIsSending(true);
        const response = await deleteChatbot(chatbotSelected.id);
        app.notification.open(response);
        setIsSending(false);
        close();
    };

    const onFinish = async ({ parent_id, number, type, title, body, help_text, upload, phone, phone_name }: Values) => {
        setIsSending(true);

        if (type === 'file') {
            const file = upload[0];

            if (!file.response && body === '')
                throw new Error('Value of the `response` property is unknown');
            else {
                body = file.response;
            }
        }

        const response = await updateChatbot(chatbotSelected.id, {
            parent_id,
            number,
            type,
            title,
            body,
            phone,
            phone_name,
            help_text
        });

        setIsSending(false);

        app.notification.open(response);

        close();
    };

    /** @see https://github.com/ant-design/ant-design/issues/21563 */
    const normFile: FormItemProps['getValueFromEvent'] = (event) => {
        if (Array.isArray(event))
            return event;

        return event?.fileList;
    };

    const options = (): Options => {
        const data = chatbot.filter(o => o.type === 'parent' && o.parent_id === null);
        const options: Options = [];

        const createOption = (data: Chatbot.Model[], labelparent_id: string | null = null) => {
            data.forEach(option => {
                options.push({
                    value: option.id,
                    label: labelparent_id ? `${labelparent_id} > ${option.title}` : option.title
                });

                if (option.children.length > 0)
                    createOption(option.children.filter(o => o.type === 'parent'), option.title);
            });
        };

        createOption(data);

        return options;
    };

    const nextNumber = () => {
        const parent_id = form.getFieldValue('parent_id');

        if (parent_id) {
            // eslint-disable-next-line eqeqeq
            const options = chatbot.find(o => o.id == parent_id)?.children ?? [];
            form.setFieldValue('number', Math.max(...options.map(o => o.number) ?? 0, 0) + 1);
        }
        else
            form.setFieldValue('number', Math.max(...chatbot.map(o => o.number) ?? 0, 0) + 1);
    };

    const fieldsByType = () => {
        switch (type) {
        case 'text':
            return <Form.Item
                name="body"
                label="Mensagem que será exibida ao condômino"
                rules={[{ required: true, message: 'Por favor, preencha o campo.' }]}
            >
                <TextArea />
            </Form.Item>;
        case 'file':
            return <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                label="Faça o upload do arquivo"
                tooltip="São aceitos somente arquivos em PDF"
                rules={[{ required: true, message: 'Por favor, insira um arquivo.' }]}
            >
                <Upload accept="application/pdf" action={`${API.api_url}/file/upload`} maxCount={1}>
                    <Button icon={<UploadOutlined />}>
                            Carregar
                    </Button>
                </Upload>
            </Form.Item>;
        case 'talk':
            return <>
                <Form.Item
                    name="phone_name"
                    label="Nome de quem recebe o recado do condômino"
                    rules={[{ required: true, message: 'Por favor, preencha o campo.' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Número que o recado deve ser encaminhado"
                    tooltip="O número deve ser digitado com DDD."
                    rules={[{ required: true, message: 'Por favor, preencha o campo.' }]}
                >
                    <Input placeholder='DDD999999999' />
                </Form.Item>

                <Form.Item
                    name="body"
                    label="Orientação para que o condômino escreva seu recado de forma adequada."
                    rules={[{ required: true, message: 'Por favor, preencha o campo.' }]}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name="help_text"
                    label="Mensagem de retorna para o condômino, após envio da mensagem."
                    rules={[{ required: true, message: 'Por favor, preencha o campo.' }]}
                >
                    <TextArea />
                </Form.Item>
            </>;
        default:
            return null;
        }
    };

    useEffect(() => {
        nextNumber();
        const {
            parent_id,
            number,
            type,
            title,
            body,
            help_text,
            phone,
            phone_name
        } = chatbotSelected;

        const file: UploadFile = {
            uid: body,
            name: 'anexo.pdf',
            url: body,
            status: 'done',
        };

        setType(type);

        form.setFieldsValue({
            parent_id,
            number,
            type,
            title,
            body,
            help_text,
            phone,
            phone_name,
            upload: [file]
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatbotSelected, form]);

    return (
        <Modal
            width={700}
            title="Editar opção"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
            footer={[
                <Popconfirm
                    title="Excluir opção"
                    description="Tem certeza que deseja excluir?"
                    open={isPopconfirmVisible}
                    placement="left"
                    cancelText="Não"
                    okText="Sim"
                    okType="danger"
                    okButtonProps={{ loading: isSending }}
                    onConfirm={onDelete}
                    onCancel={() => setIsPopconfirmVisible(false)}
                >
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => setIsPopconfirmVisible(true)}
                        danger>
                        Remover
                    </Button>
                </Popconfirm>,
                <Button key="back" type="default" onClick={close}>
                    Cancelar
                </Button>,
                <Button
                    type="primary"
                    onClick={form.submit}
                    loading={isSending}
                >
                    Salvar
                </Button>,
            ]}
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createChatbot"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="parent_id"
                    label="Adicionar em submenu"
                >
                    <Select
                        allowClear
                        onChange={nextNumber}
                        options={options()} />
                </Form.Item>

                <Form.Item
                    name="number"
                    label="Número da opção"
                    tooltip="Será o número exibido como opção ao morador."
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input type='number' />
                </Form.Item>

                <Form.Item
                    name="type"
                    label="Tipo da opção"
                    tooltip="O tipo da opção configura como será o retorno para o morador."
                    rules={[{ required: true }]}>
                    <Select onChange={value => setType(value)}>
                        <Select.Option disabled value="issue">Abertura de chamados</Select.Option>
                        <Select.Option disabled value="change">Agendamento de mudanças</Select.Option>
                        <Select.Option value="file">Arquivo</Select.Option>
                        <Select.Option value="talk">Encaminhar WhatsApp</Select.Option>
                        <Select.Option value="parent">Menu</Select.Option>
                        <Select.Option disabled value="reserve">Reserva de ambientes</Select.Option>
                        <Select.Option value="text">Texto</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="title"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={100} />
                </Form.Item>
                {fieldsByType()}
            </Form>
        </Modal>
    );
}
