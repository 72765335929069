import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Result } from 'antd';

export function NotFound() {
    const history = useHistory();

    const button = (
        <Button type="primary" onClick={() => history.goBack()}>
            Voltar para a página anterior
        </Button>
    );

    return (
        <Result
            status="404"
            title="404"
            subTitle="Não foi possível encontrar a página desejada!"
            extra={button}
        />
    );
}
