import React from 'react';
import { useHistory } from 'react-router-dom';

import { 
    Avatar, 
    Dropdown, 
    Layout,
    MenuProps, Select,
    Space,
    Typography
} from 'antd';

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Authentication from 'lib/Authentication';

type Option = {
    value: Client.Model['id'],
    label: Client.Model['name'],
};

export function Header() {
    const history = useHistory();

    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const user = Authentication.getUser();

    const logout = () => {
        Authentication.logout();
        history.push('/');
    };

    const userItems: MenuProps['items'] = [
        {
            key: 'account',
            label: 'Minha conta',
            onClick: () => history.push('/account'),
        },
        {
            key: 'logout',
            label: 'Desconectar',
            onClick: logout,
        }
    ];

    const clients: Option[] = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const handleFilter = (input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleSelect = (value: Client.Model['id']) => {
        Authentication.setCurrentClientId(value);
        history.go(0); // Refresh page
    };

    return (
        <Layout.Header style={{ padding: 0, paddingRight: 30, background: '#fff' }}>
            <Space align='center' style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    isMobile
                        ? null
                        : <Typography.Title className='client-title' level={3} style={{ margin: 5, marginLeft: 35 }}>
                            {
                                Authentication.getClients().find(c =>
                                    // eslint-disable-next-line eqeqeq
                                    c.id == Authentication.getCurrentClientId())?.name
                            }
                        </Typography.Title>
                }
                <Space align='center' style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
                    <Select
                        showSearch
                        filterOption={handleFilter}
                        style={{ width: '200px' }}
                        defaultValue={Authentication.getCurrentClientId()}
                        options={clients}
                        onSelect={handleSelect}
                    />

                    <Dropdown menu={{ items: userItems }}>
                        <Space style={{ cursor: 'pointer' }}>
                            <Avatar
                                size={38}
                                src={user.personal?.picture}
                                icon={<UserOutlined />}
                            />
                            {
                                isMobile
                                    ? user.personal?.name.split(' ')[0]
                                    : user.personal?.name
                            }
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </Space>
            </Space>
        </Layout.Header>
    );
}
