import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { Author } from 'pages/issue/Columns';
import * as XLSX from 'xlsx';

type IssueExcel = Array<{}>;

export const prepareToDownload = (issues: Issue.With<
    | 'user'
    | 'user_who_resolved'
    | 'user_responsible'
    | 'supplier'
    | 'issueType'
    | 'client'
>[]) => {
    const issuesClosed = issues.filter(issue => issue.status === 'closed')
        .map(issue => ({
            'ID': issue.id,
            'Aberto em': dayjs(issue.creation_date).format('DD/MM/YYYY'),
            'Aberto por': Author(issue),
            'Responsável': issue.user_responsible?.name,
            'Categoria': issue.issue_type.name,
            'Prazo estimado para finalização': issue.deadline
                ? dayjs(issue.deadline).format('DD/MM/YYYY')
                : undefined,
            'Assunto': issue.subject,
            'Descrição': issue.description,
            'Data de finalização': dayjs(issue.resolutionDate).format('DD/MM/YYYY HH:mm'),
            'Solução': issue.finalization,
            'Resolvido em': `${dayjs().diff(dayjs(issue.creation_date), 'day')} dia(s).`
        }));

    const issuesInProgress = issues.filter(issue => issue.status === 'in_progress')
        .map(issue => ({
            'ID': issue.id,
            'Aberto em': dayjs(issue.creation_date).format('DD/MM/YYYY'),
            'Aberto por': Author(issue),
            'Responsável': issue.user_responsible?.name,
            'Categoria': issue.issue_type.name,
            'Prazo estimado para finalização': issue.deadline
                ? dayjs(issue.deadline).format('DD/MM/YYYY')
                : undefined,
            'Assunto': issue.subject,
            'Descrição': issue.description,
            'Data de finalização': '',
            'Solução': issue.finalization,
            'Resolvido em': ''
        }));

    const issuesWaitingOgm = issues.filter(issue => issue.status === 'waiting_ogm')
        .map(issue => ({
            'ID': issue.id,
            'Aberto em': dayjs(issue.creation_date).format('DD/MM/YYYY'),
            'Aberto por': Author(issue),
            'Responsável': issue.user_responsible?.name,
            'Categoria': issue.issue_type.name,
            'Prazo estimado para finalização': issue.deadline
                ? dayjs(issue.deadline).format('DD/MM/YYYY')
                : undefined,
            'Assunto': issue.subject,
            'Descrição': issue.description,
            'Data de finalização': '',
            'Solução': issue.finalization,
            'Resolvido em': ''
        }));

    const issuesOpened = issues.filter(issue => issue.status === 'opened')
        .map(issue => ({
            'ID': issue.id,
            'Aberto em': dayjs(issue.creation_date).format('DD/MM/YYYY HH:mm'),
            'Aberto por': Author(issue),
            'Responsável': issue.user_responsible?.name,
            'Categoria': issue.issue_type.name,
            'Prazo estimado para finalização': issue.deadline
                ? dayjs(issue.deadline).format('DD/MM/YYYY')
                : undefined,
            'Assunto': issue.subject,
            'Descrição': issue.description,
            'Pendente': `${dayjs().diff(dayjs(issue.creation_date), 'day')} dia(s).`
        }));

    download(issuesClosed, issuesOpened, issuesInProgress, issuesWaitingOgm);
};

const download = (issuesClosed: IssueExcel, issuesOpened: IssueExcel, issuesInProgress: IssueExcel, issuesWaitingOgm: IssueExcel) => {
    const client = Authentication.getClients().find(client => client.id === Authentication.getCurrentClientId());
    const workbook = XLSX.utils.book_new();

    const worksheetClosed = XLSX.utils.json_to_sheet(issuesClosed);
    XLSX.utils.book_append_sheet(workbook, worksheetClosed, 'Resolvidas');

    const worksheetOpened = XLSX.utils.json_to_sheet(issuesOpened);
    XLSX.utils.book_append_sheet(workbook, worksheetOpened, 'Pendentes');

    const worksheetInProgress = XLSX.utils.json_to_sheet(issuesInProgress);
    XLSX.utils.book_append_sheet(workbook, worksheetInProgress, 'Em andamento');

    const worksheetWaitingOgm = XLSX.utils.json_to_sheet(issuesWaitingOgm);
    XLSX.utils.book_append_sheet(workbook, worksheetWaitingOgm, 'Aguardando assembleia');

    const excelBase64 = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

    const link = document.createElement('a');
    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + excelBase64;
    link.download = `${client?.name} - Relatório de chamados.xlsx`;
    link.target = '_blank';

    link.click();
};