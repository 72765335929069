import type { ColumnsType } from 'antd/es/table';

export function getColumns() {
    const Columns: ColumnsType<ClientDevice.Model> = [
        { title: 'Nome', key: 'name', dataIndex: 'name' },
        { title: 'Tipo', key: 'type', dataIndex: 'type' },
        { title: 'Endereço (IP)', key: 'ip', dataIndex: 'ip' }
    ];

    return Columns;
};