import React, { } from 'react';

import { Card } from 'antd';

import { Bar } from '@ant-design/plots';
import { useStats } from 'lib/providers/StatsContextProvider';

export function ChartIssuesPerClient() {
    const { stats, isLoading } = useStats();

    const data = stats.total_issues_per_client ?? [];
    const config = {
        data,
        xField: 'value',
        yField: 'name',
        seriesField: 'name'
    };

    return <Card title="Chamados por condomínio">
        <Bar {...config} loading={isLoading} />
    </Card>;
};