import API from '../lib/Request';
import {
    CreateIssueByClient,
    FindClientByURL,
    FindClientManagerByURL, 
    FindResidentByParams, 
    ListApartmentByClient,
    ListIssueTypesByClient,
    SaveResident
} from './contracts/Client.contract';

export const findClientByURL = (url: Client.Model['url']): Promise<FindClientByURL.Response> => API.get(`/external/client/${url}`);
export const findClientManagerByURL = (url: Client.ClientManager['url']): Promise<FindClientManagerByURL.Response> => API.get(`/external/client_manager/${url}`);

export const findResidentByParams = (body: {
    cpf: Apartment.Resident['cpf'],
    email: Apartment.Resident['email'],
    client_id: Client.Model['id']
}): Promise<FindResidentByParams.Response> => API.post('/external/resident/find', body);

export const listApartmentsByClient = (
    id: Client.Model['id']
): Promise<ListApartmentByClient.Response> => API.get(`/external/apartment/client/${id}`);

export const listIssueTypesByClient = (
    id: Client.Model['id']
): Promise<ListIssueTypesByClient.Response> => API.get(`/external/issue/types/client/${id}`);

export const saveResident = (body: SaveResident.Body): Promise<SaveResident.Response> => API.post('/external/resident', body);

export const createIssueByClient = (
    id: Client.Model['id'],
    body: CreateIssueByClient.Body,
): Promise<CreateIssueByClient.Response> => API.post(`/external/issue/client/${id}`, body);