import React, { useEffect, useState } from 'react';

import { Row, Typography } from 'antd';

import { listClientDevice } from '../../services/ClientDeviceService';
import Content from '../_Common/_Layout/Content';
import List from '../_Common/List';
import { getColumns } from './Columns';

const ClientDeviceIndex: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [clientDevices, setClientDevices] = useState<Array<ClientDevice.Model>>([]);

    const columns = getColumns();

    async function fetchData() {
        setLoading(true);

        const clientDeviceResponse = await listClientDevice();
        if ('status' in clientDeviceResponse)
            return;

        setClientDevices(clientDeviceResponse);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Content>
            <Row className='space-between' align="middle">
                <Typography.Title level={3}>Dispositivos</Typography.Title>
            </Row>
            <List data={clientDevices} columns={columns} loading={isLoading} />
        </Content>
    );

};

export default ClientDeviceIndex;