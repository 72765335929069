import React, {
    createContext,
    ReactNode,
    useEffect,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { findClientManagerByURL } from 'services/Client.service';

type Value = {
    isLoading: boolean,
    client_manager: Client.ClientManager | undefined,
    fetchClientManager: () => Promise<void>
};

type Props = { children: (value: Value) => ReactNode, url: Client.Model['url'] };

const ClientManagerPageContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ClientManagerPageContextProvider({ children, url }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [client_manager, setClientManager] = useState<Client.ClientManager>();

    const app = App.useApp();

    const fetchClientManager = async () => {
        setIsLoading(true);

        const clientManagerResponse = await findClientManagerByURL(url);

        if (!clientManagerResponse.success)
            return handleServiceError(app, clientManagerResponse);

        setIsLoading(false);
        setClientManager(clientManagerResponse.client_manager);
    };


    const value: Value = {
        isLoading,
        client_manager,
        fetchClientManager
    };

    useEffect(() => {
        fetchClientManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ClientManagerPageContext.Provider value={value}>
            {children(value)}
        </ClientManagerPageContext.Provider>
    );
}