import React, { 
    createContext, 
    Dispatch, 
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listNotices } from 'services/Notice.service';

type Value = {
    isLoading: boolean,
    notices: Notice.Model[],
    notice: Notice.Model | null,
    noticeId: Notice.Model['id'] | null,
    setNoticeId: Dispatch<SetStateAction<Value['noticeId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    fetchNotices: () => Promise<void>,
};

type Props = { children: (value: Value) => ReactNode };

const NoticeContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function NoticeContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [notices, setNotices] = useState<Notice.Model[]>([]);
    const [noticeId, setNoticeId] = useState<Notice.Model['id'] | null>(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const app = App.useApp();

    const fetchNotices = async () => {
        setIsLoading(true);

        const response = await listNotices();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setNotices(response.notices);
    };

    const memoizedFetchNotices = useCallback(fetchNotices, [app]);

    useEffect(() => {
        memoizedFetchNotices();
    }, [memoizedFetchNotices]);

    const notice = useMemo(() => {
        if (!noticeId)
            return null;

        const found = notices.find(notice => notice.id === noticeId);

        if (!found)
            throw new Error(`Could not find a notice with id ${noticeId}`);

        return found;

    }, [notices, noticeId]);

    const value: Value = { 
        isLoading, 
        notices,
        notice,
        noticeId,
        setNoticeId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        fetchNotices: memoizedFetchNotices,
    };

    return (
        <NoticeContext.Provider value={value}>
            {children(value)}
        </NoticeContext.Provider>
    );
}

export function useNotice() {
    const context = useContext(NoticeContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `NoticeContextProvider`.');

    return context;
}