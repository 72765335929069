import { createElement } from 'react';

import { TagProps } from 'antd';

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

type DerivedStatusTagProps = Pick<TagProps, 'color' | 'icon' | 'children'>;

export function getIssueDerivedStatus(status: Issue.Model['status'], deadline: Issue.Model['deadline']): Issue.DerivedStatus {
    if (['opened', 'in_progress'].includes(status) && deadline !== null && dayjs().isAfter(dayjs(deadline), 'day'))
        return 'overdue';

    if (status === 'closed')
        return status;

    if (status === 'in_progress')
        return status;

    if (status === 'waiting_ogm')
        return status;

    if (status === 'opened' && deadline === null)
        return status;

    return 'opened';
}

export function getIssueDerivedStatusTagProps(derivedStatus: Issue.DerivedStatus): DerivedStatusTagProps {
    if (derivedStatus === 'closed')
        return { color: 'success', icon: createElement(CheckCircleOutlined), children: 'Resolvido' };

    if (derivedStatus === 'in_progress')
        return { color: 'blue', icon: createElement(CheckCircleOutlined), children: 'Em andamento' };

    if (derivedStatus === 'waiting_ogm')
        return { color: 'yellow', icon: createElement(CheckCircleOutlined), children: 'Aguardando assembleia' };

    if (derivedStatus === 'opened')
        return { color: 'orange', icon: createElement(ClockCircleOutlined), children: 'Pendente' };

    return { color: 'error', icon: createElement(CloseCircleOutlined), children: 'Atrasado' };
}

export function getUserInitials(name: string): string {
    const [firstName, lastName] = name.toUpperCase().split(' ');
    const initials = lastName ? firstName[0] + lastName[0] : firstName[0];

    return initials;

}