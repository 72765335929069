import React, { } from 'react';

import {
    Modal,
    Row,
    Typography
} from 'antd';

import { FilesGalery } from 'components/_Common/FilesGalery';
import { renderStatusMaintenance } from 'components/Overview/PendingMaintenancesCard';
import { formatBRL } from 'lib/helpers/BRL';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { Show } from 'lib/Show';

export function DetailMaintenanceModal() {

    const {
        maintenance,
        setIsDetailModalVisible,
    } = useMaintenance();

    if (!maintenance)
        throw new Error('Value of the `maintenance` property is unknown');

    return (
        <Modal
            title={null}
            footer={false}
            closable={false}
            onCancel={() => setIsDetailModalVisible(false)}
            open
        >
            <Row style={{ marginTop: 0, justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
                <Typography.Title style={{ marginTop: 0 }} level={5}>
                    <span>Manutenção #{maintenance.id} {maintenance.maintenance_type ? ` - ${maintenance.maintenance_type.name}` : null}</span>
                </Typography.Title>
                {renderStatusMaintenance(maintenance)}
            </Row>
            <Typography.Title style={{ marginTop: 10 }} level={5}>Descrição</Typography.Title>
            <Typography.Paragraph><div dangerouslySetInnerHTML={{ __html: maintenance.description }} /></Typography.Paragraph>
            <Show when={maintenance.isFinished}>
                <Typography.Title level={5}>Observações sobre a finalização</Typography.Title>
                <Typography.Paragraph><div dangerouslySetInnerHTML={{ __html: maintenance.solution ?? 'Nenhuma informação.' }} /></Typography.Paragraph>
                <Typography.Paragraph style={{ marginBottom: 5, marginTop: 0 }}>
                    <Typography.Text strong>Fornecedor: </Typography.Text>
                    <Typography.Text>{maintenance.supplier ? maintenance.supplier.name : 'Nenhum fornecedor'}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginTop: 0 }}>
                    <Typography.Text strong>Custos: </Typography.Text>
                    <Typography.Text>{formatBRL(maintenance.cost) ?? '-'}</Typography.Text>
                </Typography.Paragraph>
            </Show>
            <FilesGalery files={maintenance.files} />
        </Modal >
    );
}
