import config from '../config';
import Authentication from './Authentication';
import Request, { JSONHeaders } from './Request';

async function _handleRequest(url: string, methodType: string, dataObject: any = null, headers: any = JSONHeaders) {
    headers = { 
        ...headers,
        'x-authorization-gcondo': Authentication.getToken(),
        'x-client-gcondo': Authentication.getCurrentClientId(),
    };

    return await Request.asyncCall(url, methodType, dataObject, headers);
}

class BackendRequest {
    public api_url = config.api;

    async get(url: string, headers: any = JSONHeaders) {
        return await _handleRequest(url, 'GET', null, headers);
    }

    async post(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(url, 'POST', dataObject, headers);
    }

    async patch(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(url, 'PATCH', dataObject, headers);
    }

    async put(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(url, 'PUT', dataObject, headers);
    }

    async delete(url: string, dataObject: any = null, headers: any = JSONHeaders) {
        return await _handleRequest(url, 'DELETE', dataObject, headers);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BackendRequest();