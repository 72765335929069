import React, { useEffect, useState } from 'react';

import { App, Button, Card, Col, List, Popconfirm, Row, Typography, Upload, UploadFile } from 'antd';
import { OptionProps } from 'antd/es/select';
import { RcFile } from 'antd/es/upload';
import Select from 'antd/lib/select';

import { CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Show } from 'lib/Show';
import { listAccountClients } from 'services/Account.service';
import { importResidents } from 'services/Setup.service';

export function ResidentImportCard() {
    const app = App.useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<UploadFile | null>(null);
    const [clients, setClients] = useState<OptionProps[]>([]);
    const [result, setResult] = useState<Setup.ImportResult>();
    const [clientId, setClientId] = useState<string | null>(null);

    useEffect(() => {
        setIsLoading(true);

        listAccountClients().then(response => {
            if (response.success)
                setClients(response.clients.map(client => ({
                    value: client.id.toString(),
                    label: client.name,
                    children: null
                })));

            setIsLoading(false);
        });
    }, []);

    const handleUpload = async () => {
        if (!file) {
            app.notification.error({ message: 'Por favor, selecione um arquivo primeiro.' });
            return;
        }

        if (!clientId) {
            app.notification.error({ message: 'Por favor, selecione um condomínio para realizar a importação.' });
            return;
        }

        const response = await importResidents(clientId, file as RcFile);

        if (response.success) {
            setResult(response.result);
        }
    };

    const uploadProps = {
        beforeUpload: (file: UploadFile) => {
            setFile(file);
            return false;
        },
        fileList: file ? [file] : [],
    };

    const renderFailedItem = (item: Setup.ResultItem) => (
        <Typography.Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} />
            <Typography.Text strong> Linha {item.line} ({item.resident ?? ''}):</Typography.Text>
            <Typography.Text> {item.reason}</Typography.Text>
        </Typography.Paragraph>
    );

    return (
        <Card>
            <Show when={!result}>
                <Typography.Link href='/assets/sheets/modelo_condominos.xlsx' target='_blank'>Baixar planilha modelo <DownloadOutlined /></Typography.Link>
                <Row style={{ marginTop: 16 }}>
                    <Col span={24}>
                        <Select
                            allowClear
                            value={clientId}
                            options={clients}
                            loading={isLoading}
                            disabled={isLoading}
                            style={{ width: '100%' }}
                            placeholder="Selecione um condomínio"
                            onChange={(value: string) => setClientId(value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Upload.Dragger {...uploadProps} style={{ marginTop: 16 }}>
                            Realizar upload do arquivo preenchido <UploadOutlined />
                        </Upload.Dragger>
                    </Col>
                </Row>
                <Row style={{ paddingTop: 45 }}>
                    <Col span={24}>
                        <Popconfirm
                            title="Tem certeza que deseja realizar a importação?"
                            description="Após a confirmação, essa ação não poderá ser cancelada."
                            onConfirm={handleUpload}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="primary"
                                disabled={!file}
                            >
                                Importar
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Show>
            {/* eslint-disable-next-line eqeqeq */}
            <Show when={result != undefined}>
                <div className="desc">
                    <Typography.Paragraph>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Resultado da importação:
                        </Typography.Text>
                    </Typography.Paragraph>

                    {/* eslint-disable-next-line eqeqeq */}
                    <Show when={result !== undefined && result.success.length > 0 && result.failed.length == 0}>
                        <CheckCircleOutlined color='green' /> Todos os condôminos foram importados com sucesso.
                    </Show>

                    <Show when={result !== undefined && result.failed.length > 0}>
                        <List
                            dataSource={result?.failed}
                            renderItem={renderFailedItem}
                        />
                    </Show>

                    <Show when={result !== undefined}>
                        <Typography.Paragraph style={{ marginTop: 16 }}>
                            <Typography.Link onClick={() => setResult(undefined)} strong>Clique aqui para realizar uma nova importação.</Typography.Link>
                        </Typography.Paragraph>
                    </Show>
                </div>
            </Show>
        </Card>
    );
};