import React from 'react';

import { ColumnsType } from 'antd/es/table';

import dayjs from 'dayjs';
import { formatBRL } from 'lib/helpers/BRL';

import { ConsumptionActionsCell } from './ConsumptionActionsCell';

export function Columns(consumptions: Consumption.Model[]) {

    const columns: ColumnsType<Consumption.Reading> = [
        {
            title: 'Consumo',
            dataIndex: 'consumption_id',
            render: (_, row) => row.consumption.type_consumption.name,
            filters: consumptions.map(consumption => (
                {
                    value: consumption.id,
                    text: consumption.type_consumption.name
                }
            )),
            onFilter: (value, row) => row.consumption_id === value,
            filterSearch: true
        },
        {
            title: 'Unidade',
            dataIndex: 'apartment_id',
            render: (_, row) => row.apartment
                ? `${row.apartment.tower.name} - ${row.apartment.name}`
                : 'Coletivo'
        },
        {
            title: 'Data da leitura',
            dataIndex: 'reading_date',
            render: value => dayjs(value).format('DD/MM/YYYY')
        },
        {
            title: 'Última leitura',
            dataIndex: 'last_read'
        },
        {
            title: 'Leitura atual',
            dataIndex: 'current_reading'
        },
        {
            title: 'Consumo do mês',
            dataIndex: 'monthly_consumption'
        },
        {
            title: 'Valor do consumo',
            dataIndex: 'monthly_value_consumption',
            render: (_, record) => formatBRL(record.monthly_consumption * record.consumption.price)
        },
        {
            title: 'Registro',
            dataIndex: 'picture',
            render: value => value
                ? <a href={value} target='_blank' rel="noreferrer">Clique para baixar</a>
                : 'Sem registro'
        },

        {
            width: '100px',
            align: 'center',
            render: (_, record) => <ConsumptionActionsCell record={record} />,
        },
    ];

    return columns;

}