import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { AuthRoutes } from 'components/_Common/_Routes/Routes';


export function getColumns(setOpen: Function, setApartmentId: Function) {
    const Columns: ColumnsType<Apartment.Model> = [
        { title: 'Bloco', dataIndex: 'towerName', render: (_: any, a) => a.tower.name },
        { title: 'Unidade', dataIndex: 'name', key: 'name' },
        { title: 'Tipo de unidade', dataIndex: 'apartment_type_id', render: (_: any, a) => a.apartment_type ? a.apartment_type.name : '' },
        { title: 'Proprietário principal', dataIndex: 'mainOwnerId', render: (_: any, a) => a.main_owner ? a.main_owner.name : '' },
        {
            key: 'action',
            width: '200px',
            render: (_: any, apartment) => { 
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const history = useHistory();

                const handleView = () => {
                    const path = AuthRoutes.apartmentView.replace(':id', apartment.id.toString());
                    history.push(path);
                };

                const handleEdit = () => {
                    setOpen(true);
                    setApartmentId(apartment.id);
                };
                
                return (
                    <Space size="middle">
                        <Button size="small" onClick={handleView} title="Detalhes">
                            <EyeOutlined />
                        </Button>

                        <Button size="small" onClick={handleEdit} title="Editar">
                            <EditOutlined />
                        </Button>
                    </Space>
                );
            }
        }
    ];

    return Columns;
};