import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Result } from 'antd';

export function Forbidden() {
    const history = useHistory();

    const button = (
        <Button type="primary" onClick={() => history.goBack()}>
            Voltar para a página anterior
        </Button>
    );

    return (
        <Result
            status="403"
            title="403"
            subTitle="Você não tem acesso a essa página!"
            extra={button}
        />
    );
}
