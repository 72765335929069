import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card, Carousel, Col, Flex, Row, Typography } from 'antd';

import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { Layout } from 'components/Client/Layout';
import { ClientContextProvider, ClientContextProviderProps } from 'lib/providers/ClientContextProvider';
import { Show } from 'lib/Show';

type Params = { url_client: NonNullable<Client.Model['url']> };

type Props = RouteComponentProps<Params>;

type File = { filename: string, url: string };

const IMAGE_FILE_EXTENSIONS = ['jpg', 'png', 'jpeg'] as const;

const isValidImageFileExtension = (value: any) => IMAGE_FILE_EXTENSIONS.includes(value.toLowerCase());

const isValidImageFile = ({ url }: File) => {
    const lowerCaseUrl = url.toLowerCase();

    const splittedLowerCaseUrl = lowerCaseUrl.split('.');

    const extension = splittedLowerCaseUrl.length > 1
        ? splittedLowerCaseUrl[splittedLowerCaseUrl.length - 1]
        : null;

    if (!extension)
        return false;

    const isValid = isValidImageFileExtension(extension);

    return isValid;
};

export function Issues({ match }: Props) {
    const url = match.params.url_client;
    const IssueDetail = ({ issue }: { issue: Issue.Model }) => {
        return <Col xs={24} sm={24} md={8} lg={8} style={{ marginBottom: 15 }}>
            <Card
                styles={{
                    body: {
                        paddingTop: 15,
                        paddingBottom: 15
                    }
                }}
                cover={
                    <Carousel autoplay>
                        {issue.issue_file.filter(file => isValidImageFile(file)).map((image, index) => (
                            <div key={index}>
                                <img src={image.url} alt={`carousel-img-${index}`} style={{ width: '100%', height: '250px' }} />
                            </div>
                        ))}
                    </Carousel>
                }
            >
                <Typography.Text style={{ fontSize: 16 }} strong>
                    {issue.subject}
                </Typography.Text>
                <Typography.Paragraph strong>
                    <Typography.Text strong>
                        Categoria:
                    </Typography.Text> {issue.issue_type.name}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ marginBottom: 0 }} strong>
                    Descrição
                </Typography.Paragraph>
                <Typography.Paragraph>
                    {issue.description}
                </Typography.Paragraph>
                <Show when={issue.status === 'closed'}>
                    <Typography.Paragraph style={{ marginBottom: 0 }} strong>
                        Solução
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {issue.finalization}
                    </Typography.Paragraph>
                </Show>
            </Card>
        </Col>;
    };

    const children: ClientContextProviderProps['children'] = ({ isLoading, loadClient, issues }) => {
        if (isLoading)
            return <LoadingGcondo />;

        const client = loadClient();

        return (
            <Layout backgroundColor={client.client_manager.primary_color}>
                <Row style={{ marginBottom: 0, alignItems: 'center' }}>
                    <Col>
                        <Flex align="center" gap="middle" justify="space-between" id="header">
                            <img
                                src={client.client_manager.logo}
                                alt={client.client_manager.name}
                                style={{ marginBottom: 24 }}
                                width="196"
                            />
                        </Flex>
                    </Col>
                    <Col>
                        <Typography.Title style={{ marginBottom: 0, marginTop: 0 }} level={2}>Portal do condômino</Typography.Title>
                        <Typography.Title style={{ marginTop: 0 }} level={3}>{client.name}</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    {issues.map(issue => <IssueDetail issue={issue} />)}
                </Row>
            </Layout>
        );
    };

    return (
        <ClientContextProvider url={url}>
            {children}
        </ClientContextProvider>
    );
}
