import React, { useEffect, useState } from 'react';

import {
    App,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    SelectProps,
    UploadFile
} from 'antd';

import dayjs from 'dayjs';
import { DATE_SERVER_FORMAT } from 'internal-constants';
import { DynamicSelect } from 'lib/DynamicSelect';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { usePayment } from 'lib/providers/PaymentContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { ParsedFile } from 'services/contracts/Payment.contract';
import { createCategory, updatePayment, uploadFile } from 'services/Payment.service';

type SelectOptions = NonNullable<SelectProps['options']>;

type Values = {
    name: string,
    type: Payment.Type,
    payment_category_id: number,
    due_date: string,
    remember_to_pay_before_in_days: number,
    notify_each_in_days: number,
    files: UploadFile[],
    generate_next_payment_in_days?: number,
    category: number,
    value: number,
}

export default function EditPaymentModal() {
    const [paymentType, setPaymentType] = useState('single');
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm();

    const {
        setIsEditModalVisible,
        fetchPayments,
        payment,
        setPaymentId,
        paymentId,
        categories,
        fetchCategories,
    } = usePayment();

    const handleTypeChange = (value: Payment.Type) => {
        setPaymentType(value);
    };

    const app = App.useApp();

    const close = () => {
        setIsEditModalVisible(false);
        setPaymentId(undefined);
    };

    const onFinish = async () => {
        if (paymentId === undefined)
            throw new Error('Payment not found!');

        setIsSending(true);
        const values = await form.validateFields();

        const body = {
            name: values.name,
            type: values.type,
            value: values.value,
            payment_category_id: values.category,
            due_date: values.due_date.format(DATE_SERVER_FORMAT),
            remember_to_pay_before_in_days: values.remember_to_pay_before_in_days,
            notify_each_in_days: values.notify_each_in_days,
            generate_next_payment_in_days: values.generate_next_payment_in_days,
            files: [],
            category: values.category
        };

        const response = await updatePayment(paymentId, body);

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles: ParsedFile[] = values.files?.map((file: UploadFile): ParsedFile => ({
            filename: file.name,
            url: file.response ?? file.url,
            payment_id: paymentId,
        })) ?? [];

        const promises = parsedFiles.map(file => uploadFile(file));

        const files = await Promise.all(promises);

        const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

        if (firstErrorFile !== undefined)
            return handleServiceError(app, firstErrorFile);

        await fetchPayments();
        close();
    };


    useEffect(() => {
        if (payment === null)
            throw new Error('Payment not found!');

        const files: UploadFile[] = payment.files?.map(file => (
            {
                uid: file.url,
                name: file.filename,
                url: file.url,
                status: 'done',
            }
        ));

        form.setFieldsValue({
            name: payment.name,
            type: payment.type,
            due_date: dayjs(payment.due_date),
            remember_to_pay_before_in_days: payment.remember_to_pay_before_in_days,
            notify_each_in_days: payment.notify_each_in_days,
            generate_next_payment_in_days: payment.type === 'recurrent' ? payment.generate_next_payment_in_days : 1,
            category: payment.payment_category_id,
            value: payment.value,
            files: files,
        });

        setPaymentType(payment.type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment]);

    const handleNewCategoryOption = async (option: string) => {
        setIsSending(true);

        // eslint-disable-next-line eqeqeq
        if (option == '') {
            setIsSending(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva a categoria que deseja inserir' });
        }

        await createCategory({ name: option });

        await sleep(1000);

        setIsSending(false);

        const fetchedCategories = await fetchCategories();
        const newCategory = fetchedCategories?.find(category => category.name === option);

        form.setFieldValue('category', newCategory?.id);
    };

    const parsedCategories: SelectOptions = categories.map(({ id, name }) => ({ value: id, label: name }));

    const parsedTypes = [
        { value: 'single', label: 'Simples' },
        { value: 'recurrent', label: 'Recorrente' },
        { value: 'immediate_recurrent', label: 'Recorrente imediato' },
        { value: 'installment', label: 'Parcelado' },
    ];

    return (
        <Modal
            centered
            title="Editar pagamento"
            destroyOnClose={true}
            onCancel={close}
            confirmLoading={isSending}
            okText="Salvar"
            open={true}
            onOk={onFinish}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>

                <Form.Item name="name" label="Título do pagamento" rules={[{ required: true, message: 'Descreva o título do pagamento' }]}>
                    <Input />
                </Form.Item>

                <Form.Item<Values>
                    name="category"
                    label="Categoria"
                    rules={[{ required: true, message: 'Por favor, selecione a categoria do chamado.' }]}
                >
                    <DynamicSelect
                        options={parsedCategories}
                        dropdown={{ placeholder: 'Insira aqui uma nova categoria', onNewOption: handleNewCategoryOption }}
                    />
                </Form.Item>

                <Form.Item name="type" label="Tipo do pagamento" rules={[{ required: true, message: 'Selecione o tipo do pagamento' }]}>
                    <Select onChange={handleTypeChange} options={parsedTypes} />
                </Form.Item>

                <Form.Item
                    name="value"
                    label="Valor"
                    rules={[{ required: true, message: 'Por favor, selecione um valor.' }]}
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item name="due_date" label="Data de vencimento" rules={[{ required: true, message: 'Selecione a data de vencimento' }]}>
                    <DatePicker format={'DD/MM/YYYY'} disabledDate={(current) => current && current < dayjs().endOf('day')} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="remember_to_pay_before_in_days" label="Com quantos dias de antecedência deseja ser lembrado deste pagamento?" rules={[{ required: true, message: 'Informe os dias para lembrar antes da data de vencimento' }]}>
                    <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name="notify_each_in_days" label="Gostaria de receber notificações sobre este pagamento durante quantos dias?" rules={[{ required: true, message: 'Informe os dias para notificação' }]}>
                    <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>

                <Show when={paymentType === 'recurrent'}>
                    <Form.Item name="generate_next_payment_in_days" label="Informe com quantos dias de antecedência à data de vencimento você deseja que o próximo pagamento seja criado automaticamente">
                        <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                </Show>

                <UploadField name='files' type='picture' multiple />

            </Form >
        </Modal >
    );
}