import React, { useEffect, useState } from 'react';

import { 
    App,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Select, 
    SelectProps,
    UploadFile 
} from 'antd';

import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useDocument } from 'lib/providers/DocumentContextProvider';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { updateDocument } from 'services/Document.service';

type Values = {
    client: Client.Model['id'],
    title: string,
    type: Document.Type['id'],
    situation: Document.Model['situation'],
    share_with_resident: Document.Model['share_with_resident'],
    files: UploadFile[],
};

type Options = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function EditDocumentModal() {
    const [isSending, setIsSending] = useState(false);

    const {
        document,
        setIsEditModalVisible,
        setDocumentId,
        fetchDocuments,
        types,
    } = useDocument();

    if (!document)
        throw new Error('Value of the `document` property is unknown');

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    useEffect(() => {
        const { 
            title,
            document_type_id,
            situation,
            share_with_resident,
            files,
        } = document;

        const parsedFiles: UploadFile[] = files.map(({ url, filename }) => ({
            uid: url,
            url: url,
            name: filename,
            status: 'done',
        }));

        form.setFieldsValue({
            client: document.client_id,
            title, 
            type: document_type_id,
            situation,
            share_with_resident,
            files: parsedFiles,
        });
    }, [document, form]);

    const close = () => {
        setIsEditModalVisible(false);
        setDocumentId(null);
    };

    const onFinish = async ({ client, title, type, situation, share_with_resident, files }: Values) => {
        setIsSending(true);

        const parsedFiles = files.map(file => ({ 
            // The `response` property only exists when a new file has been uploaded,
            // while `url` only exists when the initial file has not ben modified
            url: file.response ?? file.url, 
            filename: file.name
        }));

        const response = await updateDocument(document.id, { 
            client_id: client,
            title,
            document_type_id: type,
            situation,
            share_with_resident,
            files: parsedFiles,
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchDocuments();
    };

    const parsedTypes: Options = types.map(({ id, name }) => ({ value: id, label: name }));

    const parsedClients: Options = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Modal
            title="Editar documento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Editar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editDocument"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item<Values>
                    name="client"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select 
                        options={parsedClients}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>
                
                <Form.Item<Values>
                    name="title"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item<Values>
                    name="type"
                    label="Tipo"
                    rules={[{ required: true, message: 'Por favor, selecione um tipo.' }]}
                >
                    <Select options={parsedTypes} />
                </Form.Item>

                <Form.Item<Values>
                    name="situation"
                    label="Situação"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value="invalid">Inválido</Radio>
                        <Radio value="valid">Válido</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item<Values>
                    name="share_with_resident"
                    label="Disponibilizar no aplicativo dos condôminos?"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>Sim</Radio>
                        <Radio value={0}>Não</Radio>
                    </Radio.Group>
                </Form.Item>

                <UploadField
                    name="files"
                    type="picture"
                    multiple={true}
                    rules={[{ required: true, message: 'Por favor, insira ao menos um arquivo.' }]}
                />
            </Form>
        </Modal>
    );
}
