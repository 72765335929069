/** @example '99999999999999' to '99.999.999/9999-99' */
export const makeCnpj = (value: string) => {
    const cnpj = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpj;
};

/** @example '99.999.999/9999-99' to '99999999999999' */
export const makeRawCnpj = (value: string | null | undefined): string => {

    if (!value) return '';
    /** Matches any character that is not a digit and replace with empty string */
    const cnpj = value.replace(/[^\d]/g, '');

    return cnpj;
};