import React, { Component } from 'react';

import { Divider, Form, Input, Modal, Radio, Upload } from 'antd';
import { FormInstance } from 'antd/lib/form';
import ImgCrop from 'antd-img-crop';

import API from 'lib/API';

import { PetInterface } from '../../_Common/_Interfaces/Pet';


type State = {
    saving: boolean;
    fileList: any;
};

type Props = {
    pet: PetInterface;
    apartmentId: number;
    isVisible: boolean;
    hideModalForm: Function;
};

class FormPet extends Component<Props, State> {
    state: State = {
        saving: false,
        fileList: []
    };

    private formRef = React.createRef<FormInstance>();
    private propsForSmallScreen = {
        sm: 24,
        xs: 24,
        md: 24
    };

    onCancel = () => this.props.hideModalForm();

    saveItem = async (pet: PetInterface) => {
        this.setState({ saving: true });

        pet.apartmentId = this.props.apartmentId;

        if (this.props.pet.id)
            await API.put(`/default/pet/${this.props.pet.id}`, pet);
        else
            await API.post('/default/pet', pet);

        this.setState({ saving: false, fileList: [] }, () => this.props.hideModalForm(true));
    };


    onOk = async () => {
        const values = await this.formRef.current?.validateFields();
        await this.saveItem(values);
        this.formRef.current?.resetFields();
    };

    onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
    };

    onChange = (file: any) => {
        if (file.file.status === 'done')
            this.formRef.current?.setFieldsValue({ picture: file.file.response });

        this.setState({ fileList: file.fileList });
    };

    onRemove = () => this.formRef.current?.setFieldsValue({ picture: null });

    componentDidUpdate(prevProps: Props) {
        const { pet } = this.props;
        
        if (pet !== prevProps.pet) {
            if (pet.id) {
                this.setState({
                    fileList: [
                        {
                            uid: pet.id,
                            name: pet.picture,
                            url: pet.picture,
                            status: 'done'
                        }
                    ]
                });
            } else
                this.setState({ fileList: [] });


        }
    };

    render() {
        const { isVisible, pet } = this.props;
        const { saving, fileList } = this.state;

        return (
            <Modal
                centered
                title={pet.id ? 'Editar pet' : 'Novo pet'}
                destroyOnClose={true}
                open={isVisible}
                confirmLoading={saving}
                onOk={this.onOk}
                onCancel={this.onCancel}
                okText="Salvar"
            >
                <Divider />
                <Form ref={this.formRef} layout="vertical" initialValues={pet} onFinish={this.onOk}>
                    <ImgCrop>
                        <Upload
                            action={`${API.api_url}/file/upload`}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={this.onChange}
                            onRemove={this.onRemove}
                            onPreview={this.onPreview}
                            multiple={false}
                            maxCount={1}
                        >
                            Foto
                        </Upload>
                    </ImgCrop>

                    <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Digite o nome' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="type" label="Seu pet é?" >
                        <Radio.Group defaultValue={pet.type}>
                            <Radio value="dog" >Cachorro</Radio>
                            <Radio value="cat" >Gato</Radio>
                            <Radio value="other" >Outros</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="apartmentId" style={{ height: 0 }}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="picture" style={{ height: 0 }}>
                        <Input type="hidden" />
                    </Form.Item>
                </Form>
            </Modal >
        );
    }
}

export default FormPet;
