import React, { useCallback, useEffect, useState } from 'react';

import { App, Button, Row, Typography } from 'antd';

import Content from '../../components/_Common/_Layout/Content';
import List from '../../components/_Common/List';
import { CreateAmbientModal, EditAmbientModal } from '../../components/Ambient';
import { AmbientDatesModal } from '../../components/Ambient/AmbientDatesModal';
import { handleServiceError } from '../../lib/helpers/ServiceHelper';
import { AmbientContext, Value } from '../../lib/hooks/useAmbient';
import { Show } from '../../lib/Show';
import { sleep } from '../../lib/Sleep';
import { findAmbient, listAmbients } from '../../services/Ambient.service';
import { Columns } from './Columns';

export function Ambients() {
    const [ambients, setAmbients] = useState<Ambient.SimpleModel[]>([]);
    const [ambientId, setAmbientId] = useState<Ambient.Model['id'] | null>(null);
    const [ambient, setAmbient] = useState<Ambient.Model | null>(null);
    const [isCreateAmbientModalVisible, setIsCreateAmbientModalVisible] = useState(false);
    const [isEditAmbientModalVisible, setIsEditAmbientModalVisible] = useState(false);
    const [isAmbientDatesModalVisible, setIsAmbientDatesModalVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const app = App.useApp();

    const fetchAmbients = useCallback(async () => {
        setIsFetching(true);

        const response = await listAmbients();

        // Improves UX
        await sleep(250);

        setIsFetching(false);

        if (!response.success)
            return handleServiceError(app, response);

        setAmbients(response.ambients);
    }, [app]);

    useEffect(() => {
        fetchAmbients();
    }, [fetchAmbients]);

    const handleAmbientChange = useCallback(async () => {
        // Reset "ambient" state when id is null
        if (!ambientId)
            return setAmbient(null);

        setIsFetching(true);

        const response = await findAmbient(ambientId);

        // Improves UX
        await sleep(250);

        setIsFetching(false);

        if (!response.success)
            return handleServiceError(app, response);

        setAmbient(response.ambient);
    }, [ambientId, app]);

    useEffect(() => {
        handleAmbientChange();
    }, [handleAmbientChange, ambientId]);

    const value: Value = {
        ambients,
        fetchAmbients,
        isCreateAmbientModalVisible,
        setIsCreateAmbientModalVisible,
        ambient,
        ambientId,
        setAmbientId,
        isEditAmbientModalVisible,
        setIsEditAmbientModalVisible,
        isAmbientDatesModalVisible,
        setIsAmbientDatesModalVisible,
    };

    return (
        <AmbientContext.Provider value={value}>
            <Content>
                <Row justify="space-between" align="middle">
                    <Typography.Title level={3}>
                        Ambientes
                    </Typography.Title>

                    <Button type="primary" onClick={() => setIsCreateAmbientModalVisible(true)}>
                        Cadastrar
                    </Button>
                </Row>

                <List
                    columns={Columns}
                    data={ambients}
                    loading={isFetching}
                />
            </Content>

            <Show when={isCreateAmbientModalVisible}>
                <CreateAmbientModal />
            </Show>

            <Show when={isEditAmbientModalVisible}>
                <EditAmbientModal />
            </Show>

            <Show when={isAmbientDatesModalVisible}>
                <AmbientDatesModal />
            </Show>
        </AmbientContext.Provider>
    );
}
