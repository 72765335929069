import React, { useState } from 'react';

import { App, Col, Divider, Form, Input, InputNumber, Modal, Radio, Row } from 'antd';

import { TeamOutlined } from '@ant-design/icons';

import { handleServiceError } from '../../lib/helpers/ServiceHelper';
import { useAmbient } from '../../lib/hooks/useAmbient';
import { sleep } from '../../lib/Sleep';
import { updateAmbient } from '../../services/Ambient.service';

type Values = {
    name: string,
    description: string | undefined,
    approval_required_to_reservation: boolean,
    maximum_capacity: number | null | undefined,
    price: number | null | undefined,
};

export function EditAmbientModal() {
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();
    const app = App.useApp();

    const {
        ambient,
        setAmbientId,
        setIsEditAmbientModalVisible,
        fetchAmbients,
    } = useAmbient();

    // Wait ambient to be fetched in "Ambients.page.tsx"
    if (ambient === null)
        return null;

    const onFinish = async ({ description, maximum_capacity, price, ...values }: Values) => {
        setIsSending(true);

        const body: Parameters<typeof updateAmbient>['1'] = {
            ...values,
            description: description ?? null,
            maximum_capacity: maximum_capacity ?? null,
            price: price?.toString() ?? null,
        };

        const response = await updateAmbient(ambient.id, body);

        // Improves UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIsEditAmbientModalVisible(false);
        setAmbientId(null);
        fetchAmbients();
    };

    const onCancel = () => {
        setIsEditAmbientModalVisible(false);
        setAmbientId(null);
    };

    return (
        <Modal
            open
            width={610}
            title="Editar ambiente"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Editar"
            onCancel={onCancel}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createAmbient"
                layout="vertical"
                autoComplete="off"
                initialValues={ambient}
            >
                <Form.Item
                    name="name"
                    label="Nome"
                    rules={[{ required: true, message: 'Por favor, digite um nome.' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Descrição"
                >
                    <Input.TextArea />
                </Form.Item>

                <Row>
                    <Col span="12">
                        <Form.Item
                            name="maximum_capacity"
                            label="Capacidade máxima"
                            tooltip="Caso não queira informar a capacidade máxima, deixe o campo em branco."
                        >
                            <InputNumber
                                addonBefore={<TeamOutlined />}
                                precision={0}
                                min={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span="12">
                        <Form.Item
                            name="price"
                            label="Preço"
                            tooltip="Caso não queira informar um valor, deixe o campo em branco."
                        >
                            <InputNumber
                                addonBefore="R$"
                                decimalSeparator=","
                                min={1}
                                precision={2}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="approval_required_to_reservation"
                    label="Aprovação necessária para reservar"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value={false}>Não</Radio>
                        <Radio value={true}>Sim</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}
