import React, { useState } from 'react';

import { notification, Popconfirm, Space } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

import { useConsumption } from '../../lib/hooks/useConsumption';
import { sleep } from '../../lib/Sleep';
import { deleteConsumptionReading } from '../../services/Consumption.service';

type Props = { record: Consumption.Reading };

export function ConsumptionActionsCell({ record }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);


    const {
        fetchConsumptions,
    } = useConsumption();

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteConsumptionReading(record.id);

        // Improves UX
        await sleep(1000);

        setIsSending(false);
        setIsPopconfirmVisible(false);

        notification.open(response);

        setIsPopconfirmVisible(false);
        fetchConsumptions();
    };

    return (
        <Space size="middle">
            <Popconfirm
                title="Excluir leitura"
                description="Tem certeza que deseja excluir?"
                open={isPopconfirmVisible}
                placement="left"
                cancelText="Não"
                okText="Sim"
                okType="danger"
                okButtonProps={{ loading: isSending }}
                onConfirm={handleDelete}
                onCancel={() => setIsPopconfirmVisible(false)}
            >
                <DeleteOutlined title="Excluir" onClick={() => setIsPopconfirmVisible(true)} />
            </Popconfirm>
        </Space>
    );
}
