import React, { Fragment } from 'react';

import { Button, Card, Col, Row, Space, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import CreatePaymentModal from 'components/Overview/CreatePaymentModal';
import EditPaymentModal from 'components/Overview/EditPaymentModal';
import PaymentMadeModal from 'components/Overview/PaymentMadeModal';
import { downloadPaymentReport } from 'components/Overview/PaymentsExcel';
import { PaymentsFilterBar } from 'components/Overview/PaymentsFilterBar';
import ViewPaymentModal from 'components/Overview/ViewPaymentModal';
import { PaymentContextProvider } from 'lib/providers/PaymentContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Payments() {
    return (
        <PaymentContextProvider>
            {({
                isLoading,
                payments,
                isCreateModalVisible,
                isEditModalVisible,
                setIsCreateModalVisible,
                isPaymentMadeModalVisible,
                isViewModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <span style={{ display: 'flex', gap: 8, alignItems: 'center', margin: '24px 0 12px' }}>
                                <Typography.Title style={{ margin: 0 }} level={3}>Pagamentos</Typography.Title>
                            </span>
                            <Space>
                                <Button
                                    type="default"
                                    onClick={() => downloadPaymentReport(payments)}
                                >
                                    Gerar relatório
                                </Button>

                                <Button
                                    type="primary"
                                    onClick={() => setIsCreateModalVisible(true)}
                                >
                                    Cadastrar
                                </Button>
                            </Space>
                        </Row>
                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <PaymentsFilterBar />

                                    <List
                                        columns={Columns}
                                        data={payments}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                    <Show when={isCreateModalVisible}>
                        <CreatePaymentModal />
                    </Show>
                    <Show when={isEditModalVisible}>
                        <EditPaymentModal />
                    </Show>
                    <Show when={isPaymentMadeModalVisible}>
                        <PaymentMadeModal />
                    </Show>
                    <Show when={isViewModalVisible}>
                        <ViewPaymentModal />
                    </Show>
                </Fragment>
            )}
        </PaymentContextProvider>
    );
}
