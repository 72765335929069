import React, { Fragment } from 'react';

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import type { ValidatorRule } from 'types/packages/antd';

type Props = { required: boolean };

export function SchedulesField({ required }: Props ) {
    /** @see https://ant.design/components/form#components-form-demo-dynamic-form-item */
    const requiredValidator: ValidatorRule = {
        validator: async (_, value) => {
            if (!required)
                return Promise.resolve();

            if (!Array.isArray(value) || value.length < 1)
                return Promise.reject('Por favor, adicione ao menos um agendamento.');

            Promise.resolve();
        },
    };

    return (
        <Fragment>
            <Typography.Text className={required ? 'form-item-required' : undefined}>
                Cronograma de envio
            </Typography.Text>
            
            <Form.List name="schedules" rules={[requiredValidator]}>
                {(fields, { add, remove }, { errors }) => (
                    <div style={{ paddingTop: '8px' }}>
                        {fields.map(({ key, name }) => (
                            <Row key={key}>
                                <Col span={14}>
                                    <Form.Item
                                        name={[name, 'when']}
                                        rules={[{ required: true, message: 'Selecione uma data' }]}
                                    >
                                        <Input type='date' />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        name={[name, 'whatTime']}
                                        rules={[{ required: true, message: 'Selecione o horário' }]}
                                    >
                                        <Select>
                                            <Select.Option value="8h">8h</Select.Option>
                                            <Select.Option value="10h">10h</Select.Option>
                                            <Select.Option value="12h">12h</Select.Option>
                                            <Select.Option value="14h">14h</Select.Option>
                                            <Select.Option value="16h">16h</Select.Option>
                                            <Select.Option value="18h">18h</Select.Option>
                                            <Select.Option value="20h">20h</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginBottom: 26 }} />
                                </Col>
                            </Row>
                        ))}

                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Novo agendamento
                            </Button>

                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </div>
                )}
            </Form.List>
        </Fragment>
    );
}
