import React, { useState } from 'react';

import { 
    App,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Select,
    SelectProps,
    UploadFile 
} from 'antd';

import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useDocument } from 'lib/providers/DocumentContextProvider';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import { createDocument } from 'services/Document.service';

type Values = {
    client: Client.Model['id'],
    title: string,
    type: number,
    share_with_resident: Document.Model['share_with_resident'],
    files: UploadFile[],
};

type Options = NonNullable<SelectProps['options']>;

const filterOption = makeFilterOption();

export function CreateDocumentModal() {
    const [isSending, setIsSending] = useState(false);

    const { 
        setIsCreateModalVisible,
        fetchDocuments,
        types,
    } = useDocument();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => setIsCreateModalVisible(false);

    const onFinish = async ({ client, title, type, share_with_resident, files }: Values) => {
        setIsSending(true);

        const parsedFiles = files.map(file => ({ 
            url: file.response, 
            filename: file.name
        }));

        const response = await createDocument({ 
            client_id: client,
            title,
            document_type_id: type,
            share_with_resident,
            files: parsedFiles
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchDocuments();
    };

    const parsedTypes: Options = types.map(({ id, name }) => ({ value: id, label: name }));

    const parsedClients: Options = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Modal
            title="Criar documento"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Criar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createDocument"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item<Values>
                    name="client"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select 
                        options={parsedClients}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>
                
                <Form.Item<Values>
                    name="title"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item<Values>
                    name="type"
                    label="Tipo"
                    rules={[{ required: true, message: 'Por favor, selecione um tipo.' }]}
                >
                    <Select options={parsedTypes} />
                </Form.Item>

                <Form.Item<Values>
                    name="share_with_resident"
                    label="Disponibilizar no aplicativo dos condôminos?"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value={1}>Sim</Radio>
                        <Radio value={0}>Não</Radio>
                    </Radio.Group>
                </Form.Item>

                <UploadField
                    name="files"
                    type="picture"
                    multiple={true}
                    rules={[{ required: true, message: 'Por favor, insira ao menos um arquivo.' }]}
                />
            </Form>
        </Modal>
    );
}
