import React, { useEffect, useState } from 'react';

import { Col, Modal, Row, Table, Typography } from 'antd';

import API from '../../../lib/API';
import { ApartmentInterface } from '../../_Common/_Interfaces/Apartment';
import { PetInterface } from '../../_Common/_Interfaces/Pet';
import { ResidentInterface } from '../../_Common/_Interfaces/Resident';
import { VehicleInterface } from '../../_Common/_Interfaces/Vehicle';

type Props = {
    isOpen: boolean;
    closeModal: Function;
    apartment: ApartmentInterface;
};

const ViewApartment: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [residents, setResidents] = useState(Array<ResidentInterface>);
    const [vehicles, setVehicles] = useState(Array<VehicleInterface>);
    const [pets, setPets] = useState(Array<PetInterface>);

    async function cleanState(){
        setResidents([]);
        setVehicles([]);
        setPets([]);
    };  

    async function fetchData() {
        setLoading(true);

        const residents = await API.get(`/resident/apartment/${props.apartment.id}`);
        const vehicles = await API.get(`/vehicle/${props.apartment.id}`);
        const pets = await API.get(`/pet/${props.apartment.id}`);

        setResidents(residents);
        setVehicles(vehicles);
        setPets(pets);

        setLoading(false);
    };

    useEffect(() => {
        if (props.apartment.id) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    return (
        <Modal
            width={1000}
            footer={false}
            open={props.isOpen}
            destroyOnClose={true}
            onCancel={() => props.closeModal()}
            afterClose={cleanState}
            title={props.apartment.id ? `Unidade ${props.apartment.tower.name} - ${props.apartment.name}` : ''}
        >
            <Row>
                <Col span={12}>
                    <Typography.Title level={5}>Moradores</Typography.Title>
                    <Table
                        size='small'
                        loading={loading}
                        pagination={false}
                        columns={[
                            { title: 'Nome', key: 'name', dataIndex: 'name' },
                            { title: 'Contatos', key: 'phone', render: (_: any, resident: ResidentInterface) => (`${resident.phone ?? ''}\n${resident.cellphone ?? ''}`) }
                        ]}
                        dataSource={residents}
                    />
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>Visitantes autorizados</Typography.Title>
                    <Table
                        size='small'
                        loading={loading}
                        pagination={false}
                        columns={[
                            { title: 'Nome', key: 'name', dataIndex: 'name' },
                            { title: 'CPF', key: 'cpf', dataIndex: 'cpf' },
                            { title: 'RG', key: 'rg', dataIndex: 'rg' },
                        ]}
                        dataSource={[]}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Typography.Title level={5}>Veículos</Typography.Title>
                    <Table
                        size='small'
                        loading={loading}
                        pagination={false}
                        columns={[
                            {
                                title: 'Tipo', key: 'type', dataIndex: 'type', render: (_: any, vehicle: VehicleInterface) => {
                                    if (vehicle.type === 'car')
                                        return 'Carro';
                                    else if (vehicle.type === 'motorbike')
                                        return 'Moto';

                                    return 'Outro';
                                }
                            },
                            { title: 'Marca', key: 'brand', dataIndex: 'brand' },
                            { title: 'Modelo', key: 'model', dataIndex: 'model' },
                            { title: 'Placa', key: 'board', dataIndex: 'board' }
                        ]}
                        dataSource={vehicles}
                    />
                </Col>
                <Col span={12}>
                    <Typography.Title level={5}>Pets</Typography.Title>
                    <Table
                        size='small'
                        loading={loading}
                        pagination={false}
                        columns={[
                            { title: 'Nome', key: 'name', dataIndex: 'name' },
                            {
                                title: 'Tipo', key: 'type', dataIndex: 'type', render: (_: any, pet: PetInterface) => {
                                    if (pet.type === 'dog')
                                        return 'Cachorro';
                                    else if (pet.type === 'cat')
                                        return 'Gato';

                                    return 'Outro';
                                }
                            }
                        ]}
                        dataSource={pets}
                    />
                </Col>
            </Row>

        </Modal>
    );

};

export default ViewApartment;