import React, { ReactNode } from 'react';

import { 
    Card, 
    CardProps, 
    Statistic,
    StatisticProps,
    Typography
} from 'antd';

import styles from './Indicator.module.scss';

type IndicatorProps = {
    label: string,
    value: string | number | undefined,
    icon: ReactNode,
    /** @default '#FFFFFF' */
    iconColor?: '#0B1A62' | '#FFFFFF' | '#FFE58F',
    /** @default '#0B1A62' */
    iconBackgroundColor?: '#0B1A62' | '#008b1f' | '#FA8C16' | '#B7EB8F' | '#FFA39E' | '#FFE58F' | '#ff4d4f'| '#d48806' | '#52c41a' | null,
    /** @default 'default' */
    size?: CardProps['size'],
    /** @default '#FFFFFF' */
    backgroundColor?: '#FFFFFF' | '#EDEDF0' | '#FFE58F',
    /** @default false */
    loading?: boolean,
}

type Formatter = StatisticProps['formatter'];

/**
 * @example
 * ```tsx
 * <Indicator
 *   label="Condomínios administrados"
 *   value={60}
 *   icon={<ApartmentOutlined />}
 *   loading={false}
 * />
 * ```
 */
export function Indicator({
    label,
    value,
    icon,
    iconColor = '#FFFFFF',
    iconBackgroundColor = '#0B1A62',
    size = 'default',
    backgroundColor = '#FFFFFF',
    loading = false,
}: IndicatorProps) {
    const formatter: Formatter = () => {
        return (
            <div className={styles.container}>
                <span 
                    className={styles['icon-wrapper']}
                    style={{
                        color: iconColor,
                        backgroundColor: iconBackgroundColor ?? 'unset'
                    }}
                >
                    {icon}
                </span>

                <span className={styles['text-wrapper']}>
                    <Typography.Title level={size === 'default' ? 3 : 4}>
                        {value}
                    </Typography.Title>

                    <Typography.Text>
                        {label}
                    </Typography.Text>
                </span>
            </div>
        );
    };
    
    return (
        <Card
            bordered={false}
            size={size}
            style={{ backgroundColor }}
        >
            <Statistic
                loading={loading}
                formatter={formatter}
            />
        </Card>
    );
}
