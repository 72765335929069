import React, { Fragment } from 'react';

import { Avatar, Button, Col, Divider, Modal, notification, Popconfirm, Row, Tag, Timeline, Typography } from 'antd';

import { DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { FilesGalery } from 'components/_Common/FilesGalery';
import dayjs from 'dayjs';
import { getConstructionDerivedStatusTagProps } from 'lib/helpers/Construction.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { Show } from 'lib/Show';
import { deleteConstructionFollowUp } from 'services/Construction.service';

import FollowUpCreateModal from './FollowUpCreateModal';

export function DetailConstructionModal() {

    const {
        construction,
        setIsDetailModalVisible,
        setIsFollowUpCreateModal,
        isFollowUpCreateModal,
        setConstructionId,
        fetchConstructions
    } = useConstruction();

    if (!construction)
        throw new Error('Value of the `issue` property is unknown');

    const close = () => {
        setIsDetailModalVisible(false);
        setConstructionId(null);
    };

    const deleteFollowUp = async (id: number) => {
        const response = await deleteConstructionFollowUp(id);
        if (!response.success)
            return;

        await fetchConstructions();
        notification.success({
            message: 'Sucesso',
            description: 'Acompanhamento deletado com sucesso'
        });
    };

    const followUpTimeline = () => {
        return construction.follow_ups.map(fup => ({
            dot: (
                <Avatar
                    size={38}
                    src={fup.user.picture}
                    icon={<UserOutlined />}
                />
            ),
            children:
                <Typography.Paragraph>
                    <Typography.Paragraph style={{ fontWeight: 'bold', marginBottom: 1 }}>
                        {fup.user.name} em {dayjs(fup.created_at).format('DD/MM/YYYY HH:mm')}
                        <Divider type='vertical' />
                        <Popconfirm title={'Deseja remover?'}
                            placement="top"
                            onConfirm={() => deleteFollowUp(fup.id)}
                            cancelText={'Cancelar'}
                            okText={'Deletar'}
                            okType={'danger'}>
                            <Button type='text' size='small'><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Typography.Paragraph>

                    <Typography.Paragraph>
                        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: fup.description }}></div>
                    </Typography.Paragraph>

                    <FilesGalery files={fup.files} />

                </Typography.Paragraph>
        }));
    };

    const props = getConstructionDerivedStatusTagProps(construction.status);

    const title = (
        <Fragment>
            <div style={{ justifyContent: 'space-between' }}>
                Obra #{construction.id} - {construction.name}
                <Tag {...props} />
            </div>
        </Fragment>
    );


    return (
        <Modal
            centered
            title={title}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            footer={null}
            open
        >
            <Row>
                <Col span={24}>
                    <Show when={construction.expected_end_date !== null}>
                        <Typography.Paragraph style={{ marginBottom: 8 }}>
                            <strong>Prazo estimado para finalização:</strong>
                            {' '}
                            {dayjs(construction.expected_end_date).format('DD/MM/YYYY')}
                        </Typography.Paragraph>
                    </Show>
                    <Timeline style={{ marginLeft: 15, paddingTop: 15 }} items={followUpTimeline()} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button
                        onClick={() => setIsFollowUpCreateModal(true)}
                        type="dashed"
                        icon={<PlusOutlined />}
                        block
                    >
                        Novo acompanhamento
                    </Button>
                </Col>
            </Row>
            <Show when={isFollowUpCreateModal}>
                <FollowUpCreateModal />
            </Show>
        </Modal>
    );
}
