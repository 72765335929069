import React, { useEffect, useState } from 'react';

import { Pie } from '@ant-design/plots';

import { getTotalIssueByType } from '../../services/Issue.service';

const IssueChart = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [issueGroupByType, setIssuesGroupByType] = useState<Array<Issue.Chart>>([]);

    async function fetchData() {
        const promises = [];
        setLoading(true);

        promises.push(getTotalIssueByType().then((response) => {
            if ('status' in response)
                return;
            setIssuesGroupByType(response);
        }));

        Promise.all(promises).then(() => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const data = issueGroupByType;
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };
    return <Pie {...config} loading={loading} />;
};

export default IssueChart;