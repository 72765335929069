import React, { useState } from 'react';

import { Divider, Form, Input, Modal, notification } from 'antd';

import { createBudget, updateBudget } from 'services/BudgetService';

type Props = {
    isOpen: boolean,
    closeForm: Function,
    budget: Budget.Model | undefined
};

export type Values = {
    year: Budget.Model['year'],
    start: Budget.Model['start'],
    end: Budget.Model['end']
};

const FormBudget: React.FC<Props> = (props: Props) => {
    const [form] = Form.useForm<Values>();
    const [isSaving, setSaving] = useState(false);

    async function onFinish() {
        try {
            setSaving(true);

            const budget = await form.validateFields();
            const response = props.budget?.id
                ? await updateBudget(props.budget?.id, budget)
                : await createBudget(budget);

            notification.open(response);
            setSaving(false);
            props.closeForm();
        } catch (error) {
            setSaving(false);
        }
    };

    return (
        <Modal
            centered
            title={props.budget?.id ? 'Editar ano fiscal' : 'Cadastrar ano fiscal'}
            destroyOnClose={true}
            open={props.isOpen}
            confirmLoading={isSaving}
            onOk={onFinish}
            onCancel={() => props.closeForm()}
            afterClose={() => form.resetFields()}
            okText="Salvar"
        >
            <Divider />
            <Form form={form} layout="vertical" initialValues={props.budget} onFinish={onFinish}>
                <Form.Item name="year" label="Nome do período" tooltip="Por exemplo: 2023 - 2024 (Síndico Vinicius Santos)" rules={[{ required: true, message: 'Digite o ano ' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="start" label="Início do ano fiscal" rules={[{ required: true, message: 'Selecione uma data' }]}>
                    <Input type='date' />
                </Form.Item>
                <Form.Item name="end" label="Fim do ano fiscal" rules={[{ required: true, message: 'Selecione uma data' }]}>
                    <Input type='date' />
                </Form.Item>
                {/* <Form.Item name="amount" label="Orçamento total do período" rules={[{ required: true, message: "Digite o valor" }]}>
                    <InputNumber
                        defaultValue={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        addonAfter="R$"
                    />
                </Form.Item> */}
            </Form>
        </Modal >
    );

};

export default FormBudget;