import API from '../lib/API';
import { CreateAccountClient, DeleteAccounClient, FindAccount, GetAccountSubscription, ListAccountClients, UpdateAccount, UpdateAccountClient } from './contracts/Account.contract';

export const findAccount = (): Promise<FindAccount.Response> => {
    return API.get('/account');
};

export const getAccountSubscription = (): Promise<GetAccountSubscription.Response> => {
    return API.get('/account/subscription');
};

export const updateAccount = (body: UpdateAccount.Body): Promise<UpdateAccount.Response> => {
    return API.put('/account', body);
};

export const createAccountClient = (body: CreateAccountClient.Body): Promise<CreateAccountClient.Response> => {
    return API.post('/account/client', body);
};

export const updateAccountClient = (id: Client.Model['id'], body: UpdateAccountClient.Body): Promise<UpdateAccountClient.Response> => {
    return API.put(`/account/client/${id}`, body);
};

export const deleteAccountClient = (id: Client.Model['id']): Promise<DeleteAccounClient.Response> => {
    return API.delete(`/account/client/${id}`);
};

export const listAccountClients = (): Promise<ListAccountClients.Response> => {
    return API.get('/account/client');
};