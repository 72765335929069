import React, { Fragment, useState } from 'react';

import { App, Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Tooltip, Typography } from 'antd';

import { CalendarOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { handleServiceError } from '../../lib/helpers/ServiceHelper';
import { useAmbient } from '../../lib/hooks/useAmbient';
import { sleep } from '../../lib/Sleep';
import { updateAmbientDates } from '../../services/Ambient.service';

type DateValue = { date: string };

type Values = {
    minimum_days_to_new_reservation: number | null | undefined,
    maximum_days_to_new_reservation: number | null | undefined,
    minimum_days_to_cancel_reservation: number | null | undefined,
    blocked_booking_dates: DateValue[] | undefined,
};

type Body = Parameters<typeof updateAmbientDates>['1'];

export function AmbientDatesModal() {
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();
    const app = App.useApp();

    const {
        ambient,
        setAmbientId,
        setIsAmbientDatesModalVisible,
        fetchAmbients,
    } = useAmbient();

    // Wait ambient to be fetched in "Ambients.page.tsx"
    if (ambient === null)
        return null;

    const onFinish = async (values: Values) => {
        setIsSending(true);

        const body: Body = {
            minimum_days_to_new_reservation: values.minimum_days_to_new_reservation ?? null,
            maximum_days_to_new_reservation: values.maximum_days_to_new_reservation ?? null,
            minimum_days_to_cancel_reservation: values.minimum_days_to_cancel_reservation ?? null,
            blocked_booking_dates: values.blocked_booking_dates?.map(value => value.date ) ?? [],
        };

        const response = await updateAmbientDates(ambient.id, body);

        // Improves UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIsAmbientDatesModalVisible(false);
        setAmbientId(null);
        fetchAmbients();
    };

    const onCancel = () => {
        setIsAmbientDatesModalVisible(false);
        setAmbientId(null);
    };

    const initialValues = {
        ...ambient,
        blocked_booking_dates: ambient.blocked_booking_dates.map(item => ({ date: item.blocked_booking_date })),
    };

    return (
        <Modal
            open
            width={610}
            title={`Datas do ambiente "${ambient.name}"`}
            confirmLoading={isSending}
            onOk={form.submit}
            okButtonProps={{ htmlType: 'submit' }}
            okText="Salvar"
            onCancel={onCancel}
            cancelText="Cancelar"
        >
            <Divider />

            <Typography.Paragraph>
                Os campos abaixos afetam apenas as reservas realizadas diretamente pelos moradores, pelo aplicativo de celular.
            </Typography.Paragraph>

            <Form
                form={form}
                onFinish={onFinish}
                name="datesAmbient"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row>
                    <Col span="12">
                        <Form.Item
                            name="minimum_days_to_new_reservation"
                            label="Mínimo de dias para nova reserva"
                            tooltip="Caso não queira um valor mínimo, deixe o campo em branco."
                        >
                            <InputNumber
                                addonBefore={<CalendarOutlined />}
                                precision={0}
                                min={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span="12">
                        <Form.Item
                            name="maximum_days_to_new_reservation"
                            label="Máximo de dias para nova reserva"
                            tooltip="Caso não queira um valor máximo, deixe o campo em branco."
                        >
                            <InputNumber
                                addonBefore={<CalendarOutlined />}
                                precision={0}
                                min={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="minimum_days_to_cancel_reservation"
                    label="Mínimo de dias para cancelar reserva"
                    tooltip="Caso não queira um valor mínimo, deixe o campo em branco."
                >
                    <InputNumber
                        addonBefore={<CalendarOutlined />}
                        precision={0}
                        min={1}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.List name="blocked_booking_dates">
                    {(fields, { add, remove }) => (
                        <Fragment>
                            <Typography.Text style={{ display: 'inline-flex' }}>
                                Datas bloqueadas para novas reservas
                                <Tooltip title="O ano não é levado em conta pelo sistema, de forma que, por exemplo, ao bloquear reservas na data 25/12/2023, não será possível realizar reservas em 25/12/2023, 25/12/2024, 25/12/2025, etc.">
                                    <span className="form-item-tooltip">
                                        <QuestionCircleOutlined />
                                    </span>
                                </Tooltip>
                            </Typography.Text>

                            <div style={{ paddingTop: '8px' }}>
                                {fields.map(({ name, ...restFieldProps }) => (
                                    <Form.Item
                                        {...restFieldProps}
                                        name={[name, 'date']}
                                        rules={[{ required: true, message: 'Por favor, selecione uma data.' }]}
                                    >
                                        <Input
                                            type="date"
                                            addonAfter={
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                    title="Remover"
                                                />
                                            }
                                        />
                                    </Form.Item>
                                ))}
                            </div>

                            <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Nova data
                            </Button>
                        </Fragment>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}
