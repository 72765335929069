import API from '../lib/API';
import {
    CreateAmbient,
    DeleteAmbient,
    FindAmbient,
    ListAmbients,
    UpdateAmbient,
    UpdateAmbientDates,
} from './contracts/Ambient.contract';

export const listAmbients = (): Promise<ListAmbients.Response> => API.get('/ambient');

export const findAmbient = (id: Ambient.SimpleModel['id']): Promise<FindAmbient.Response> => API.get(`/ambient/${id}`);

export const createAmbient = (body: CreateAmbient.Body): Promise<CreateAmbient.Response> => API.post('/ambient', body);

export const updateAmbient = (id: Ambient.SimpleModel['id'], body: UpdateAmbient.Body): Promise<UpdateAmbient.Response> => API.put(`/ambient/${id}`, body);

export const updateAmbientDates = (id: Ambient.SimpleModel['id'], body: UpdateAmbientDates.Body): Promise<UpdateAmbientDates.Response> => API.put(`/ambient/${id}`, body);

export const deleteAmbient = (id: Ambient.SimpleModel['id']): Promise<DeleteAmbient.Response> => API.delete(`/ambient/${id}`);