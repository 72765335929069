import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useHistory } from 'react-router-dom';

import { App, UploadFile } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useSearchParams } from 'lib/hooks/useSearchParams';
import { FinishIssue } from 'services/contracts/Issue.contract';
import { listIssue, listIssueType } from 'services/Issue.service';
import { listUser } from 'services/UserService';

type Value = {
    isLoading: boolean,
    issues: Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client'>[],
    issuesFiltered: Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client'>[],
    issueTypes: Issue.Type[],
    users: User.Model[],
    issue: Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client'> | null,
    issueId: Issue.Model['id'] | null,
    setIssueId: Dispatch<SetStateAction<Value['issueId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    isDuplicateModalVisible: boolean,
    setIsDuplicateModalVisible: Dispatch<SetStateAction<Value['isDuplicateModalVisible']>>,
    isDetailModalVisible: boolean,
    setIsDetailModalVisible: Dispatch<SetStateAction<Value['isDetailModalVisible']>>,
    isFollowUpCreateModal: boolean,
    setIsFollowUpCreateModal: Dispatch<SetStateAction<Value['isFollowUpCreateModal']>>,
    isFinishIssueModal: boolean,
    setIsFinishIssueModal: Dispatch<SetStateAction<Value['isFinishIssueModal']>>,
    isReportIssueModal: boolean,
    setIsReportIssueModal: Dispatch<SetStateAction<Value['isReportIssueModal']>>,
    isTutorialIssueModal: boolean,
    setIsTutorialIssueModal: Dispatch<SetStateAction<Value['isTutorialIssueModal']>>,
    setIssuesFiltered: Dispatch<SetStateAction<Value['issuesFiltered']>>,
    fetchIssues: () => Promise<void>,
    isOverviewModule: boolean,
    createCheckListModal: boolean,
    setCreateChecklistModal: Dispatch<SetStateAction<Value['createCheckListModal']>>,
    finishCheckboxModal: boolean,
    setFinishCheckboxModal: Dispatch<SetStateAction<Value['finishCheckboxModal']>>,
    editCheckListModal: boolean,
    setEditChecklistModal: Dispatch<SetStateAction<Value['editCheckListModal']>>,
    checklists: Issue.Checklist[],
    setChecklists: Dispatch<SetStateAction<Value['checklists']>>,
    checklist: Issue.Checklist | undefined,
    setChecklist: Dispatch<SetStateAction<Value['checklist']>>,
    task: Issue.Tasks | undefined,
    setTask: Dispatch<SetStateAction<Value['task']>>,
    tasks: Issue.Tasks[] | undefined,
    setTasks: Dispatch<SetStateAction<Value['tasks']>>,
    viewTaskModal: boolean,
    setViewTaskModal: Dispatch<SetStateAction<Value['viewTaskModal']>>,
    viewTasksDisapprovedModal: boolean,
    setViewTasksDisapprovedModal: Dispatch<SetStateAction<Value['viewTasksDisapprovedModal']>>,
    setBodyFinishIssue: Dispatch<SetStateAction<Value['bodyFinishIssue']>>,
    bodyFinishIssue: FinishIssue.Body | undefined;
    setFilesIssue: Dispatch<SetStateAction<Value['filesIssue']>>,
    filesIssue: UploadFile[] | undefined,
};

type Props = { isOverviewModule: boolean, children: (value: Value) => ReactNode };

const IssueContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function IssueContextProvider({ isOverviewModule, children }: Props) {
    const searchParams = useSearchParams();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [issues, setIssues] = useState<Value['issues']>([]);
    const [issuesFiltered, setIssuesFiltered] = useState<Value['issuesFiltered']>([]);

    const [issueTypes, setIssueTypes] = useState<Issue.Type[]>([]);
    const [users, setUsers,] = useState<User.Model[]>([]);

    const [issueId, setIssueId] = useState<Issue.Model['id'] | null>(() => {
        const detail = searchParams.get('detail');

        if (!detail)
            return null;

        const parsedId = parseInt(detail, 10);

        return parsedId;
    });

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(() => issueId !== null);
    const [isFollowUpCreateModal, setIsFollowUpCreateModal] = useState<boolean>(false);
    const [isFinishIssueModal, setIsFinishIssueModal] = useState<boolean>(false);
    const [isReportIssueModal, setIsReportIssueModal] = useState<boolean>(false);
    const [isTutorialIssueModal, setIsTutorialIssueModal] = useState<boolean>(false);
    const [createCheckListModal, setCreateChecklistModal] = useState<boolean>(false);
    const [editCheckListModal, setEditChecklistModal] = useState<boolean>(false);
    const [viewTaskModal, setViewTaskModal] = useState<boolean>(false);
    const [viewTasksDisapprovedModal, setViewTasksDisapprovedModal] = useState<boolean>(false);
    const [finishCheckboxModal, setFinishCheckboxModal] = useState<boolean>(false);
    const [checklists, setChecklists] = useState<Issue.Checklist[]>([]);
    const [checklist, setChecklist] = useState<Issue.Checklist | undefined>();
    const [task, setTask] = useState<Issue.Tasks | undefined>();
    const [tasks, setTasks] = useState<Issue.Tasks[] | undefined>();
    const [bodyFinishIssue, setBodyFinishIssue] = useState<FinishIssue.Body | undefined>();
    const [filesIssue, setFilesIssue] = useState<UploadFile[] | undefined>();

    const app = App.useApp();

    const fetchUsers = async () => {
        setIsLoading(true);

        const clients = isOverviewModule
            ? Authentication.getClients().map(client => client.id)
            : [Authentication.getCurrentClientId()];

        const response = await listUser(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setUsers(response.users);
    };

    const fetchIssues = async () => {
        setIsLoading(true);

        const clients = isOverviewModule
            ? Authentication.getClients().map(client => client.id)
            : [Authentication.getCurrentClientId()];

        const response = await listIssue(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIssues(response.issues);
        setIssuesFiltered(response.issues);
    };

    const fetchIssueTypes = async () => {
        setIsLoading(true);

        const response = await listIssueType();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setIssueTypes(response.issue_types);
    };

    const memoizedFetchIssues = useCallback(fetchIssues, [app, isOverviewModule]);

    useEffect(() => {
        fetchUsers();
        fetchIssueTypes();
        memoizedFetchIssues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedFetchIssues]);

    useEffect(() => {
        // Only delete the parameter when the modal is closed
        if (isDetailModalVisible === false) {
            searchParams.delete('detail');

            history.replace({ search: searchParams.toString() });
        }
    }, [searchParams, history, isDetailModalVisible]);

    const issue = useMemo(() => {
        if (!issueId || issues.length === 0)
            return null;

        const found = issues.find(issue => issue.id === issueId);

        if (!found)
            throw new Error(`Could not find a issue with id ${issueId}`);

        return found;

    }, [issues, issueId]);

    const value: Value = {
        isLoading,
        issues,
        issuesFiltered,
        issueTypes,
        users,
        issue,
        issueId,
        setIssueId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        isDuplicateModalVisible,
        setIsDuplicateModalVisible,
        isDetailModalVisible,
        setIsDetailModalVisible,
        isFollowUpCreateModal,
        setIsFollowUpCreateModal,
        isFinishIssueModal,
        setIsFinishIssueModal,
        isReportIssueModal,
        setIsReportIssueModal,
        isTutorialIssueModal,
        setIsTutorialIssueModal,
        setIssuesFiltered,
        fetchIssues: memoizedFetchIssues,
        isOverviewModule,
        createCheckListModal,
        setCreateChecklistModal,
        checklists,
        setChecklists,
        checklist,
        setChecklist,
        editCheckListModal,
        setEditChecklistModal,
        finishCheckboxModal,
        setFinishCheckboxModal,
        setTask,
        task,
        setViewTaskModal,
        viewTaskModal,
        setViewTasksDisapprovedModal,
        viewTasksDisapprovedModal,
        setTasks,
        tasks,
        setBodyFinishIssue,
        bodyFinishIssue,
        filesIssue,
        setFilesIssue,
    };

    return (
        <IssueContext.Provider value={value}>
            {children(value)}
        </IssueContext.Provider>
    );
}

export function useIssue() {
    const context = useContext(IssueContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `IssueContextProvider`.');

    return context;
}