import React, { Fragment, useState } from 'react';

import { Alert, Button, Result } from 'antd';

import Authentication from 'lib/Authentication';

import { NonAuthRoutes } from './_Routes/Routes';

type Props = { error: Error };

export function InternalServerError({ error }: Props) {
    const [isDetailsAlertVisible, setIsDetailsAlertVisible] = useState(false);

    const logout = () => {
        Authentication.logout();
        // We need to change the location with the default browser
        // method to refresh the page and recreate the React tree
        window.location.assign(NonAuthRoutes.login);
    };

    const buttons = (
        <Fragment>
            <Button type="primary" onClick={logout}>
                Voltar para tela de login
            </Button>

            <Button onClick={() => setIsDetailsAlertVisible(true)}>
                Visualizar detalhes
            </Button>
        </Fragment>
    );

    if (!isDetailsAlertVisible)
        return <Result status="500" title="500" subTitle="Algo deu errado!" extra={buttons} />;

    return (
        <Result
            status="500"
            title="500"
            subTitle="Algo deu errado!"
            extra={buttons}
        >
            <Alert
                message={error.message}
                description={error.stack}
                type="error"
                showIcon
                closable
                onClose={() => setIsDetailsAlertVisible(false)}
            />
        </Result>
    );
}
