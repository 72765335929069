import React, { useState } from 'react';

import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from 'antd';

import { makeClientOptions } from 'components/_Common/_Layout/Secondary/SecondaryHeader';
import dayjs from 'dayjs';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useReport } from 'lib/providers/ReportContextProvider';
import { Show } from 'lib/Show';

type SelectOptions = NonNullable<SelectProps['options']>;

type Values = {
    user_responsible_id?: Array<number> | null,
    issueTypeId?: Array<number> | null,
    maintenance_type_id?: Array<number> | null,
    isFinished: boolean | null,
    first_date: dayjs.Dayjs | null,
    last_date: dayjs.Dayjs | null,
    clients: Array<number> | null,
    report: 'issue' | 'maintenance' | 'construction' | 'all'
};

export function CustomCard() {

    const {
        loading,
        callSpecificReportMethod,
        users,
        types,
        maintenanceTypes,
    } = useReport();

    const [form] = Form.useForm<Values>();

    const clients = makeClientOptions();
    const filterOption = makeFilterOption();

    const [reportType, setReportType] = useState('single');

    const handleTypeChange = (value: string) => {
        setReportType(value);
    };

    const parsedUsers: SelectOptions = users.map(({ id, name }) => ({ value: id, label: name }));

    const parsedTypes: SelectOptions = types.map(({ id, name }) => ({ value: id, label: name }));

    const parsedMaintenanceTypes: SelectOptions = maintenanceTypes.map(({ id, name }) => ({ value: id, label: name }));


    const onFinish = (values: Values) => {
        const filters: Report.Filter = {
            clients: values.clients ?? null,
            first_date: values.first_date ?? null,
            last_date: values.last_date ?? null,
            isFinished: values.isFinished ?? null,
            user_responsible_id: values.user_responsible_id ?? null,
            issueTypeId: values.issueTypeId ?? null,
            maintenance_type_id: values.maintenance_type_id ?? null,
        };

        console.log(filters);

        callSpecificReportMethod(values.report, filters);
    };

    return (
        <Card title='Filtros customizados' loading={loading}>
            <Form
                form={form}
                name="report"
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="report"
                            label="Relatório"
                            rules={[{ required: true, message: 'Você precisa selecionar um dos relatórios.' }]}
                        >
                            <Select onChange={handleTypeChange}>
                                <Select.Option value="issue">Chamados (detalhado)</Select.Option>
                                <Select.Option value="maintenance">Manutenções (detalhado)</Select.Option>
                                <Select.Option value="construction">Obras (detalhado)</Select.Option>
                                <Select.Option value="all">Chamados e manutenções (consolidado)</Select.Option>
                            </Select>
                        </Form.Item>
                        <Show when={reportType === 'issue'}>
                            <Form.Item
                                name="user_responsible_id"
                                label="Informe o usuário responsável"
                                help="Se você deixar em branco, o sistema irá retornar os casos de todos os usuários."
                            >
                                <Select allowClear options={parsedUsers} filterOption={filterOption} showSearch />
                            </Form.Item>

                            <Form.Item
                                name="issueTypeId"
                                label="Informe a categoria"
                                help="Se você deixar em branco, o sistema irá retornar os casos de todas as categorias."
                            >
                                <Select allowClear options={parsedTypes} filterOption={filterOption} showSearch />
                            </Form.Item>
                        </Show>

                        <Show when={reportType === 'maintenance'}>
                            <Form.Item
                                name="maintenance_type_id"
                                label="Informe a categoria"
                                help="Se você deixar em branco, o sistema irá retornar os casos de todas as categorias."
                            >
                                <Select allowClear options={parsedMaintenanceTypes} filterOption={filterOption} showSearch />
                            </Form.Item>
                        </Show>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="clients"
                            label="Clientes"
                            help="Se você deixar em branco, o sistema irá retornar os casos de todos os condomínios."
                        >
                            <Select mode='multiple' options={clients} filterOption={filterOption} showSearch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="isFinished"
                            label="Situação (status)"
                            help="Se você deixar em branco, o sistema irá retornar os casos pendentes e resolvidos."
                        >
                            <Select allowClear>
                                <Select.Option value={false}>Pendente</Select.Option>
                                <Select.Option value={true}>Finalizado</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="first_date"
                            label="Primeira data"
                        >
                            <Input type='date' />

                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="last_date"
                            label="Última data"
                        >
                            <Input type='date' />

                        </Form.Item>
                    </Col>
                </Row>
                <div className="flex align-center space-between">
                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        {loading ? 'Carregando...' : 'Gerar relatório'}
                    </Button>
                </div>
            </Form>
        </Card>
    );
}