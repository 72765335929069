import React, { Component } from 'react';

import { Button, Image, Modal } from 'antd';

import dayjs from 'dayjs';

import API from '../../../lib/API';
import { LogInterface } from '../../_Common/_Interfaces/Log';
import { ResidentInterface } from '../../_Common/_Interfaces/Resident';
import List from '../../_Common/List';

type State = {
    log: LogInterface;
    logs: Array<LogInterface>;
    loading: boolean;
    visible: boolean;
    picture: string;
};

type Props = {
    resident: ResidentInterface;
    isVisible: boolean;
    hideModalForm: Function;
};

class ListLog extends Component<Props, State> {
    state: State = {
        log: {} as LogInterface,
        logs: [],
        loading: true,
        visible: false,
        picture: ''
    };

    columns = [
        {
            title: 'Acesso', key: 'clientDevice',
            render: (_: any, log: LogInterface) => log.client_device.name
        },
        {
            title: 'Data de acesso', key: 'created_at', dataIndex: 'created_at',
            render: (_: any, log: LogInterface) => dayjs(log.dateTimeAccess).format('DD/MM/YYYY HH:mm:ss')
        },
        {
            title: 'Tipo', key: 'type', dataIndex: 'type',
            render: (_: any, log: LogInterface) => {
                // eslint-disable-next-line eqeqeq
                if (log.type == 'Face' || log.picture != null)
                    return <Button type="link" onClick={() => this.setState({ visible: true, picture: log.picture })}>
                        {log.type}
                    </Button>;
                else
                    return log.type;
            }
        },
        {
            title: 'Status', key: 'status', dataIndex: 'status',
            render: (_: any, log: LogInterface) => {
                switch (log.result) {
                case 'Succ':
                    return 'Sucesso';
                case '0':
                    return 'Sucesso';
                case 'Failed':
                    return 'Falha';
                case '1':
                    return 'Falha';
                }
            }
        },
    ];

    onCancel = () => {
        this.setState({ logs: [], log: {} as LogInterface });
        this.props.hideModalForm();
    };

    fetchData = async (id: number) => {
        this.setState({ loading: true });
        const logs = await API.get(`/resident/${id}/logs`);
        this.setState({ logs, loading: false });
    };


    componentDidUpdate(prevProps: Props) {
        const { resident } = this.props;
        // eslint-disable-next-line eqeqeq
        if (resident != prevProps.resident && resident.id) {
            this.fetchData(resident.id);
        }
    };

    render() {
        const { isVisible, resident } = this.props;
        const { logs, loading, visible, picture } = this.state;

        return (
            <>
                <Modal
                    width={700}
                    key={resident.id}
                    centered
                    title={`Log(s) do morador ${resident.name}`}
                    destroyOnClose={true}
                    open={isVisible}
                    onCancel={this.onCancel}
                    footer={null}
                >
                    <List columns={this.columns} data={logs} loading={loading} />
                    <Image
                        width={200}
                        style={{ display: 'none' }}
                        src={picture}
                        preview={{
                            visible,
                            src: picture,
                            onVisibleChange: (value) => {
                                this.setState({ visible: value });
                            },
                        }}
                    />
                </Modal>
            </>
        );
    }
}

export default ListLog;
