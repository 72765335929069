import React, { useState } from 'react';

import { App, Button, Form, Input, Typography } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { ReCAPTCHA } from 'lib/ReCAPTCHA';
import { sleep } from 'lib/Sleep';
import { resetPasswordByEmail } from 'services/UserService';

const wl = makeWhiteLabel();

type Values = {
    email: string;
};

export function ResetPasswordByEmail() {
    const [isSending, setIsSending] = useState(false);
    const [form] = Form.useForm<Values>();
    const app = App.useApp();

    const handleResetPasswordByEmail = async (values: Values) => {
        setIsSending(true);

        const response = await resetPasswordByEmail(values.email);

        await sleep(1000);

        setIsSending(false);

        app.notification.open(await response);
    };
    

    return (
        <div className="screen-container">
            <div className="form-container">
                <Form
                    form={form}
                    onFinish={handleResetPasswordByEmail}
                    name="ResetPasswordByEmail"
                    layout="vertical"
                    autoComplete="off"
                >
                    <div style={{ textAlign: 'center', padding: 20 }}>
                        <img src={wl.logo} alt="Gcondo logo" style={{ width: '80%' }} />
                    </div>
                    <Typography.Title style={{ marginTop: 0 }} level={5}>
                        Recuperar senha
                    </Typography.Title>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[{ required: true, type: 'email', message: 'Por favor, digite seu e-mail.' }]}
                    >
                        <Input
                            type="email"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Digite seu e-mail"
                        />
                    </Form.Item>

                    <ReCAPTCHA onChange={(value) => console.log(value)} />

                    <hr />

                    <div className="flex align-center space-between">
                        <Button
                            loading={isSending}
                            type="primary"
                            htmlType="submit"
                        >
                            {isSending ? 'Carregando...' : 'Recuperar'}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

