import React, { CSSProperties, ReactNode } from 'react';

import { Layout as AntdLayout } from 'antd';

import { expiredTrial } from 'components/_Common/_Layout/MainLayout';
import { COPYRIGHT } from 'internal-constants';

import { Header } from './LayoutHeader';

type LayoutProps = { children: ReactNode };

export function Layout({ children }: LayoutProps) {
    // This is assigned only when a re-render happens!
    // If the value needs to be in real time, see the link above
    // https://chakra-ui.com/docs/hooks/use-media-query
    const isMobile = window.matchMedia('(max-width: 768px').matches;

    const style: CSSProperties = {
        margin: isMobile ? '0px 10px' : '0px 16px',
        padding: isMobile ? 'unset' : '10px 24px',
        minHeight: 280,
    };

    return (
        <AntdLayout style={{ minHeight: '100vh' }}>
            <Header />

            {expiredTrial()}

            <AntdLayout.Content style={style}>
                {children}
            </AntdLayout.Content>

            <AntdLayout.Footer style={{ textAlign: 'center' }}>
                {COPYRIGHT}
            </AntdLayout.Footer>
        </AntdLayout>
    );
}
