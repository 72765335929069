import API from '../lib/API';
import { GenerateConsolidatedReportIssuesAndMaintenances, GenerateDetailedReportIssues } from './Report.contract';

const route = '/report';

export function generateConsolidatedReportIssuesAndMaintenances(filters: Report.Filter): Promise<GenerateConsolidatedReportIssuesAndMaintenances.Response> {
    return API.post(`${route}/consolidated`, filters);
};

export function generateDetailedReportIssues(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/issues/detailed`, filters);
};

export function generateDetailedReportMaintenances(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/maintenances/detailed`, filters);
};

export function generateDetailedReportConstructions(filters: Report.Filter): Promise<GenerateDetailedReportIssues.Response> {
    return API.post(`${route}/constructions/detailed`, filters);
};