import API from '../lib/API';
import { ActivitiesForToday, GetStatistics, GetUserIssues } from './contracts/Overview.contract';
const route = '/overview';

export function getStatistics(): Promise<GetStatistics.Response> {
    return API.get(`${route}/statistics`);
};

export function getActivitiesForToday(): Promise<ActivitiesForToday.Response> {
    return API.get(`${route}/activities_for_today`);
};

export function getUserIssues(): Promise<GetUserIssues.Response> {
    return API.get(`${route}/issues_by_user`);
};