import React from 'react';

import { Button, Col, Row, Typography } from 'antd';

import { LeftOutlined } from '@ant-design/icons';
import { AuthRoutes } from 'components/_Common/_Routes/Routes';

import API from '../../lib/API';
import { ApartmentInterface } from '../_Common/_Interfaces/Apartment';
import Content from '../_Common/_Layout/Content';
import IssueList from './View/Issue';
import ResidentList from './View/Resident';

type State = {
    loading: boolean;
    apartment: ApartmentInterface
};

type Props = {
    history: any;
    match: any;
};

export default class ApartmentView extends React.Component<Props, State> {

    state = {
        loading: true,
        apartment: {} as ApartmentInterface
    };

    fetchData = async () => {
        const { id } = this.props.match.params;
        const apartment = await API.get(`/apartment/${id}`);
        this.setState({ apartment, loading: false });
    };

    componentDidMount = async () => {
        await this.fetchData();
    };

    render() {
        const { loading, apartment } = this.state;

        if (loading)
            return <div>Carregando</div>;

        return (
            <Content>
                <Row className="space-between" align="middle">
                    <Typography.Title level={3}>
                        Unidade {apartment.tower.name} - {apartment.name}
                    </Typography.Title>

                    <Button 
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={() => this.props.history.push(AuthRoutes.apartment)}
                    >
                        Voltar para página anterior
                    </Button>
                </Row>

                <Row>
                    <Col span={12}>
                        <Typography.Title level={5}>Moradores</Typography.Title>
                        <ResidentList {...this.props} apartmentId={apartment.id} />
                    </Col>
                    <Col span={12}>
                        <Typography.Title level={5}>Chamados</Typography.Title>
                        <IssueList {...this.props} apartmentId={apartment.id} />
                    </Col>
                </Row>
            </Content>
        );
    }

}