import React, { useState } from 'react';

import { Button, Divider, Modal, notification, Radio, Typography } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';
import { Filter } from 'pages/issue/Filters';
import { FileFormat } from 'services/contracts/File.contract';
import { generateReportIssue } from 'services/Issue.service';

import { prepareToDownload } from './Excel';
import { IssuePDF } from './PDF';

export default function ReportIssueModal() {
    const [type, setType] = useState<FileFormat>(FileFormat.WORD);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isTypePDF, setIsTypePDF] = useState<boolean>(false);

    const {
        issuesFiltered,
        setIsReportIssueModal
    } = useIssue();

    const close = () => {
        setIsReportIssueModal(false);
    };

    const closeModalPDF = () => {
        setIsTypePDF(false);
    };

    const handleDownload = async () => {
        if (type === 'excel') {
            prepareToDownload(issuesFiltered);
            setIsReportIssueModal(false);
        }

        if (type === 'pdf')
            setIsTypePDF(true);

        if (type === 'docx') {

            setIsLoading(true);
            const response = await generateReportIssue({ ids: issuesFiltered.map(i => i.id), format: type });

            if (!response.success)
                return notification.error({ message: 'Atenção', description: 'Entre em contato com o suporte' });

            notification.info({ message: 'Atenção', description: 'O relatório solicitado está sendo processado e logo será enviado para o seu e-mail.' });

            setIsLoading(false);
            setIsReportIssueModal(false);
        }
    };

    return (
        <Modal
            open
            centered
            footer={null}
            onCancel={close}
            destroyOnClose={true}
            title='Relatório de chamados'
        >
            <Typography.Paragraph>
                <Typography.Text>Os dados do relatório são gerados de acordo com os filtros aplicados. Caso você preciso do relatório com um grande volume de imagens, recomendamos optar pelo WORD.</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
                <Typography.Text strong>Em qual formato você deseja exportar?</Typography.Text>
            </Typography.Paragraph>
            <Radio.Group
                name='type'
                defaultValue={type}
                onChange={(e) => {
                    const format = e.target.value as FileFormat;
                    setType(format);
                }}
            >
                <Radio value={FileFormat.WORD}>Word</Radio>
                <Radio value={FileFormat.PDF}>PDF</Radio>
                <Radio value={FileFormat.EXCEL}>Excel</Radio>
            </Radio.Group>
            <Divider />
            <Button
                loading={isLoading}
                size='middle'
                type='primary'
                icon={<CloudDownloadOutlined />}
                onClick={handleDownload} block>
                Baixar relatório
            </Button>
            <Show when={isTypePDF}>
                <Modal
                    onCancel={closeModalPDF}
                    title='Relatório'
                    footer={null}
                    width={1000}
                    open
                >
                    <IssuePDF issues={issuesFiltered} filters={{} as Filter} />
                </Modal>
            </Show>
        </Modal >
    );

};