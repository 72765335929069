import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { AmbientActionsCell } from './AmbientActionsCell';

export const Columns: ColumnsType<Ambient.SimpleModel> = [
    {
        title: 'Nome',
        dataIndex: 'name',
        render: value => value ?? '-',
    },

    {
        title: 'Capacidade',
        dataIndex: 'maximum_capacity',
        render: value => value ?? '-'
    },

    {
        title: 'Valor',
        dataIndex: 'price',
        render: value => {
            if (!value)
                return '-';

            const BRL = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            return BRL.format(value);
        }
    },

    {
        width: '100px',
        align: 'center',
        render: (_, record) => <AmbientActionsCell record={record} />,
    },
];