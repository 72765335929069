import React, { useReducer, useState } from 'react';

import { Button, Col, DatePicker, Row, Select } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { usePayment } from 'lib/providers/PaymentContextProvider';
import { makeClientOptions } from 'pages/issue/Filters';
import type { ExtractOptionalField, OptionalField } from 'types/packages/antd';

type Filter = {
    client: OptionalField<Payment.Model['client_id']>,
    category: OptionalField<Payment.Model['category']['id']>,
    type: OptionalField<Payment.Model['type']>,
    due_date: [Dayjs | null, Dayjs | null] | undefined | null,
}

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    client: undefined,
    category: undefined,
    type: undefined,
    due_date: undefined,
};

const filterOption = makeFilterOption();

export function PaymentsFilterBar() {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);

    const [isFiltering, setIsFiltering] = useState(false);

    const { 
        payments,
        setPayments,
        fetchPayments,
        categories,
    } = usePayment();

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchPayments();
    };

    const handleFilter = () => {
        let filteredPayments = payments;

        const { 
            client,
            category,
            type,
            due_date,
        } = filter;

        if (isFilledField<Payment.Model['client_id']>(client)) 
            filteredPayments = filteredPayments.filter(payment => payment.client_id === client);

        if (isFilledField<Payment.Model['category']['id']>(category))
            filteredPayments = filteredPayments.filter(payment => payment.category.id === category);

        if (isFilledField<Payment.Model['type']>(type))
            filteredPayments = filteredPayments.filter(payment => payment.type === type);

        if (isFilledField<ExtractOptionalField<Filter['due_date']>>(due_date) && due_date[0] !== null && due_date[1] !== null)
            filteredPayments = filteredPayments.filter(payment => dayjs(payment.due_date).isBetween(due_date[0], due_date[1]));

        setIsFiltering(true);
        setPayments(filteredPayments);
    };

    /** Disable the filter action when all filters are empty */
    const isActionButtonDisabled = Object.values(filter).every(value => value === undefined);
    
    const parsedCategories = categories.map(({ id, name }) => ({ value: id, label: name }));
    
    const parsedTypes = [
        { value: 'single', label: 'Simples' },
        { value: 'recurrent', label: 'Recorrente' },
        { value: 'immediate_recurrent', label: 'Recorrente imediato' },
    ];

    return (
        <Row gutter={[8, 8]}>
            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={makeClientOptions()}
                    value={filter.client}
                    onChange={value => setFilter({ client: value })}
                    onClear={() => setFilter({ client: undefined })}
                    placeholder="Condomínio"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedCategories}
                    value={filter.category}
                    onChange={value => setFilter({ category: value })}
                    onClear={() => setFilter({ category: undefined })}
                    placeholder="Categoria"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedTypes}
                    value={filter.type}
                    onChange={value => setFilter({ type: value })}
                    onClear={() => setFilter({ type: undefined })}
                    placeholder="Tipo"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={8} xs={24}>
                <DatePicker.RangePicker
                    style={{ fontWeight: 'normal', width: '100%' }}
                    value={filter.due_date}
                    disabled={[isFiltering, isFiltering]}
                    onChange={(value) => {
                        if (value === null)
                            return setFilter({ due_date: undefined });

                        setFilter({ due_date: [dayjs(value[0]), dayjs(value[1])] });
                    }}
                    placeholder={['Vencimento de', 'até']}
                    format="DD/MM/YYYY"
                    
                />
            </Col>

            <Col md={4} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
