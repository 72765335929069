import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type Value = {
    isFetching: boolean,

    apartments: Apartment.Model[],

    typeConsumption: Consumption.Type[],
    consumptions: Consumption.Model[],
    consumptionsReading: Consumption.Reading[],
    fetchConsumptions: () => Promise<void>,

    isFormConsumptionModalVisible: boolean,
    setIsFormConsumptionModalVisible: Dispatch<SetStateAction<Value['isFormConsumptionModalVisible']>>,

    isConsumptionListModalVisible: boolean,
    setIsConsumptionListModalVisible: Dispatch<SetStateAction<Value['isConsumptionListModalVisible']>>,

    isConsumptionReadingFormModalVisible: boolean,
    setIsConsumptionReadingFormModalVisible: Dispatch<SetStateAction<Value['isConsumptionReadingFormModalVisible']>>,
};

export const ConsumptionContext = createContext<Value>(null!);
export const useConsumption = () => useContext(ConsumptionContext);