import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card, Col, Flex, Row, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';

import { FileOutlined, SnippetsOutlined, UserAddOutlined } from '@ant-design/icons';
import { NonAuthRoutes } from 'components/_Common/_Routes/Routes';
import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { Layout } from 'components/Client/Layout';
import { ClientContextProvider, ClientContextProviderProps } from 'lib/providers/ClientContextProvider';

type Params = { url_client: NonNullable<Client.Model['url']> };

type Props = RouteComponentProps<Params>;

export function Client({ history, match }: Props) {
    const url = match.params.url_client;

    const children: ClientContextProviderProps['children'] = ({ isLoading, loadClient }) => {
        if (isLoading)
            return <LoadingGcondo />;

        const client = loadClient();

        return (
            <Layout backgroundColor={client.client_manager.primary_color}>
                <Row style={{ marginBottom: 0, alignItems: 'center' }}>
                    <Col>
                        <Flex align="center" gap="middle" justify="space-between" id="header">
                            <img
                                src={client.client_manager.logo}
                                alt={client.client_manager.name}
                                style={{ marginBottom: 24 }}
                                width="196"
                            />
                        </Flex>
                    </Col>
                    <Col>
                        <Typography.Title style={{ marginBottom: 0, marginTop: 0 }} level={2}>Portal do condômino</Typography.Title>
                        <Typography.Title style={{ marginTop: 0 }} level={3}>{client.name}</Typography.Title>
                    </Col>
                </Row>
                {/* <Row>
                        <Col span={24}>
                            <Typography.Title
                                level={3}
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    color: client.client_manager.primary_color ?? undefined
                                }}
                            >
                                {client.name}
                            </Typography.Title>

                            <Typography.Title level={5} type="secondary">
                                Estamos felizes em recebê-lo na nossa comunidade! Este canal foi criado para facilitar a sua vida e melhorar a comunicação entre os condôminos e a  <span style={{ color: client.client_manager.primary_color ?? undefined }}>{client.client_manager.name}</span>. Aqui, você pode:
                                <ul>
                                    <li>Realizar seu cadastro: Mantenha suas informações atualizadas para receber todas as notificações e comunicados importantes.</li>
                                    <li>Abrir chamados para o síndico: Relate problemas, faça sugestões ou solicite serviços diretamente ao síndico de maneira rápida e eficiente.</li>
                                </ul>

                                Nosso objetivo é tornar a sua experiência no condomínio a melhor possível. Se tiver qualquer dúvida ou precisar de assistência, não hesite em nos contatar. Sua participação e colaboração são fundamentais para um ambiente harmonioso e bem administrado.
                            </Typography.Title>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]}>
                        <Col span={24} md={12}>
                            <Button
                                style={{ fontSize: 18, height: 'auto' }}
                                block
                                type="primary"
                                onClick={() => history.push(NonAuthRoutes.clientFirstAccess.replace(':url_client', url))}
                            >
                                <UserOutlined />
                                Realizar meu cadastro
                            </Button>
                        </Col>

                        <Col span={24} md={12}>
                            <Button
                                style={{ fontSize: 18, height: 'auto' }}
                                block
                                size='large'
                                type="default"
                                onClick={() => history.push(NonAuthRoutes.clientIssue.replace(':url_client', url))}
                            >
                                <SnippetsOutlined />
                                Abrir um chamado
                            </Button>
                        </Col>
                    </Row> */}
                <Row>
                    {/* <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<SettingOutlined style={{ fontSize: 24 }} />}
                                title="Manutenções"
                                description="Visualize as manutenções programadas e concluídas no condomínio."
                            />
                        </Card>
                    </Col> */}
                    <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card onClick={() => history.push(NonAuthRoutes.clientIssueList.replace(':url_client', url))}>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<SnippetsOutlined style={{ fontSize: 24 }} />}
                                title="Chamados"
                                description="Acompanhe o status dos chamados e veja as soluções aplicadas."
                            />
                        </Card>
                    </Col>
                    {/* <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<FormatPainterOutlined style={{ fontSize: 24 }} />}
                                title="Obras"
                                description="Acompanhe o andamento e detalhes das obras realizadas no condomínio."
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<NotificationOutlined style={{ fontSize: 24 }} />}
                                title="Enquentes"
                                description="Participe das enquetes e ajude a decidir questões importantes do condomínio."
                            />
                        </Card>
                    </Col> */}
                    <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card onClick={() => { console.log('oi'); }}>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<FileOutlined style={{ fontSize: 24 }} />}
                                title="Documentos"
                                description="Acesse documentos do condomínio, como atas, regulamentos e contratos."
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} style={{ marginTop: 10, cursor: 'pointer' }}>
                        <Card onClick={() => history.push(NonAuthRoutes.clientFirstAccess.replace(':url_client', url))}>
                            <Meta
                                style={{ alignItems: 'center' }}
                                avatar={<UserAddOutlined style={{ fontSize: 24 }} />}
                                title="Realizar meu cadastro"
                                description="Complete ou atualize suas informações de cadastro no sistema do condomínio."
                            />
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    };

    return (
        <ClientContextProvider url={url}>
            {children}
        </ClientContextProvider>
    );
}
