import React, { useCallback, useEffect, useState } from 'react';

import { 
    App,
    Button, 
    Card,
    Divider, 
    Form,
    Input, 
    Modal, 
    Select,
    Space
} from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { sleep } from 'lib/Sleep';
import { listApartmentTypes } from 'services/ApartmentService';
import { createTower } from 'services/TowerService';

type Props = {
    close: () => void
};

export type Values = {
    towers: Tower.Model[]
};

export function CreateTowerModal({ close }: Props) {
    const [isSending, setIsSending] = useState(false);
    const [types, setTypes] = useState<Apartment.Type[]>([]);

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    const fetchTypes = useCallback(async () => {
        const response = await listApartmentTypes();

        if ('type' in response)
            return app.notification.open(response);

        setTypes(response.types);
    }, [app]);

    const handleFinish = async (values: Values) => {

        setIsSending(true);

        const response = await createTower(values);

        // Improves UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
    };

    useEffect(() => {
        fetchTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            open
            width={610}
            title="Cadastrar torres e apartamentos"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            {
                types.length === 0
                    ? 'Você deve cadastrar primeiro ao menos um tipo de unidade.'
                    : <Form
                        form={form}
                        onFinish={handleFinish}
                        name="createTowerAndApartment"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.List name="towers">
                            {(fields, { add, remove }) => (
                                <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                    {fields.map((field) => (
                                        <Card
                                            size="small"
                                            title={`Torre ${field.name + 1}`}
                                            key={field.key}
                                            extra={
                                                <CloseOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />
                                            }
                                        >
                                            <Form.Item label="Nome" name={[field.name, 'name']}>
                                                <Input />
                                            </Form.Item>

                                            {/* Nest Form.List */}
                                            <Form.Item label="Unidades">
                                                <Form.List name={[field.name, 'apartments']}>
                                                    {(subFields, subOpt) => (
                                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                                            {subFields.map((subField) => (
                                                                <Space key={subField.key}>
                                                                    <Form.Item noStyle name={[subField.name, 'name']}>
                                                                        <Input placeholder="Apartamento" />
                                                                    </Form.Item>
                                                                    <Form.Item initialValue={types[0].id} noStyle name={[subField.name, 'apartment_type_id']} rules={[{ required: true }]}>
                                                                        <Select>
                                                                            {types?.map(t => (
                                                                                <Select.Option value={t.id}>{t.name}</Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <CloseOutlined
                                                                        onClick={() => {
                                                                            subOpt.remove(subField.name);
                                                                        }}
                                                                    />
                                                                </Space>
                                                            ))}
                                                            <Button type="dashed" onClick={() => subOpt.add()} block>
                                                                + Adicionar unidade
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Form.Item>
                                        </Card>
                                    ))}

                                    <Button type="dashed" onClick={() => add()} block>
                                        + Adicionar bloco
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Form>
            }

        </Modal>
    );
}
