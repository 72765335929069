import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Layout, Row, Typography } from 'antd';

import LoadingGcondo from 'components/_Common/LoadingGcondo';
import { FormResidentExternal } from 'components/Client/FormResidentExternal';
import { FormResidentIdentification } from 'components/Client/FormResidentIdentification';
import { SuccessPage } from 'components/Client/SuccessPage';
import { ResidentFirstAccessContextProvider } from 'lib/providers/ResidentFirstAccessContextProvider';
import { Show } from 'lib/Show';

export function ResidentFirstAccess(props: RouteComponentProps<{ url_client: string }>) {

    return (
        <ResidentFirstAccessContextProvider url={props.match.params.url_client}>
            {({
                isLoading,
                client,
                isFormResidentIdentification,
                isFormResidentExternal,
                isSuccessPage
            }) => (
                <Fragment>
                    {
                        isLoading
                            ? <LoadingGcondo />
                            : <Layout className="layout" style={{ alignItems: 'center', backgroundColor: client?.client_manager.secondary_color ?? '#FAFAFA' }}>
                                <Layout.Content className='form-resident' style={{ width: '60%', padding: 20 }}>
                                    <img src={client?.client_manager.logo} alt='logo' width="300" />
                                    <Show when={!isSuccessPage}>
                                        <Row>
                                            <Typography.Title level={3} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Seja bem-vindo!</Typography.Title>
                                            <Typography.Title level={4} style={{ color: client?.client_manager.secondary_color ? 'white' : 'black' }}>Estamos muito felizes em fazer parte da história do <span style={{ color: client?.client_manager.primary_color ?? 'black' }}>{client?.name}</span>. Para que você possa aproveitar todas as funcionalidades que oferecemos, por favor, inicie o processo de cadastramento.</Typography.Title>
                                        </Row>
                                    </Show>
                                    <Show when={isFormResidentIdentification}>
                                        <FormResidentIdentification />
                                    </Show>
                                    <Show when={isFormResidentExternal}>
                                        <FormResidentExternal />
                                    </Show>
                                    <Show when={isSuccessPage}>
                                        <SuccessPage />
                                    </Show>
                                </Layout.Content>
                            </Layout>
                    }
                </Fragment>
            )}
        </ResidentFirstAccessContextProvider>
    );
}
