import React, { useState } from 'react';

import { App, Button, Popconfirm } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import { handleServiceError,Options } from 'lib/helpers/ServiceHelper';
import { deleteReserve } from 'services/Reserve.service';

type Props = {
    reserve: Reserve.Model
    fetchReserves: () => void,
};

const PRIOR_OR_CURRENT_DATE_BOOKING_DATE_ERROR_MESSAGE = 'It\'s not possible to delete a reserve with a booking date prior to the current date';

const handleMessage: Options['message'] = message => message === PRIOR_OR_CURRENT_DATE_BOOKING_DATE_ERROR_MESSAGE
    ? 'Não é possível excluir uma reserva com data de reserva anterior à data atual ou na data atual'
    : message;

export function CurrentBookingDateActionsCell({ reserve, fetchReserves }: Props) {
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const app = App.useApp();

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteReserve(reserve.id);

        setIsSending(false);

        if (!response.success) 
            return handleServiceError(app, response, { message: handleMessage });

        app.notification.success({ message: 'Sucesso', description: 'Reserva excluída com sucesso' });
        setIsPopconfirmVisible(false);
        fetchReserves();
    };

    return (
        <Popconfirm
            title="Excluir reserva"
            description="Tem certeza que deseja excluir?"
            open={isPopconfirmVisible}
            placement="left"
            cancelText="Não"
            okText="Sim"
            okType="danger"
            okButtonProps={{ loading: isSending }}
            onConfirm={handleDelete}
            onCancel={() => setIsPopconfirmVisible(false)}
        >
            <Button
                type="text"
                icon={<DeleteOutlined />}
                title="Excluir"
                onClick={() => setIsPopconfirmVisible(true)}
            />
        </Popconfirm>
    );
}
