import React, { useEffect, useState } from 'react';

import { Button, Card, Col, DatePicker, Row, Select } from 'antd';

import { ClearOutlined, FilterFilled } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Authentication from 'lib/Authentication';
import { useStats } from 'lib/providers/StatsContextProvider';

dayjs.extend(isBetween);

export type FilterIssueStats = {
    client_id: Issue.Model['clientId'] | null | undefined,
    from_to: [Dayjs | null, Dayjs | null],
};

export function Filters() {
    const [filter, setFilter] = useState<FilterIssueStats>({} as FilterIssueStats);
    const { fetchStats } = useStats();
    const clients = Authentication.getClients();

    function Client() {
        return (
            <Select
                style={{ width: '100%' }}
                value={filter.client_id}
                onChange={(value) => setFilter({ ...filter, client_id: value })}
                placeholder='Condomínio'
                allowClear
                options={clients.map(client => ({ value: client.id, label: client.name }))}
            />)
        ;
    };

    function CreatedAt() {

        return (
            <DatePicker.RangePicker
                style={{ width: '100%' }}
                defaultValue={[dayjs().startOf('y'), dayjs()]}
                value={filter.from_to}
                onChange={(value) => {
                    if (value === null)
                        return setFilter({ ...filter, from_to: [null, null] });

                    setFilter({ ...filter, from_to: [dayjs(value[0]), dayjs(value[1])] });
                }}
                placeholder={['Filtrar de', 'até']}
                format={'DD/MM/YYYY'}
            />
        );
    };


    function ClearFilter() {
        return <Button
            type='text'
            icon={<ClearOutlined />}
            onClick={() => setFilter({} as FilterIssueStats)}>
            Limpar filtros
        </Button>;
    };

    const handleFilter = () => {
        fetchStats(filter);
    };

    useEffect(() => {
        handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <Card>
            <Row align="middle">
                <Col style={{ marginTop: 5 }} xs={24} sm={24} md={1} lg={1}>
                    <FilterFilled style={{ fontSize: 24 }} />
                </Col>
                <Col style={{ marginTop: 5 }} xs={24} sm={24} md={6} lg={6}>
                    <Client />
                </Col>
                <Col style={{ marginTop: 5 }} xs={24} sm={24} md={6} lg={6}>
                    <CreatedAt />
                </Col>
                <Col style={{ marginTop: 5 }} xs={24} sm={24} md={3} lg={3}>
                    <ClearFilter />
                </Col>
            </Row >
        </Card>
    );
};