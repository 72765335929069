import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import {
    App,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Radio,
    Row,
    Select,
    SelectProps,
    UploadFile
} from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useMaintenance } from 'lib/providers/MaintenanceContextProvider';
import { UploadField } from 'lib/UploadField';
import { finishMaintenance, uploadMaintenanceFile } from 'services/Maintenance.service';
import { listSuppliers } from 'services/Supplier.service';

type SelectOptions = NonNullable<SelectProps['options']>;

export type FinishValues = {
    solution: Maintenance.Model['solution'],
    completionDate: Maintenance.Model['completionDate'],
    supplierId: Maintenance.Model['supplierId'] | undefined,
    tag: Maintenance.Model['tag'],
    cost: Maintenance.Model['cost'],
    files?: UploadFile[] | undefined,
};

type Body = Parameters<typeof finishMaintenance>['1'];

export function FinishMaintenanceModal() {
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<FinishValues>();

    const app = App.useApp();

    const {
        maintenance,
        setIsFinishMaintenanceModal,
        fetchMaintenances,
    } = useMaintenance();


    if (!maintenance)
        throw new Error('Value of the `maintenance` property is unknown');

    useEffect(() => {

        const fetchData = async () => {
            setIsFetching(true);

            const response = await listSuppliers();

            if (!response.success)
                return;

            setSuppliers(response.suppliers);
            setIsFetching(false);
        };

        fetchData();
    }, [form]);

    const onFinish = async (values: FinishValues) => {
        setIsSending(true);

        const body: Body = {
            // TODO: Get this by back-end layer to improves security
            solution: values.solution,
            completionDate: values.completionDate,
            supplierId: values.supplierId,
            tag: values.tag,
            cost: values.cost
        };

        const response = await finishMaintenance(maintenance.id, body);

        if (!response.success) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles = values.files?.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
            maintenanceId: maintenance.id,
        })) ?? [];

        const promises = parsedFiles.map(file => uploadMaintenanceFile(file));

        const files = await Promise.all(promises);

        // Maybe we have more errors, but it'is not so important in this context
        const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

        if (firstErrorFile !== undefined)
            return handleServiceError(app, firstErrorFile);

        notification.open({
            type: response.type,
            message: response.message,
            description: response.description
        });

        setIsSending(false);
        fetchMaintenances();
        setIsFinishMaintenanceModal(false);
    };

    const parsedSuppliers: SelectOptions = suppliers.map(({ id, name }) => ({ value: id, label: name }));

    useEffect(() => {
        const files: UploadFile[] = maintenance.files.map(file => (
            {
                uid: file.url,
                name: file.filename,
                url: file.url,
                status: 'done',
            }
        ));

        form.setFieldsValue({
            files
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenance]);

    return (
        <Modal
            title="Finalizar manutenção"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Finalizar"
            onCancel={() => setIsFinishMaintenanceModal(false)}
            cancelText="Cancelar"
            width={1000}
            open
        >
            <Divider />

            <Form
                initialValues={maintenance}
                form={form}
                onFinish={onFinish}
                name="finishMaintenance"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FinishValues>
                            name="completionDate"
                            label="Quando a manutenção foi realizada?"
                            rules={[{ required: true, message: 'Selecione uma data' }]}
                        >
                            <Input type='date' />
                        </Form.Item>

                        <Form.Item<FinishValues>
                            name="tag"
                            label="O objetivo da manutenção foi?"
                            rules={[{ required: true, message: 'Selecione uma opção' }]}>
                            <Radio.Group>
                                <Radio value="predictive" >Preditivo</Radio>
                                <Radio value="corrective" >Corretivo</Radio>
                                <Radio value="preventive" >Preventivo</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item<FinishValues>
                            name="solution"
                            label="Detalhes sobre a finalização"
                            tooltip='Para garantir um histórico completo, por favor, forneça o máximo de informações relevantes.'
                        >
                            <ReactQuill theme="snow" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item<FinishValues>
                            name="supplierId"
                            label="Qual fornecedor realizou a manutenção?"
                            help="Se foi realizado pela equipe interna, você pode deixar em branco."
                        >
                            <Select
                                options={parsedSuppliers}
                                loading={isFetching}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item<FinishValues>
                            name="cost"
                            label="Qual o custo final da manutenção?"
                            help="Se não houve nenhum custo, você pode deixar em branco."
                        >
                            <InputNumber
                                addonBefore="R$"
                                decimalSeparator=","
                                min={1}
                                precision={2}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <UploadField 
                            name="files"
                            buttonText="Anexar documentos e imagens"
                            type="picture"
                            multiple
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
