import React, { useState } from 'react';

import {
    App,
    Button,
    List,
    Modal,
    Popconfirm,
    Typography
} from 'antd';
import Upload, { RcFile, UploadFile } from 'antd/es/upload';

import { CheckCircleOutlined, CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Show } from 'lib/Show';
import { reconcileUnitsWithDebit } from 'services/ApartmentService';
import { ReconcileUnitsWithDebit } from 'services/contracts/ApartmentServiceContract';

type Props = {
    close: () => void
};

export type Values = {
    towers: Tower.Model[]
};

export function ModalReconcile({ close }: Props) {
    const app = App.useApp();

    const [file, setFile] = useState<UploadFile | null>(null);
    const [result, setResult] = useState<ReconcileUnitsWithDebit.Result>();

    const handleUpload = async () => {
        if (!file) {
            app.notification.error({ message: 'Por favor, selecione um arquivo primeiro.' });
            return;
        }

        const response = await reconcileUnitsWithDebit(file as RcFile);

        if (response.success) {
            app.notification.success({ message: response.message });
            setResult(response.result);
        }
    };

    const uploadProps = {
        beforeUpload: (file: UploadFile) => {
            setFile(file);
            return false;
        },
        fileList: file ? [file] : [],
    };

    const renderFailedItem = (item: string) => (
        <Typography.Paragraph>
            <CloseCircleOutlined style={{ color: 'red' }} />
            <Typography.Text strong> {item}</Typography.Text>
        </Typography.Paragraph>
    );


    const renderSuccessItem = (item: string) => (
        <Typography.Paragraph>
            <CheckCircleOutlined style={{ color: 'green' }} />
            <Typography.Text strong> {item}</Typography.Text>
        </Typography.Paragraph>
    );

    return (
        <Modal
            open
            width={610}
            title="Atualizar unidades inadimplentes"
            footer={null}
            onCancel={close}
        >
            <Show when={!result}>
                <Upload.Dragger {...uploadProps} style={{ marginTop: 16 }}>
                    Realizar upload do arquivo preenchido <UploadOutlined />
                </Upload.Dragger>
                <Popconfirm
                    title="Tem certeza que deseja realizar a atualização?"
                    description="Após a confirmação, essa ação não poderá ser cancelada."
                    onConfirm={handleUpload}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button
                        type="primary"
                        style={{ marginTop: 16 }}
                        disabled={!file}
                    >
                        Atualizar
                    </Button>
                </Popconfirm>
            </Show>

            {/* eslint-disable-next-line eqeqeq */}
            <Show when={result != undefined}>
                <div className="desc">
                    <Typography.Paragraph>
                        <Typography.Text
                            strong
                            style={{
                                fontSize: 16,
                            }}
                        >
                            Resultado da atualização:
                        </Typography.Text>
                    </Typography.Paragraph>

                    { }
                    <Show when={result !== undefined && result.success.length > 0}>
                        <List
                            dataSource={result?.success}
                            renderItem={renderSuccessItem}
                        />
                    </Show>

                    <Show when={result !== undefined && result.failed.length > 0}>
                        <List
                            dataSource={result?.failed}
                            renderItem={renderFailedItem}
                        />
                    </Show>

                    <Show when={result !== undefined}>
                        <Typography.Paragraph style={{ marginTop: 16 }}>
                            <Typography.Link onClick={() => setResult(undefined)} strong>Clique aqui para realizar uma nova atualização.</Typography.Link>
                        </Typography.Paragraph>
                    </Show>

                </div>
            </Show>
        </Modal>
    );
}
