import React, { useCallback, useEffect, useRef, useState } from 'react';

import { 
    App, 
    Checkbox, 
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    UploadFile,
} from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

import { useNotice } from 'lib/providers/NoticeContextProvider';
import { UploadField } from 'lib/UploadField';

import { Show } from '../../lib/Show';
import { updateNotice } from '../../services/Notice.service';
import { SchedulesField } from './SchedulesField';
import { useNoticeOptions } from './useNoticeOptions';

type SendingMethod = 'email' | 'whatsapp' | 'push';

export type Values = {
    title: Notice.Model['title'],
    body: Notice.Model['body'],
    sendingMethods: SendingMethod[],
    recipient: Notice.Model['recipient'],
    towers?: Notice.Model['towers'], 
    apartments?: Notice.Model['apartments'],
    immediateDispatch: Notice.Model['immediate_dispatch'],
    schedules?: Array<Notice.ScheduleModel>,
    files?: Array<UploadFile>,
};

export function EditNoticeModal() {
    const [isSending, setIsSending] = useState(false);

    const {
        notice, 
        setNoticeId, 
        setIsEditModalVisible,
        fetchNotices,
    } = useNotice();

    if (notice === null)
        throw new TypeError('Null value was provided to `notice` constant!');

    const close = () => {
        setIsEditModalVisible(false);
        setNoticeId(null);
    };

    const [form] = Form.useForm<Values>();

    const watchedTowers = Form.useWatch('towers', form);
    const watchedApartments = Form.useWatch('apartments', form);

    const {
        isFetching,
        towerOptions,
        apartmentOptions,
        isApartmentsFieldVisible,
        isTowersFieldRequired,
    } = useNoticeOptions(form);

    const isApartmentOptionsFirstValueRef = useRef<boolean>();

    const handleApartmentOptionsFirstValueRef = () => {
        if (isApartmentOptionsFirstValueRef.current === undefined)
            return isApartmentOptionsFirstValueRef.current = true;

        if (isApartmentOptionsFirstValueRef.current === true)
            return isApartmentOptionsFirstValueRef.current = false;
    };

    useEffect(() => {
        handleApartmentOptionsFirstValueRef();
    }, [apartmentOptions]);

    /** @todo Improves performance */
    const filterValidApartments = () => {
        /** The {@link apartmentOptions} state is default value yet, so will need to skip the filtering */
        if (isApartmentOptionsFirstValueRef.current)
            return;

        // Is the first render, so the `watchedTowers` constant is not updated yet and will need to skip the filtering
        if (notice.towers && !watchedTowers)
            return;

        // Clear the field when no towers are selected
        if (!watchedTowers || watchedTowers.length === 0)
            return form.setFieldValue('apartments', []);

        // Skip when no apartments are selected
        if (!watchedApartments || watchedApartments.length === 0)
            return;

        const selectedApartments = apartmentOptions.filter(apartment =>
            watchedApartments.includes(apartment.value as number)
        );
    
        const validApartments = selectedApartments.filter(apartment =>
            watchedTowers.includes(apartment.towerId)
        );


        form.setFieldValue('apartments', validApartments.map(apartment => apartment.value));
    };
    
    const memoizedFilterValidApartments = useCallback(
        filterValidApartments, 
        [
            notice.towers,
            watchedTowers,
            watchedApartments,
            apartmentOptions,
            form
        ],
    );

    useEffect(() => {
        memoizedFilterValidApartments();
    }, [memoizedFilterValidApartments]);

    const app = App.useApp();

    const onFinish = async ({
        files,
        sendingMethods,
        schedules,
        towers,
        apartments,  
        recipient,
        title,
        body: bodyValue,
    }: Values) => {
        setIsSending(true);

        const parsedFiles = files?.map(file => ({ url: file.response || file.url, filename: file.name }));

        const body: Parameters<typeof updateNotice>['1'] = {
            files: parsedFiles ?? [],
            sendEmail: sendingMethods.includes('email'),
            sendWhatsApp: sendingMethods.includes('whatsapp'),
            sendPushNotification: sendingMethods.includes('push'),
            schedules: schedules ?? [],
            towers: towers ?? [],
            apartments: apartments ?? [],
            body: bodyValue,
            recipient,
            title,
        };

        const response = await updateNotice(notice.id, body);
        
        setIsSending(false);

        app.notification.open(response);

        fetchNotices();

        close();
    };

    const sendingMethodOptions: CheckboxGroupProps['options']  = [
        { label: 'E-mail', value: 'email' },
        // @ts-ignore
        { label: 'Whatsapp', value: 'whatsapp',  disabled: true, title: 'Entre em contato com o suporte para usar essa funcionalidade' },
        { label: 'Push Notification', value: 'push' },
    ];

    const sendingMethodsObjectNotation: Record<SendingMethod, boolean> = {
        email: notice.sendEmail,
        whatsapp: notice.sendWhatsApp,
        push: notice.sendPushNotification,
    };

    const sendingMethods = Object
        .entries(sendingMethodsObjectNotation)
        .filter(([, value]) => value === true)
        .map(([key]) => key as SendingMethod);

    const files: UploadFile[] = notice.files.map(({ url, filename }) => ({
        uid: url,
        url: url,
        name: filename,
        status: 'done',
    }));

    const initialValues: Values = {
        title: notice.title,
        body: notice.body,
        sendingMethods: sendingMethods,
        recipient: notice.recipient,
        towers: notice.towers,
        apartments: notice.apartments,
        immediateDispatch: notice.immediate_dispatch,
        schedules: notice.schedules,
        files,
    };

    return (
        <Modal
            open
            centered
            width={640}
            title="Editar aviso"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Editar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editNotice"
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
            >
                <Row>
                    <Col xs={24} lg={16}>
                        <Form.Item
                            name="title"
                            label="Título"
                            rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="body"
                    label="Conteúdo"
                    rules={[{ required: true, message: 'Por favor, digite algo.' }]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <UploadField name='files' label='' buttonText='Anexar documentos e imagens' type='picture' />

                <Form.Item
                    name="sendingMethods"
                    label="Método de envio"
                    tooltip="Push Notification: Notificar os moradores diretamente pelo aplicativo Gcondo."
                    rules={[{ required: true, message: 'Por favor, selecione ao menos uma opção.' }]}
                >
                    <Checkbox.Group options={sendingMethodOptions} />
                </Form.Item>

                <Form.Item 
                    name="recipient" 
                    label="Para quem enviar?"
                    rules={[{ required: true, message: 'Por favor, selecione uma opção.' }]}
                >
                    <Radio.Group>
                        <Radio value="owners">Proprietários</Radio>
                        <Radio value="residents">Moradores</Radio>
                        <Radio value="tenants">Inquilinos</Radio>
                        <Radio value="all">Todas as opções anteriores</Radio>
                    </Radio.Group>
                </Form.Item>

                <Row>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            name="towers"
                            label="Torres"
                            tooltip="Caso queira enviar para todas as torres, deixe este campo em branco."
                            rules={[{ required: isTowersFieldRequired, message: 'Por favor, selecione uma ou mais opções.' }]}
                        >
                            <Select options={towerOptions} loading={isFetching} mode="multiple" />
                        </Form.Item>
                    </Col>

                    <Show when={isApartmentsFieldVisible}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="apartments"
                                label="Apartamentos"
                                tooltip="Caso queira enviar para todos os apartamentos das torres selecionadas, deixe este campo em branco."
                                rules={[{ required: false, message: 'Por favor, selecione uma ou mais opções.' }]}
                            >
                                <Select options={apartmentOptions} loading={isFetching} mode="multiple" />
                            </Form.Item>
                        </Col>
                    </Show>
                </Row>

                <Form.Item 
                    name="immediateDispatch" 
                    label="Enviar imediatamente?"
                    tooltip="Este campo não pode ser alterado."
                >
                    <Radio.Group disabled>
                        <Radio value={false}>Não</Radio>
                        <Radio value={true}>Sim</Radio>
                    </Radio.Group>
                </Form.Item>

                <SchedulesField required={notice['immediate_dispatch'] !== true} />
            </Form>
        </Modal>
    );
}
