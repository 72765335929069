import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Authentication from 'lib/Authentication';
import { hasAuthRouteAccess } from 'lib/helpers/Router.helper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

import { AuthRoutes } from '../_Routes/Routes';

type WrapperProps = { children: ReactNode };

const wl = makeWhiteLabel();

/** @private Don't export this! */
const Wrapper = ({ children }: WrapperProps) => (
    <div className="header-logo-wrapper">
        {children}
    </div>
);

/** Maybe redirect to `overview` page when his have permission and trigger onClick! */
export function HeaderLogo() {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    const profile = Authentication.getUserProfile();
    const hasOverviewPageAccess = hasAuthRouteAccess(profile, AuthRoutes.overview);

    const logo = isMobile
        ? <img src={wl.icon_mini ?? wl.icon} alt="Gcondo logo" height="40" />
        : <img src={wl.icon} alt="Gcondo logo" height="40" />;

    if (!hasOverviewPageAccess)
        return <Wrapper>{logo}</Wrapper>;

    return (
        <Wrapper>
            <button
                type="button"
                className="header-logo-button"
                title="Navegar para Visão Geral"
            >
                <Link to={AuthRoutes.overview}>
                    {logo}
                </Link>
            </button>
        </Wrapper>
    );
}

