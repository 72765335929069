import React, { useEffect, useState } from 'react';

import { Card, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Indicator } from 'lib/Indicator';

import { listIssue } from '../../services/Issue.service';
import IssueChart from './IssueChart';

const columns: ColumnsType<Issue.Model> = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <>#{text}</>,
    },
    {
        title: 'Responsável',
        dataIndex: 'user_responsible_id',
        key: 'user_responsible_id',
        render: (_, issue) => issue.user_responsible
            ? issue.user_responsible.name
            : 'Sem responsável'
    },
    {
        title: 'Tipo',
        dataIndex: 'issue_type',
        key: 'issue_type',
        render: (_, issue) => issue.issue_type.name
    }
];

const IssueClient: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [averageTime, setAverageTime] = useState<number>(0);
    const [issues, setIssues] = useState<Array<Issue.Model>>([]);


    async function fetchData() {
        const promises = [];
        setLoading(true);

        promises.push(listIssue().then((response) => {
            if (!response.success)
                return;
            setIssues(response.issues);
            calculateAverageTime(response.issues);
        }));

        Promise.all(promises).then(() => setLoading(false));
    }

    function calculateAverageTime(data: Array<Issue.Model>) {
        // eslint-disable-next-line eqeqeq
        const issuesTimes = data.filter((i) => i.status == 'closed').map((i): number => {
            const start = dayjs(i.resolutionDate);
            return start.diff(i.created_at, 'days');
        });

        const sum = issuesTimes.reduce((a, b) => a + b, 0);
        const avg = (sum / issuesTimes.length) || 0;
        setAverageTime(Math.round(avg));
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Card title="Chamados" loading={loading}>
                    <Row>
                        <Col sm={24} md={24} lg={10}>
                            <Indicator
                                label="Tempo médio de resolução de Chamados"
                                value={`${averageTime} dias`}
                                icon={<ClockCircleOutlined />}
                                iconColor='#0B1A62'
                                iconBackgroundColor={null}
                                backgroundColor="#EDEDF0"
                                size="small"
                            />

                            <IssueChart />
                        </Col>
                        <Col sm={24} md={24} lg={14}>
                            <Table
                                showHeader={false}
                                pagination={false}
                                title={() => 'Chamados pendentes'}
                                // eslint-disable-next-line eqeqeq
                                dataSource={issues?.filter((i) => i.status == 'opened')}
                                columns={columns}
                                scroll={{ x: true }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );

};

export default IssueClient;