import React, { Dispatch, SetStateAction } from 'react';

import type { ColumnsType } from 'antd/es/table';

import { SupplierActionsCell } from './SupplierActionsCell';

export function getColumns(
    setOpen: Dispatch<SetStateAction<any>>, 
    setSupplierId: Dispatch<SetStateAction<any>>,
    deleteSupplier: (id: Supplier.Model['id']) => Promise<void>, 
    serviceCategories: Supplier.ServiceCategory[]
) {

    const Columns: ColumnsType<Supplier.Model> = [
        { title: 'Nome', dataIndex: 'name', key: 'name' },
        {
            title: 'Serviços', key: 'service_category', render: (_, supplier) => (
                supplier.supplier_service_category.map(service =>
                    service.service_category.name).join(', ')),
            filters: serviceCategories.map(s => (
                {
                    value: s.id,
                    text: s.name
                }
            )),
            onFilter: (value, supplier) => {
                // eslint-disable-next-line eqeqeq
                return supplier.supplier_service_category.some((s) => s.service_category_id == value);
            },
            filterSearch: true,
        },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Responsável', dataIndex: 'accountable', key: 'accountable' },
        { title: 'Celular', dataIndex: 'phone_1', key: 'phone_1' },
        { title: 'Telefone', dataIndex: 'phone_2', key: 'phone_2' },
        {
            key: 'action',
            render: (_, supplier) => (
                <SupplierActionsCell
                    onEdit={() => {
                        setOpen(true);
                        setSupplierId(supplier.id);
                    }}
                    onRemove={() => deleteSupplier(supplier.id)}
                />
            )
        }
    ];

    return Columns;
};