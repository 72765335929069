import React, { Component } from 'react';

import { Divider, Modal, Popconfirm, Typography } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import API from 'lib/API';

import { ApartmentInterface } from '../../_Common/_Interfaces/Apartment';
import { ResidentInterface } from '../../_Common/_Interfaces/Resident';
import { VehicleInterface } from '../../_Common/_Interfaces/Vehicle';
import List from '../../_Common/List';
import FormVehicle from './FormVehicle';

type State = {
    vehicles: Array<VehicleInterface>;
    vehicle: VehicleInterface;
    apartments: Array<ApartmentInterface>;
    showFormVehicle: boolean;
    loading: boolean;
};

type Props = {
    resident: ResidentInterface;
    isVisible: boolean;
    hideModalForm: Function;
};

class ListVehicle extends Component<Props, State> {
    state: State = {
        vehicles: [],
        vehicle: {} as VehicleInterface,
        apartments: [],
        loading: true,
        showFormVehicle: false
    };

    columns = [
        {
            title: 'Tipo', key: 'type', dataIndex: 'type', render: (_: any, vehicle: VehicleInterface) => {
                if (vehicle.type === 'car')
                    return 'Carro';
                else if (vehicle.type === 'motorbike')
                    return 'Moto';

                return 'Outro';
            }
        },
        { title: 'Marca', key: 'brand', dataIndex: 'brand' },
        { title: 'Modelo', key: 'model', dataIndex: 'model' },
        { title: 'Placa', key: 'board', dataIndex: 'board' },
        {
            key: 'action',
            render: (_: any, vehicle: VehicleInterface) => (
                <>
                    <EditOutlined onClick={() => this.setState({ vehicle: vehicle, showFormVehicle: true })} />
                    <Divider type="vertical" />
                    <Popconfirm title={'Deseja remover?'}
                        placement="top"
                        onConfirm={() => this.onDelete(vehicle)}
                        cancelText={'Cancelar'}
                        okText={'Deletar'}
                        okType={'danger'}>
                        <DeleteOutlined />
                    </Popconfirm>
                </>)
        }
    ];

    onCancel = () => {
        this.setState({vehicles: [], vehicle: {} as VehicleInterface});
        this.props.hideModalForm();
    };

    fetchData = async (apartmentId: number) => {
        this.setState({ loading: true });
        const vehicles = await API.get(`/vehicle/${apartmentId}`);
        this.setState({ vehicles, loading: false });
    };

    onDelete = async (vehicle: VehicleInterface) => {
        this.setState({ loading: true });
        await API.delete(`/default/vehicle/${vehicle.id}`);
        await this.fetchData(vehicle.apartmentId);
    };

    componentDidUpdate(prevProps: Props) {
        const { resident } = this.props;
        // eslint-disable-next-line eqeqeq
        if (resident != prevProps.resident && resident.id) {
            this.fetchData(resident.apartmentId);
        }
    };

    render() {
        const { isVisible, resident } = this.props;
        const { vehicles, vehicle, loading, showFormVehicle } = this.state;

        return (
            <>
                <Modal
                    key={resident.apartmentId}
                    width={1000}
                    centered
                    title={`Veículo(s) do morador ${resident.name}`}
                    destroyOnClose={true}
                    open={isVisible}
                    onCancel={this.onCancel}
                    footer={null}
                >
                    <Typography.Link className="right" onClick={() => this.setState({ vehicle: {} as VehicleInterface, showFormVehicle: true })}>
                        Clique aqui para adicionar um veículo
                    </Typography.Link>
                    <List columns={this.columns} data={vehicles} loading={loading} />
                </Modal>
                <FormVehicle apartmentId={resident.apartmentId}
                    vehicle={vehicle}
                    isVisible={showFormVehicle}
                    hideModalForm={() => {
                        this.setState({ showFormVehicle: false });
                        this.fetchData(resident.apartmentId);
                    }} />
            </>
        );
    }
}

export default ListVehicle;
