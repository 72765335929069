import React from 'react';

import { Button, Form, FormItemProps, notification, Space, Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadChangeParam } from 'antd/lib/upload/interface';

import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getPresignedUrl } from 'services/Upload.service';

import { normFile } from './helpers/File.helper';

type Props = {
    name: FormItemProps['name'],
    label?: FormItemProps['label'],
    rules?: FormItemProps['rules'],
    buttonText?: string,
    type?: UploadProps['listType'],
    maxCount?: UploadProps['maxCount'],
    multiple?: UploadProps['multiple'],
}

const makeContent = (type: Props['type'], buttonText: Props['buttonText']) => {
    const label = buttonText ?? 'Carregar';
    if (type === 'picture-card')
        return (
            <Space>
                <UploadOutlined />
                {label}
            </Space>
        );

    return (
        <Button icon={<UploadOutlined />}>
            {label}
        </Button>
    );
};

const onChange = (info: UploadChangeParam) => {
    const { file } = info;

    if (file.status === 'done')
        file.response = `https://gcondo-files.s3.sa-east-1.amazonaws.com/${file.name}`;
};

const beforeUpload = (file: RcFile) => {
    const tag = dayjs().format('mmss'); // Define sua tag aqui
    const modifiedFile = new File([file], `${tag}_${file.name}`, { type: file.type });
    return modifiedFile;
};

export function UploadField({ name, label, rules, buttonText, type, maxCount, multiple }: Props) {
    const content = makeContent(type, buttonText);

    const uploadToS3 = async ({ file, onSuccess, onError, onProgress }: any) => {
        try {
            const response = await getPresignedUrl(file.name, file.type);

            if (!response.success)
                return notification.error({ message: 'Atenção', description: `Erro ao realizar upload do arquivo: ${file.name}` });


            const responseS3 = await fetch(response.upload_url, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                    'x-amz-acl': 'public-read'
                },
                body: file
            });

            if (!responseS3.ok) {
                throw new Error('Upload failed');
            }

            if (onProgress) {
                onProgress({ percent: 100 });
            }

            onSuccess(null, file);
        } catch (error) {
            onError(error);
        }
    };

    return (
        <Form.Item
            name={name}
            label={label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={rules}
        >
            <Upload
                listType={type}
                maxCount={maxCount}
                customRequest={uploadToS3}
                onChange={onChange}
                beforeUpload={beforeUpload}
                multiple={multiple}
            >
                {content}
            </Upload>
        </Form.Item>
    );
}