import React, { useState } from 'react';

import { App, Col, Divider, Form, Input, InputNumber, Modal, Row } from 'antd';

import { sleep } from 'lib/Sleep';
import { createApartmentType } from 'services/ApartmentService';

type Props = {
    close: () => void,
    fetchTypes: () => void,
};

export type Values = {
    name: string,
    idealFraction: number,
};

export function CreateTypeModal({ close, fetchTypes }: Props) {
    const [isSending, setIsSending] = useState(false);

    const [form] = Form.useForm<Values>();

    const app = App.useApp();

    const handleFinish = async (values: Values) => {
        setIsSending(true);

        const response = await createApartmentType(values);

        // Improves UX
        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        close();
        fetchTypes();
    };

    return (
        <Modal
            open
            width={610}
            title="Cadastrar tipo"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
        >
            <Divider />

            <Form
                form={form}
                onFinish={handleFinish}
                name="createType"
                layout="vertical"
                autoComplete="off"
            >
                <Row>
                    <Col span="12">
                        <Form.Item<Values>
                            name="name"
                            label="Nome"
                            rules={[{
                                required: true,
                                message: 'Por favor, digite um nome.'
                            }]}
                        >
                            <Input data-1p-ignore />
                        </Form.Item>
                    </Col>

                    <Col span="12">                
                        <Form.Item<Values>
                            name="idealFraction"
                            label="Cota condominial"
                            rules={[{
                                required: true,
                                message: 'Por favor, digite um valor.'
                            }]}
                        >
                            <InputNumber
                                decimalSeparator=","
                                min={0}
                                max={1}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
