import React, { Fragment } from 'react';

import { Button, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateConstructionModal } from 'components/Construction/CreateConstructionModal';
import { DetailConstructionModal } from 'components/Construction/DetailConstructionModal';
import { EditConstructionModal } from 'components/Construction/EditConstructionModal';
import { ConstructionContextProvider } from 'lib/providers/ConstructionContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Constructions() {
    return (
        <ConstructionContextProvider>
            {({
                constructions,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
                isDetailModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Obras
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <List
                            data={constructions}
                            columns={Columns} 
                            loading={isLoading}                        />
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateConstructionModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditConstructionModal />
                    </Show>

                    <Show when={isDetailModalVisible}>
                        <DetailConstructionModal />
                    </Show>
                </Fragment>
            )}
        </ConstructionContextProvider>
    );
}
