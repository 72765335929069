import React, { useState } from 'react';

import { 
    App,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    SelectProps,
} from 'antd';

import Authentication from 'lib/Authentication';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { sleep } from 'lib/Sleep';
import { createConstruction } from 'services/Construction.service';

export type Values = {
    name: string,
    description: Construction.Model['description'],
    start_date: Construction.Model['start_date'],
    expected_end_date: Construction.Model['expected_end_date'],
    end_date: Construction.Model['end_date'],
    approved_budget: Construction.Model['approved_budget'],
    final_budget: Construction.Model['final_budget'],
    client_id: number,
};

type SelectOptions = NonNullable<SelectProps['options']>;

export function CreateConstructionModal() {
    const [isSending, setIsSending] = useState(false);
    const { setIsCreateModalVisible, fetchConstructions } = useConstruction();

    const app = App.useApp();

    const [form] = Form.useForm<Values>();

    const close = () => setIsCreateModalVisible(false);

    const onFinish = async ({
        name,
        description,
        start_date,
        expected_end_date,
        approved_budget,
        client_id,}: Values) => {
        setIsSending(true);

        const response = await createConstruction({
            name,
            description,
            start_date,
            expected_end_date,
            approved_budget,
            client_id,
        });

        // Slow down a little to improve UX
        await sleep(1000);

        setIsSending(false);

        if (!response.success) {
            app.notification.error({
                message: 'Atenção',
                description: response.message,
            });
            return;
        }

        app.notification.success({
            message: response.message,
            description: response.data.description,
        });

        fetchConstructions();
        close();
    };

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    const filterOption = makeFilterOption();

    return (
        <Modal
            title="Criar Obra"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={close}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="createConstruction"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    label="Título"
                    rules={[{ required: true, message: 'Por favor, digite um título.' }]}
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Insira uma descrição"
                >
                    <Input maxLength={500} />
                </Form.Item>

                <Form.Item
                    name="client_id"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select options={clients} filterOption={filterOption} />
                </Form.Item>

                <Form.Item
                    name="start_date"
                    label="Data inicial"
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>

                <Form.Item
                    name="expected_end_date"
                    label="Data esperada"
                >
                    <DatePicker
                        size='middle'
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>

                <Form.Item
                    name="approved_budget"
                    label="Orçamento aprovado"
                >
                    <InputNumber
                        addonBefore="R$"
                        decimalSeparator=","
                        min={1}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

