import React, { useState } from 'react';

import { Button, Popconfirm, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { AlertOutlined, CheckCircleOutlined, DeleteOutlined, DollarOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { usePayment } from 'lib/providers/PaymentContextProvider';
import { Show } from 'lib/Show';
import { deletePayment } from 'services/Payment.service';

export const renderStatusCard = (payment: Payment.Model) => {
    if (payment.status === 'payment_made')
        return <Tag icon={<CheckCircleOutlined />} color="success">Realizado</Tag>;

    if (isBeforeToday(payment.due_date))
        return <Tag icon={<AlertOutlined />} color='error'>Atrasado</Tag>;

    return <Tag icon={<ExclamationCircleOutlined />} color='warning'>Pendente</Tag>;
};

export const isBeforeToday = (dateString: string): boolean => {
    const [year, month, day] = dateString.split('-').map(Number);

    const date = new Date(year, month - 1, day);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return date < today;
};

const PaymentActions = ({ payment }: { payment: Payment.Model }) => {
    const [isSending, setIsSending] = useState(false);

    const { fetchPayments, setIsEditModalVisible, setPaymentId, setIsPaymentMadeModalVisible, setIsViewModalVisible } = usePayment();

    const handleEdit = () => {
        setPaymentId(payment.id);
        setIsEditModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        await deletePayment(payment.id);

        setIsSending(false);

        fetchPayments();
    };

    const handlePaymentMade = async () => {
        setPaymentId(payment.id);
        setIsPaymentMadeModalVisible(true);
    };

    const handleView = async () => {
        setPaymentId(payment.id);
        setIsViewModalVisible(true);
    };

    return (
        <Space size="middle">
            <Button size="small" onClick={handleEdit} title="Editar" style={{ marginRight: 8 }}>
                <EditOutlined />
            </Button>

            <Popconfirm
                title="Deletar pagamento"
                description="Você tem certeza que deseja deletar este pagamento?"
                onConfirm={handleDelete}
                okText="Sim"
                cancelText="Não"
            >
                <Button size="small" title="Excluir" disabled={isSending} style={{ marginRight: 8 }}>
                    <DeleteOutlined style={{ color: 'red' }} />
                </Button>
            </Popconfirm>

            <Show when={payment.status === 'pending_payment'}>
                <Button size="small" title="Realizar pagamento" onClick={handlePaymentMade} disabled={isSending}>
                    <DollarOutlined />
                </Button>
            </Show>

            <Show when={payment.status === 'payment_made'}>
                <Button size="small" onClick={handleView} title="Visualizar">
                    <EyeOutlined />
                </Button>
            </Show>
        </Space>
    );
};

export const Columns: ColumnsType<Payment.Model> = [
    {
        title: 'Título',
        dataIndex: 'name',
    },

    {
        title: 'Condomínio',
        dataIndex: ['client', 'name'],
        key: 'clientName'
    },

    {
        title: 'Status',
        key: 'status',
        render: (_, payment) => renderStatusCard(payment),
    },

    {
        title: 'Categoria',
        dataIndex: 'category',
        render: (category) => category.name,
    },

    {
        title: 'Tipo',
        dataIndex: 'type',
        render: (type) => {
            if (type === 'single')
                return 'Simples';

            if (type === 'immediate_recurrent')
                return 'Recorrente imediato';

            return 'Recorrente';
        },
    },

    {
        title: 'Data de vencimento',
        dataIndex: 'due_date',
        render: (due_date) => (dayjs(due_date).format('DD/MM/YYYY')),
    },

    {
        title: 'Valor',
        dataIndex: 'value',
        render: value => {
            const BRL = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            return BRL.format(value);
        }
    },

    {
        title: 'Ações',
        key: 'action',
        width: '200px',
        render: (_, payment) => <PaymentActions payment={payment} />
    }
];
