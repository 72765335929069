import React from 'react';

import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import Authentication from 'lib/Authentication';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { Show } from 'lib/Show';
import { Filter } from 'pages/issue/Filters';

Font.register({ family: 'Montserrat', src: '/assets/fonts/Montserrat/static/Montserrat-Regular.ttf' });
Font.register({ family: 'Montserrat Bold', src: '/assets/fonts/Montserrat/static/Montserrat-Bold.ttf' });

const wl = makeWhiteLabel();

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Montserrat',
        flexDirection: 'column', // Define o fluxo das páginas para "coluna"
        backgroundColor: '#ffffff',
    },
    section: {
        margin: 5,
        marginLeft: 20,
        padding: 5,
    },
    title: {
        fontSize: 16,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    subtitle: {
        fontSize: 12,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    subtitle2: {
        fontSize: 10,
        fontFamily: 'Montserrat Bold',
        marginBottom: 5,
    },
    image: {
        width: 150
    },
    imageContainer: {
        width: '48%',
        margin: 2,
    },
    imageIssue: {
        width: '100%',
        height: 'auto',
    },
    paragraph: {
        fontSize: 10,
        marginBottom: 5,
    },
});

const pageProps = {
    title: 'Relatório de chamados',
    author: 'Gcondo',
    subject: 'Gerado automaticamente via sistema Gcondo'
};

type Props = {
    issues: Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client'>[],
    filters: Filter
};

export function IssuePDF({ issues, filters }: Props) {
    const withImage = (file: Issue.IssueFile) => ['.png', '.jpg', '.jpeg'].some(type => file.url && file.url.includes(type));
    const client = Authentication.getClients().find(client => client.id === Authentication.getCurrentClientId());
    const issuesClosed = issues.filter(issue => issue.status === 'closed');
    const issuesOpened = issues.filter(issue => issue.status === 'opened');

    function Logos() {
        return <View style={{
            ...styles.section,
            marginTop: 0,
            marginBottom: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center'
        }}>
            <View>
                <Show when={client?.client_manager.logo === null || client?.client_manager.logo === undefined}>
                    <Image cache={false} source={wl.icon} style={styles.image} />
                </Show>
                <Show when={client?.client_manager.logo !== null}>
                    <Image source={client?.client_manager.logo} style={styles.image} />
                </Show>
            </View>
        </View>;
    };

    function Header() {
        return <View style={styles.section}>
            <Text style={styles.title}>{client?.name} - Relatório de chamados</Text>
            <Text style={styles.paragraph}>
                Período completo
            </Text>
        </View>;
    };

    function renderIssuesClosed() {
        if (issuesClosed.length === 0)
            return <Text style={styles.paragraph}>Nenhum chamado resolvido neste período.</Text>;

        return issuesClosed.map((issue, index) =>
            <View 
                break={issue.issue_file.filter(withImage).length > 0 && index > 0} 
                style={{ 
                    marginBottom: 15,
                    marginTop: 15,
                    borderBottom: 1,
                    borderColor: 'gray'
                }}
                key={issue.id}
            >
                <Text style={styles.subtitle}>
                    {issue.issue_type.name} #{issue.id}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Data de abertura:</Text>
                    {' '}
                    {dayjs(issue.creation_date).format('DD/MM/YYYY')}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Assunto:</Text>
                    {' '}
                    {issue.subject}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Descrição:</Text>
                    {' '}
                    {issue.description}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Categoria:</Text>
                    {' '}
                    {issue.issue_type.name}
                </Text>

                <Show when={issue.user_responsible !== null}>
                    <Text style={styles.paragraph}>
                        <Text style={{ fontFamily: 'Montserrat Bold' }}>Responsável:</Text>
                        {' '}
                        {issue.user_responsible?.name}
                    </Text>
                </Show>

                <Show when={issue.deadline !== null}>
                    <Text style={styles.paragraph}>
                        <Text style={{ fontFamily: 'Montserrat Bold' }}>Prazo estimado para finalização:</Text>
                        {' '}
                        {dayjs(issue.deadline).format('DD/MM/YYYY')}
                    </Text>
                </Show>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Data de finalização:</Text>
                    {' '}
                    {dayjs(issue.resolutionDate).format('DD/MM/YYYY HH:mm')}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Solução:</Text>
                    {' '}
                    {issue.finalization}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Resolvido em:</Text>
                    {' '}
                    {dayjs(issue.resolutionDate).diff(dayjs(issue.creation_date), 'day')} dia(s).
                </Text>

                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    {
                        issue.issue_file.filter(withImage).map(file =>
                            <View key={file.id} style={styles.imageContainer}>
                                <Image src={file.url} style={styles.imageIssue} />
                            </View>
                        )
                    }
                </View>
            </View>);
    };

    function renderIssuesOpened() {
        if (issuesOpened.length === 0)
            return <Text style={styles.paragraph}>Nenhum chamado pendente.</Text>;

        return issuesOpened.map((issue, index) =>
            <View 
                break={issue.issue_file.filter(withImage).length > 0 && index > 0} 
                style={{ 
                    marginBottom: 15,
                    marginTop: 15,
                    borderBottom: 1,
                    borderColor: 'gray'
                }}
                key={issue.id}
            >
                <Text style={styles.subtitle}>
                    {issue.issue_type.name} #{issue.id}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Data de abertura:</Text>
                    {' '}
                    {dayjs(issue.creation_date).format('DD/MM/YYYY HH:mm')}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Assunto:</Text>
                    {' '}
                    {issue.subject}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Descrição:</Text>
                    {' '}
                    {issue.description}
                </Text>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Categoria:</Text>
                    {' '}
                    {issue.issue_type.name}
                </Text>

                <Show when={issue.user_responsible !== null}>
                    <Text style={styles.paragraph}>
                        <Text style={{ fontFamily: 'Montserrat Bold' }}>Responsável:</Text>
                        {' '}
                        {issue.user_responsible?.name}
                    </Text>
                </Show>

                <Show when={issue.deadline !== null}>
                    <Text style={styles.paragraph}>
                        <Text style={{ fontFamily: 'Montserrat Bold' }}>Prazo estimado para finalização:</Text>
                        {' '}
                        {dayjs(issue.deadline).format('DD/MM/YYYY')}
                    </Text>
                </Show>

                <Text style={styles.paragraph}>
                    <Text style={{ fontFamily: 'Montserrat Bold' }}>Pendente:</Text>
                    {' '}
                    {dayjs().diff(dayjs(issue.creation_date), 'day')} dia(s).
                </Text>
                
                {
                    issue.issue_follow_up.filter(fup => !fup.is_internal).length > 0
                        ? <>
                            <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>Acompanhamentos: </Text></Text>
                            {issue.issue_follow_up.filter(fup => !fup.is_internal).map(f =>
                                <Text style={styles.paragraph}><Text style={{ fontFamily: 'Montserrat Bold' }}>{f.user.name} ({dayjs(f.created_at).format('DD/MM/YYYY HH:mm')}): </Text>{f.description}</Text>
                            )}
                        </>
                        : null
                }
                <View style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    {
                        issue.issue_file.filter(withImage).map(file =>
                            <View key={file.id} style={styles.imageContainer}>
                                <Image src={file.url} style={styles.imageIssue} />
                            </View>
                        )
                    }
                </View>
            </View>);
    };

    return <PDFViewer style={{ width: '100%', height: '500px' }}>
        <Document>
            {
                issues.filter(issue => issue.status === 'closed').length > 0
                    ? <Page {...pageProps} size="A4" wrap style={styles.page}>
                        <Logos />
                        <Header />
                        <View style={styles.section}>
                            <Text style={{ ...styles.title, textAlign: 'center', border: 1 }}>Chamados resolvidos</Text>
                            {renderIssuesClosed()}
                        </View>
                    </Page>
                    : null
            }
            {
                issues.filter(issue => issue.status === 'opened').length > 0
                    ? <Page {...pageProps} size="A4" wrap style={styles.page}>
                        <Logos />
                        <Header />
                        <View style={styles.section}>
                            <Text style={{ ...styles.title, textAlign: 'center', border: 1 }}>Chamados pendentes</Text>
                            {renderIssuesOpened()}
                        </View>
                    </Page>
                    : null
            }
        </Document>
    </PDFViewer>;
};