import React, { } from 'react';

import { Card } from 'antd';

import { Pie } from '@ant-design/plots';
import { useStats } from 'lib/providers/StatsContextProvider';

export function ChartIssuesByType() {
    const { stats, isLoading } = useStats();

    const data = stats.total_issues_by_type ?? [];
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'name',
        radius: 0.9,
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    return <Card title="Chamados por categoria">
        <Pie {...config} loading={isLoading} />
    </Card>;
};