import API from '../lib/API';
import { CreateNotice, DeleteNotice, FindNotice, ListNextNoticeSchedules, ListNotices, UpdateNotice } from './contracts/Notice.contract';

const route = '/notice';

export function listNotices(): Promise<ListNotices.Response> {
    return API.get(route);
};

export function findNotice(id: Notice.Model['id']): Promise<FindNotice.Response> {
    return API.get(`${route}/${id}`);
};

export function createNotice(body: CreateNotice.Body): Promise<CreateNotice.Response> {
    return API.post(route, body);
};

export function updateNotice(id: Notice.Model['id'], body: UpdateNotice.Body): Promise<UpdateNotice.Response> {
    return API.put(`${route}/${id}`, body);
};

export function listNextNoticeSchedules(): Promise<ListNextNoticeSchedules.Response> {
    return API.get(`${route}/next`);
};

export function deleteNotice(id: Notice.Model['id']): Promise<DeleteNotice.Response> {
    return API.delete(`${route}/${id}`);
};