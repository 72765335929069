import React, { Fragment } from 'react';

import { Button, Card, Col, Row, Tree, Typography } from 'antd';
import { DataNode } from 'antd/es/tree';

import { DownOutlined, FilePdfOutlined, FontSizeOutlined, OrderedListOutlined, WhatsAppOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import { CreateChatbotModal } from 'components/ChatBot/CreateChatbotModal';
import { EditChatbotModal } from 'components/ChatBot/EditChatbotModal';
import { ChatbotContextProvider } from 'lib/providers/ChatbotContextProvider';
import { Show } from 'lib/Show';

export function Chatbot() {

    const icon = (type: string) => {
        switch (type) {
        case 'text':
            return <FontSizeOutlined />;
        case 'file':
            return <FilePdfOutlined />;
        case 'talk':
            return <WhatsAppOutlined />;
        case 'parent':
            return <OrderedListOutlined />;
        default:
            break;
        }
    };

    const createNode = (option: Chatbot.Model): DataNode => {
        return {
            key: option.id,
            title: `${option.number} - ${option.title}`,
            icon: icon(option.type),
            children: option.children.map(children => createNode(children))
        };
    };

    return (
        <ChatbotContextProvider>
            {({
                chatbot,
                isCreateModalVisible,
                isEditModalVisible,
                setIsCreateModalVisible,
                setIsEditModalVisible,
                setChatbotId
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Chatbot
                            </Typography.Title>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card
                                    title="Menu disponível"
                                    actions={[
                                        <Button type='primary' onClick={() => setIsCreateModalVisible(true)}>
                                            Cadastrar nova opção
                                        </Button>
                                    ]}>
                                    <Tree
                                        showLine
                                        showIcon
                                        defaultExpandAll
                                        switcherIcon={<DownOutlined />}
                                        onClick={(_, node) => {
                                            // @ts-ignore
                                            setChatbotId(node.key);
                                            setIsEditModalVisible(true);
                                        }}
                                        treeData={chatbot.filter(o => o.parent_id === null).map(option => createNode(option))}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                    <Show when={isCreateModalVisible}>
                        <CreateChatbotModal />
                    </Show>
                    <Show when={isEditModalVisible}>
                        <EditChatbotModal />
                    </Show>
                </Fragment>
            )}
        </ChatbotContextProvider>
    );
}
